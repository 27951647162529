import { ProjectDetailsProvider } from './state/ProjectDetailsState';
import ProjectDetails from './ProjectDetails';

const ContainProjectDetails = () => {
    return (
        <ProjectDetailsProvider>
            <ProjectDetails />
        </ProjectDetailsProvider>
    );
};

export default ContainProjectDetails;
