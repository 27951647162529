import { fetchWithCorten, fetchWithCortenAndHandleAbort } from '../state/CortenClient';
import fetchFromBackend from '../state/BackendClient';
import { ProductDisplayData } from '../state/AppConfig';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_DISPLAY_FORMAT } from './utcToLocalFormat';

const fetchProducts = async (issuerId?: string, configuredProducts?: ProductDisplayData[]) => {

    try {
        let nextPage = null;
        let combinedData: any[] = [];

        do {
            let url = `/api/pub/queryProducts`;
            let args = [];

            if (issuerId) {
                args.push(`issuerId=${issuerId}`);
            }
            if (configuredProducts) {
                // TODO: Filter on IDs instead when core10 supports it (and remove QUERY_CODE from config)
                args.push(`code=${configuredProducts.map((product: ProductDisplayData) => product.queryCode).join(',')}`);
            }
            if (nextPage) {
                args.push(`page.from=${nextPage}`);
            }
            let argString: string = args.length === 0 ? '' : '?' + args.join('&');            

            let response = await fetchWithCorten(url + argString, { method: 'GET' });

            if (!response.ok) {
                throw new Error("Failed to fetch Products");
            }

            const data = await response.json();

            combinedData.push(...data.list);

            nextPage = data.nextPage;
        } while (nextPage);

        if (configuredProducts) {
            // sort results by configured order
            const configuredProductIds = configuredProducts.map((product: ProductDisplayData) => product.id);
            combinedData.sort((product1, product2) => configuredProductIds.indexOf(product1.productId) - configuredProductIds.indexOf(product2.productId));
        }

        return combinedData;
    }  catch (error) {
        console.log('error', error);
    }
}

/**
 * Fetch product attributes
 * @param productId
 * @returns all product attributes for a given product ID
 */
const fetchProductAttributesWithId = async (productId: string, signal: AbortSignal | undefined = undefined) => {
    try {
        let url = `/api/pub/product/${productId}`;

        let response = await fetchWithCorten(url, { method: 'GET', ...(signal && { signal: signal }) });

        if (!response.ok) {
            throw new Error('Failed to fetch product attributes');
        }

        let productAttributeData = await response.json();

        return productAttributeData;
    } catch (error) {
        console.log('error', error);
    }
};

/**
 * Fetch product Items
 * @param productId - The ID of the product to fetch items for
 * @param attributeFilters - An optional array of filters on attributes to use when looking up product items, to only
 *                           return items with attributes that match the criteria.
 * @param isUnassigned - Whether to return only assigned items (false), unassigned items (true) or all items (undefined). Returns assigned items only by default.
 * @returns all batches of product items for a given product ID, matching attribute filters if specified
 */
const fetchProductItemsWithId = async (productId: string, attributeFilters: any[] | undefined = undefined, isUnassigned: boolean | undefined = false) => {
    // clear next page
    let nextPageProductItems = undefined;
    // clear accumulated product Item batches
    let combinedProductItems = [];

    try {
        do {
            let pageFromQueryString: string = nextPageProductItems
                ? `&page.from=${nextPageProductItems}`
                : '';

            let url = `/api/pub/productitems?productId=${productId}&isUnassigned=${isUnassigned !== undefined ? isUnassigned : 'false'}` +
                (attributeFilters?.filter(attr => attr.value !== ``).map(attr => `&attributes[${attr.code}]=${attr.value}`).join('') ?? ``) +
                `${pageFromQueryString}`;

            let response = await fetchWithCorten(url, { method: 'GET' });

            if (!response.ok) {
                throw new Error('Failed to fetch product items');
            }

            let productItems = await response.json();

            // combine all batches
            combinedProductItems.push(...productItems.list);

            // set next batch page
            nextPageProductItems = productItems.nextPage;
        } while (nextPageProductItems);

        return combinedProductItems;
    } catch (error) {
        console.log('error', error);
        throw error;
    }
};

interface AttributeCriteria {
    code: string,
    value: string,
}

interface HoldingCriteria {
    productId?: string[],
    accountId?: string,
    productItemId?: string,
    sumProductItems?: boolean,
    attributes?: AttributeCriteria[],
    includeProductItemData?: boolean,
}

/**
 * Fetch all product balances
 *
 * @param criteria
 * @param appConfigState
 * @returns list of all balances based on the provided criteria
 */
const fetchBalances = async ({
    criteria,
    appConfigState,
    signal = undefined,
    includeCertificateBased = undefined
}: {
    criteria: HoldingCriteria, 
    appConfigState: any, 
    signal?: AbortSignal | undefined, 
    includeCertificateBased?: boolean
}) => {
    let productId = criteria.productId ?? appConfigState.getProducts(includeCertificateBased).map((data: ProductDisplayData) => data.id);
    let url = `/api/pub/balances?productId=${productId.join(',')}` +
        (criteria.sumProductItems ? `&sumProductItems=${criteria.sumProductItems}` : ``) +
        (criteria.accountId ? `&accountId=${criteria.accountId}` : ``) +
        (criteria.productItemId ? `&productItemId=${criteria.productItemId}` : ``) +
        (criteria.attributes?.filter(attr => attr.value !== ``).map(attr => `&attributes[${attr.code}]=${attr.value}`).join('') ?? ``) +
        (criteria.includeProductItemData ? `&includeProductItemData=true` : ``);

    if (signal !== undefined) {
        return await fetchWithCortenAndHandleAbort(url, {method: 'GET'}, signal);
    } else {
        return (await fetchWithCorten(url, {method: 'GET'})).json();
    }
}

/**
 * Query parameters for "/api/pub/queryBalances" API
 */
interface ProductCriteria {
    productIds: string[],
    axes?: string[],
    accountId?: string,
    attributes?: AttributeCriteria[],
    includeBalances?: boolean,
    isUnassigned?: boolean,
}

/**
 *
 * Fetch product data by attributes using 'group by' api
 *
 * @param criteria see {@link ProductCriteria}
 * @return a list of attribute groups that match provided criteria
 */
const fetchByCriteria = async (criteria: ProductCriteria, signal: AbortSignal | undefined = undefined)=> {
    try {
        let nextPage = null;
        let combinedData: any[] = [];

        do {
            let url = `/api/pub/queryBalances?` +
            `productIds=${criteria.productIds.join()}` +
            `&axes=${criteria.axes?.join() ?? ``}` +
            (criteria.accountId == null ? `` : `&accountId=${criteria.accountId}`) +
            (criteria.attributes?.map(attr => `&attributes%5B${attr.code}%5D=${encodeURIComponent(attr.value)}`).join('') ?? ``) +
            (criteria.includeBalances == null ? `` : `&includeBalances=${criteria.includeBalances}`) +
            `&isUnassigned=${criteria.isUnassigned !== undefined ? criteria.isUnassigned : 'false'}`;

            if (nextPage) {
                url += `&page.from=${nextPage}`;
            }

            let response = await fetchWithCorten(url, { method: 'GET', ...(signal && { signal: signal }) }, false);

            if (!response.ok) {
                throw new Error("Failed to fetch Product Attributes");
            }

            const data = await response.json();

            combinedData.push(...data.list);

            nextPage = data.nextPage;
        } while (nextPage);

        return { list: combinedData };
    }  catch (error) {
        console.log('error', error);
        return { list: [] };
    }
};

/**
 * Fetch holding details by holding ID
 * @param id
 * @returns details for the given holding
 */
const fetchHolding = async (id: string) => {
    let url = `/api/pub/holding/${id}`;
    return (await fetchWithCorten(url, { method: 'GET' })).json();
};
/**
 * Fetch Locked holding/escrow
 * @param params
 * @returns list of holdings based on the given params
 */
const fetchLockedHolding = async (params: string) => {
    const url = `/api/pub/escrow?${params}`;
    return (await fetchWithCorten(url, { method: 'GET' })).json();
};


/**
 * Fetch product item details by product item ID
 * @param id
 * @returns details for the given product item
 */
const fetchProductItem = async (id: string) => {
    let url = `/api/pub/productitem/${id}`;
    return (await fetchWithCorten(url, { method: 'GET' })).json();
};

interface ProductItemOrderingRequest {
    productId: string,
    accountId: string,
    projectTypes?: string[],
    projects?: string[],
    vintages?: string[],
    states?: string[],
    countries?: string[],
}

/**
 * Fetch filtered product items, grouped by project and vintage and enriched with curve price data
 * @param request The product item filters to apply, see {@link ProductItemOrderingRequest}
 * @param user The authenticated user
 */
const fetchOrderedProductItems = async (request: ProductItemOrderingRequest, user: any) => {
    let url = `/api/ordered-project-vintages` +
        `?productId=${request.productId}` +
        `&accountId=${request.accountId}` +
        (request.projectTypes ? `&projectTypes=${request.projectTypes.join(',')}` : ``) +
        (request.projects ? `&projects=${request.projects.join(',')}` : ``) +
        (request.vintages ? `&vintages=${request.vintages.join(',')}` : ``) +
        (request.states ? `&states=${request.states.join(',')}` : ``) +
        (request.countries ? `&countries=${request.countries.join(',')}` : ``);
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetch vintage-project prices
 *
 * @param productId The ID of the product
 * @param endOfDay Optional, "ISO 8601"-formatted EOD date
 * @param user The authenticated user
 */
const fetchCurvePrices = async (productId: string, user: any, endOfDay: string | null) => {
    let url = `/api/curve/prices` +
        `?productId=${productId}` +
        (endOfDay ? `&endOfDay=${endOfDay}` : ``);
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Response object for "prices/projects" API, returned by {@link fetchProjectPrices}
 */
interface ProjectPrices {
    projectPrices: {
        projectId: string,
        price: number,
        vintagePrices: {
            vintage: string,
            price: number
        }[],
    }[],
}

/**
 * Fetch project prices
 *
 * @param productId The ID of the product
 * @param endOfDay Optional, "ISO 8601"-formatted EOD date
 * @param user The authenticated user
 */
const fetchProjectPrices = async (productId: string, user: any, endOfDay: string | null = null) => {
    let url = `/api/curve/prices/projects` +
        `?productId=${productId}` +
        (endOfDay ? `&endOfDay=${endOfDay}` : ``);
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetch all dates for which an EOD curve is configured
 *
 * @param productId The ID of the product
 * @param user The authenticated user
 */
const fetchCurveEndOfDayDates = async (productId: string, user: any) => {
    let url = `/api/curve/endOfDay/list?productId=${productId}`;
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetch the complete curve marking
 *
 * @param productId The ID of the product
 * @param user The authenticated user
 */
const fetchCurveMarking = async (productId: string, user: any /* TODO add endOfDay param */) => {
    let url = `/api/curve?productId=${productId}`;
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Save the complete curve marking
 *
 * @param curveMarking The complete curve marking
 * @param user The authenticated user
 * @param endOfDay Save as EOD curve for today's date
 */
const saveCurveMarking = async (curveMarking: any, user: any, endOfDay: boolean = false) => {
    let url = `/api/curve?productId=${curveMarking.productId}&endOfDay=${endOfDay}&utcOffset=${dayjs().utcOffset()}`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(curveMarking)
    }
    return await fetchFromBackend(url, options, user);
};

/**
 * Fetch the inventory score marking
 *
 * @param productId The ID of the product
 * @param endOfDay  Fetch price at a given date. Leave undefined to get current price
 * @param user      The authenticated user
 * @param abortSignal Used for aborting any ongoing outdated requests
 */
const fetchInventoryScoreMarking = async (
    productId: string,
    endOfDay: Dayjs | undefined,
    user: any,
    abortSignal: AbortSignal
) => {
    let url = `/api/optimiser/base-scores?productId=${productId}`;
    if (endOfDay !== undefined) {
        url += `&endOfDay=${endOfDay.format(DATE_DISPLAY_FORMAT)}`
    }
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user, abortSignal);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Save the inventory score marking
 *
 * @param inventoryScoreMarking The inventory score marking
 * @param endOfDay Save as EOD snapshot for today's date
 * @param user The authenticated user
 */
const saveInventoryScoreMarking = async (inventoryScoreMarking: any, endOfDay: boolean = false, user: any) => {
    let url = `/api/optimiser/base-scores?productId=${inventoryScoreMarking.productId}&endOfDay=${endOfDay}`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(inventoryScoreMarking)
    }
    return await fetchFromBackend(url, options, user);
};

/**
 * Fetch computed inventory scores
 *
 * @param productId The ID of the product
 * @param endOfDay  Fetch computed inventory score at a given date. Leave undefined to get current score
 * @param user      The authenticated user
 * @param abortSignal Used for aborting any ongoing outdated requests
 */
const fetchComputedInventoryScores = async (
    productId: string, 
    endOfDay: Dayjs | undefined, 
    user: any,
    abortSignal: AbortSignal
) => {
    let url = `/api/optimiser/calculated-scores?productId=${productId}`;
    if (endOfDay !== undefined) {
        url += `&endOfDay=${endOfDay.format(DATE_DISPLAY_FORMAT)}`
    }
    try {
        let response = await fetchFromBackend(url, { method: 'GET' }, user, abortSignal);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`);
        return response.json();
    } catch (error) {
        console.error(error);
    }
};

/**
 * Fetch transactions for a specific product item ID
 *
 * @param productItemId The product item ID to fetch transactions for
 */
const fetchTransactionsByProductItem = async (productItemId: string) => {
    let url = `/api/pub/transactions?productItemId=${productItemId}`;
    return (await fetchWithCorten(url, { method: 'GET' })).json();
};

/**
 * Tag projects with the final inventory score, to be used with selection algorithms
 *
 * @param productId The product to use to tag all included projects with inventory scores
 * @param user The authenticated user
 */
const tagProjectsWithInventoryScores = async (productId: string, user: any) => {
    let url = `/api/optimiser/tag-projects?productId=${productId}`;
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return await fetchFromBackend(url, options, user);
};

export {
    fetchProducts,
    fetchProductAttributesWithId,
    fetchProductItemsWithId,
    fetchBalances,
    fetchByCriteria,
    fetchHolding,
    fetchLockedHolding,
    fetchProductItem,
    fetchOrderedProductItems,
    fetchCurvePrices,
    fetchProjectPrices,
    fetchCurveEndOfDayDates,
    fetchCurveMarking,
    saveCurveMarking,
    fetchInventoryScoreMarking,
    saveInventoryScoreMarking,
    fetchComputedInventoryScores,
    fetchTransactionsByProductItem,
    tagProjectsWithInventoryScores,
    type AttributeCriteria,
    type HoldingCriteria,
    type ProductCriteria,
    type ProjectPrices,
};
