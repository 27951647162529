import { HoldingRetirement } from "../../../component/HoldingRetirement";
import { Typography } from "@mui/material";

const RetirementTrade = () => {

    return (
        <>
            <Typography align={'center'} variant='h2'>Retirement Ticket</Typography>
            <HoldingRetirement
                useDialog={false}
                accountId={undefined}
                holdings={[]}
                products={[]}
                retirementDialogActive={true}
                onRetirementDialogClosed={undefined}
                preSelectedProjectId={undefined}
                preSelectedProjectType={undefined}
                preSelectedVintage={undefined}

            />
        </>
    );
}

export  {RetirementTrade}