
import { Typography } from '@mui/material';
import { Trades, TradePageType } from '../../component/Trades'

const TradeBlotter = () => {
    return (
        <>
            <Typography variant='h2'>Trade Blotter</Typography>
            <Trades
                pageType={TradePageType.TradeBlotter}
                columnDefinitions={[
                    {key: "transactionTimestamp", showByDefault: true},
                    {key: "tradeDate", showByDefault: false},
                    {key: "tradeId", showByDefault: false},
                    {key: "transactionId", showByDefault: false},
                    {key: "tradeType", showByDefault: true},
                    {key: "counterParty", showByDefault: true},
                    {key: "productId", showByDefault: true},
                    {key: "registry", showByDefault: false},
                    {key: "projectType", showByDefault: true},
                    {key: "projectId", showByDefault: false},
                    {key: "projectName", showByDefault: false},
                    {key: "vintage", showByDefault: true},
                    {key: "country", showByDefault: true},
                    {key: "state", showByDefault: true},
                    {key: "accreditationCode", showByDefault: false},
                    {key: "fuelSource", showByDefault: false},
                    {key: "creationYear", showByDefault: false},
                    {key: "generationYear", showByDefault: false},
                    {key: "generationState", showByDefault: false},
                    {key: "greenpowerAccredited", showByDefault: false},
                    {key: "quantity", showByDefault: true},
                    {key: "currency", showByDefault: true},
                    {key: "price", showByDefault: true},
                    {key: "trader", showByDefault: false},
                    {key: "salesPerson", showByDefault: false},
                    {key: "salesCredits", showByDefault: false},
                    {key: "brokerName", showByDefault: false},
                    {key: "brokerage", showByDefault: false},
                ]}
            />
        </>
    );
};

export default TradeBlotter;
