import { Box, Typography, LinearProgress, Stack, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppConfigState } from '../../state/AppConfig';
import { useProjectVintageDetailsState } from './state/ProjectVintageDetailsState';
import { useProjectDetailsState, useProjectDetailsDispatch } from './state/ProjectDetailsState';
import { HoldingsSummary } from './HoldingsSummary';
import ProjectAttributes from './ProjectAttributes';
import { Holdings, HoldingQueryType } from '../../component/Holdings';
import { HoldingRetirement, ProductDataRaw } from '../../component/HoldingRetirement';
import { fetchProducts } from '../../utility/Fetch';
import { sectionSpace } from '../../Themes';
import Error from '../../component/Error';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { useProductDataState } from '../../state/ProductDataProvider';

const ProjectVintageDetails = () => {
    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();
    const { selectedIds, projectBalances, selectedProjectDetails, isPathError } = useProjectDetailsState();
    const { setIsPathError } = useProjectDetailsDispatch()
    const { handleVintageDetailsView, loading } = useProjectVintageDetailsState();
    const [currentProduct, setCurrentProduct] = useState<ProductDataRaw[] | undefined>(undefined);
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [holdingRefreshSignal, setHoldingRefreshSignal] = useState<number | any>(0);

    // extract productID, projectID & vintage from url for use in fetch requests for this view
    // URL (/app/inventory-management/product/:productID/project/:projectID/vintages/:year)
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productID = splitLocation[4];
    const projectID = splitLocation[6];
    const vintage = splitLocation[8];

    // entry point for view
    useEffect(() => {
        // clear path error
        setIsPathError(false)

        // if theres a product ID in path, check its valid before making any requests
        if (productID && appConfigState.getProducts().map(p => p.id).includes(productID)) {
            // set global state for productID and projectID
            selectedIds.current = {
                productID: productID,
                projectID: projectID,
                vintage: vintage
            };

            handleVintageDetailsView();

            fetchProducts(
                appConfigState.getAccount('INVENTORY_ISSUER').id,
                appConfigState.getProducts()
            ).then((foundProducts) => {
                if (foundProducts) {
                    const filteredProducts = foundProducts.filter(
                        (item: ProductDataRaw) =>
                        productID === item.productId
                    );
                    setCurrentProduct(filteredProducts);    
                } else {
                    setCurrentProduct([])
                }
            });
        } else {
            // show an error
            setIsPathError(true)
        }
    }, []);

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setRetirementDialogActive(false);
        handleVintageDetailsView();
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setLockToMarketDialogActive(false);
        handleVintageDetailsView();
    }

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    return (
        <>
            <Typography variant="h2">
                Project Vintage Details
            </Typography>

            {
                isPathError ?
                    <Error message='Error - Product ID not recognised' />
                :
                <>
                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <>

                            {/* HOLDINGS *********************/}
                            {projectBalances && Object.keys(projectBalances).length > 0 ? (
                                <HoldingsSummary
                                    issuerAmount={projectBalances?.issuerAmount}
                                    assignedAmount={projectBalances?.assignedAmount}
                                    unassignedAmount={projectBalances?.unassignedAmount}
                                    escrowAmount={projectBalances?.escrowAmount}
                                    title="Project Vintage Holding Summary"
                                    minDecimalPos={productData.get(productID)?.minDecimalPos!!}
                                    maxDecimalPos={productData.get(productID)?.maxDecimalPos!!}
                                />
                            ) : (
                                <Box sx={{ marginBottom: sectionSpace }}>
                                    <Typography variant='h3'>
                                        Project Vintage Holding Summary
                                    </Typography>
                                    <Typography>No holding summary to display</Typography>
                                </Box>
                            )}

                            {/* PROJECT ATTRIBUTES *********************/}
                            <ProjectAttributes
                                title='Project Vintage Summary'
                                showVintage={true}
                                vintage={vintage}

                            />
                            {/* VINTAGE HOLDINGS *********************/}
                                    <Box sx={{ marginBottom: sectionSpace }}>
                                        <Typography variant='h3'>
                                            Vintage Holdings
                                        </Typography>

                                        <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                                            <Button variant='outlined' onClick={openRetirementDialog} disabled={currentProduct === undefined}>Retire</Button>
                                            {retirementDialogActive && (
                                                <HoldingRetirement
                                                    useDialog={true}
                                                    accountId={appConfigState.getAccount('INVENTORY_ISSUER').id}
                                                    holdings={selectedHoldings}
                                                    products={currentProduct}
                                                    preSelectedProjectId={projectID}
                                                    preSelectedProjectType={selectedProjectDetails?.type}
                                                    preSelectedVintage={vintage}
                                                    retirementDialogActive={retirementDialogActive}
                                                    onRetirementDialogClosed={onRetirementDialogClosed}
                                                />
                                            )}
                                            <Button disabled={currentProduct === undefined} variant='outlined' onClick={openLockToMarketDialog} >Lock to Market</Button>
                                            {lockToMarketDialogActive && (
                                                <HoldingLockToMarket
                                                    lockToMarketDialogActive={lockToMarketDialogActive}
                                                    products={currentProduct}
                                                    preSelectedProjectId={projectID}
                                                    preSelectedProjectType={selectedProjectDetails?.type}
                                                    preSelectedVintage={vintage}
                                                    onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                                                />
                                            )}
                                        </Stack>
                                        <Holdings
                                            queryType={HoldingQueryType.PRODUCT_PROJECT_VINTAGE}
                                            columnDefinitions={[
                                                {key: 'selectbox', showByDefault: true},
                                                {key: 'holdingId', showByDefault: true},
                                                {key: 'amount', showByDefault: true},
                                                {key: 'vintage', showByDefault: true},
                                                {key: 'serialNumRange', showByDefault: true},
                                                {key: 'accountId', showByDefault: true},
                                                {key: 'state', showByDefault: true},
                                                {key: 'info', showByDefault: true}
                                            ]}
                                            // Filters:
                                            product={productID}
                                            project={projectID}
                                            vintage={vintage}
                                            // Other:
                                            selectedHoldings={selectedHoldings}
                                            onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                                            refreshSignal={holdingRefreshSignal}
                                        />
                                    </Box>
                        </>
                    )}
                </>
            }
        </>
    );
};

export default ProjectVintageDetails;
