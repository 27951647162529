import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Option, Options, ResettableFormFields, useProductItemFilters } from '../../../state/ProductItemFilters';
import { useTransactionMonitor } from '../../../state/transaction/TransactionMonitor';
import { TransactionOverview } from '../../../component/TransactionOverview';
import { AlertDialogContent, AlertType } from '../../../component/AlertDialogContent';
import { useProductDataState } from '../../../state/ProductDataProvider';
import { AmountFormatWrapper } from '../../../utility/AmountFormatWrapper';
import {
    getOrderedProjectsAndAmounts,
    renderOrderedProjectVintageAmounts,
    TradeOptimiser,
    TradeOptimiserAmountType
} from '../../../utility/TradeOptimiserUtil';
import { TransactionType } from '../../../state/Variables';
import { ControlledTextField } from '../../../view/trade/TradeUtil';
import { renderDialogField } from '../../../utility/DialogFieldRenderUtil';
import { renderEntry } from '../../../utility/HoldingUtil';
import { ProductBase, useAppConfigState } from '../../../state/AppConfig';
import { useAuth } from '../../../state/AuthProvider';
import { executeTransaction } from '../../../state/CortenClient';
import { DATE_DISPLAY_FORMAT } from "../../../utility/utcToLocalFormat";
import dayjs from 'dayjs';

interface ForwardDeliverForm {
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    state: string,
    country: string,
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenpowerAccredited: string,
    quantity: number,
    tradeOptimiser: TradeOptimiser
}

const defaultValues: ForwardDeliverForm = { 
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    state: '',
    country: '',
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenpowerAccredited: '',
    quantity: 0,
    tradeOptimiser: TradeOptimiser.DEFAULT
};

const ForwardTradeDeliverDialog = ({
    dialogActive,
    forwardTradeRow,
    onActionTaken,
    onClose
}: {
    dialogActive: boolean;
    forwardTradeRow: any;
    onActionTaken: () => void;
    onClose: () => void;
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm<ForwardDeliverForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({
        name: ['product'],
        control
    });

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        stateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenpowerAccreditedOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({includeCertificateBasedProduct: true});

    const { productData } = useProductDataState();
    const appConfigState = useAppConfigState();
    const user = useAuth();

    const [fixedFields, setFixedFields] = useState<string[]>([]);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);
    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [balanceError, setBalanceError] = useState<string>();
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const productItemIds = useRef<Promise<string[]>>();

    useEffect(() => {
        if (dialogActive) {
            let preSelectedData = getValues();

            preSelectedData.product = forwardTradeRow.productId;
            preSelectedData.projectType = forwardTradeRow.projectType ?? "";
            preSelectedData.project = forwardTradeRow.projectId ?? "";
            preSelectedData.vintage = forwardTradeRow.vintage?.toString() ?? "";
            preSelectedData.state = forwardTradeRow.state ?? "";
            preSelectedData.country = forwardTradeRow.country ?? "";
            preSelectedData.fuelSource = forwardTradeRow.fuelSource ?? "";
            preSelectedData.creationYear = forwardTradeRow.creationYear?.toString() ?? "";
            preSelectedData.generationYear = forwardTradeRow.generationYear?.toString() ?? "";
            preSelectedData.generationState = forwardTradeRow.generationState ?? "";
            preSelectedData.greenpowerAccredited = forwardTradeRow.greenpowerAccredited?.toString() ?? "";

            preSelectedData.quantity = forwardTradeRow.quantity;

            // These are fields enforced by the original Forward Trade that cannot be modified when carrying out the deliver trade
            setFixedFields(Object.keys(preSelectedData).filter(k => preSelectedData[`${k}` as keyof ForwardDeliverForm] != ''));

            preSelectedData.tradeOptimiser = TradeOptimiser.DEFAULT;

            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, {...preSelectedData, account: appConfigState.getAccount('INVENTORY_ISSUER').id});
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [dialogActive]);

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product) {
            error = 'Please select a Product';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]);

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
            onActionTaken();
        },
        (tx) => {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when delivering units to the forward trade.');
            setTransactionErrorCode(tx.errorCode);
        },
        () => {
            setIsTransactionInProgress(false);
            setTransactionWarnMessage('The transaction timed out. Please check back later to confirm whether the transaction was processed.');
            onActionTaken();
        }
    );

    const handleDialogClose = () => {
        unsubscribe();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        // update state in the parent view
        onClose();
    }

    const performTransaction = async() => {
        setIsTransactionInProgress(true);
        setTransactionInReview(false);

        const getCountryCode = (): string => {
            // ACCUs will always be AU
            return appConfigState.getProduct(forwardTradeRow.productId)?.displayCode === 'ACCU' ? 'AU' : getValues().country
        }

        const getProjectName = (): string | undefined => {
            return getValues().project !== "" ? projectAmounts![0].projectName : "";
        }

        const assignHoldingRequest = {
            type: 'AssignHoldingRequest',
            issuerId: appConfigState.getAccount('INVENTORY_ISSUER').id,
            holdingIds: [forwardTradeRow.forwardId],
            productItems: await productItemIds.current,
            deflationMode: 'DeflateAndDelete',
            // Using the default inflationMode of 'DontInflate',
            attributes: {
                ...forwardTradeRow.tradeId && { [`${appConfigState.getAttribute('TRADE', 'ID').key}`]: forwardTradeRow.tradeId },
                [`${appConfigState.getAttribute('TRADE', 'TIMESTAMP').key}`]: forwardTradeRow.transactionTimestamp,
                [`${appConfigState.getAttribute('TRADE', 'TRADER_NAME').key}`]: forwardTradeRow.trader,
                [`${appConfigState.getAttribute('TRADE', 'PRICE').key}`]: forwardTradeRow.price,
                [`${appConfigState.getAttribute('TRADE', 'CURRENCY').key}`]: forwardTradeRow.currency,
                [`${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`]: forwardTradeRow.valueDate,
                ...forwardTradeRow.salesPerson && { [`${appConfigState.getAttribute('TRADE', 'SALES_PERSON').key}`]: forwardTradeRow.salesPerson },
                ...forwardTradeRow.salesCredits && { [`${appConfigState.getAttribute('TRADE', 'SALES_CREDITS').key}`]: forwardTradeRow.salesCredits },
                ...forwardTradeRow.brokerName && { [`${appConfigState.getAttribute('TRADE', 'BROKER_NAME').key}`]: forwardTradeRow.brokerName },
                ...forwardTradeRow.brokerage && { [`${appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').key}`]: forwardTradeRow.brokerage },
                [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key}`]: TransactionType.ForwardSellDeliver,
                [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key}`]: TransactionType.ForwardBuyDeliver
            }
        };

        executeTransaction(assignHoldingRequest, user).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(r => {
                    pendingTransaction.current = r.txId;
                    subscribe(r.txId);
                });
            } else {
                console.error(response);
                setIsTransactionInProgress(false);
                setTransactionErrorMessage("Could not complete the transaction - the request was rejected")
            }
        }).catch(console.error);
    };

    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(resetField, {...getValues(), account: appConfigState.getAccount('INVENTORY_ISSUER').id}, field);
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        getOrderedProjectsAndAmounts(
            getValues(),
            appConfigState.getAccount('INVENTORY_ISSUER').id,
            setProjectAmounts,
            productItemIds,
            user,
            appConfigState
        );
    };

    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };

    const getTradeOptimiserOptions = () => {
        let values = [
            new Option(TradeOptimiser.DEFAULT, TradeOptimiser.DEFAULT),
            // TODO (https://gitlab.com/trovio/tech/product/commodity-desk/-/issues/537) remove the condition on disabling this option
            new Option(TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.CHEAPEST_FIRST, undefined, appConfigState.getProduct(forwardTradeRow.productId)?.displayCode === 'LGC'),
            new Option(TradeOptimiser.INVENTORY_SCORE_BASED, TradeOptimiser.INVENTORY_SCORE_BASED, undefined, appConfigState.getProduct(forwardTradeRow.productId)?.displayCode === 'LGC')
        ];
        return new Options(values);
    }

    return (
        <Dialog open={dialogActive} onClose={onClose} fullWidth maxWidth="xs">

            {!transactionInReview && !transactionSuccess && !isTransactionInProgress && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Deliver Units
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Deliver units to Forward:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.forwardId}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Counterparty:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.counterParty}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2rem' }}>
                            <Typography>
                                Value Date:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{dayjs(forwardTradeRow.valueDate).format(DATE_DISPLAY_FORMAT)}</Typography>
                            </Box>
                        </Box>

                        {
                            renderEntry(
                                'Product:',
                                (
                                    <ControlledTextField name='product' label='Product'
                                        options={productOptions}
                                        customOnChange={() => onOptionSelect('product')}
                                        rules={{ required: 'Product is required' }}
                                        control={control} errors={errors} reset={resetField}
                                        disabled={fixedFields.includes('product')}
                                        />
                                ),
                                true, true
                            )
                        }

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Project &&
                            renderEntry('Project Type:', (
                                <ControlledTextField name='projectType' label='Project Type'
                                    options={projectTypeOptions}
                                    customOnChange={() => onOptionSelect('projectType')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('projectType')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                    />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Project && 
                            renderEntry('Project:', (
                                <ControlledTextField name='project' label='Project'
                                    options={projectOptions}
                                    customOnChange={() => onOptionSelect('project')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('project')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                    />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Project &&
                            renderEntry('Vintage:', (
                                <ControlledTextField name='vintage' label='Vintage'
                                    options={vintageOptions}
                                    customOnChange={() => onOptionSelect('vintage')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('vintage')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                    />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.displayCode === 'ACCU' && 
                            renderEntry('State:', (
                                <ControlledTextField name='state' label='State'
                                    options={stateOptions}
                                    customOnChange={() => onOptionSelect('state')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('state')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.displayCode === 'VCU' && 
                            renderEntry('Country:', (
                                <ControlledTextField name='country' label='Country'
                                    options={countryOptions}
                                    customOnChange={() => onOptionSelect('country')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('country')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Certificate &&
                            renderEntry('Fuel Source:', (
                                <ControlledTextField name='fuelSource' label='Fuel Source'
                                    options={fuelSourceOptions}
                                    customOnChange={() => onOptionSelect('fuelSource')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('fuelSource')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos} 
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Certificate &&
                            renderEntry('Creation Year:', (
                                <ControlledTextField name='creationYear' label='Creation Year'
                                    options={creationYearOptions}
                                    customOnChange={() => onOptionSelect('creationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('creationYear')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos} 
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Certificate &&
                            renderEntry('Generation Year:', (
                                <ControlledTextField name='generationYear' label='Generation Year'
                                    options={generationYearOptions}
                                    customOnChange={() => onOptionSelect('generationYear')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('generationYear')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos} 
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Certificate &&
                            renderEntry('Generation State:', (
                                <ControlledTextField name='generationState' label='Generation State'
                                    options={generationStateOptions}
                                    customOnChange={() => onOptionSelect('generationState')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('generationState')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos} 
                                />
                            ), true, true)}

                        {appConfigState.getProduct(forwardTradeRow.productId)?.productBase === ProductBase.Certificate &&
                            renderEntry('GreenPower Accredited:', (
                                <ControlledTextField name='greenpowerAccredited' label='GreenPower Accredited'
                                    options={greenpowerAccreditedOptions}
                                    customOnChange={() => onOptionSelect('greenpowerAccredited')}
                                    control={control} errors={errors} reset={resetField}
                                    disabled={fixedFields.includes('greenpowerAccredited')}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos} 
                                />
                            ), true, true)}

                        {renderEntry('Quantity:', (
                            <>
                                <ControlledTextField name='quantity' label='Quantity' integer
                                    control={control} errors={errors}
                                    disabled={true} />
                                {!balanceError && availableBalance == null
                                    ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                    : (<Typography variant='caption'
                                        color={balanceError ? 'error' : 'textSecondary'}
                                        mt={1} mb={1}
                                        sx={{ float: 'right' }}>
                                        {balanceError
                                            ? <div>{balanceError}</div>
                                            : <div>Available Balance: <AmountFormatWrapper
                                                amount={availableBalance}
                                                minDecimalPos={productData.get(getValues().product)?.minDecimalPos!}
                                                maxDecimalPos={productData.get(getValues().product)?.maxDecimalPos!}
                                            /></div>}
                                    </Typography>)}
                            </>
                        ), true, true)}
                        {(renderEntry('Trade Optimiser:', (
                            <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                                options={getTradeOptimiserOptions()}
                                rules={{ required: 'Trade optimiser is required' }}
                                control={control} errors={errors} reset={resetField} />
                        ), true, true))}
                    </DialogContent>

                    <DialogActions>
                        {
                            availableBalance !== null &&
                            availableBalance < forwardTradeRow.quantity && (
                                <Typography sx={{ color: 'red', marginRight: 2 }}>
                                    Insufficient Balance
                                </Typography>
                            )
                        }

                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => openReviewDialog()}
                            color="primary"
                            variant="outlined"
                            disabled={
                                !isValid ||
                                !availableBalance ||
                                availableBalance < forwardTradeRow.quantity
                            }
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {(transactionInReview || isTransactionInProgress) && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>Review Details Below</Typography>
                        {getValues().product && renderDialogField('Product', appConfigState.getProduct(forwardTradeRow.productId)?.displayCode)}
                        {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}
                        {getValues().project && renderDialogField(
                            'Project',
                            projectOptions?.values.find(opt => opt.id === getValues().project)?.label)
                        }
                        {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                        {getValues().state && renderDialogField('State', getValues().state)}
                        {getValues().country && renderDialogField('Country', getValues().country)}
                        {getValues().fuelSource && renderDialogField('Fuel Source', getValues().fuelSource)}
                        {getValues().generationYear && renderDialogField('Generation Year', getValues().generationYear)}
                        {getValues().creationYear && renderDialogField('Creation Year', getValues().creationYear)}
                        {getValues().generationState && renderDialogField('Generation State', getValues().generationState)}
                        {getValues().greenpowerAccredited && renderDialogField('Greenpower Accredited', getValues().greenpowerAccredited)}
                        {renderDialogField('Quantity', getValues().quantity, {
                            minDecimalPos: productData.get(getValues().product)?.minDecimalPos!,
                            maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos!,
                        })}
                        {getValues().tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Price)}
                        {getValues().tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Score)}
                        {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                        <Button 
                            onClick={performTransaction} 
                            color='primary' 
                            variant='outlined'
                            disabled={
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && 
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity)
                            }
                        >
                            {isTransactionInProgress ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '2px',
                                }}
                                >
                                    <CircularProgress size={20} />
                                </div>
                            ) : ('Confirm')}
                        </Button>
                    </DialogActions>
                </>
            )}
            { transactionSuccess && (<TransactionOverview
                open={transactionSuccess}
                onClose={handleDialogClose}
                title='Transaction Submitted successfully'
                attributes={{
                    ...(pendingTransaction.current !== null) && {
                        'Transaction ID': {
                            value: pendingTransaction.current,
                            shorterFormId: true,
                            key: 'transactionId'
                        }
                    },
                    'Product': { value: getValues().product, key: 'productId' },
                    ...getValues().projectType && {'Project Type': { value: getValues().projectType, key: 'projectType' }},
                    ...getValues().product && {'Project': {
                        value: projectOptions?.values.find(opt => opt.id === getValues().project)?.label,
                        key: 'projectName'
                    }},
                    ...getValues().vintage && {'Vintage': { value: getValues().vintage, key: 'vintage' }},
                    ...getValues().state && {'State': { value: getValues().state, key: 'state' }},
                    ...getValues().country && {'Country': { value: getValues().country, key: 'country' }},
                    ...getValues().fuelSource && {'Fuel Source': { value: getValues().fuelSource, key: 'fuelSource' }},
                    ...getValues().generationYear && {'Generation Year': { value: getValues().generationYear, key: 'generationYear' }},
                    ...getValues().creationYear && {'Creation Year': { value: getValues().creationYear, key: 'creationYear' }},
                    ...getValues().generationState && {'Generation State': { value: getValues().generationState, key: 'generationState' }},
                    ...getValues().greenpowerAccredited && {'Greenpower Accredited': { value: getValues().greenpowerAccredited, key: 'greenpowerAccredited' }},
                    'Quantity': {
                        value: getValues().quantity.toString(),
                        renderProp: { 
                            minDecimalPos: productData.get(getValues().product)?.minDecimalPos!, 
                            maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos!
                        },
                        key: 'quantity'
                    }
                }}
            />)}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onClose}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onClose}
                />
            )}
        </Dialog>
    );
};

export { ForwardTradeDeliverDialog };
