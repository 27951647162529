import { useEffect, useRef, useState } from 'react';
import { fetchBalances, fetchByCriteria } from '../utility/Fetch';
import { useAppConfigState } from './AppConfig';
import { wrapFilterValueWithQuotes } from '../utility/CoreFilterUtil';

class Options {
    values: Option[];
    disabled: boolean;

    constructor(values: Option[] = [], disabled: boolean = false) {
        this.values = values;
        this.disabled = disabled;
    }

    getTotalBalance = () => {
        return this.values.every(option => option.balance != null)
            ? this.values.reduce((sum: number, option: Option) => sum + +option.balance!, 0)
            : null;
    };
}

class Option {
    id: string;
    label: string;
    balance: number | null;
    disabled: boolean;

    constructor(id: string, label: string, balance: number | null = null, disabled: boolean|null = null) {
        this.id = id;
        this.label = label;
        this.balance = balance;
        this.disabled = disabled ?? (balance != null && balance <= 0);
    }
}

interface ResettableFormFields {
    product?: string,
    projectType?: string,
    project?: string,
    vintage?: string,
    state?: string,
    country?: string,
    fuelSource?: string,
    generationYear?: string,
    creationYear?: string,
    generationState?: string,
    greenpowerAccredited?: string
}

interface ProductItemForm extends ResettableFormFields {
    account?: string,
}

interface ProductItemFilterOptions {
    productOptions: Options,
    projectTypeOptions: Options,
    projectOptions: Options,
    vintageOptions: Options,
    stateOptions: Options,
    countryOptions: Options,
    fuelSourceOptions: Options,
    generationYearOptions: Options,
    creationYearOptions: Options,
    generationStateOptions: Options,
    greenpowerAccreditedOptions: Options
}

class ProductItemFilter {
    private readonly key: keyof ResettableFormFields | undefined;
    private readonly loadOptions: (form: ProductItemForm) => Promise<Option[]>;
    private readonly setOptionsState: (value: ((prevState: Options) => Options)) => void;
    private readonly subscribers: ProductItemFilter[] = [];

    private currentOptions: Options = new Options();

    constructor(
        key: keyof ResettableFormFields | undefined,
        loadOptions: (form: ProductItemForm) => Promise<Option[]>,
        setOptionsState: (value: ((prevState: Options) => Options)) => void,
    ) {
        this.key = key;
        this.loadOptions = loadOptions;
        this.setOptionsState = setOptionsState;
    }

    /**
     * Subscribe to changes in the specified filters.
     *
     * @param filters product item filters to subscribe to
     */
    public subscribeTo = (filters: ProductItemFilter[]) => {
        filters.forEach(filter => filter.subscribers.push(this));
    };

    /**
     * This function will notify all subscribers (i.e. dependants) of this filter, prompting
     * them to reload their options values and associated balances from Corten. For any field
     * that gets reset as a result - its subscribers are updated as well.
     *
     * @param form the current state of the product item filters form
     * @param resetCallback the function to be called if the selected option is no longer available
     */
    public onChange = async (form: ProductItemForm, resetCallback: (fieldName: keyof ResettableFormFields) => void) => {
        this.setOptionsState(prevState => new Options(prevState.values, true));
        let subscribersToNotify = new Set(this.subscribers);
        let touchedSubscribers = new Set<ProductItemFilter>();
        while (subscribersToNotify.size > 0) {
            // reload options for all subscribers
            let promises = Array.from(subscribersToNotify).map(sub => {
                subscribersToNotify.delete(sub);
                touchedSubscribers.add(sub);
                return sub.reloadOptions(form, resetCallback);
            });
            // if any fields were reset we need to update their subscribers as well
            let moreSubscribers = await Promise.all(promises);
            moreSubscribers.flat(1).forEach(sub => subscribersToNotify.add(sub));
        }
        // set state for all changed fields simultaneously
        touchedSubscribers.forEach(sub => sub.setOptionsState(p => sub.currentOptions));
        this.setOptionsState(prevState => new Options(prevState.values, false));
    };

    /**
     * Get total available balance for this filter, if available.
     * - filter disabled: undefined
     * - an option is selected: its balance
     * - else: sum of all options' balances
     *
     * @param form the current state of the product item filters form
     * @returns balance the total available balance based on this filter's state
     */
    public getAvailableBalance = (form: ProductItemForm): number | undefined => {
        return this.currentOptions.disabled
            ? undefined
            : this.getSelectedOption(form)?.balance! ?? this.currentOptions.getTotalBalance();
    };

    /**
     * Re-fetch options for this filter using {@link loadOptions} callback and the current form state.
     * If the currently selected option is no longer available, use {@link resetField} callback to
     * clear the filter.
     *
     * @param form the current state of the product item filters form
     * @param resetCallback the function to be called if the selected option is no longer available
     * @returns subscribers list of subscribers to notify if this filter was reset as a result of
     *          option update, ar else an empty list
     */
    private reloadOptions = async (
        form: ProductItemForm,
        resetCallback: (fieldName: keyof ResettableFormFields) => void
    ): Promise<ProductItemFilter[]> => {
        // disable the field while we load the new options
        this.setOptionsState(prevState => new Options(prevState.values, true));
        // we let the caller update the option state so that all updated fields are re-enabled together
        this.currentOptions = await this.loadOptions(form).then(result => new Options(result));
        let currentOption = this.getSelectedOption(form);
        // when the selected option is no longer available - reset the field
        if (!currentOption && form[this.key!]) {
            resetCallback(this.key!);
            form[this.key!] = undefined;
            return this.subscribers;
        }
        return [];
    };

    /**
     * Use form state to find the currently selected {@link Option} object from the list
     * of {@link currentOptions}.
     *
     * @param form the current state of the product item filters form
     * @returns option currently selected {@link Option} object, or undefined
     */
    private getSelectedOption = (form: ProductItemForm) => {
        return this.currentOptions.values.find(opt => opt.id === form[this.key!] && !opt.disabled);
    };
}

/**
 * Custom hook providing dynamic option values for product item filter fields: Product,
 * Project Type, Project and Vintage, as well as the total available balance based on current
 * filter values.
 *
 * Plug the state into your form and call {@link onFilterChange} whenever any values from
 * {@link ProductItemForm} are updated.
 *
 * Use {@link resetProductFilters} to reset the option values and force reload.
 */
const useProductItemFilters = ({
    includeCertificateBasedProduct
}: {
    includeCertificateBasedProduct?: boolean
}) => {
    const [productOptions, setProductOptions] = useState(new Options([]));
    const [projectTypeOptions, setProjectTypeOptions] = useState(new Options([]));
    const [projectOptions, setProjectOptions] = useState(new Options([]));
    const [vintageOptions, setVintageOptions] = useState(new Options([]));
    const [stateOptions, setStateOptions] = useState(new Options([]));
    const [countryOptions, setCountryOptions] = useState(new Options([]));
    const [fuelSourceOptions, setFuelSourceOptions] = useState(new Options([]));
    const [generationYearOptions, setGenerationYearOptions] = useState(new Options([]));
    const [creationYearOptions, setCreationYearOptions] = useState(new Options([]));
    const [generationStateOptions, setGenerationStateOptions] = useState(new Options([]));
    const [greenpowerAccreditedOptions, setGreenpowerAccreditedOptions] = useState(new Options([]));
    const [availableBalance, setAvailableBalance] = useState<number | null>(0);

    const allFiltersRef = useRef(new Map<keyof ProductItemForm, ProductItemFilter>());
    const formFilterRef = useRef<ProductItemFilter>();

    const appConfigState = useAppConfigState();
    const projectTypeAttribute = appConfigState.getAttribute('PROJECT', 'TYPE').key;
    const projectIdAttribute = appConfigState.getAttribute('PROJECT', 'ID').key;
    const projectNameAttribute = appConfigState.getAttribute('PROJECT', 'NAME').key;
    const projectVintageAttribute = appConfigState.getAttribute('PROJECT', 'VINTAGE').key;
    const projectStateAttribute = appConfigState.getAttribute('PROJECT', 'STATE').key;
    const projectCountryAttribute = appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key;
    const projectFuelSourceAttribute = appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key;
    const projectGenerationYearAttribute = appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key;
    const projectCreationYearAttribute = appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key;
    const projectGenerationStateAttribute = appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key;
    const projectGreenpowerAccreditedAttribute = appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key;

    useEffect(() => {
        let root = buildFilter(); // root form filter
        let product = buildFilter('product', setProductOptions, fetchProductsFromBalances);
        let projectType = buildFilter('projectType', setProjectTypeOptions, fetchProjectTypes);
        let project = buildFilter('project', setProjectOptions, fetchProjects);
        let vintage = buildFilter('vintage', setVintageOptions, fetchVintages);
        let state = buildFilter('state', setStateOptions, fetchStates);
        let country = buildFilter('country', setCountryOptions, fetchCountries);
        let fuelSource = buildFilter('fuelSource', setFuelSourceOptions, fetchFuelSources);
        let generationYear = buildFilter('generationYear', setGenerationYearOptions, fetchGenerationYears)
        let creationYear = buildFilter('creationYear', setCreationYearOptions, fetchCreationYears)
        let generationState = buildFilter('generationState', setGenerationStateOptions, fetchGenerationState)
        let greenpowerAccredited = buildFilter('greenpowerAccredited', setGreenpowerAccreditedOptions, fetchGreenpowerAccredited)

        product.subscribeTo([root]);
        projectType.subscribeTo([root, product, project, vintage, state, country, fuelSource, generationYear, generationState, greenpowerAccredited, creationYear]);
        project.subscribeTo([root, product, projectType, vintage, state, country, fuelSource, generationYear, generationState, greenpowerAccredited, creationYear]);
        vintage.subscribeTo([root, product, projectType, project, state, country, fuelSource, generationYear, generationState, greenpowerAccredited, creationYear]);
        state.subscribeTo([root, product, projectType, project, vintage, country, fuelSource, generationYear, generationState, greenpowerAccredited, creationYear]);
        country.subscribeTo([root, product, projectType, project, vintage, state, fuelSource, generationYear, generationState, greenpowerAccredited, creationYear]);
        fuelSource.subscribeTo([root, product, projectType, project, vintage, state, country, generationYear, generationState, greenpowerAccredited, creationYear]);
        creationYear.subscribeTo([root, product, projectType, project, vintage, state, country, fuelSource, generationState, greenpowerAccredited, generationYear]);
        generationYear.subscribeTo([root, product, projectType, project, vintage, state, country, fuelSource, generationState, greenpowerAccredited, creationYear]);
        generationState.subscribeTo([root, product, projectType, project, vintage, state, country, fuelSource, generationYear, greenpowerAccredited, creationYear]);
        greenpowerAccredited.subscribeTo([root, product, projectType, project, vintage, state, country, fuelSource, generationYear, generationState, creationYear]);
    }, []);

    const resetProductFilters = (
        resetCallback: (fieldName: keyof ResettableFormFields) => void,
        initialValues: ProductItemForm = {},
        initialOptions: ProductItemFilterOptions | undefined = undefined,
    ) => {
        // set placeholder options while we load the current values
        if (initialOptions) {
            setProductOptions(initialOptions.productOptions);
            setProjectTypeOptions(initialOptions.projectTypeOptions);
            setProjectOptions(initialOptions.projectOptions);
            setVintageOptions(initialOptions.vintageOptions);
            setStateOptions(initialOptions.stateOptions);
            setCountryOptions(initialOptions.countryOptions);
            setFuelSourceOptions(initialOptions.fuelSourceOptions);
            setCreationYearOptions(initialOptions.creationYearOptions);
            setGenerationYearOptions(initialOptions.generationYearOptions);
            setGenerationStateOptions(initialOptions.generationStateOptions);
            setGreenpowerAccreditedOptions(initialOptions.greenpowerAccreditedOptions);
        }
        // trigger option and balances load
        onFilterChange(resetCallback, initialValues);
    };

    // update options and available balance based on the selected filters
    const onFilterChange = (
        resetCallback: (fieldName: keyof ResettableFormFields) => void,
        form: ProductItemForm,
        fieldName: keyof ProductItemForm | undefined = undefined,
    ) => {
        setAvailableBalance(null);
        let fieldFilter = fieldName ? allFiltersRef.current.get(fieldName) : undefined;
        let changedFilter = fieldFilter ?? formFilterRef.current!;
        changedFilter.onChange(form, resetCallback).then(() => {
            // set the smallest filter balance as available
            let balances = Array.from(allFiltersRef.current.values())
                .map(filter => filter.getAvailableBalance(form))
                .filter(Boolean) as number[];
            setAvailableBalance(balances.length > 0 ? Math.min(...balances) : 0);
        });
    };

    const fetchProjectTypes = (form: ProductItemForm) => fetchAttributeOptions(form, projectTypeAttribute);
    const fetchProjects = (form: ProductItemForm) => fetchAttributeOptions(form, projectIdAttribute, projectNameAttribute);
    const fetchVintages = (form: ProductItemForm) => fetchAttributeOptions(form, projectVintageAttribute);
    const fetchStates = (form: ProductItemForm) => fetchAttributeOptions(form, projectStateAttribute);
    const fetchCountries = (form: ProductItemForm) => fetchAttributeOptions(form, projectCountryAttribute);
    const fetchFuelSources = (form: ProductItemForm) => fetchAttributeOptions(form, projectFuelSourceAttribute);
    const fetchCreationYears = (form: ProductItemForm) => fetchAttributeOptions(form, projectCreationYearAttribute);
    const fetchGenerationYears = (form: ProductItemForm) => fetchAttributeOptions(form, projectGenerationYearAttribute);    
    const fetchGenerationState = (form: ProductItemForm) => fetchAttributeOptions(form, projectGenerationStateAttribute);
    const fetchGreenpowerAccredited = (form: ProductItemForm) => fetchAttributeOptions(form, projectGreenpowerAccreditedAttribute);

    const fetchProductsFromBalances = async (form: ProductItemForm): Promise<Option[]> => {
        let allProductsBalances: any[] = [];
        if (form.account) {
            let criteria = {
                sumProductItems: true,
                accountId: form.account
            };
            allProductsBalances = await fetchBalances({
                criteria: criteria, 
                appConfigState: appConfigState, 
                includeCertificateBased: includeCertificateBasedProduct
            });
            // add products with zero balance as they are not included into the API response
            appConfigState.getProducts(includeCertificateBasedProduct).map(product => product.id)
                .filter(id => !allProductsBalances.find(balance => balance['productId'] === id))
                .forEach(id => allProductsBalances.push({ issuerAmount: 0, assignedAmount: 0, productId: id}))
        }
        return appConfigState.getProducts(includeCertificateBasedProduct).map(product => {
            let balances = allProductsBalances.find(balance => balance['productId'] === product.id);
            let balance = balances ? resolveAvailableBalance(balances, form) : null;
            return new Option(product.id, product.displayCode, balance);
        });
    };

    // fetch options and balance via attribute api
    const fetchAttributeOptions = async (
        form: ProductItemForm,
        idAttribute: string,
        labelAttribute: string = idAttribute,
    ) => {
        // we cannot fetch valid option if no product is selected
        if (!form.product) return [];
        let criteria = {
            productIds: [form.product], // we expect a product to be selected
            axes: Array.from(new Set([idAttribute, labelAttribute])),
            attributes: buildAttributeFilters(form, idAttribute),
            includeBalances: true,
        };

        let issuerOptionsPromise = fetchByCriteria(criteria);

        // if this is a BUY, resolve balances separately in order to obtain 0-balance options too
        let balanceSource = undefined;
        if (form.account === appConfigState.getAccount('INVENTORY_ISSUER').id) {
            balanceSource = issuerOptionsPromise;
        } else if (form.account) {
            balanceSource = fetchByCriteria({ ...criteria, accountId: form.account });
        }
        let balancesPromise = balanceSource?.then(data => {
            let balanceMap = new Map<string, number>();
            for (let item of data.list) {
                let id = String(item['attributes'][idAttribute]);
                let balance = resolveAvailableBalance(item['balances'], form);
                balanceMap.set(id, balance);
            }
            return balanceMap;
        }) ?? Promise.resolve(undefined);

        // resolve option values and enrich them with balance amounts
        let [issuerOptions, balances] = await Promise.all([issuerOptionsPromise, balancesPromise]);
        return issuerOptions.list.map((item: any) => {
            let id = String(item['attributes'][idAttribute]);
            let label = String(item['attributes'][labelAttribute]);
            let balance = balances ? balances.get(id) ?? 0 : null;
            return new Option(id, label, balance);
        });
    };

    const buildAttributeFilters = (form: ProductItemForm, idAttribute: string) => {
        let filters = [];
        if (form.projectType && idAttribute !== projectTypeAttribute) {
            filters.push({ code: projectTypeAttribute, value: wrapFilterValueWithQuotes(form.projectType)});
        }
        if (form.project && idAttribute !== projectIdAttribute) {
            filters.push({ code: projectIdAttribute, value: wrapFilterValueWithQuotes(form.project) });
        }
        if (form.vintage && idAttribute !== projectVintageAttribute) {
            filters.push({ code: projectVintageAttribute, value: wrapFilterValueWithQuotes(form.vintage)});
        }
        if (form.state && idAttribute !== projectStateAttribute) {
            filters.push({ code: projectStateAttribute, value: wrapFilterValueWithQuotes(form.state)});
        }
        if (form.country && idAttribute !== projectCountryAttribute) {
            filters.push({ code: projectCountryAttribute, value: wrapFilterValueWithQuotes(form.country)});
        }
        if (form.fuelSource && idAttribute !== projectFuelSourceAttribute) {
            filters.push({ code: projectFuelSourceAttribute, value: wrapFilterValueWithQuotes(form.fuelSource)});
        }
        if (form.creationYear && idAttribute !== projectCreationYearAttribute) {
            filters.push({ code: projectCreationYearAttribute, value: wrapFilterValueWithQuotes(form.creationYear)});
        }
        if (form.generationYear && idAttribute !== projectGenerationYearAttribute) {
            filters.push({ code: projectGenerationYearAttribute, value: wrapFilterValueWithQuotes(form.generationYear)});
        }
        if (form.generationState && idAttribute !== projectGenerationStateAttribute) {
            filters.push({ code: projectGenerationStateAttribute, value: wrapFilterValueWithQuotes(form.generationState)})
        }
        if (form.greenpowerAccredited && idAttribute !== projectGreenpowerAccreditedAttribute) {
            filters.push({ code: projectGreenpowerAccreditedAttribute, value: wrapFilterValueWithQuotes(form.greenpowerAccredited)})
        }
        return filters;
    };

    const resolveAvailableBalance = (balances: any, form: ProductItemForm) => {
        if (form.account === appConfigState.getAccount('INVENTORY_ISSUER').id) {
            return balances['issuerAmount'];
        } else {
            return form.account ? balances['assignedAmount'] : null;
        }
    };

    const buildFilter = (
        key?: keyof ResettableFormFields | undefined,
        setOptionsState: (value: (prevState: Options) => Options) => void = () => {},
        loadOptions: (form: ProductItemForm) => Promise<Option[]> = () => Promise.resolve([]),
    ) => {
        let filter = new ProductItemFilter(key, loadOptions, setOptionsState);
        if (key) {
            allFiltersRef.current.set(key, filter);
        } else {
            formFilterRef.current = filter;
        }
        return filter;
    };

    return {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        stateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenpowerAccreditedOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    };
};

export {
    useProductItemFilters,
    Option,
    Options,
    type ProductItemForm,
    type ResettableFormFields,
    type ProductItemFilterOptions,
    type ProductItemFilter,
};
