import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { sectionSpace } from "../Themes";

interface BodyProp {
    heading: string;
    value: string;
}

const ProductItemAttributesTable = ({
    title,
    bodyProps
}: {
    title: string,
    bodyProps: BodyProp[]
}) => {

    const tableRowStyle = { '&:last-child td, &:last-child th': { border: 0 } };

    return (
        <Box sx={{ marginBottom: sectionSpace }}>
            <Typography variant='h3'>{title}</Typography>
            <TableContainer component={Paper} sx={{ minWidth: 750 }}>
                <Table sx={{ minWidth: 750 }} size='small' aria-label="project attributes table">
                    <TableBody>
                        {bodyProps.map(prop => (
                            <TableRow hover sx={tableRowStyle}>
                                <TableCell sx={{ width: 250 }}>{prop.heading}</TableCell>
                                <TableCell>{ prop.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ProductItemAttributesTable;