import { Container } from "@mui/system";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    LinearProgress,
    Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { executeTransaction } from '../state/CortenClient';
import { useAuth } from '../state/AuthProvider';
import { useAppConfigState } from '../state/AppConfig';
import { ProductData, ProductItem } from '../model/Shared';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import { useTransactionMonitor } from '../state/transaction/TransactionMonitor';
import { TransactionOverview } from './TransactionOverview';
import { renderEntry } from '../utility/HoldingUtil';
import { renderDialogField } from '../utility/DialogFieldRenderUtil';
import { AlertDialogContent, AlertType } from './AlertDialogContent';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextField } from '../view/trade/TradeUtil';
import {
    getOrderedProjectsAndAmounts,
    getTradeOptimiserOptions,
    renderOrderedProjectVintageAmounts,
    TradeOptimiser,
    TradeOptimiserAmountType
} from '../utility/TradeOptimiserUtil';
import {
    Option,
    Options,
    ProductItemFilterOptions,
    ProductItemForm,
    useProductItemFilters,
} from '../state/ProductItemFilters';
import { TransactionType } from '../state/Variables';
import { useFormDrafts } from '../view/trade/drafts/FormDraftsHook';
import { FormDrafts } from '../view/trade/drafts/FormDraftsComponent';
import { StorageItemKey } from '../state/StorageUtils';
import { useProductDataState } from '../state/ProductDataProvider';

interface RetirementTradeDraft {
    form: RetirementForm,
    filterOptions?: ProductItemFilterOptions,
    version: string,
};

type ProductDataRaw = {
    productId: string;
    data: ProductData;
};

type ProductItemDataRaw = {
    productItemId: string;
    productItem: ProductItem;
};

interface RetirementForm {
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    state: string,
    country: string,
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenpowerAccredited: string,
    quantity: number,
    retireReason: string,
    holdings: string,
    account?: string,
    tradeOptimiser: TradeOptimiser,
}

const defaultValues: RetirementForm = {
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    state: '',
    country: '',
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenpowerAccredited: '',
    quantity: 0,
    retireReason: '',
    holdings: '',
    account: '',
    tradeOptimiser: TradeOptimiser.DEFAULT,
};

const HoldingRetirement = ({
    useDialog,
    accountId,
    holdings,
    products,
    retirementDialogActive,
    onRetirementDialogClosed,
    preSelectedProjectId,
    preSelectedProjectType,
    preSelectedVintage
}: {
    useDialog: boolean;
    accountId: string | undefined;
    holdings: any[];
    products: any[] | undefined;
    retirementDialogActive: boolean;
    onRetirementDialogClosed: any;
    preSelectedProjectId: string | undefined;
    preSelectedProjectType: string | undefined;
    preSelectedVintage: string | undefined;
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm<RetirementForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['product', 'account'], control });
    const formWatch = useWatch({ control });

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        stateOptions,
        countryOptions,
        fuelSourceOptions,
        creationYearOptions,
        generationYearOptions,
        generationStateOptions,
        greenpowerAccreditedOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({});

    const {
        draftsMetadata,
        initialiseDrafts,
        selectDraft,
        updateDraft,
        deleteDraft,
        getCurrentDraftId,
    } = useFormDrafts<RetirementTradeDraft>(
        StorageItemKey.RETIREMENT_FORM.key,
        { form: defaultValues },
        '5',
        () => { skipDraftUpdates.current++ }
    );
    const skipDraftUpdates = useRef(1); // set to "1" to skip update upon the load of default values

    const [isRetiringTransactions, setIsRetiringTransactions] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [balanceError, setBalanceError] = useState<string>();

    const [retirementSuccess, setRetirementSuccess] = useState<boolean>(false);
    const [retireErrorMessage, setRetireErrorMessage] = useState<string>("");
    const [retireErrorCode, setRetireErrorCode] = useState<string>("");
    const [retireWarnMessage, setRetireWarnMessage] = useState<string>("");
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [availableBalanceOfSelectedHoldings, setAvailableBalanceOfSelectedHoldings] = useState<number | undefined>(undefined)
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const { productData } = useProductDataState();

    const productItemIds = useRef<Promise<string[]>>();
    const [accountOptions, setAccountOptions] = useState<Options>(new Options([]));

    const user = useAuth();

    const appConfigState = useAppConfigState();

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsRetiringTransactions(false);
            setRetirementSuccess(true);
        },
        (tx) => {
            setIsRetiringTransactions(false);
            setRetireErrorMessage('An error occurred when retiring transactions.');
            setRetireErrorCode(tx.errorCode);
        },
        () => {
            setIsRetiringTransactions(false);
            setRetireWarnMessage('The transaction timed out. Please check back later to confirm whether the transaction was processed.');
        }
    );

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        if (retirementDialogActive || !useDialog) {
            let preSelectedData = getValues();

            if (accountId !== inventoryIssuer.id && accountId !== undefined) {
                let accountOptionsList: Option[] = [];
                accountOptionsList.push(
                    new Option(accountId, appConfigState.getClientForAddress(accountId)!!, null, false)
                );
                setAccountOptions(new Options(accountOptionsList, false));
                preSelectedData.account = accountId;
            } else if (accountId === undefined) {
                let accountOptionsList: Option[] = [];
                accountOptionsList.push(
                    new Option(inventoryIssuer.id, inventoryIssuer.display, null, false)
                );
                appConfigState.getClients().forEach(clientConfig =>
                    accountOptionsList.push(
                        new Option(clientConfig.id, clientConfig.display, null, false)
                    )
                );
                setAccountOptions(new Options(accountOptionsList, false));
            } else if (accountId === inventoryIssuer.id) {
                preSelectedData.account = inventoryIssuer.id;
            }

            if (holdings.length > 0) {
                preSelectedData.holdings = holdings.map((holding: any) => { return holding.holdingId }).join(", ");
                setAvailableBalanceOfSelectedHoldings(
                    holdings.map((holding: any) => { return parseFloat(holding.amount) }).reduce((sumAmount: number, amount: number) => sumAmount + amount, 0)
                );
            } else {
                if (products !== undefined && products.length === 1) {
                    preSelectedData.product = products![0].productId;
                } else {
                    preSelectedData.product = "";
                }
                if (preSelectedProjectId !== undefined) {
                    preSelectedData.project = preSelectedProjectId;

                } else {
                    preSelectedData.project = "";
                }
                if (preSelectedProjectType !== undefined) {
                    preSelectedData.projectType = preSelectedProjectType;
                } else {
                    preSelectedData.projectType = "";
                }
                if (preSelectedVintage !== undefined) {
                    preSelectedData.vintage = preSelectedVintage;
                } else {
                    preSelectedData.vintage = "";
                }
            }
            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, preSelectedData);
            setIsRetiringTransactions(false);
            setTransactionId(null);
            setRetirementSuccess(false);
            setRetireErrorMessage("");
        }
        if (!useDialog) {
            setFormFromDraft(initialiseDrafts());
        }

    }, [retirementDialogActive]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product && holdings.length === 0) {
            error = 'Please select a Product';
        }
        if (!useDialog && !form.account) {
            error = 'Please select an Account';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]);

    // on form update - update stored values
    useEffect(() => {
        if (!useDialog) {
            if (skipDraftUpdates.current > 0) {
                // ignore changes that are a result of switching drafts
                // to preserve the correct "modified date"
                skipDraftUpdates.current--;
            } else {
                updateDraft((data) => {
                    data.form = formWatch as RetirementForm;
                    // We store dynamic options so that, when switching to a draft, we can show the previously
                    // selected values immediately rather than waiting for a load to complete.
                    // Current options will still be loaded in the background.
                    data.filterOptions = {
                        productOptions: productOptions,
                        projectTypeOptions: projectTypeOptions,
                        projectOptions: projectOptions,
                        vintageOptions: vintageOptions,
                        stateOptions: stateOptions,
                        countryOptions: countryOptions,
                        fuelSourceOptions: fuelSourceOptions,
                        creationYearOptions: creationYearOptions,
                        generationYearOptions: generationYearOptions,
                        generationStateOptions: generationStateOptions,
                        greenpowerAccreditedOptions: greenpowerAccreditedOptions
                    };
                });
            }
        }
    }, [formWatch]);

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    const handleRetirementDialogClose = () => {
        unsubscribe();
        setIsRetiringTransactions(false);
        setTransactionId(null);
        if (!transactionInReview && !useDialog) {
            let currentDraftId = draftsMetadata!.currentDraftId;
            let draft = retirementSuccess
                ? deleteDraft(currentDraftId, true)
                : selectDraft(currentDraftId);
            setFormFromDraft(draft);
        }
        setRetirementSuccess(false);
        setTransactionInReview(false)
        setRetireErrorMessage("");
        setRetireWarnMessage("");
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        if (onRetirementDialogClosed != undefined) {
            onRetirementDialogClosed();
        }
    };

    const retireTransactions = async () => {
        let accountIdToUse = getValues().account;
        setIsRetiringTransactions(true);
        setTransactionInReview(false);
        let requestFrom = (holdings.length > 0) ? {
            type: 'HoldingAmount',
            inputList: holdings.map((holding: any) => holding.holdingId),
            amount: getValues().quantity,
        } : {
            type: 'ProductItemsAmount',
            productItemIds: await productItemIds.current,
            amount: getValues().quantity,
        };
        const unsignedRequestData = {
            type: 'BakeHoldingRequest',
            fromAccountId: accountIdToUse,
            from: requestFrom,
            nonce: new Date().getTime(),
            attributes: {
                [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key}`]:
                    accountIdToUse === inventoryIssuer.id ? TransactionType.InventoryRetirement : TransactionType.ClientRetirement,
                ...accountIdToUse !== inventoryIssuer.id && { [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key}`]: TransactionType.ClientRetirement },
                ...getValues().retireReason !== '' && { [`${appConfigState.getAttribute('RETIREMENT', 'REASON').key}`]: getValues().retireReason }
            }
        };
        executeTransaction(unsignedRequestData, user)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    console.log("Successfully submitted BakeHoldingRequest, waiting for notification.");
                    response.json().then(r => {
                        setTransactionId(r.txId);
                        subscribe(r.txId);
                    });
                } else {
                    console.log(response);
                    setIsRetiringTransactions(false);
                    setRetireErrorMessage("Could not retire transactions - the request was rejected")
                }
            }).catch(console.error);
    };

    const inventoryIssuer = appConfigState.getAccount('INVENTORY_ISSUER')

    const onOptionSelect = (field: keyof ProductItemForm) => {
        if (useDialog) {
            onFilterChange(resetField, getValues(), field);
        } else {
            onFilterChange(buildResetCallback(), getValues(), field);
        }
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        if (holdings.length === 0) {
            let accountIdToUse = getValues().account;
            getOrderedProjectsAndAmounts(
                getValues(),
                accountIdToUse!,
                setProjectAmounts,
                productItemIds,
                user,
                appConfigState,
                handleError
            );
        };
    };

    const handleError = () => {
        setTransactionInReview(false);
        setRetireErrorMessage("Unable to get productItems for transaction.");
    };

    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };
    const onAccountChange = () => {
        onFilterChange(resetField, getValues(), 'account');
    };

    const handleDraftChange = (event: any, value: string) => {
        let draftId = value === 'ADD' ? null : value;
        setFormFromDraft(selectDraft(draftId));
    };

    const handleDraftDelete = (value: string) => setFormFromDraft(deleteDraft(value));

    const setFormFromDraft = (draft: RetirementTradeDraft) => {
        reset(draft.form, { keepDefaultValues: true });
        resetProductFilters(buildResetCallback(), draft.form, draft.filterOptions);
    };

    const buildResetCallback = () => {
        let draftId = getCurrentDraftId();
        return (fieldName: keyof ProductItemForm) => {
            // if the user hasn't switched drafts - reset form field
            if (draftId === getCurrentDraftId()) {
                resetField(fieldName);
                return;
            }
            // otherwise update the target draft
            updateDraft(data => data.form[fieldName] = defaultValues[fieldName] as any, draftId);
        };
    };

    const getRetirementForm = (): JSX.Element => {
        return (
            <>
                {holdings.length > 0 && (
                    <>
                        {renderEntry('Holdings:', (
                            <ControlledTextField name='holdings' label='Holdings'
                                control={control} errors={errors} disabled />
                        ), useDialog, true)}
                    </>
                )}
                {holdings.length === 0 && (
                    <>
                        {renderEntry('Product:', (
                            <ControlledTextField name='product' label='Product'
                                options={productOptions}
                                customOnChange={() => onOptionSelect('product')}
                                rules={{ required: 'Product is required' }}
                                control={control} errors={errors} reset={resetField}
                            />
                        ), useDialog, true)}
                        {renderEntry('Project Type:', (
                            <ControlledTextField name='projectType' label='Project Type'
                                options={projectTypeOptions}
                                customOnChange={() => onOptionSelect('projectType')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                            />
                        ), useDialog, true)}
                        {renderEntry('Project:', (
                            <ControlledTextField name='project' label='Project'
                                options={projectOptions}
                                customOnChange={() => onOptionSelect('project')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                            />
                        ), useDialog, true)}
                        {renderEntry('Vintage:', (
                            <ControlledTextField name='vintage' label='Vintage'
                                options={vintageOptions}
                                customOnChange={() => onOptionSelect('vintage')}
                                control={control} errors={errors} reset={resetField}
                                balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                            />
                        ), useDialog, true)}
                        {appConfigState.getProduct(getValues('product'))?.displayCode === 'ACCU' && 
                            renderEntry('State:', (
                                <ControlledTextField name='state' label='State'
                                    options={stateOptions}
                                    customOnChange={() => onOptionSelect('state')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), useDialog, true)}
                        {appConfigState.getProduct(getValues('product'))?.displayCode === 'VCU' && 
                            renderEntry('Country:', (
                                <ControlledTextField name='country' label='Country'
                                    options={countryOptions}
                                    customOnChange={() => onOptionSelect('country')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), useDialog, true)}
                    </>
                )}
                {renderEntry('Quantity :', (

                    <>
                        <ControlledTextField name='quantity' label='Quantity' integer
                            rules={{
                                required: 'Quantity is required',
                                pattern: {
                                    value: /^\d*$/,
                                    message: 'Quantity must be a whole number',
                                },
                                min: {
                                    value: 1,
                                    message: 'Quantity must be greater than 0',
                                },
                                max: {
                                    value: holdings.length > 0 ? availableBalanceOfSelectedHoldings : availableBalance,
                                    message: 'Quantity exceeds available balance',
                                },
                            }}
                            control={control} errors={errors} />
                        {(holdings.length == 0 && !balanceError && availableBalance == null) || (holdings.length > 0 && availableBalanceOfSelectedHoldings == undefined)
                            ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                            : (<Typography variant='caption'
                                color={balanceError ? 'error' : 'textSecondary'}
                                mt={1} mb={1}
                                sx={{ float: 'right' }}>
                                {balanceError
                                    ? <div>{balanceError}</div>
                                    : <div>Available Balance: <AmountFormatWrapper
                                        amount={holdings.length > 0 ? availableBalanceOfSelectedHoldings : availableBalance}
                                        minDecimalPos={productData.get(getValues().product)?.minDecimalPos!}
                                        maxDecimalPos={productData.get(getValues().product)?.maxDecimalPos!}
                                    /></div>}
                            </Typography>)}
                    </>

                ), useDialog, true)}

                {renderEntry('Reason:', (
                    <ControlledTextField name='retireReason' label='Reason'
                        control={control} errors={errors} />
                ), useDialog, true)}
                {holdings.length === 0 && (renderEntry('Trade Optimiser:', (
                    <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                        options={getTradeOptimiserOptions()}
                        rules={{ required: 'Trade optimiser is required' }}
                        control={control} errors={errors} reset={resetField} />
                ), useDialog, true))}
            </>
        );
    };

    const getRetirementFormActions = (): JSX.Element => {
        return (
            <>
                <Button onClick={handleRetirementDialogClose} color="primary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    onClick={() => openReviewDialog()}
                    color="primary"
                    variant="outlined"
                    disabled={!isValid || (!availableBalance && holdings.length === 0) || (!availableBalanceOfSelectedHoldings && holdings.length > 0)}
                >
                    {isRetiringTransactions ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '2px'
                            }}
                        >
                            <CircularProgress size={20} />
                        </Box>
                    ) : (
                        'Submit'
                    )}
                </Button></>
        );
    };

    return (<>
        {useDialog && (<Dialog
            open={retirementDialogActive && !transactionInReview && !retirementSuccess && !isRetiringTransactions && !retireErrorMessage && !retireWarnMessage}
            onClose={handleRetirementDialogClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogContent>
                <Typography variant='h2'>
                    {accountId !== inventoryIssuer.id && ("Retire Holdings")}
                    {accountId === inventoryIssuer.id && (`Retire Holdings from ${inventoryIssuer.display}`)}
                </Typography>

                {accountId !== inventoryIssuer.id && renderEntry('Client:', (
                    <ControlledTextField
                        name='account'
                        options={accountOptions}
                        label='Client'
                        control={control}
                        errors={errors}
                        disabled={true}
                    />
                ), useDialog, true)}

                {getRetirementForm()}

            </DialogContent>
            <DialogActions>
                {getRetirementFormActions()}
            </DialogActions>


        </Dialog>)}

        {!useDialog && (<>
            <Box display='flex' justifyContent={'center'} sx={{ mt: -2, mb: 2.5 }}>
                <FormDrafts draftsMetadata={draftsMetadata} onChange={handleDraftChange} onDelete={handleDraftDelete} />
            </Box>
            <Container maxWidth='sm'>
                <Grid container spacing={2} mt={-1}>
                    {renderEntry('Account:', (
                        <ControlledTextField name='account' label='Account'
                            rules={{ required: 'Account is required' }}
                            customOnChange={() => onAccountChange()}
                            options={accountOptions}
                            control={control} errors={errors} reset={resetField} />
                    ), useDialog, true)}
                    {getRetirementForm()}
                    <Grid item xs={12} mt={4}>
                        <Button disabled={!isValid || !availableBalance}
                            onClick={() => openReviewDialog()}
                            variant='outlined'
                            color='primary'
                            fullWidth>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>)

        }

        <Dialog
            open={retirementDialogActive && (transactionInReview || isRetiringTransactions || retireErrorMessage !== "" || retireWarnMessage !== "")}
            onClose={handleRetirementDialogClose}
            fullWidth
            maxWidth="sm"
        >
            {(transactionInReview || isRetiringTransactions) && (<>
                <DialogContent>
                    <Typography variant='h2'>Review Details Below</Typography>
                    {accountId !== inventoryIssuer.id && useDialog &&
                        renderDialogField('Client', (appConfigState.getClientForAddress(accountId!)))
                    }
                    {!useDialog && renderDialogField(
                        'Account', getValues().account === inventoryIssuer.id ? inventoryIssuer.display : appConfigState.getClientForAddress(getValues().account!)
                    )}
                    {holdings.length > 0 && (
                        <>
                            {renderDialogField('Holdings', (
                                holdings.map((holding: any) => { return holding.holdingId }).join(", ")

                            ))}
                        </>
                    )}
                    {holdings.length === 0 && (
                        <>
                            {getValues().product && renderDialogField('Product', appConfigState.getProduct(getValues().product)?.displayName)}
                            {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}

                            {getValues().project && renderDialogField(
                                'Project',
                                projectOptions?.values.find(opt => opt.id === getValues().project)?.label
                            )}
                            {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                            {getValues().state && renderDialogField('State', getValues().state)}
                            {getValues().country && renderDialogField('Country', getValues().country)}
                        </>
                    )}
                    {renderDialogField('Quantity', getValues().quantity, {
                        minDecimalPos: productData.get(getValues().product)?.minDecimalPos!,
                        maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos!,
                    })}
                    {renderDialogField('Reason', getValues().retireReason)}
                    {getValues().tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Price)}
                    {getValues().tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Score)}
                    {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                    <Button
                        onClick={retireTransactions}
                        color='primary'
                        variant='outlined'
                        disabled={
                            ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && 
                            (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity)
                        }>
                        {isRetiringTransactions ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '2px',
                            }}
                            >
                                <CircularProgress size={20} />
                            </div>
                        ) : ('Confirm')}
                    </Button>
                </DialogActions>
            </>)}


            {retireErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={retireErrorMessage}
                    errorCode={retireErrorCode}
                    handleDialogClose={handleRetirementDialogClose}
                />
            )}
            {retireWarnMessage && !retireErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={retireWarnMessage}
                    errorCode={null}
                    handleDialogClose={handleRetirementDialogClose}
                />
            )}
        </Dialog>
        {retirementSuccess && <TransactionOverview
            open={retirementDialogActive && retirementSuccess}
            onClose={handleRetirementDialogClose}
            title='Holdings Retired Successfully'
            attributes={{
                'Transaction ID': { value: transactionId!, shorterFormId: true, key:'transactionId' },
                ...(holdings.length > 0 ? {
                    'Holdings': { value: holdings.map((holding: any) => holding.holdingId).join(', '), key:'holdings' }
                } : {
                    'Product': { value: appConfigState.getProduct(getValues().product)?.displayName, key:'productId' },
                    ...getValues().projectType && { 'Project Type': { value: getValues().projectType, key:'projectType' } },
                    ...getValues().project && { 'Project': { value: projectOptions?.values.find(opt => opt.id === getValues().project)?.label , key:'projectId'} },
                    ...getValues().vintage && { 'Vintage': { value: getValues().vintage, key:'vintage' } },
                    ...getValues().state && { 'State': { value: getValues().state , key:'state'} },
                    ...getValues().country && { 'Country': { value: getValues().country ,key:'country'} },
                }),
                ...useDialog && accountId && appConfigState.getClientForAddress(accountId) && {
                    'From Account': { value: appConfigState.getClientForAddress(accountId), key:'accountId' },
                },
                ...!useDialog && {
                    'From Account': { value: getValues().account === inventoryIssuer.id ? inventoryIssuer.display : appConfigState.getClientForAddress(getValues().account!), key:'accountId' }
                },
                'Quantity': { value: getValues().quantity.toString(), renderProp: { 
                    minDecimalPos: productData.get(getValues().product)?.minDecimalPos!, 
                    maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos! 
                },
                key:'quantity' },
                'Reason': { value: getValues().retireReason, key:'reason'},
            }}
        />}
    </>);
};

export { HoldingRetirement, type ProductDataRaw, type ProductItemDataRaw };
