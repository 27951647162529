import { useEffect, useState } from 'react';
import { useAuth } from '../state/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { homepage } from './AppNavigate';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


function LoginRedirectHandler() {
    const { userManager, setUser, setLoading, signOut } = useAuth();
    const navigate = useNavigate();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    const closeErrorDialog = () => {
        setIsErrorDialogOpen(false);
    };

    const handleOkClick = () => {
        signOut();
        closeErrorDialog();
    };

    useEffect(() => {
        if (userManager) {
            userManager
                .signinRedirectCallback()
                .then((loadedUser) => {
                    setUser(loadedUser);

                    // Redirect the user to the homepage once login is successful
                    navigate(homepage);
                })
                .catch((err) => {
                    console.error(err);
                    setLoading(false);
                    // We encountered an error during retrieving user info. We need them to sign in again
                    setIsErrorDialogOpen(true);
                });
        }
    }, [userManager]);

    return (
        <Dialog open={isErrorDialogOpen} onClose={closeErrorDialog}>
            <DialogTitle>Login Error</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Something went wrong your login. Please login again.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LoginRedirectHandler;
