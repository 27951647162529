import { Alert, Box, Grid, LinearProgress, MenuItem, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../state/AuthProvider";
import { useAppConfigState } from "../../state/AppConfig";
import { fetchInventoryScoreMarking, saveInventoryScoreMarking, fetchComputedInventoryScores } from "../../utility/Fetch";
import { InventoryScoreMarkingTable } from "./InventoryScoreMarkingTable";
import { ComputedInventoryScoreTable } from "./ComputedInventoryScoreTable";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { InventoryScoreTagger } from "./InventoryScoreTagger";

/**
 * Top-level application component, allowing the user to modify marked inventory scores
 */
const InventoryScores = () => {
    const config = useAppConfigState();
    const user = useAuth();

    const [inventoryScores, setInventoryScores] = useState<InventoryScoreMarking>();
    const [computedScores, setComputedScores] = useState<ComputedInventoryScores>();
    const [isEditing, setEditing] = useState(false);
    const [productId, setProductId] = useState(config.getProducts()[0].id);
    const inventoryScoresAbortController = useRef(new AbortController());
    const computedScoresAbortController = useRef(new AbortController());

    useEffect(() => {
        setInventoryScores(undefined);
        setComputedScores(undefined);
        loadInventoryScores(productId);
    }, [productId]);

    useEffect(() => {
        loadComputedScores(productId);
    }, [inventoryScores]);

    const loadInventoryScores = (productId: string): void => {
        setEditing(false);
        inventoryScoresAbortController.current.abort();
        inventoryScoresAbortController.current = new AbortController();
        fetchInventoryScoreMarking(productId, undefined, user, inventoryScoresAbortController.current.signal).then(response => {
            if (response) {
                setInventoryScores(response);
            }
        });
    };

    const loadComputedScores = (productId: string): void => {
        setEditing(false);
        computedScoresAbortController.current.abort();
        computedScoresAbortController.current = new AbortController();
        fetchComputedInventoryScores(productId, undefined, user, computedScoresAbortController.current.signal).then(response => {
            if (response) {
                setComputedScores(response);
            }
        });
    };

    const saveScores = async (inventoryScoreMarking: any, endOfDay: boolean = false): Promise<boolean> => {
        const eodType = endOfDay ? 'EOD' : 'CURRENT';
        const response = await saveInventoryScoreMarking(inventoryScoreMarking, endOfDay, user);
        if (response.ok) {
            toast(<Alert severity='success'>{eodType} Inventory Score Marking saved successfully</Alert>);
            // reset state to force a render, updating "initial" values for all subcomponents
            setInventoryScores({ ...inventoryScoreMarking });
            return true;
        } else {
            toast(<Alert severity='error'>{`Failed to save ${eodType} Inventory Score Marking - ${await response.text()}`}</Alert>);
            return false;
        }
    };

    return (
        <>
            <Box sx={{ maxWidth: 'lg', margin: '0 0 auto' }}>
                <Typography variant='h2'>Inventory Score Marking</Typography>
                <Typography variant='h3' mt={2} mb={4}>Configure Score Grid</Typography>
                <TextField
                    label='Product'
                    value={productId}
                    onChange={event => setProductId(event.target.value)}
                    select size='small' sx={{ marginRight: '1rem', width: 200 }}
                >
                    {config.getProducts().map((product) =>
                        <MenuItem key={product.id} value={product.id}>{product.displayCode}</MenuItem>
                    )}
                </TextField>
                {inventoryScores
                    ? <Fragment>
                        <InventoryScoreTagger productId={productId} />
                        <InventoryScoreMarkingTable
                            initialRows={inventoryScores}
                            handleSave={rows => saveScores(rows)}
                            handleEditing={setEditing}
                            disableEditing={isEditing}
                        />
                        {computedScores
                            ? <Fragment>
                                <ComputedInventoryScoreTable
                                    rows={computedScores}
                                />

                            </Fragment>
                            : <LinearProgress sx={{ mt: 4 }} />
                        }
                    </Fragment>
                    : <LinearProgress sx={{ mt: 4 }} />
                }
            </Box>
        </>
    )
};

/**
 * The complete inventory score marking, mapped from the API response.
 * See BaseInventoryScoreMarking.kt for details.
 */
interface InventoryScoreMarking {
    productId: string,
    projectTypeScores: Array<OptimiserProjectTypeScore>
}

interface OptimiserProjectTypeScore {
    projectType: string,
    baseScore: number,
    vintageScores: Array<OptimiserVintageYearScore>
}

interface OptimiserVintageYearScore {
    vintageYear: string,
    score: number
}

/**
 * The complete computed inventory score array, mapped from the API response.
 * See ResolvedInventoryScores.kt for details
 */
interface ComputedInventoryScores {
    productId: string,
    endOfDay: string | undefined,
    vintageYears: string[],
    rows: ComputedInventoryScoreRow[]
}

interface ComputedInventoryScoreRow {
    projectType: string,
    method: string | null,
    vintageScores: ComputedInventoryScoreColumn[]
}

interface ComputedInventoryScoreColumn {
    vintageYear: string,
    resolvedScore: number
}

export {
    InventoryScores,
    type InventoryScoreMarking,
    type OptimiserProjectTypeScore,
    type OptimiserVintageYearScore,
    type ComputedInventoryScores,
    type ComputedInventoryScoreRow,
    type ComputedInventoryScoreColumn
}
