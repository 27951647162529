import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useLayoutState } from '../state/Layout';

type TabProps = {
    /**
     * Label of the tab
     */
    label: string;
    /**
     * Contents of the tab
     */
    contents?: React.ReactNode;
};

type Props = {
    open: boolean;
    onClose: () => void;
    title: string;
    isLoading?: boolean;
    tabs: TabProps[];
};

/**
 * Responsible for displaying a dialog with tabs
 *
 * @param title : title of the dialog
 * @param isLoading : boolean to indicate whether the dialog is loading
 * @param open : boolean to indicate whether the dialog is open
 * @param onClose : callback function to close the dialog
 * @param tabs : array of tabs to display, based on array tab will be created dynamically
 * @returns Dialog with tabs
 */
const TransactionOverviewWithTab = ({ title, isLoading, open, onClose, tabs }: Props) => {
    const { customTheme } = useLayoutState();
    const hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };
    const [tabValue, setTabValue] = useState<number>(0);

    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" scroll="paper">
            <DialogContent style={{ minHeight: '50vh' }}>
                <Typography variant="h2">{title}</Typography>

                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                    <Tabs value={tabValue} onChange={onTabChange}>
                        {tabs?.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.label}
                                value={index}
                                sx={{
                                    ...hoverTabTextColor,
                                    transition: customTheme.customProps.navHoverTransition,
                                    '&:hover': {
                                        backgroundColor:
                                            customTheme.customProps.navHoverBackgroundColor
                                    }
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>

                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <>
                        {tabs?.map((tab, index) => {
                            if (tabValue === index) {
                                return <Box key={index}>{tab.contents}</Box>;
                            }
                        })}
                    </>
                )}
            </DialogContent>
            <DialogActions sx={{p:3, pt:0}}>
                <Button onClick={onClose} color="primary" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TransactionOverviewWithTab;
