import {
    DataGrid,
    getGridSingleSelectOperators,
    GridFilterItem,
    GridFilterModel,
    GridPaginationModel,
    GridSortItem,
    GridSortModel,
    GridToolbar,
} from '@mui/x-data-grid';
import { Box, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ProductBase, useAppConfigState } from '../../state/AppConfig';
import { fetchWithCorten, fetchWithCortenAndHandleAbort } from '../../state/CortenClient';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { utcToLocalFormat } from '../../utility/utcToLocalFormat';
import {
    buildFilterUri,
    buildSortUri,
    dateRangeFilterOperators,
    extendedNumericFilterOperators,
    getComparedFilterModel,
    getTransactionIdFilterOperators,
    truncateTransactionId,
} from '../../utility/ProjectUtils';
import { fetchHolding, fetchProductItem } from '../../utility/Fetch';
import { TransactionType } from '../../state/Variables';
import { useProductDataState } from '../../state/ProductDataProvider';
import { renderFieldValue } from '../../utility/DialogFieldRenderUtil';
import Big from 'big.js';
import TransactionOverviewWithTab from '../../component/TransactionOverviewWithTab';
import { TransactionContents } from '../../component/TransactionOverview';

interface Transaction {
    transactionTimestamp: string,
    transactionId: string,
    quantity: number,
    productId: string,
    transactionTypeClient: TransactionType,
    request: any,
    blockAndIndex:string
}

interface TransactionHoldingsDetails {
    accountId: string;
    holdingId: string;
    state: string;
    amount: string;
}

interface SelectedRow extends Transaction {
    isLoading: boolean;
    transaction: Transaction;
    spentHoldings: TransactionHoldingsDetails[];
    issuedHoldings: TransactionHoldingsDetails[];
    projectTypes: string;
    projectNames: string;
    projectLists: { type: string; name: string; vintage: string | number }[];
    fuelSource:string
}

// We currently use fixed table height with a fixed number of items per page
const PAGE_SIZE = 20;
const INITIAL_PAGINATION_MODEL = { page: 0, pageSize: PAGE_SIZE };
enum TransactionRequestType {
    TRANSFER = 'TransferRequest',
    BAKE_HOLDING = 'BakeHoldingRequest',
    CREATE_PRODUCT_ITEM = 'CreateProductItemRequest',
    ASSIGN_HOLDING = 'AssignHoldingRequest',
}

const Transactions = ({ accountId, products, refreshSignal }: { accountId: string, products: any[], refreshSignal: number }) => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const abortController = useRef(new AbortController());
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const lastLoadedPage = useRef<number>(0);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [filterModel, setFilterModel] = useState( {items: []} as GridFilterModel);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
    const [totalAvailableRowCount, setTotalAvailableRowCount] = useState(0);
    const combinedTransactions = useRef<any[]>([]);
    const [initTableReady, setInitTableReady] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState<SelectedRow>();

    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();

    useEffect(() => {
        handleFetchTransactions().then(() => setInitTableReady(true));
    }, [accountId, products, refreshSignal]);

    useEffect(() => {
        if (!loading && nextPage) {
            mapPageToNextCursor.current[paginationModel.page] = nextPage;
        }
    }, [paginationModel.page, loading, nextPage]);

    const handleFetchTransactions = async (filters: GridFilterItem[] = [], sorts: GridSortItem[] = []) => {
        combinedTransactions.current = [];
        lastLoadedPage.current = 0;
        mapPageToNextCursor.current = {};
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchTransactions(undefined, filters, sorts, abortController.current.signal);
    };

    const fetchTransactions = async (
        nextPage: string | undefined,
        filterItems: GridFilterItem[],
        sortItems: GridSortItem[],
        abortSignal: AbortSignal
    ) => {
        let url = '/api/pub/history';
        url += `?transactionType=${TransactionRequestType.TRANSFER},${TransactionRequestType.BAKE_HOLDING},${TransactionRequestType.CREATE_PRODUCT_ITEM},${TransactionRequestType.ASSIGN_HOLDING}`;
        url += `&accountId=${accountId}`;
        url += `&${buildFilterUri(filterItems, undefined, appConfigState)}`;
        url += `&sort=${buildSortUri({items: sortItems, appConfigState: appConfigState})}`;

        // Attributes to group our response by
        let attributesToGroupBy = [
            ['transactionAttribute', appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key]
        ];
        url += `&groupBy=${attributesToGroupBy.map(attribute => `${attribute[0]}.${attribute[1]}`).join(',')},transaction`;

        // query params related to pagination
        const pageFromQueryString: string = nextPage ? `&page.from=${nextPage}` : '';
        url += `&page.withCount=true${pageFromQueryString}&page.limit=${PAGE_SIZE}`;

        const response = await fetchWithCortenAndHandleAbort(url, {method: 'GET'}, abortSignal, false);
        if (response === null) {
            return; // The request was aborted
        }

        setLoading(false);
        setNextPage(response.nextPage);
        setTotalAvailableRowCount(response.count);
        let transactions: Transaction[] = response.list.map((data: any) => {
            let txTypeAttributeKey = appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key;
            return {
                transactionTimestamp: data['transaction']['timestamp'],
                transactionId: data['transaction']['txId'],
                quantity: data['balances']['amount'],
                productId: data['balances']['productId'],
                transactionTypeClient: data['transaction']['request']['attributes'][txTypeAttributeKey],
                request: data['transaction']['request'],
                blockAndIndex: data['transaction']['blockAndIndex']
            } as Transaction;
        });
        combinedTransactions.current = [...combinedTransactions.current, ...transactions];
        setTransactionState(combinedTransactions.current, lastLoadedPage.current);
    };

    const fetchTransactionHoldings = async (id: string) => {
        try {
            let url = `/api/pub/transaction/${id}`;
            const response = await fetchWithCorten(url, {}, false);
            const data = await response.json();
            return data;
        } catch (error) {
            console.log("fetchTransactionHoldings Error",error)
        }
    };

    const getTransactionDetails = async (
        transaction: Transaction
    ): Promise<{
        transaction: Transaction;
        spentHoldings: TransactionHoldingsDetails[];
        issuedHoldings: TransactionHoldingsDetails[];
        projectTypes: string;
        projectNames: string;
        projectLists: { type: string; name: string; vintage: string }[];
        fuelSource:string;
    }> => {
        const [productItems, transactionHoldings] = await Promise.all([
            fetchProductItems(transaction),
            fetchTransactionHoldings(transaction.blockAndIndex)
        ]);

        const projectTypes = new Set<string>();
        const projectNames = new Set<string>();
        const projectLists = new Set<{
            type: string;
            name: string;
            vintage: string;
        }>();
        
        const fuelSources = new Set<string>();

        productItems.map((item: any) => {
            const attributes = item?.productItem?.data?.attributes;
            const projectTypeKey = appConfigState.getAttribute('PROJECT', 'TYPE').key;
            const projectNameKey = appConfigState.getAttribute('PROJECT', 'NAME').key;
            const projectVintageKey = appConfigState.getAttribute('PROJECT', 'VINTAGE').key;
            const fuelSource = appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key;

            projectTypes.add(attributes[projectTypeKey]);
            projectNames.add(attributes[projectNameKey]);
            fuelSources.add(attributes[fuelSource]);
            projectLists.add({
                type: attributes[projectTypeKey],
                name: attributes[projectNameKey],
                vintage: attributes[projectVintageKey]
            });
        });

        return {
            spentHoldings: [...transactionHoldings?.spentHoldings],
            issuedHoldings: [...transactionHoldings?.issuedHoldings],
            transaction: transaction,
            projectTypes: Array.from(projectTypes).join(', '),
            projectNames: Array.from(projectNames).join(', '),
            projectLists: Array.from(projectLists).filter(
                (v, i, a) => a.findIndex((t) => t.type === v.type && t.name === v.name) === i
            ),
            fuelSource: Array.from(fuelSources).join(', ')
        };
    };

    const fetchProductItems = async(transaction: Transaction) => {
        if (transaction.request['type'] === TransactionRequestType.CREATE_PRODUCT_ITEM) {
            return Promise.all([fetchProductItem(transaction.blockAndIndex)]);
        }
        if (transaction.request['type'] === TransactionRequestType.ASSIGN_HOLDING) {
            return Promise.all(transaction.request.productItems.map((id:string) => fetchProductItem(id)));
        }
        let holdingSelector = getHoldingSelector(transaction.request);
        switch (holdingSelector['type']) {
            case 'HoldingAmount':
                return Promise.all(holdingSelector['inputList'].map((id:string) => fetchHolding(id)));
            case 'ProductItemsAmount':
                return Promise.all(holdingSelector['productItemIds'].map((id:string) => fetchProductItem(id)));
            default:
                console.error('Unexpected holding selector type');
                return [];
        }
    };

    const getHoldingSelector = (txRequest: any) => {
        switch(txRequest['type']) {
            case TransactionRequestType.TRANSFER:
                return txRequest['outputList'][0]['from'];
            case TransactionRequestType.BAKE_HOLDING:
                return txRequest['from'];
            default:
                throw Error('Unsupported transaction request type');
        }
    };

    const handleRowClick = async (event: any) => {
        try {
            setSelectedRow({ ...event.row, isLoading: true });
            const results = await getTransactionDetails(event.row);
            setSelectedRow({
                ...event.row,
                transaction: results.transaction,
                spentHoldings: results.spentHoldings,
                issuedHoldings: results.issuedHoldings,
                projectNames: results.projectNames,
                projectTypes: results.projectTypes,
                projectLists: results.projectLists,
                fuelSource:results.fuelSource,
                isLoading: false
            });
        } catch (error) {
            console.log(error)
            setSelectedRow({ ...event.row, isLoading: false, details: [], holdings: [] });
        }
        
    };

    const handleCloseDialog = () => {
        setSelectedRow(undefined); // Close the dialog by resetting the selected row
    };

    const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
        if (loading) {
            // Since we use block based pagination we cannot skip ahead to future pages,
            // we need to wait for the current page to load
            return;
        }

        if (newPaginationModel.page > lastLoadedPage.current) {
            lastLoadedPage.current = newPaginationModel.page;
            let nextPage = mapPageToNextCursor.current[newPaginationModel.page - 1];
            
            setLoading(true);
            abortController.current.abort();
            abortController.current = new AbortController();
            await fetchTransactions(nextPage, filterModel.items, sortModel, abortController.current.signal);
        } else {
            setTransactionState(combinedTransactions.current, newPaginationModel.page);
        }

        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    const setTransactionState = (allTransactions: any[], page: number) => {
        setTransactions(allTransactions.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE));
    };

    const getAttribute = (transaction: Transaction, group: string, attributeName: string) => {
        return transaction.request['attributes'][appConfigState.getAttribute(group, attributeName).key];
    };

    const handleFilterModelChange = async (newFilterModel: GridFilterModel) => {
        const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
        setFilterModel(comparedFilterModel);
        await handleFetchTransactions(comparedFilterModel.items, sortModel);
    };

    const handleSortModelChange = async (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
        await handleFetchTransactions(filterModel.items, newSortModel);
    };

    // Responsible for displaying the client name for the given account id from the app config
    const getClientName = (accountId: string) => {
        let accountName = appConfigState.getClientForAddress(accountId) ?? accountId;
        const currentAccount = appConfigState.getAccount('INVENTORY_ISSUER');
        if (currentAccount.id === accountId) {
            accountName = currentAccount.display;
        }
        return <>{accountName}</>;
    };


    return (
        <>
            {
                !initTableReady ?
                    <LinearProgress />
                :
                <Box style={{ width: '100%', height: 'auto', overflow: "auto"}}>
                    <DataGrid
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            rows={transactions}
                            getRowId={row => row.transactionId}
                            onRowClick={handleRowClick}
                            disableRowSelectionOnClick
                            pageSizeOptions={[PAGE_SIZE]}
                            rowCount={totalAvailableRowCount}
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={handleFilterModelChange}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            paginationMode="server"
                            onPaginationModelChange={handlePaginationModelChange}
                            paginationModel={paginationModel}
                            loading={loading}
                            // TODO: When the server supports exports (server side) then configure the export button to use that instead of exporting the current page only
                            columns={[
                                {
                                    field: 'transactionTimestamp',
                                    headerName: 'Timestamp',
                                    type: 'dateTime',
                                    flex: 1,
                                    filterOperators:dateRangeFilterOperators({showTimeStamp:true}),
                                    valueGetter: ({ value }) => new Date(value),
                                    valueFormatter: ({ value }) => utcToLocalFormat(value)
                                },
                                {
                                    field: 'transactionId',
                                    headerName: 'Transaction ID',
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: getTransactionIdFilterOperators(),
                                    valueFormatter: (params) => params.value,
                                    renderCell: (params) =>
                                        <Tooltip title={params.value} arrow>
                                            <Typography>{truncateTransactionId(params.value)}</Typography>
                                        </Tooltip>
                                },
                                {
                                    field: 'quantity',
                                    headerName: 'Amount',
                                    flex: 1,
                                    type: 'number',
                                    // Multiple negation operation is not supported by Corten which is required for this field
                                    filterOperators: extendedNumericFilterOperators.filter(
                                        (operator) => operator.value !== '!='
                                    ),
                                    renderCell: (params) =>
                                        <AmountFormatWrapper
                                            amount={params.value as number}
                                            minDecimalPos={productData.get(params.row.productId)?.minDecimalPos!}
                                            maxDecimalPos={productData.get(params.row.productId)?.maxDecimalPos!}
                                        />
                                },
                                {
                                    field: 'productId',
                                    headerName: 'Product',
                                    type: 'singleSelect',
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value !== 'not')
                                        .map((operator) => {
                                            return {
                                                ...operator,
                                                getValueAsString: (value) => {
                                                    let valueArray: string[] = [];
                                                    if (!Array.isArray(value)) {
                                                        valueArray = [value];
                                                    } else {
                                                        valueArray = value;
                                                    }
                                                    return appConfigState.getProducts(true).
                                                        filter((data) => valueArray.includes(data.id))
                                                        .map(data => data.displayCode).join(", ");
                                                },
                                            }
                                        }),
                                    valueOptions: appConfigState.getProducts(true).map((data) => ({
                                        value: data.id,
                                        label: data.displayCode
                                    }))
                                },
                                {
                                    field: 'transactionTypeClient',
                                    headerName: 'Transaction Type',
                                    type: 'singleSelect',
                                    flex: 1,
                                    valueOptions: [
                                        { value: TransactionType.PhysicalBuy, label: TransactionType.PhysicalBuy },
                                        { value: TransactionType.PhysicalSell, label: TransactionType.PhysicalSell },
                                        { value: TransactionType.ForwardBuy, label: TransactionType.ForwardBuy },
                                        { value: TransactionType.ForwardBuyDeliver, label: TransactionType.ForwardBuyDeliver },
                                        { value: TransactionType.ForwardSell, label: TransactionType.ForwardSell },
                                        { value: TransactionType.ForwardSellDeliver, label: TransactionType.ForwardSellDeliver },
                                        { value: TransactionType.ClientRetirement, label: TransactionType.ClientRetirement },
                                    ]
                                }
                            ]}
                            density="compact"
                            disableDensitySelector
                            aria-label="transactions table"
                        />
                </Box>
            }
            {selectedRow && (
                <TransactionOverviewWithTab
                    open={!!selectedRow}
                    onClose={handleCloseDialog}
                    title="Transaction Details"
                    isLoading={selectedRow?.isLoading}
                    tabs={[
                        {
                            label: 'Summary',
                            contents: (
                                <>
                                    <TransactionContents
                                        productTableView
                                        attributes={{
                                            'Transaction ID': {
                                                value: selectedRow?.transaction?.transactionId,
                                                shorterFormId: true,
                                                key: 'transactionId'
                                            },
                                            'Transaction Timestamp': {
                                                value: utcToLocalFormat(
                                                    selectedRow?.transaction?.transactionTimestamp
                                                ),
                                                key: 'transactionTimestamp'
                                            },
                                            'Transaction Type': {
                                                value: selectedRow?.transaction
                                                    ?.transactionTypeClient,
                                                key: 'transactionTypeClient'
                                            },
                                            Quantity: {
                                                value: selectedRow?.transaction?.quantity,
                                                renderProp: { minDecimalPos: 0, maxDecimalPos: 0 },
                                                key: 'quantity'
                                            },
                                            'Product Code': {
                                                value: appConfigState.getProduct(
                                                    selectedRow?.transaction?.productId
                                                )?.displayCode,
                                                key: 'productId'
                                            },
                                            Projects: {
                                                hideLabel: true,
                                                value: (
                                                    <>
                                                        <Table
                                                            size="small"
                                                            sx={{
                                                                border: 1,
                                                                borderColor: 'divider'
                                                            }}
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell
                                                                        sx={{ fontWeight: 'bold' }}
                                                                    >
                                                                        Project Type
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={{ fontWeight: 'bold' }}
                                                                    >
                                                                        Project Name
                                                                    </TableCell>
                                                                    <TableCell
                                                                        sx={{ fontWeight: 'bold' }}
                                                                    >
                                                                        Vintage
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {selectedRow?.projectLists?.map(
                                                                    (row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {row?.type}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {row?.name}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Typography variant="body2">
                                                                                    {row?.vintage}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </>
                                                ),
                                                key: 'projectName'
                                            },

                                            ...([TransactionRequestType.TRANSFER, TransactionRequestType.ASSIGN_HOLDING].includes(selectedRow?.transaction?.request.type) && {
                                                Trader: {
                                                    value: getAttribute(
                                                        selectedRow?.transaction,
                                                        'TRADE',
                                                        'TRADER_NAME'
                                                    ),
                                                    key: 'trader'
                                                },
                                                Currency: {
                                                    value: getAttribute(
                                                        selectedRow?.transaction,
                                                        'TRADE',
                                                        'CURRENCY'
                                                    ),
                                                    key: 'currency'
                                                },
                                                Price: {
                                                    value: getAttribute(
                                                        selectedRow?.transaction,
                                                        'TRADE',
                                                        'PRICE'
                                                    ),
                                                    renderProp: {
                                                        minDecimalPos: 2,
                                                        maxDecimalPos: 2
                                                    },
                                                    key: 'price'
                                                },
                                                ...(getAttribute(
                                                    selectedRow?.transaction,
                                                    'TRADE',
                                                    'SALES_PERSON'
                                                ) && {
                                                    'Sales Person': {
                                                        value: getAttribute(
                                                            selectedRow?.transaction,
                                                            'TRADE',
                                                            'SALES_PERSON'
                                                        ),
                                                        key: 'salesPerson'
                                                    }
                                                }),
                                                ...(getAttribute(
                                                    selectedRow?.transaction,
                                                    'TRADE',
                                                    'SALES_CREDITS'
                                                ) && {
                                                    'Sales Credits': {
                                                        value: getAttribute(
                                                            selectedRow?.transaction,
                                                            'TRADE',
                                                            'SALES_CREDITS'
                                                        ),
                                                        renderProp: {
                                                            minDecimalPos: 2,
                                                            maxDecimalPos: 2
                                                        },
                                                        key: 'salesCredits'
                                                    }
                                                }),
                                                ...(getAttribute(
                                                    selectedRow?.transaction,
                                                    'TRADE',
                                                    'BROKER_NAME'
                                                ) && {
                                                    'Broker Name': {
                                                        value: getAttribute(
                                                            selectedRow?.transaction,
                                                            'TRADE',
                                                            'BROKER_NAME'
                                                        ),
                                                        key: 'brokerName'
                                                    }
                                                }),
                                                ...(getAttribute(
                                                    selectedRow?.transaction,
                                                    'TRADE',
                                                    'BROKERAGE_FEE'
                                                ) && {
                                                    'Brokerage Fee': {
                                                        value: getAttribute(
                                                            selectedRow?.transaction,
                                                            'TRADE',
                                                            'BROKERAGE_FEE'
                                                        ),
                                                        renderProp: {
                                                            minDecimalPos: 2,
                                                            maxDecimalPos: 2
                                                        },
                                                        key: 'brokerage'
                                                    }
                                                })
                                            }),
                                            ...(appConfigState.getProduct(selectedRow?.transaction?.productId)?.productBase === ProductBase.Certificate && 
                                            {
                                                'Fuel Sources':{
                                                    value:selectedRow?.fuelSource,
                                                    key:'fuelSource'
                                                }
                                            }),
                                            ...(selectedRow?.transaction?.request.type ===
                                                TransactionRequestType.BAKE_HOLDING && {
                                                'Reason to Retire': {
                                                    value: getAttribute(
                                                        selectedRow?.transaction,
                                                        'RETIREMENT',
                                                        'REASON'
                                                    ),
                                                    key: 'reason'
                                                }
                                            })
                                        }}
                                    />
                                </>
                            )
                        },
                        {
                            label: 'Holdings',
                            contents: (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Holding</TableCell>
                                            <TableCell>State</TableCell>
                                            <TableCell align="right">Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedRow?.spentHoldings?.map((holding, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {getClientName(holding?.accountId)}
                                                </TableCell>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {holding?.holdingId}
                                                </TableCell>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {holding?.state}
                                                </TableCell>
                                                <TableCell align="right" sx={{ border: 'none' }}>
                                                    <HoldingAmount
                                                        spentAmount={holding?.amount}
                                                        minDecimalPos={0}
                                                        maxDecimalPos={0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {selectedRow?.issuedHoldings?.map((holding, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {getClientName(holding?.accountId)}
                                                </TableCell>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {holding?.holdingId}
                                                </TableCell>
                                                <TableCell sx={{ border: 'none' }}>
                                                    {holding?.state}
                                                </TableCell>
                                                <TableCell align="right" sx={{ border: 'none' }}>
                                                    <HoldingAmount
                                                        issuedAmount={holding?.amount}
                                                        minDecimalPos={0}
                                                        maxDecimalPos={0}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )
                        }
                    ]}
                />
            )}
            

        </>
    );
};




const BIG_ZERO = Big('0');

/**
 * Holding amount component that display the amount based on the holding state
 *
 * @param issuedAmount
 * @param spentAmount
 * @param minDecimalPos
 * @param maxDecimalPos
 * @returns {JSX.Element} AmountFormatWrapper
 * 
 * Note: Logic is based on Core 10 UI
 * https://gitlab.com/trovio/tech/product/core10/-/blob/master/wallet/wallet-host-frontend/src/component/ProductAmount.js?ref_type=heads#L21
 */
const HoldingAmount = ({
    issuedAmount,
    spentAmount,
    minDecimalPos,
    maxDecimalPos
}: {
    issuedAmount?: string;
    spentAmount?: string;
    minDecimalPos?: number;
    maxDecimalPos?: number;
}) => {
    const bigSpentAmount = spentAmount ? Big(spentAmount) : BIG_ZERO;
    const bigAmount = (issuedAmount ? Big(issuedAmount) : BIG_ZERO).minus(bigSpentAmount);

    return (
        <AmountFormatWrapper
            amount={bigAmount}
            minDecimalPos={minDecimalPos || 0}
            maxDecimalPos={maxDecimalPos || 0}
            fixedWidth={true}
        />
    );
};

export default Transactions;
