
enum StorageType {
    SESSION_STORAGE,
    LOCAL_STORAGE,
}

/**
 * A class that's keeping track of the list of storage items that should not be kept around when a user is signed out
 *
 * Exposes {@link LOCAL_STORAGE_KEYS} and {@link SESSION_STORAGE_KEYS}, each being an extensive set of items being
 * stored in the corresponding storage
 */
class StorageItemKey {
    static LOCAL_STORAGE_KEYS: string[] = [];
    static SESSION_STORAGE_KEYS: string[] = [];

    static PHYSICAL_TRADE_FORM = new StorageItemKey('PhysicalTradeTicketDataKey', StorageType.LOCAL_STORAGE);
    static FORWARD_TRADE_FORM = new StorageItemKey('ForwardTradeTicketDataKey', StorageType.LOCAL_STORAGE);
    static RETIREMENT_FORM = new StorageItemKey('RetirementTicketDataKey', StorageType.LOCAL_STORAGE);

    readonly key: string;
    readonly type: StorageType;

    constructor(key: string, type: StorageType) {
        this.key = key;
        this.type = type;
        let storage = type === StorageType.SESSION_STORAGE
            ? StorageItemKey.SESSION_STORAGE_KEYS
            : StorageItemKey.LOCAL_STORAGE_KEYS;
        storage.push(key);
    }

    private getStorage(): Storage {
        switch (this.type) {
            case StorageType.SESSION_STORAGE:
                return sessionStorage;
            case StorageType.LOCAL_STORAGE:
                return localStorage;
        }
    }

    getItem(): string | null {
        return this.getStorage().getItem(this.key);
    }

    setItem(item: string): void {
        this.getStorage().setItem(this.key, item);
    }
}

const getTableColumnsStorageItemKey = (tableName: string): StorageItemKey => {
    return new StorageItemKey(`${tableName}ColumnVisibility`, StorageType.LOCAL_STORAGE);
}


export { StorageItemKey, getTableColumnsStorageItemKey };
