import { useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Draft, DraftData, Drafts, DRAFTS_MODEL_VERSION, DraftsMetadata, draftsReviver } from './FormDraftsModel';

const storageSpace = localStorage;

/**
 * Custom hook providing persistent form draft capabilities. Call {@link initialiseDrafts} before use.
 *
 * @param storageKey the unique key used to identify stored values
 * @param defaultData default form used for new draft initiation
 * @param formVersion version (discriminator) of the actual data: gives us the ability to easily
 * invalidate stored data when its interface changes
 * @param onDraftSelect callback to be invoked on every draft select (including selects that result
 * from {@link initialiseDrafts} or {@link deleteDraft}
 */
const useFormDrafts = <T extends DraftData>(
    storageKey: string,
    defaultData: any,
    formVersion: string,
    onDraftSelect: () => void,
) => {
    const drafts = useRef<Drafts<T>>();
    const [draftsMetadata, setDraftsMetadata] = useState<DraftsMetadata>();

    const initialiseDrafts = (): T => {
        drafts.current = loadDraftsFromStorage();
        return selectDraft(drafts.current!.currentDraftId);
    };

    const selectDraft = (id: string | null): T => {
        onDraftSelect();
        if (id == null) {
            let draft = buildNewDraft();
            drafts.current!.list.unshift(draft); // add as first draft
            id = draft.id;
        }
        drafts.current!.currentDraftId = id;
        write(drafts.current!);
        return getDraft(id)!.data;
    };

    const deleteDraft = (id: string, switchToNewTab: boolean = false): T => {
        let deletedIndex = drafts.current!.list.findIndex(draft => draft.id === id);
        drafts.current!.list.splice(deletedIndex, 1);
        let length = drafts.current!.list.length;
        let draftId = null;
        if (!switchToNewTab && length > 0) {
            draftId = length > deletedIndex
                ? drafts.current!.list[deletedIndex].id
                : drafts.current!.list[length - 1].id;
        }
        return selectDraft(draftId);
    };

    const updateDraft = (applyUpdate: (data: T) => void, id: string = drafts.current!.currentDraftId): void => {
        let targetDraft = getDraft(id);
        if (targetDraft) {
            applyUpdate(targetDraft.data);
            targetDraft.modifiedDate = dayjs();
            write(drafts.current!);
        }
    };

    const loadDraftsFromStorage = () => {
        let storedString = storageSpace.getItem(storageKey);
        if (storedString) {
            let storedObject = JSON.parse(storedString, draftsReviver);
            if (hasCorrectVersion(storedObject)) {
                return storedObject;
            }
        }
        let defaultDraft = buildNewDraft();
        return { version: DRAFTS_MODEL_VERSION, list: [defaultDraft], currentDraftId: defaultDraft.id };
    };

    const buildNewDraft = (): Draft<T> => {
        return { id: crypto.randomUUID(), data: { ...defaultData, version: formVersion }, modifiedDate: dayjs() };
    };

    const getDraft = (id: string): Draft<T> | undefined => {
        return drafts.current!.list.find(draft => draft.id === id);
    };

    const hasCorrectVersion = (drafts: Drafts<T>) => {
        return drafts.version === DRAFTS_MODEL_VERSION && !drafts.list.some(d => d.data.version !== formVersion);
    };

    const write = (source: Drafts<T>) => {
        // update stored values
        storageSpace.setItem(storageKey, JSON.stringify(drafts.current!));
        // rebuild metadata
        let getDraftName = (modifiedDate: Dayjs) => modifiedDate.isSame(dayjs(), 'day')
            ? modifiedDate.format('HH:mm:ss')
            : modifiedDate.format('DD MMM');
        setDraftsMetadata({
            drafts: source.list.map(draft => ({ id: draft.id, name: getDraftName(draft.modifiedDate) })),
            currentDraftId: source.currentDraftId,
        });
    };

    return {
        draftsMetadata,
        initialiseDrafts,
        selectDraft,
        updateDraft,
        deleteDraft,
        getCurrentDraftId: () => drafts.current!.currentDraftId,
    };
};

export { useFormDrafts };
