import {
    Box,
    Button,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress
} from '@mui/material';
import {
    useEmissionsDispatch,
    useEmissionsState,
    defaultEmissionsForm,
    DialogState
} from './state/EmissionsState';
import { useRef } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { useForm, Controller } from 'react-hook-form';
import { renderDialogField } from '../../utility/DialogFieldRenderUtil';
import Link from '@mui/material/Link';
import { TransactionOverview } from '../../component/TransactionOverview';
import { AlertDialogContent, AlertType } from '../../component/AlertDialogContent';
import { useAppConfigState } from '../../state/AppConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const EmissionsForm = () => {
    const {
        isEmissionsFormOpen,
        submitEmissionForm,
        formData,
        uploadFile,
        uploadError,
        dialogState,
        performIssueEmissionAsset,
        requesting,
        fetchItemsForEmissionAsset
    } = useEmissionsState();
    const { setFormData, setIsEmissionsFormOpen, setUploadError, setDialogState } =
        useEmissionsDispatch();
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        reset,
        trigger
    } = useForm();

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const appConfigState = useAppConfigState();

    // opens finder to select document
    const openFinder = () => {
        // clear upload error
        setUploadError(false);
        // Trigger a click event on the hidden file input when the button is clicked
        fileInputRef.current?.click();
    };

    // remove attached document
    const removeFile = () => {
        // remove document from state
        setFormData((prevState) => {
            // new object without the document property
            const { document, ...newState } = prevState;
            return newState;
        });
    };

    // close form
    const handleEmissionsFormClose = () => {
        setIsEmissionsFormOpen(false);

        // refresh table data
        if (dialogState === DialogState.SUCCESS || dialogState === DialogState.ERROR) {
            fetchItemsForEmissionAsset();
        }

        // clear fields
        setFormData(defaultEmissionsForm);
        reset(defaultEmissionsForm);
        setUploadError(false);
        setDialogState(DialogState.FORM);
    };

    return (
        <>
            <Dialog
                open={isEmissionsFormOpen}
                onClose={handleEmissionsFormClose}
                fullWidth
                maxWidth="sm"
            >
                {/* Main form */}
                {dialogState === DialogState.FORM && (
                    <form onSubmit={handleSubmit(submitEmissionForm)}>
                        <DialogContent>
                            <Typography variant="h2">Create New Asset</Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Controller
                                    name="emissionName"
                                    control={control}
                                    defaultValue={formData.emissionName}
                                    render={({ field }) => (
                                        <TextField
                                            id="emissionName"
                                            label={
                                                appConfigState.getAttribute(
                                                    'EMISSION_ASSET',
                                                    'NAME'
                                                ).display
                                            }
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginBottom: 2 }}
                                            error={errors.emissionName ? true : false}
                                            helperText={errors.emissionName?.message as string}
                                            {...field}
                                        />
                                    )}
                                    rules={{ required: 'This field is required' }}
                                />

                                <Controller
                                    name="emissionIntensity"
                                    control={control}
                                    defaultValue={formData.emissionIntensity}
                                    render={({ field }) => (
                                        <TextField
                                            id="emissionIntensity"
                                            label="Total Emission Intensity"
                                            variant="outlined"
                                            size="small"
                                            sx={{ marginBottom: 2 }}
                                            inputProps={{
                                                inputMode: 'numeric'
                                            }}
                                            error={errors.emissionIntensity ? true : false}
                                            helperText={errors.emissionIntensity?.message as string}
                                            {...field}
                                            onChange={(e) => {
                                                // Check if the input is valid, accepts whole and decimal values
                                                const validIntensity = /^[0-9]*\.?[0-9]*$/.test(
                                                    e.target.value
                                                );
                                                if (validIntensity) {
                                                    field.onChange(e.target.value);
                                                    trigger('emissionIntensity').then();
                                                }
                                            }}
                                        />
                                    )}
                                    rules={{
                                        required: 'This field is required',
                                        validate: (value) => {
                                            const numericValue = parseFloat(value);
                                            if (isNaN(numericValue)) {
                                                return 'Invalid input';
                                            }
                                            if (numericValue <= 0) {
                                                return 'Intensity must be greater than zero';
                                            }
                                            return true;
                                        }
                                    }}
                                />

                                {formData.document?.contentType ? (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'nowrap',
                                                alignItems: 'center',
                                                marginBottom: 2
                                            }}
                                        >
                                            <CloudDoneIcon />
                                            <Typography sx={{ marginLeft: 2 }}>
                                                File Uploaded
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            onClick={removeFile}
                                            startIcon={<ClearIcon />}
                                        >
                                            Remove File
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={openFinder}
                                            startIcon={<CloudUploadIcon />}
                                        >
                                            Upload Document
                                            <input
                                                hidden
                                                accept="*/*"
                                                type="file"
                                                onChange={uploadFile}
                                                ref={fileInputRef}
                                            />
                                        </Button>

                                        {uploadError && (
                                            <Typography sx={{ color: 'red', marginTop: 1 }}>
                                                Error uploading document
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <Button variant="outlined" onClick={handleEmissionsFormClose}>
                                Cancel
                            </Button>
                            <Button variant="outlined" type="submit" disabled={!isValid}>
                                Submit
                            </Button>
                        </DialogActions>
                    </form>
                )}

                {/* Review dialog */}
                {dialogState === DialogState.REVIEW && (
                    <>
                        <DialogContent>
                            <Typography variant="h2">Review Details Below</Typography>

                            {renderDialogField('Name', formData.emissionName)}
                            {renderDialogField(
                                'Total Emission Intensity',
                                formData.emissionIntensity
                            )}
                            {formData.document?.contentType && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography>Document</Typography>
                                    <Link
                                        align="right"
                                        href={formData.document.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faUpRightFromSquare}
                                            style={{ paddingLeft: '2rem' }}
                                        />
                                    </Link>
                                </Box>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() => setDialogState(DialogState.FORM)}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Button onClick={performIssueEmissionAsset} variant="outlined">
                                {requesting ? <CircularProgress size={24} /> : 'Confirm'}
                            </Button>
                        </DialogActions>
                    </>
                )}

                {/* Error Dialog */}
                {dialogState === DialogState.ERROR && (
                    <>
                        <AlertDialogContent
                            alertType={AlertType.Error}
                            alertMessage="An error occurred while issuing emission asset"
                            errorCode={null}
                            handleDialogClose={handleEmissionsFormClose}
                        />
                    </>
                )}
            </Dialog>

            {/* Success dialog */}
            {dialogState === DialogState.SUCCESS && (
                <>
                    <TransactionOverview
                        open={isEmissionsFormOpen}
                        onClose={handleEmissionsFormClose}
                        title="Transaction Submitted Successfully"
                        attributes={{
                            Name: { value: formData.emissionName, key:'emissionName' },
                            'Total Emission Intensity': { value: formData.emissionIntensity , key:'totalEmissionIntensity'},
                            ...(formData.document?.url && {
                                Document: {
                                    value: (
                                        <Link
                                            align="right"
                                            href={formData.document.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon
                                                size="lg"
                                                icon={faUpRightFromSquare}
                                                style={{ paddingLeft: '2rem' }}
                                            />
                                        </Link>
                                    ),
                                    key:'document'
                                }
                            })
                        }}
                    />
                </>
            )}
        </>
    );
};

export default EmissionsForm;
