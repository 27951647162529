
// Create a union type from the values defined in supportedCurrencies
const supportedCurrencies = ['AUD', 'USD'] as const;
type Currency = (typeof supportedCurrencies)[number];

const projectKeyNames = {
    clientAmountAssigned: 'Client Holdings',
    price: 'Price (AUD)',
    product: 'Product',
    marketHoldings: 'Market Holdings (Locked)'
};

//We should never change these values
enum TransactionType {
    PhysicalSell = 'Physical Sell',
    PhysicalBuy = 'Physical Buy',
    ForwardSell = 'Forward Sell',
    ForwardBuy = 'Forward Buy',
    ForwardSellDeliver = 'Forward Sell Deliver',
    ForwardBuyDeliver = 'Forward Buy Deliver',
    InventoryRetirement = 'Inventory Retirement',
    ClientRetirement = 'Client Retirement',
    LockToMarket = 'Lock to Market'
}

const isTrade = (t?: TransactionType): Boolean => [
    TransactionType.PhysicalBuy, TransactionType.PhysicalSell, TransactionType.ForwardBuy, TransactionType.ForwardSell, TransactionType.ForwardSellDeliver].some((a) => a === t);
    
const isRetirement = (t?: TransactionType): Boolean => [
    TransactionType.InventoryRetirement, TransactionType.ClientRetirement].some((a) => a === t);

const LOGIN_CALLBACK_PATH = '/callback/login';
const LOGOUT_CALLBACK_PATH = '/callback/logout'

// Emission & Decarbonisation
const EMISSION_UNIT_AMOUNT: string = '1';
const EMISSION_ASSET_AMOUNT: string = '1';

const COGNITO_GROUPS_KEY = `cognito:groups`;
const ADMIN_GROUP_ID = "commodity_desk:admin";

export {
    supportedCurrencies,
    type Currency,
    projectKeyNames, 
    TransactionType,
    isTrade,
    isRetirement,
    LOGIN_CALLBACK_PATH,
    LOGOUT_CALLBACK_PATH,
    EMISSION_ASSET_AMOUNT,
    EMISSION_UNIT_AMOUNT,
    COGNITO_GROUPS_KEY,
    ADMIN_GROUP_ID
};
