import React from 'react';
import { AmountFormat } from "@trovio-tech/trovio-core-api-jsx";
import { getDefaultLocaleConfig } from '../state/LocaleConfig';

interface AmountFormatWrapperProps {
    amount: any;
    minDecimalPos: number;
    maxDecimalPos: number;
    fixedWidth?: boolean;
}

/**
 * Wrapper JSX Component for the AmountFormat lib that deals with its
 * return type and ensures that the output is always a JSX element
 *
 * @param param0 @see AmountFormatWrapperProps
 * @returns @see JSX.Element
 */
const AmountFormatWrapper = ({
    amount,
    minDecimalPos,
    maxDecimalPos,
    fixedWidth = false
}: AmountFormatWrapperProps) => {
    const localeConfig = getDefaultLocaleConfig();
    
    let value = AmountFormat({
        amount: amount,
        minDecimalPos: minDecimalPos,
        maxDecimalPos: maxDecimalPos,
        groupSep: localeConfig.amountFormatGroupSeparator,
        decimalPoint: localeConfig.amountFormatDecimalPoint,
        fixedWidth: fixedWidth
    });

    if (typeof value === 'string') {
        // If the value returned is string then wrap in div to make it a JSX element
        return <>{value}</>;
    } else if (React.isValidElement(value)) {
        // If the value is a valid JSX element then return it as is
        return value;
    }

    console.warn(`Unexpected type for AmountFormat: '${value}', with type: '${typeof value}'`);
    return <></>;
};

export { AmountFormatWrapper };
