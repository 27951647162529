import {
    Dialog,
    DialogContent,
    LinearProgress,
    Typography,
    Box,
    DialogActions,
    Button,
    Link
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import { useTheme } from '@mui/material/styles';
import { truncateUri } from '../utility/TruncateUri';
import { ProductBase, useAppConfigState } from '../state/AppConfig';
import { renderFieldValue } from '../utility/DialogFieldRenderUtil';

interface HoldingDisplayDetails {
    holdingId: string;
    forwardId?: string;
    valueDate?: string;
    accountId: string;
    amount: string;
    productCode: string;
    productBase: ProductBase;
    projectId: string;
    projectName: string;
    projectType: string;
    projectCountry: string;
    projectState: string | undefined;
    projectUri: string;
    projectVintage: number;
    certificateAccreditationCode: string;
    certificateFuelSource: string;
    certificateGenerationYear: number;
    certificateCreationYear: number;
    certificateGenerationState: string;
    certificateGreenpowerAccredited: string;
    tradeTimestamp?: string;
    tradeId?: string;
    tradeCurrency?: string;
    tradePrice?: string;
    traderName?: string;
    salesPerson?: string;
    salesCredits?: string;
    brokerName?: string;
    brokerFee?: string;
    serialNumbers?: string;
    maxDecimalPos: number;
    minDecimalPos: number;
}

/**
 * Props for the {HoldingDetails} Component
 */
interface HoldingDetailsProps {
    /**
     * Boolean to indicate the dialog window should be displayed
     */
    open: boolean;

    /**
     * Callback Function that would be called upon dismissal of the dialog window
     * @returns {void}
     */
    onClose: () => void;

    /**
     * Boolean to indicate if the loading bar should be displayed
     */
    loading: boolean;

    /**
     * Response data
     */
    data: HoldingDisplayDetails;
}

const HoldingDetails = ({ open, onClose, loading, data }: HoldingDetailsProps) => {
    const theme = useTheme();
    const appConfigState = useAppConfigState();

    // formatting for each row rendered in modal
    const renderDialogRow = (key: string, value: any) => {
        if (value === undefined) {
            return '';
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    marginBottom: '1rem'
                }}
            >
                <Typography sx={{ flex: '0 0 130px' }}>{key}</Typography>

                {/* print a truncated clickable link for the uri value */}
                {key.toLowerCase().includes('uri') && value !== undefined ? (
                    <Link
                        href={value}
                        target="_blank"
                        style={{
                            color: theme.palette.text.primary,
                            textAlign: 'right',
                            flex: '1',
                            wordBreak: 'break-word'
                        }}
                    >
                        {truncateUri(value)}
                    </Link>
                ) : (
                    <Typography sx={{ flex: '1', wordBreak: 'break-word', textAlign: 'right' }}>
                        {renderFieldValue(value)}
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            {loading ? (
                <DialogContent>
                    <LinearProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent>
                        {/* Modal title ***********/}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'row'
                            }}
                        >
                            <FontAwesomeIcon icon={faBoxes} />
                            <Typography variant="h2" sx={{ margin: '0 8px' }}>
                                {data.holdingId ? 'Holding' : data.forwardId ? 'Forward Trade' : ''}
                            </Typography>
                            <Typography variant="h2">{data.holdingId ? data.holdingId : data.forwardId ? data.forwardId : ''}</Typography>
                        </Box>

                        {/* Modal Body ************/}
                        {renderDialogRow('Value Date:', data.valueDate)}
                        {renderDialogRow('Held By:', data.accountId)}
                        {renderDialogRow(
                            'Amount:',
                            <AmountFormatWrapper
                                amount={data.amount}
                                minDecimalPos={data.minDecimalPos}
                                maxDecimalPos={data.maxDecimalPos}
                            />
                        )}
                        {renderDialogRow('Product:', data.productCode)}

                        {data.productBase === ProductBase.Project && (
                            <>
                                <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                    Project Details
                                </Typography>
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'ID').display}:`, data.projectId)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'NAME').display}:`, data.projectName)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'TYPE').display}:`, data.projectType)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').display}:`, data.projectCountry)}
                                {data.projectState && renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'STATE').display}:`, data.projectState)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'URI').display}:`, data.projectUri)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'VINTAGE').display}:`, data.projectVintage)}
                            </>
                        )}
                        {data.productBase === ProductBase.Certificate && (
                            <>
                                <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                    Certificate Details
                                </Typography>
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').display}:`, data.certificateAccreditationCode)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').display}:`, data.certificateFuelSource)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').display}:`, data.certificateGenerationYear)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').display}:`, data.certificateCreationYear)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').display}:`, data.certificateGenerationState)}
                                {renderDialogRow(`${appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').display}:`, data.certificateGreenpowerAccredited)}
                            </>
                        )}

                        {data.tradeTimestamp && (
                            <>
                                <Typography variant="h3" sx={{ margin: '3rem 0 1.5rem 0' }}>
                                    Trade Details
                                </Typography>
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'TIMESTAMP').display}:`, data.tradeTimestamp)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'ID').display}:`, data.tradeId)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'CURRENCY').display}:`, data.tradeCurrency)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'PRICE').display}:`, data.tradePrice)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'TRADER_NAME').display}:`, data.traderName)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'SALES_PERSON').display}:`, data.salesPerson)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'SALES_CREDITS').display}:`, data.salesCredits)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'BROKER_NAME').display}:`, data.brokerName)}
                                {renderDialogRow(`${appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').display}:`, data.brokerFee)}
                            </>
                        )}

                        {renderDialogRow('Serial Numbers:', data.serialNumbers)}
                    </DialogContent>

                    {/* close modal button ********/}
                    <DialogActions>
                        <Button onClick={onClose} color="primary" variant="outlined">
                            Close
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export { HoldingDetails, type HoldingDisplayDetails };
