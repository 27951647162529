import { ProjectDetailsProvider } from './state/ProjectDetailsState';
import ProjectVintageDetails from './ProjectVintageDetails';
import { ProjectVintageDetailsProvider } from './state/ProjectVintageDetailsState';

const ContainProjectVintageDetails = () => {
    return (
        <ProjectDetailsProvider>
            <ProjectVintageDetailsProvider>
                <ProjectVintageDetails />
            </ProjectVintageDetailsProvider>
        </ProjectDetailsProvider>
    );
};

export default ContainProjectVintageDetails;
