import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import { TransactionValueLabel, TransactionValueProps } from './TransactionValueLabel';

type TransactionAttributesKey =
    | 'transactionTimestamp'
    | 'transactionId'
    | 'transactionTypeClient'
    | 'tradeDirection'
    | 'forwardId'
    | 'tradeDate'
    | 'tradeId'
    | 'tradeType'
    | 'trader'
    | 'registry'
    | 'counterParty'
    | 'projectId'
    | 'projectName'
    | 'productId'
    | 'projectType'
    | 'vintage'
    | 'quantity'
    | 'valueDate'
    | 'currency'
    | 'price'
    | 'salesPerson'
    | 'salesCredits'
    | 'market'
    | 'state'
    | 'country'
    | 'accountId'
    | 'holdings'
    | 'reason'
    | 'emissionId'
    | 'emissionName'
    | 'totalEmissionIntensity'
    | 'document'
    | 'brokerName'
    | 'brokerage'
    | 'accreditationCode'
    | 'fuelSource'
    | 'generationYear'
    | 'creationYear'
    | 'generationState'
    | 'greenpowerAccredited';

type TransactionOverviewAttributeProps = TransactionValueProps & {
    key?: TransactionAttributesKey;
    hideLabel?: boolean;
};
/**
 * Props for the {TransactionOverview} Component
 */
interface TransactionOverviewProps {
    /**
     * Boolean to indicate whether the dialog window should be displayed
     */
    open: boolean;

    /**
     * Callback Function that would be called upon dismissal of the dialog window
     * @returns {void}
     */
    onClose: () => void;

    /**
     * The title of the Dialog window
     */
    title: string;

    /**
     * Optional text to display above the overview section
     */
    bodyText?: string;

    /**
     * A dictionary of key, value string pairs where the key represents the attributes
     * to show in the overview and the values represent the corresponding value
     */
    attributes: { [key: string]: TransactionOverviewAttributeProps };

    /**
     * Optional actions to display on the overview, for example 'Deliver Units' or 'Cancel Forward',
     * used for forward trade rows.
     */
    actions?: TransactionAction[];
}

/**
 * An action component on the transaction overview dialog
 */
interface TransactionAction {
    label: string;
    actionType: ActionType;
    action?: any;
}

enum ActionType {
    Button = 'Button',
    Info = 'Info'
}

const TransactionOverview = ({
    open,
    onClose,
    title,
    attributes,
    bodyText,
    actions
}: TransactionOverviewProps) => {
    // separate the actions into info and button actions
    const infoActions = actions?.filter((action) => action.actionType === ActionType.Info) || [];
    const buttonActions =
        actions?.filter((action) => action.actionType === ActionType.Button) || [];
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                sx={{
                    '.MuiPaper-root': { maxWidth: '870px', minHeight: 380 }
                }}
            >
                <DialogContent>
                    <Typography variant="h2" mb={2}>
                        {title}
                    </Typography>
                    {bodyText && (
                        <Box mb={2}>
                            <Typography>
                                Your transaction has been submitted successfully.
                            </Typography>
                        </Box>
                    )}

                    {/* Render the info actions */}
                    {infoActions.length > 0 && (
                        <Box mb={2} textAlign={'center'}>
                            {infoActions.map((action) => (
                                <Typography sx={{ marginRight: '20px' }}>{action.label}</Typography>
                            ))}
                        </Box>
                    )}

                    {/* Render the button actions */}
                    {buttonActions.length > 0 && (
                        <Box mb={2} display="flex" justifyContent="flex-end" gap={1.5}>
                            {buttonActions.map((action) => (
                                <Button onClick={action.action} color="primary" variant="outlined">
                                    {action.label}
                                </Button>
                            ))}
                        </Box>
                    )}

                    <TransactionContents attributes={attributes} />
                </DialogContent>
                <DialogActions sx={{p:3, pt:0}}>
                    <Button onClick={onClose} color="primary" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

type Group = {
    label: string;
    keys: TransactionAttributesKey[];
};

/**
 * Renders the transaction contents as inline or group based on the isGrouped prop
 * @param attributes - the attributes to render
 * @param isGrouped - boolean to indicate whether the attributes should be grouped
 * @returns {JSX.Element} - the rendered component
 */
const TransactionContents = ({
    attributes,
    productTableView
}: {
    attributes: { [key: string]: TransactionOverviewAttributeProps };
    productTableView?: boolean;
}) => {
    const [isMore, setIsMore] = useState<boolean>(false);

    const renderDialogField = (
        fieldName: string,
        fieldValue: TransactionOverviewAttributeProps
    ): JSX.Element => {
        return (
            <Box
                key={`${fieldName}`}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    marginBottom: '1rem'
                }}
            >
                {!fieldValue.hideLabel && <Typography>{fieldName}:</Typography>}
                <Box sx={{ flex: '1', textAlign: 'right' }}>
                    <TransactionValueLabel data={{ ...fieldValue }} />
                </Box>
            </Box>
        );
    };

    // This function is used to group the attributes based on the group keys provided in the groupBy function
    // This Group keys applied as global , so any attributes key matches with the group key will be grouped
    const groupBy = (
        ...groups: Group[]
    ): { label: string; fields: TransactionOverviewAttributeProps[] }[] => {
        // initialize the result array, to store final grouped attributes
        const result: { label: string; fields: TransactionOverviewAttributeProps[] }[] = [];

        // initialize the object, to store the attributes with key as attribute key and value as attribute object {label, value, renderProp etc}
        const obj: typeof attributes = {};

        // loop through the attributes and store the attributes in the object, with key as attribute key and value as attribute object {label, value, renderProp etc}
        for (const attributeKey in attributes) {
            if (attributes.hasOwnProperty(attributeKey)) {
                const currentAttribute = attributes[attributeKey];
                if (currentAttribute.key) {
                    obj[currentAttribute.key] = {
                        ...currentAttribute,
                        label: attributeKey
                    };
                }
            }
        }

        // loop through the group keys and store the attributes in the result array, with label as group label and fields as array of attributes
        groups.forEach((group) => {
            const fields: TransactionOverviewAttributeProps[] = [];
            group.keys.forEach((key) => {
                if (obj[key]) {
                    fields.push({ ...obj[key] });
                }
            });
            result.push({ label: group.label, fields });
        });

        // all keys included in all groups
        const allFields = groups.reduce<string[]>((acc, group) => {
            return acc.concat(group.keys);
        }, []);

        // remaining fields that are not included in any group
        const remainingFields = Object.keys(obj)
            .filter((key) => !allFields.includes(key))
            .map((key) => obj[key]);

        // Put the remaining fields in the Attributes group with label as Attributes
        if (remainingFields.length > 0) {
            result.push({ label: 'Attributes', fields: remainingFields });
        }

        // filter and returns only the groups that have fields
        return result?.filter((group) => group.fields.length > 0);
    };

    const groupedItems = groupBy(
        {
            label: 'Transaction',
            keys: [
                'transactionTimestamp',
                'transactionTypeClient',
                'tradeDate',
                'forwardId',
                'transactionId',
                'tradeId',
                'tradeType',
                'trader',
                'counterParty',
                'registry'
            ]
        },
        {
            label: 'Product',
            keys: productTableView
                ? [
                      'productId',
                      'quantity',
                      'projectName',
                      'projectType',
                      'projectId',
                      'vintage',
                      'holdings'
                  ] // for table view, we move projects table to the top
                : [
                      'productId',
                      'quantity',
                      'projectType',
                      'projectId',
                      'projectName',
                      'vintage',
                      'holdings'
                  ]
        },
        {
            label: 'Emission',
            keys: ['emissionName', 'emissionId', 'totalEmissionIntensity', 'document']
        }
    );

    // As we have 2 panel layout, we need to split the groupedItems into 2 columns
    // Once more call to action is clicked, second column will be shown
    const firstColumns = groupedItems.slice(0, 2);
    const secondColumns = groupedItems.slice(2, groupedItems.length);

    const showMoreOption = secondColumns.length > 0 && isMore;

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${showMoreOption ? 2 : 1}, 1fr)`,
                    gap: 2,
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ display: 'grid', gap: 2 }}>
                    {firstColumns?.map((group, index) => (
                        <BoxStyled className="border" key={index}>
                            <BoxStyled className="padding header">
                                <Typography variant="h3" sx={{ margin: 0 }}>
                                    {group.label}
                                </Typography>
                            </BoxStyled>

                            <BoxStyled className="padding">
                                {group.fields?.map((field) =>
                                    renderDialogField(field.label || '', field)
                                )}
                            </BoxStyled>
                        </BoxStyled>
                    ))}
                </Box>

                {showMoreOption && (
                    <Box sx={{ display: 'grid', gap: 2 }}>
                        {secondColumns?.map((group, index) => (
                            <BoxStyled className="border" key={index}>
                                <BoxStyled className="padding header">
                                    <Typography variant="h3" sx={{ margin: 0 }}>
                                        {group.label}
                                    </Typography>
                                </BoxStyled>

                                <BoxStyled className="padding">
                                    {group.fields?.map((field) =>
                                        renderDialogField(field.label || '', field)
                                    )}
                                </BoxStyled>
                            </BoxStyled>
                        ))}
                    </Box>
                )}
            </Box>
            {secondColumns.length > 0 && (
                <Box mt={2}>
                    <Button sx={{ padding: '0px' }} onClick={() => setIsMore(!isMore)}>
                        {isMore ? 'Less...' : 'More...'}
                    </Button>
                </Box>
            )}
        </>
    );
};

const BoxStyled = styled('div')(({ theme }) => ({
    minWidth: '400px',
    '&.padding': {
        padding: '10px 15px'
    },
    '&.border': {
        border: `1px solid ${alpha(theme.palette.secondary.dark, 0.3)}`,
        borderRadius: '5px',
        overflow: 'hidden'
    },
    '&.header': {
        backgroundColor: alpha(theme.palette.secondary.dark, 0.2)
    }
}));

export { TransactionContents, TransactionOverview, ActionType };
