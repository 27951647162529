import { Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ConfirmDialog, DialogState } from '../../component/ConfirmDialog';
import { useAppConfigState } from '../../state/AppConfig';
import { useAuth } from '../../state/AuthProvider';
import { executeTransaction } from '../../state/CortenClient';
import { useTransactionMonitor } from '../../state/transaction/TransactionMonitor';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { renderDialogField } from '../../utility/DialogFieldRenderUtil';
import { LockedHoldingProps, useLockedHoldings } from './state/LockedHoldingState';
import { useProductState } from './state/ProductState';
import { useProductDataState } from '../../state/ProductDataProvider';
import { ProductData } from '../../model/Shared';

const LockedHoldings = () => {
    const user = useAuth();
    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();
    const { mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const { fetchLockedHoldings, isLoading, data, total, page, pageSize, handlePagination, onSuccess } =
        useLockedHoldings();

    // States for Confirm dialog
    const [dialogState, setDialogState] = useState<{
        currentSelectedItem: LockedHoldingProps | undefined;
        currentState: DialogState | undefined;
        isError?: boolean;
        errorCode?: any;
        isLoading: boolean;
    }>({
        currentSelectedItem: undefined,
        currentState: 'REVIEW',
        isLoading: false
    });

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            // On Success
            setDialogState((prev) => ({
                ...prev,
                currentState: 'SUCCESS',
                isLoading: false
            }));
        },
        (tx) =>
            // On Error
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                errorCode: tx?.errorCode,
                isLoading: false
            })),
        () =>
            // On TimeOut
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                errorCode: 'TimeOut',
                isLoading: false
            }))
    );

    // Handle  Unlock holding
    const handleUnlock = async (
        fromAccountId: string,
        controllerAccountId: string,
        escrowId: string
    ) => {
        try {
            setDialogState((prev) => ({
                ...prev,
                isLoading: true
            }));

            const request = {
                type: 'CancelEscrowRequest',
                fromAccountId,
                controllerAccountId,
                escrowId,
                requesterIsController: false
            };

            const res = await executeTransaction(request, user);

            // Check for Success
            if (res.status >= 200 && res.status < 300) {
                // If Success, Subscribe for transaction status
                res.json().then((result) => {
                    subscribe(result.txId);
                });
            } else {
                // If Error, set dialog state to error state
                setDialogState((prev) => ({
                    ...prev,
                    currentState: 'ERROR',
                    isError: true,
                    errorCode: res?.status || 500,
                    isLoading: false
                }));
            }
        } catch (error: any) {
            // Catch error and update dialog state
            setDialogState((prev) => ({
                ...prev,
                currentState: 'ERROR',
                isError: true,
                errorCode: error?.code || 500,
                isLoading: false
            }));
        }
    };

    // On Dialog Close, handle onSuccess func on Locked holding state also reset unique products in useInventoryManagementState hooks and context
    const handleDialogClose = async () => {
        unsubscribe();
        onSuccess(dialogState.currentSelectedItem?.escrowId!);
        fetchAndInitProducts(mblSelectedProduct.id);
        setDialogState((prev) => ({
            ...prev,
            currentSelectedItem: undefined,
            currentState: undefined
        }));
    };

    // On LockedHoldings Component mount, Load the data
    useEffect(() => {
        fetchLockedHoldings();
    }, []);

    return (
        <>
            <DataGrid
                loading={isLoading}
                rows={data}
                rowCount={total}
                pageSizeOptions={[20]}
                onPaginationModelChange={handlePagination}
                paginationMode="server"
                paginationModel={{ page, pageSize }}
                sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
                }}
                autoHeight={true}
                getRowId={(row) => `${row?.escrowId}-${new Date().valueOf()}`}
                columns={[
                    {
                        field: 'escrowId',
                        headerName: 'ID',
                        filterable: false,
                        sortable: false,
                        flex: 1
                    },
                    {
                        field: 'productId',
                        headerName: 'Product',
                        filterable: false,
                        sortable: false,
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <>{appConfigState.getProduct(params.row.productId)?.displayCode}</>
                            );
                        }
                    },
                    {
                        field: 'remainingAmount',
                        headerName: 'Quantity',
                        filterable: false,
                        sortable: false,
                        flex: 1,
                        type: 'number',
                        renderCell: (params) => {
                            return (
                                <AmountFormatWrapper
                                    amount={Number(params.row.remainingAmount)}
                                    minDecimalPos={productData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                    maxDecimalPos={productData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                />
                            );
                        }
                    },
                    {
                        field: 'controllerAccountId',
                        headerName: 'Market',
                        filterable: false,
                        sortable: false,
                        flex: 1,
                        renderCell: (params) => {
                            return <>{appConfigState.getAccount('MARKET_CONTROLLER').display}</>;
                        }
                    },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        filterable: false,
                        sortable: false,
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => {
                                        setDialogState((prev) => ({
                                            ...prev,
                                            currentSelectedItem: params.row,
                                            currentState: 'REVIEW'
                                        }));
                                    }}
                                >
                                    Unlock
                                </Button>
                            );
                        }
                    }
                ]}
                hideFooterSelectedRowCount={true}
                isRowSelectable={()=>false}
            />

            {/* Dialog  to handle confirm UI */}
            <ConfirmDialog
                isOpen={dialogState.currentSelectedItem ? true : false}
                isConfirmLoading={dialogState.isLoading}
                currentState={dialogState.currentState}
                handleAfterSuccess={() => handleDialogClose()}
                onCancel={() =>
                    setDialogState((prev) => ({
                        ...prev,
                        currentSelectedItem: undefined,
                        currentState: undefined
                    }))
                }
                onConfirm={() => {
                    if (dialogState.currentSelectedItem) {
                        const { accountId, controllerAccountId, escrowId } =
                            dialogState.currentSelectedItem;
                        handleUnlock(accountId, controllerAccountId, escrowId);
                    }
                }}
                render={(currentState) => {
                    // This  code conditionally renders different UI based on the value of the currentState.
                    // If currentState is 'REVIEW', it renders a UI for Review
                    if (currentState === 'REVIEW') {
                        return (
                            <>
                                <Typography variant="h2">Review Details Below</Typography>
                                {dialogState?.currentSelectedItem && (
                                    <>
                                        <HoldingPopUpContents
                                            id={dialogState.currentSelectedItem?.escrowId}
                                            productId={dialogState?.currentSelectedItem?.productId!}
                                            quantity={
                                                dialogState.currentSelectedItem?.remainingAmount
                                            }
                                            productData={productData}
                                        />
                                    </>
                                )}
                            </>
                        );
                    }
                    // If currentState is 'SUCCESS', it renders a UI for Success
                    if (currentState === 'SUCCESS') {
                        return (
                            <>
                                <Typography variant="h2">
                                    Transaction submitted successfully
                                </Typography>
                                {dialogState?.currentSelectedItem && (
                                    <>
                                        <HoldingPopUpContents
                                            id={dialogState.currentSelectedItem?.escrowId}
                                            productId={dialogState?.currentSelectedItem?.productId!}
                                            quantity={
                                                dialogState.currentSelectedItem?.remainingAmount
                                            }
                                            productData={productData}
                                        />
                                    </>
                                )}
                            </>
                        );
                    }
                    // If currentState is 'ERROR', it renders a UI for Error
                    if (currentState === 'ERROR') {
                        return (
                            <>
                                <Typography variant="h2">Transaction failed</Typography>
                                <Typography variant="h4">{dialogState?.errorCode}</Typography>
                                <Typography>Please try again later!!</Typography>
                            </>
                        );
                    }

                    return null;
                }}
            />
        </>
    );
};

export default LockedHoldings;

const HoldingPopUpContents = ({
    id,
    productId,
    quantity,
    productData
}: {
    id: string;
    productId: string;
    quantity: string;
    productData: Map<String,ProductData>
}) => {
    const appConfigState = useAppConfigState();

    return (
        <>
            {renderDialogField('ID', id)}
            {renderDialogField('Product', appConfigState.getProduct(productId!)?.displayCode)}
            {renderDialogField(
                'Quantity',
                quantity,
                {
                    minDecimalPos: productData.get(productId!)?.minDecimalPos!,
                    maxDecimalPos: productData.get(productId!)?.maxDecimalPos!,
                }
            )}
        </>
    );
};
