import { useEffect } from 'react';
import { useAuth } from '../state/AuthProvider'
import { accessKeyStore, useAccessKeyDispatch } from '../state/AccessKeyManager';
import {homepage, useAppNavigate} from '../component/AppNavigate'

function LogoutHandler() {
  const keyDispatch = useAccessKeyDispatch();
  const { userManager, setUser, setAccountId } = useAuth();
  const navigate= useAppNavigate()

  useEffect(() => {
    const signOut = async () => {
      // In most cases userManager will be undefined already. However, in the off chance that it is not, clear out any remaining details.
      if (userManager) {
        const currentKey = localStorage.getItem("currentKeyId");
        if (currentKey !== undefined) {
          accessKeyStore.removeKey(currentKey);
        }
        keyDispatch({ type: 'removeKey', id: currentKey });
        localStorage.setItem("currentKeyId", "");

        if (userManager?._settings?._metadata?.end_session_endpoint) {
          try {
            setUser(null);
          } catch (error) {
            console.error('Error during sign out:', error);
          }
        } else {
          try {
            await userManager.removeUser();
            setUser(null);
          } catch (error) {
            console.error('Error during sign out:', error);
          }
        }
        setAccountId('');
      }
    };

    signOut();
    navigate(homepage)
  }, [userManager, setUser, keyDispatch, navigate]);

  // Render nothing
  return null;
}

export default LogoutHandler;
