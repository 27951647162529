import {
    Box,
    Chip,
    Grid,
    MenuItem,
    OutlinedInput,
    SelectChangeEvent,
    TextField
} from '@mui/material';
import { SetStateAction, useState } from 'react';
import { AggregatorAttribute, getAttributes } from './AggregatorAttributes';
import { useAppConfigState } from '../../state/AppConfig';

const AggregatorInput = ({
    productId,
    setProductId,
    columnHeaders,
    setColumnHeaders,
    rowHeaders,
    setRowHeaders
}: {
    productId: string;
    setProductId: (value: SetStateAction<string>) => void;
    columnHeaders: string[];
    setColumnHeaders: (value: SetStateAction<string[]>) => void;
    rowHeaders: string[];
    setRowHeaders: (value: SetStateAction<string[]>) => void;
}) => {
    const appConfigState = useAppConfigState();
    const attributes = getAttributes(appConfigState)
    const [unselectedAttributes, setUnselectedAttributes] =
        useState<AggregatorAttribute[]>(attributes);

    /**
     * Function that is called whenever one of the axis header values are changed
     * 
     * @param event the event associated with the change
     * @param axisSetStateAction the dispatch function to call when value is changed
     */
    const handleAxisChange = (
        event: SelectChangeEvent<unknown>,
        axisSetStateAction: (value: SetStateAction<string[]>) => void
    ) => {
        const value = event.target.value as string[];
        setUnselectedAttributes((prev) => prev.filter((item) => !value.includes(item.cortenName)));
        axisSetStateAction(value);
    };

    /**
     * Function that is called whenever one of the axis header values are deleted
     * 
     * @param value the value that is deleted
     * @param axisSetStateAction the dispatch function to call when a value is deleted
     */
    const handleSelectionDelete = (
        value: any,
        axisSetStateAction: (value: SetStateAction<string[]>) => void
    ) => {
        axisSetStateAction((prev) => prev.filter((item) => item !== value));
        let attr = attributes.find((a) => a.cortenName === value)!;
        setUnselectedAttributes([...unselectedAttributes, attr]);
    };

    // Custom selector that allows multiple values to be selected and displayed 
    // as Chips with deletable buttons
    const AggregatorAxisInput = ({
        id,
        label,
        helperText,
        axisAttributes,
        axisSetStateAction
    }: {
        id: string;
        label: string;
        helperText: string;
        axisAttributes: string[];
        axisSetStateAction: (value: SetStateAction<string[]>) => void;
    }) => {
        return (
            <TextField
                sx={{ width: 400 }}
                id={id}
                select
                label={label}
                helperText={helperText}
                SelectProps={{
                    multiple: true,
                    value: axisAttributes,
                    onChange: (event) => {
                        handleAxisChange(event, axisSetStateAction);
                    },
                    input: <OutlinedInput id="select-multiple-chip" label={label} />,
                    renderValue: (selected: unknown) => (
                        <>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {(selected as string[]).map((value) => {
                                    let attr = attributes.find((a) => a.cortenName === value)!;
                                    return (
                                        <Chip
                                            onDelete={() =>
                                                handleSelectionDelete(
                                                    attr.cortenName,
                                                    axisSetStateAction
                                                )
                                            }
                                            onMouseDown={(event) => event.stopPropagation()}
                                            key={attr.displayName}
                                            label={attr.displayName}
                                        />
                                    );
                                })}
                            </Box>
                        </>
                    )
                }}
            >
                {unselectedAttributes.map((attr) => (
                    <MenuItem key={attr.cortenName} value={attr.cortenName}>
                        {attr.displayName}
                    </MenuItem>
                ))}
            </TextField>
        );
    };

    return (
        <Grid container>

            {/* Product Selector */}
            <Grid container item>
                <TextField
                    sx={{ width: 400 }}
                    id="aggregator-product"
                    select
                    label="Product"
                    helperText="Select a Product"
                    SelectProps={{
                        value: productId,
                        onChange: (event) => {
                            setProductId(event.target.value as string);
                        }
                    }}
                >
                    {appConfigState.getProducts().map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                            {product.displayCode}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            {/* Column Header selector */}
            <Grid container item marginTop={2}>
                <AggregatorAxisInput
                    id="aggregator-column-attributes"
                    label="column headers"
                    helperText="Select attributes to show as column headers"
                    axisAttributes={columnHeaders}
                    axisSetStateAction={setColumnHeaders}
                />
            </Grid>

            {/* Row Header selector */}
            <Grid container item marginTop={2}>
                <AggregatorAxisInput
                    id="aggregator-row-attributes"
                    label="row headers"
                    helperText="Select attributes to show as row headers"
                    axisAttributes={rowHeaders}
                    axisSetStateAction={setRowHeaders}
                />
            </Grid>
        </Grid>
    );
};

export default AggregatorInput;
