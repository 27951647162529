import React, {createContext, useContext, useEffect, useReducer} from "react";
import axios from "axios";
import { AccessKeyStore } from "@trovio-tech/trovio-core-api-js";
import { cortenApiUrl, cortenApiKey } from "./AppConfig";

/**
 * The access key context maintains the set of access keys currently available to the user. For each key, a
 * key id, name (username) and type ( can be 'local' and 'auth') is maintained.
 * For local type access keys a crypto(AccessKeyCrypto) object is also stored.
 * This context also provides the logic to initiate a signature using a key.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */

const AccessKeyContext = createContext();
const AccessKeyDispatchContext = createContext();

export const accessKeyStore = new AccessKeyStore()

function AccessKeyReducer(state, action) {

    switch (action.type) {
        case 'setKeys': {
            let newKeys = action.keys
            if (state.keys && Object.keys(state.keys).length > 0) {
                newKeys = {...state.keys, ...newKeys}
            }
            return { ...state, keys: newKeys, currentKey: newKeys.length > 0 ? newKeys[0].id : undefined }
        }

        case 'removeKey': {
            let newKeys = state.keys
            if (newKeys) {
                delete newKeys[action.id]
            }

            var currentKey = state.currentKey
            if (state.currentKey === action.id) {
                // unselect the key
                currentKey = undefined
                localStorage.setItem("currentKeyId", undefined)

            }
            return { ...state, keys: newKeys, currentKey }
        }

        case 'addKey': {
            // select this new key
            let newKeys = state.keys || {}
            newKeys[action.key.id] = action.key
            localStorage.setItem("currentKeyId", action.key.id)
            return { ...state, keys: newKeys, currentKey: action.key.id }
        }

        case 'updateKey': {
            // just update key without selecting it
            let newKeys = state.keys
            newKeys[action.key.id] = action.key
            return { ...state, keys: newKeys }
        }

        case 'selectKey': {
            let selectedKey = state.keys[action.id]
            if ( selectedKey?.id && selectedKey.id !== state.currentKey) {
                localStorage.setItem("currentKeyId", action.id)
                return { ...state, currentKey: selectedKey.id, currentDetails: {} }
            }
            else {
                return state
            }
        }

        case 'keyDetailsLoaded': {
            return { ...state, currentDetails: action.details }
        }

        default:
            throw new Error('unknown action type: ' + action.type)
    }
}

const AccessKeyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AccessKeyReducer, {})

    const sign = async (data, keyId, attributeRepository, access_token) => {
        let key = state.keys[keyId]
        if (key.type) { // we need a key to sign anything
            switch (key.type) {
                // Locally stored keys in IndexedDB. The crypto object in the key is an AccessKeyCrypto object.
                // To sign with this key we need to provide a valid attributeRepository.
                case 'local': {
                    if (key.crypto && attributeRepository) {
                        return key.crypto.signRequest(data, attributeRepository);
                    } else {
                        throw new Error('AccessKey error! Crypto object and/or attribute repository undefined!')
                    }
                }

                // When a user is authenticated (oidc) then they can make use of a key stored in the backend.
                // To sign with this key we need to provide the 'user' object which contains a valid access_token.
                case 'auth': {
                    if (access_token) {

                        var signTransactionRequest = {
                            request: data, 
                            accessKeyId: keyId
                        }

                        try {
                            const response = await axios.post(`${cortenApiUrl}/api/auth/tx/sign`, signTransactionRequest, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${access_token}`
                                }
                            })
                            return response.data.mintTransactionRequest
                        } catch (error) {
                            throw new Error('Signing failed: ' + error.message);
                        }
                    } else {
                        throw new Error('AccessKey error! Access token missing, cannot request signature!')
                    }

                }

            }
        } else {
            throw new Error('No key selected! Cannot initiate signature!');
        }
    }

    useEffect(() => {

        accessKeyStore.listKeys().then((keys) => {
            var keyMap = {}
            keys.forEach((k) => { keyMap[k.id] = k })

            dispatch({ type: "setKeys", keys: keyMap })

            // remember the default key that we had selected
            let currentKeyId = localStorage.getItem("currentKeyId")
            if (currentKeyId) {
                dispatch({ type: "selectKey", id: currentKeyId })
            }
        })
    }, [])

    useEffect(() => {
        if (state.currentKey) {
            // start loading key permissions
            axios.get(`${cortenApiUrl}/api/pub/key/${state.currentKey}`,
            {
                headers: {
                    'X-Trovio-DemoAuthorization': cortenApiKey
                }
            } )
                .then((response) => {
                    var newAccessMap = {}
                    response.data.accountPermissions.forEach((it) => newAccessMap[it.accountId] = it)
                    dispatch({ type: 'keyDetailsLoaded', details: newAccessMap })
                })
        }
    }, [state.currentKey])

    return (
        <AccessKeyContext.Provider value={{ ...state, sign }}>
            <AccessKeyDispatchContext.Provider value={dispatch}>
                {children}
            </AccessKeyDispatchContext.Provider>
        </AccessKeyContext.Provider>
    )
}


function useAccessKey() {
    const context = useContext(AccessKeyContext)
    if (!context) {
        throw new Error('useAccessKey without provider')
    }
    return context;
}

function useAccessKeyDispatch() {
    const context = useContext(AccessKeyDispatchContext)
    if (!context) {
        throw new Error('useAccessKeyDispatch without provider')
    }
    return context;
}

export { AccessKeyProvider, useAccessKey, useAccessKeyDispatch }