import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Typography, TableContainer, Paper, Table, TableRow, TableBody, TableCell } from '@mui/material';
import { ProductBase, useAppConfigState } from '../../state/AppConfig';
import { Holdings, HoldingQueryType } from '../../component/Holdings';
import { Trades, TradePageType, TradesColumnDefinition } from '../../component/Trades';
import dayjs from 'dayjs';
import { DATE_DISPLAY_FORMAT } from '../../utility/utcToLocalFormat';
import { displayAttributeValue } from '../trade/TradeUtil';

const TradingRiskPosition = () => {

    const appConfigState = useAppConfigState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [productId, setProductId] = useState<string>("");
    const [productBase, setProductBase] = useState<ProductBase>(ProductBase.Project);
    const [projectType, setProjectType] = useState<string>("");
    const [project, setProject] = useState<string>("");
    const [accreditationCode, setAccreditationCode] = useState<string>("");
    const [vintage, setVintage] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("");
    const [projectState, setProjectState] = useState<string>("");
    const [valueDateStart, setValueDateStart] = useState<string>("");
    const [valueDateEnd, setValueDateEnd] = useState<string>("");
    const [fuelSource, setFuelSource] = useState<string>("");
    const [generationYear, setGenerationYear] = useState<string>("");
    const [creationYear, setCreationYear] = useState<string>("");
    const [generationState, setGenerationState] = useState<string>("");
    const [greenPowerAccredited, setGreenPowerAccredited] = useState<string>("");
    // Only render the page when we have finished loading parameters from the URL. Without this, the table loads twice, and flickers with incorrect displayed values.
    // In some rare scenarios the updated state on second load does not follow through to DataGrid, resulting in an incorrect totals row and an invalid pagination arrow that 
    // throws an error and displays an empty page if clicked. To avoid this issue we make sure to only render the table once.
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        setProductId(searchParams.get('product') || "");
        setProductBase(ProductBase[(searchParams.get('productBase') || ProductBase.Project) as keyof typeof ProductBase]);
        setProjectType(searchParams.get('type') || "");
        setProject(searchParams.get('project') || "");
        setAccreditationCode(searchParams.get('accreditationCode') || "");
        setVintage(searchParams.get('vintage') || "");
        setCountryCode(searchParams.get('countryCode') || "");
        setProjectState(searchParams.get('projectState') || "");
        setValueDateStart(searchParams.get('valueDateStart') || "");
        setValueDateEnd(searchParams.get('valueDateEnd') || "");
        setFuelSource(searchParams.get('fuelSource') || "");
        setGenerationYear(searchParams.get('generationYear') || "");
        setCreationYear(searchParams.get('creationYear') || "");
        setGenerationState(searchParams.get('generationState') || "");
        setGreenPowerAccredited(searchParams.get('greenPowerAccredited') || "");
        setLoaded(true);
    }, []);

    const getColumnDefinitions = (): TradesColumnDefinition[] => {
        const definitions: TradesColumnDefinition[] = [];

        definitions.push(
            {key: "valueDate", showByDefault: true},
            {key: "tradeDate", showByDefault: false},
            {key: "forwardId", showByDefault: true},
            {key: "tradeId", showByDefault: true},
            {key: "quantity", showByDefault: true},
            {key: "productId", showByDefault: true}
        )

        switch (appConfigState.getProduct(productId)?.productBase) {
            case ProductBase.Project:
                definitions.push(
                    {key: "projectType", showByDefault: true},
                    {key: "projectId", showByDefault: false},
                    {key: "projectName", showByDefault: false},
                    {key: "vintage", showByDefault: true},
                    appConfigState.getProduct(productId)?.displayCode === 'ACCU' 
                        ? {key: "state", showByDefault: true} 
                        : { key: 'country', showByDefault: true }
                )
                break;
            case ProductBase.Certificate:
                definitions.push(
                    {key: "accreditationCode", showByDefault: true},
                    {key: "fuelSource", showByDefault: true},
                    {key: "creationYear", showByDefault: true},
                    {key: "generationYear", showByDefault: true},
                    {key: "generationState", showByDefault: true},
                    {key: "greenpowerAccredited", showByDefault: true}
                );
                break;
        }

        definitions.push(
            {key: "currency", showByDefault: true},
            {key: "price", showByDefault: true},
            {key: "counterParty", showByDefault: true},
            {key: "trader", showByDefault: false},
            {key: "salesPerson", showByDefault: false},
            {key: "salesCredits", showByDefault: false},
            {key: "brokerName", showByDefault: false},
            {key: "brokerage", showByDefault: false}
        )

        return definitions;
    }

    return (
        <>
            {loaded && ( <>
                <Typography variant='h2'>{valueDateStart !== "" && valueDateEnd !== "" ? "Risk Forward Trades" : "Risk Position Holdings"}</Typography>
                <TableContainer component={Paper} sx={{ maxWidth: 450, marginBottom: 4 }}>
                    <Table sx={{ maxWidth: 450 }} size='small' aria-label="position summary table">
                        <TableBody>
                            {productId && (<TableRow hover>
                                <TableCell>Product Name</TableCell>
                                <TableCell>
                                    {appConfigState.getProduct(productId)?.displayName}
                                </TableCell>
                            </TableRow>)}
                            {projectType && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'TYPE').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(projectType)}
                                </TableCell>
                            </TableRow>)}
                            {vintage && (<TableRow hover>
                                <TableCell>Project Vintage</TableCell>
                                <TableCell>
                                    {displayAttributeValue(vintage)}
                                </TableCell>
                            </TableRow>)}
                            {projectState && (<TableRow hover>
                                <TableCell>Project State</TableCell>
                                <TableCell>
                                    {displayAttributeValue(projectState)}
                                </TableCell>
                            </TableRow>)}
                            {countryCode && (<TableRow hover>
                                <TableCell>Project Country Code</TableCell>
                                <TableCell>
                                    {displayAttributeValue(countryCode)}
                                </TableCell>
                            </TableRow>)}
                            {project && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'ID').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(project)}
                                </TableCell>
                            </TableRow>)}
                            {fuelSource && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(fuelSource)}
                                </TableCell>
                            </TableRow>)}
                            {generationYear && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(generationYear)}
                                </TableCell>
                            </TableRow>)}
                            {creationYear && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(creationYear)}
                                </TableCell>
                            </TableRow>)}
                            {generationState && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(generationState)}
                                </TableCell>
                            </TableRow>)}
                            {greenPowerAccredited && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(greenPowerAccredited)}
                                </TableCell>
                            </TableRow>)}
                            {accreditationCode && (<TableRow hover>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').display}</TableCell>
                                <TableCell>
                                    {displayAttributeValue(accreditationCode)}
                                </TableCell>
                            </TableRow>)}
                            {valueDateStart && (<TableRow hover>
                                <TableCell>Value Date Start</TableCell>
                                <TableCell>
                                {dayjs(valueDateStart).format(DATE_DISPLAY_FORMAT)}
                                </TableCell>
                            </TableRow>)}
                            {valueDateEnd && (<TableRow hover>
                                <TableCell>Value Date End</TableCell>
                                <TableCell>
                                    {dayjs(valueDateEnd).add(-1, 'day').format(DATE_DISPLAY_FORMAT)}
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(valueDateStart !== "" && valueDateEnd !== "") ? (
                    <Trades
                        pageType={TradePageType.Forwards}
                        columnDefinitions={getColumnDefinitions()}
                        defaultOrdering={`productItemAttribute.${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`}
                        // Filters
                        product={productId}
                        projectType={projectType}
                        project={project}
                        vintage={vintage}
                        countryCode={countryCode}
                        projectState={projectState}
                        accreditationCode={accreditationCode}
                        fuelSource={fuelSource}
                        generationYear={generationYear}
                        creationYear={creationYear}
                        generationState={generationState}
                        greenPowerAccredited={greenPowerAccredited}
                        valueDateStart={valueDateStart}
                        valueDateEnd={valueDateEnd}
                    />
                ) : (
                    <Holdings
                        queryType={
                            productBase === ProductBase.Project ? HoldingQueryType.ACCOUNT_ID_PRODUCT_AND_TYPE
                            : HoldingQueryType.INVENTORY_CERTIFICATES
                        }
                        columnDefinitions={
                            productBase === ProductBase.Project ? [
                                {key: 'holdingId', showByDefault: true},
                                {key: 'amount', showByDefault: true},
                                {key: 'product', showByDefault: true},
                                {key: 'projectId', showByDefault: true},
                                {key: 'vintage', showByDefault: true},
                                {key: 'serialNumRange', showByDefault: true},
                                {key: 'state', showByDefault: true},
                                {key: 'info', showByDefault: true}
                            ]
                            : [
                                {key: 'accreditationCode', showByDefault: true},
                                {key: 'fuelSource', showByDefault: true},
                                {key: 'creationYear', showByDefault: true},
                                {key: 'generationYear', showByDefault: true},
                                {key: 'generationState', showByDefault: true},
                                {key: 'serialNumRange', showByDefault: true},
                                {key: 'amount', showByDefault: true},
                                {key: 'state', showByDefault: true},
                                {key: 'greenpowerAccredited', showByDefault: true},
                            ]
                        }
                        // Page level filters (These are immutable and apply to the entire page):
                        accountId={valueDateStart && valueDateEnd ? '' : appConfigState.getAccount('INVENTORY_ISSUER').id}
                        product={productId}
                        projectType={projectType}
                        project={project}
                        vintage={vintage}
                        countryCode={countryCode}
                        projectState={projectState}
                        accreditationCode={accreditationCode}
                        fuelSource={fuelSource}
                        generationYear={generationYear}
                        creationYear={creationYear}
                        generationState={generationState}
                        greenPowerAccredited={greenPowerAccredited}
                        // Other:
                        selectedHoldings={[]}
                    />
                )}
            </>)}
        </>
    );
};

export default TradingRiskPosition;
