import {
    Typography,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import { MutableRefObject } from "react";
import { AppConfigContextType } from "../state/AppConfig";
import { Option, Options } from "../state/ProductItemFilters";
import { AmountFormatWrapper } from "./AmountFormatWrapper";
import { fetchProductItemsWithId, fetchOrderedProductItems } from "./Fetch";
import { ProductData } from "../model/Shared";
import { wrapFilterValueWithQuotes } from "./CoreFilterUtil";


enum TradeOptimiser {
    DEFAULT = 'Default',
    CHEAPEST_FIRST = 'Cheapest First',
    INVENTORY_SCORE_BASED = 'Inventory Score Based'
};

enum TradeOptimiserAmountType {
    Price = 'Price',
    Score = 'Score'
}

const getTradeOptimiserOptions = () => {
    let values = [
        new Option(TradeOptimiser.DEFAULT, TradeOptimiser.DEFAULT),
        new Option(TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.CHEAPEST_FIRST),
        new Option(TradeOptimiser.INVENTORY_SCORE_BASED, TradeOptimiser.INVENTORY_SCORE_BASED),
    ];
    return new Options(values);
};
interface GetOrderedProjectsAndAmountsForm {
    tradeOptimiser: TradeOptimiser;
    product: string;
    projectType: string | undefined;
    project: string | undefined;
    vintage: string | undefined;
    state: string | undefined;
    country: string | undefined;
    fuelSource: string | undefined;
    creationYear: string | undefined;
    generationYear: string | undefined;
    generationState: string | undefined;
    greenpowerAccredited: string | undefined;
    quantity: number;
};

const getOrderedProjectsAndAmounts = (
    form: GetOrderedProjectsAndAmountsForm,
    accountId: string,
    setProjectAmounts: any,
    productItemIds: MutableRefObject<Promise<string[]> | undefined>,
    user: any,
    appConfigState: AppConfigContextType, 
    handleError: any | undefined = undefined
) => {
    switch (form.tradeOptimiser) {
        case TradeOptimiser.DEFAULT:
        case TradeOptimiser.INVENTORY_SCORE_BASED:
            let filters = [];
            if (form.projectType) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'TYPE').key,
                    value: wrapFilterValueWithQuotes(form.projectType),
                });
            }
            if (form.project) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'ID').key,
                    value: wrapFilterValueWithQuotes(form.project)
                });
            }
            if (form.vintage) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'VINTAGE').key,
                    value: wrapFilterValueWithQuotes(form.vintage)
                });
            }
            if (form.state) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'STATE').key,
                    value: wrapFilterValueWithQuotes(form.state)
                });
            }
            if (form.country) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key,
                    value: wrapFilterValueWithQuotes(form.country)
                });
            }
            if (form.fuelSource) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key,
                    value: wrapFilterValueWithQuotes(form.fuelSource)
                });
            }
            if (form.creationYear) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key,
                    value: wrapFilterValueWithQuotes(form.creationYear)
                });
            }
            if (form.generationYear) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key,
                    value: wrapFilterValueWithQuotes(form.generationYear)
                });
            }
            if (form.generationState) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key,
                    value: wrapFilterValueWithQuotes(form.generationState)
                });
            }
            if (form.greenpowerAccredited) {
                filters.push({
                    code: appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key,
                    value: wrapFilterValueWithQuotes(form.greenpowerAccredited)
                });
            }
            productItemIds.current = fetchProductItemsWithId(form.product, filters).then(items => {
                if (form.tradeOptimiser == TradeOptimiser.DEFAULT) {
                    setProjectAmounts(items ? items.map(item => {
                        return {
                            projectName: item.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'NAME').key],
                            balance: +item['issuerAmount'] - +item['escrowAmount']
                        }
                    }) : []);
                    return items ? items.map(item => item['productItemId']) : [];
                } else if (form.tradeOptimiser == TradeOptimiser.INVENTORY_SCORE_BASED) {
                    let remainingQuantity = form.quantity;
                    let selectedProjectAmounts: any[] = [];
                    // take just enough product items to fulfil the requested quantity
                    for (let item of items ? items.sort((a, b) => {
                        // Sort items by score ascending
                        let scoreA = parseFloat(a.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'INVENTORY_SCORE').key] || "0");
                        let scoreB = parseFloat(b.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'INVENTORY_SCORE').key] || "0");
                        let diff = scoreA - scoreB;
                        if (diff !== 0) {
                            return diff;
                        }
                        // Include secondary sort on vintage ascending
                        scoreA = parseFloat(a.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key] || "0");
                        scoreB = parseFloat(b.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key] || "0");
                        diff = scoreA - scoreB;
                        if (diff !== 0) {
                            return diff;
                        }
                        // Tertiary sort on product item ID just to ensure a deterministic result
                        return a.productItemId.localeCompare(b.productItemId);
                    }) : []) {
                        let itemBalance = item.issuerAmount - item.escrowAmount;
                        if (itemBalance === 0) {
                            continue;
                        }
                        let attributes = item.productItem.data.attributes;
                        let selectedItem = {
                            projectName: attributes[appConfigState.getAttribute('PROJECT', 'NAME').key],
                            projectId: attributes[appConfigState.getAttribute('PROJECT', 'ID').key],
                            vintage: attributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key],
                            balance: Math.min(remainingQuantity, itemBalance),
                            productItemIds: [item.productItemId],
                            score: attributes[appConfigState.getAttribute('PROJECT', 'INVENTORY_SCORE').key],
                            productId: form.product
                        };
                        selectedProjectAmounts.push(selectedItem);
                        remainingQuantity -= selectedItem.balance;
                        if (remainingQuantity <= 0) break;
                    }
                    setProjectAmounts(selectedProjectAmounts);
                    return selectedProjectAmounts.flatMap(item => item.productItemIds as string[]);
                } else {
                    throw Error('Trade sub-optimiser not implemented');
                }
            }).catch(error => {
                handleError();
                return [];
            });

            break;

        case TradeOptimiser.CHEAPEST_FIRST:
            productItemIds.current = fetchOrderedProductItems({
                productId: form.product,
                accountId: accountId,
                ...(form.projectType && { projectTypes: [form.projectType] }),
                ...(form.project && { projects: [form.project] }),
                ...(form.vintage && { vintages: [form.vintage] }),
                ...(form.state && { states: [form.state] }),
                ...(form.country && { countries: [form.country] })
                //TODO (https://gitlab.com/trovio/tech/product/commodity-desk/-/issues/537) add LGC attributes here
            }, user).then(result => {
                let remainingQuantity = form.quantity;
                let selectedProjectAmounts: any[] = [];
                // take just enough project-vintage pairs to fulfil the requested quantity
                for (let summary of result['projectVintageSummaries']) {
                    let item = {
                        projectName: summary['projectName'],
                        projectId: summary['projectId'],
                        vintage: summary['vintage'],
                        balance: Math.min(remainingQuantity, summary['availableBalance']),
                        productItemIds: summary['productItemIds'],
                        price: summary['price'],
                        productId: form.product
                    };
                    selectedProjectAmounts.push(item);
                    remainingQuantity -= item.balance;
                    if (remainingQuantity <= 0) break;
                }
                setProjectAmounts(selectedProjectAmounts);
                return selectedProjectAmounts.flatMap(item => item.productItemIds as string);
            });
            break;

        default:
            throw Error('Trade optimiser not implemented');
    }

};

const renderOrderedProjectVintageAmounts = (
    projectAmounts: any[] | undefined,
    productData: ProductData,
    amountType: TradeOptimiserAmountType
) => {

    return (
        <>
            <Typography variant="h2" mt={4}>
                Project Quantities
            </Typography>
            {projectAmounts == null ? (
                <LinearProgress />
            ) : (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Project</TableCell>
                                <TableCell>Vintage</TableCell>
                                <TableCell>Quantity</TableCell>
                                {amountType === TradeOptimiserAmountType.Price && (<TableCell>Price</TableCell>)}
                                {amountType === TradeOptimiserAmountType.Score && (<TableCell>Score</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectAmounts.map((item) => renderProjectVintageAmount(item, productData, amountType))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

const renderProjectVintageAmount = (
    item: any,
    productData: ProductData,
    amountType: TradeOptimiserAmountType
) => {
    return (
        <TableRow key={item.productItemName}>
            <TableCell>
                {`${item.projectId} - ${item.projectName}`}
            </TableCell>
            <TableCell>{item.vintage}</TableCell>
            <TableCell align="right">
                <AmountFormatWrapper
                    amount={item.balance}
                    minDecimalPos={productData.minDecimalPos!}
                    maxDecimalPos={productData.minDecimalPos!}
                />
            </TableCell>
            {amountType === TradeOptimiserAmountType.Price && (
                <TableCell align="right">
                    {item.price && (<AmountFormatWrapper amount={item.price} minDecimalPos={2} maxDecimalPos={2} />)}
                    {!item.price && (<>-</>)}
                </TableCell>
            )}
            {amountType === TradeOptimiserAmountType.Score && (
                <TableCell align="right">
                    {item.score && (<AmountFormatWrapper amount={item.score} minDecimalPos={2} maxDecimalPos={2} />)}
                    {!item.score && (<>-</>)}
                </TableCell>
            )}
        </TableRow>
    );
};

export {
    TradeOptimiser,
    TradeOptimiserAmountType,
    getTradeOptimiserOptions,
    getOrderedProjectsAndAmounts,
    renderOrderedProjectVintageAmounts
};

