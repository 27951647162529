import { IconButton, Tooltip } from '@mui/material';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import { truncateTransactionId } from '../utility/ProjectUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { ReactNode } from 'react';

type TransactionValueProps = {
    label?: string;
    value?: string | ReactNode;
    renderProp?: AmountRenderProp;
    shorterFormId?: Boolean;
};

type AmountRenderProp = {
    minDecimalPos: number;
    maxDecimalPos: number;
};

/**
 * Responsible for rendering the value of the summary table
 * Summary table value can be a string or a number with optional min/max decimal positions
 * If the value is a string and shorterFormId is true then the value will be truncated
 * @param data : with label, value, shorterFormId and renderProp, value is mandatory and rest are optional
 * @returns Rendered value
 */
const TransactionValueLabel = ({ data }: { data: TransactionValueProps }) => {
    if (!data?.value) {
        return <>-</>;
    }
    if (data?.renderProp) {
        return (
            <AmountFormatWrapper
                amount={data.value}
                minDecimalPos={data?.renderProp?.minDecimalPos}
                maxDecimalPos={data?.renderProp?.maxDecimalPos}
            />
        );
    }

    if (data?.shorterFormId) {
        return (
            <>
                {truncateTransactionId(data.value.toString())}
                <Tooltip title="Copy ID to clipboard">
                    <IconButton
                        size="small"
                        sx={{
                            color: 'primary.main'
                        }}
                        onClick={() => navigator.clipboard.writeText(data?.value?.toString() || '')}
                    >
                        <FontAwesomeIcon icon={faCopy} />
                    </IconButton>
                </Tooltip>
            </>
        );
    }

    return <>{data?.value}</>;
};

export { TransactionValueLabel, type TransactionValueProps };
