import { AppConfigContextType } from '../state/AppConfig';
import { AmountFormatAsString } from "@trovio-tech/trovio-core-api-jsx";
import { getDefaultLocaleConfig } from '../state/LocaleConfig';

/**
 * A small utility class to separate Serial Number formatting logic.
 */
export class SerialNumber {
    // Effective product code, used to determine method to render to range string
    private readonly effectiveProductCode: string;
    // variable part of the serial number, identifying the start of the range for individual holding units
    private readonly serialNumberRangeStart: number;
    // constant prefix, containing information specific to the registry, e.g. a particular product item
    private readonly prefix: string;
    // constant suffix, containing information specific to the registry
    private readonly suffix: string;
    // character that's used to split components for this serial number
    private readonly separator: string;

    public constructor(effectiveProductCode: string, serialNumberRangeStart: number, prefix: string, suffix: string, separator: string) {
        this.effectiveProductCode = effectiveProductCode;
        this.serialNumberRangeStart = serialNumberRangeStart;
        this.prefix = prefix;
        this.suffix = suffix;
        this.separator = separator;
    }

    public static fromHolding = (holding: any, appConfigState: AppConfigContextType) => {
        const effectiveProductCode = appConfigState.getProduct(holding.productId)!.displayCode!;

        const serialNumberStart = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'SERIAL_NUMBER_START').key];

        if (holding.state === 'Unassigned') {
            return null;
        }
        if (effectiveProductCode === 'ACCU' || effectiveProductCode === 'LGC') {    
            // For ACCUs the serial_number_start is assumed to be a Number
            // Remove thousand separators before converting to Number    
            return new SerialNumber(effectiveProductCode, Number(serialNumberStart.replaceAll('.', '').replaceAll(',', '')), '', '', '-')
        } else if (effectiveProductCode === 'VCU') {
            const serialNumberPrefix = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'SERIAL_NUMBER_PREFIX').key];
            const serialNumberSuffix = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'SERIAL_NUMBER_SUFFIX').key];
            return new SerialNumber(effectiveProductCode, Number(serialNumberStart), serialNumberPrefix, serialNumberSuffix, '-');
        } else {
            console.error('Received unexpected effective Product Code: ' + effectiveProductCode)
            return null;
        }
    };

    public getRange(holdingIndex: number, holdingAmount: number): string {
        const rangeStart = `${this.serialNumberRangeStart + holdingIndex}`;
        const rangeEnd = `${this.serialNumberRangeStart + holdingIndex + holdingAmount - 1}`;

        if (this.effectiveProductCode === 'VCU') {
            // We assume that the prefix and suffix attributes include separators
            // Hence the following format string only has a separator between rangeStart and rangeEnd
            return `${this.prefix}${rangeStart}${this.separator}${rangeEnd}${this.suffix}`
        } else {
            const localeConfig = getDefaultLocaleConfig();
            const rangeStartFormatted = AmountFormatAsString({
                amount: rangeStart, 
                maxDecimalPos: 0, 
                minDecimalPos: 0, 
                groupSep: localeConfig.amountFormatGroupSeparator, 
                decimalPoint: localeConfig.amountFormatDecimalPoint
            })
            const rangeEndFormatted = AmountFormatAsString({
                amount: rangeEnd, 
                maxDecimalPos: 0, 
                minDecimalPos: 0, 
                groupSep: localeConfig.amountFormatGroupSeparator, 
                decimalPoint: localeConfig.amountFormatDecimalPoint
            })

            return `${rangeStartFormatted} ${this.separator} ${rangeEndFormatted}`
        }
    };
}
