import { LinearProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { BalanceData } from "../../model/Shared";
import { HoldingsSummary } from "../../view/inventoryManagement/HoldingsSummary";
import { useLocation } from "react-router-dom";
import { ProductBase, useAppConfigState } from "../../state/AppConfig";
import { fetchByCriteria } from "../../utility/Fetch";
import { useProductDataState } from "../../state/ProductDataProvider";
import ProductItemAttributesTable from "../ProductItemAttributesTable";
import { HoldingQueryType, Holdings } from "../Holdings";

interface CertificateData {
    accreditationCode: string;
    fuelSource: string;
    generationState: string;
}

const CertificateDetails = () => {
    const appConfigState = useAppConfigState();

    const [isPathError, setIsPathError] = useState<Boolean>(false);
    const [balances, setBalances] = useState<BalanceData | undefined>(undefined);
    const [certificateData, setCertificateData] = useState<CertificateData | undefined>(undefined);
    const { productData } = useProductDataState();

    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productId = splitLocation[4];
    const accreditationCode = splitLocation[6];

    const fetchBalance = () => {
        const criteria = {
            productIds: [productId],
            includeBalances: true,
            axes: appConfigState.getProjectAttributes(),
            attributes: [{
                code: appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key,
                value: accreditationCode
            }]
        };

        return fetchByCriteria(criteria);
    }

    useEffect(() => {
        const productValid = appConfigState.getProducts(true)
            .filter(p => p.productBase === ProductBase.Certificate)
            .map(p => p.id)
            .includes(productId);

        if (productValid) {
            fetchBalance().then((balanceData) => {
                if (balanceData.list.length > 0) {
                    setCertificateData({
                        accreditationCode: balanceData.list[0].attributes[appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key],
                        fuelSource: balanceData.list[0].attributes[appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key],
                        generationState: balanceData.list[0].attributes[appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key]
                    })
                    setBalances(balanceData.list[0].balances);
                }
            })
        } else {
            setIsPathError(true);
        }
    }, [])
    
    return (
        <>
            <Typography variant="h2">Certificate Details</Typography>
            {
                isPathError ? 
                    <Typography>Error - Product ID not recognised</Typography> : 
                    <>
                        {!balances ? 
                            <LinearProgress /> : (
                                <>
                                    <HoldingsSummary
                                        issuerAmount={balances?.issuerAmount}
                                        assignedAmount={balances?.assignedAmount}
                                        unassignedAmount={balances?.unassignedAmount}
                                        escrowAmount={balances?.escrowAmount}
                                        title="Certificate Holding Summary"
                                        minDecimalPos={productData.get(productId)?.minDecimalPos!!}
                                        maxDecimalPos={productData.get(productId)?.maxDecimalPos!!}
                                    />

                                    <ProductItemAttributesTable
                                        title="Certificate Summary"
                                        bodyProps={[
                                            {
                                                heading: appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').display,
                                                value: certificateData!.accreditationCode
                                            },
                                            {
                                                heading: appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').display,
                                                value: certificateData!.fuelSource
                                            },
                                            {
                                                heading: appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').display,
                                                value: certificateData!.generationState
                                            }
                                        ]}
                                    />

                                    <Typography variant="h3">Certificates</Typography>
                                    <Holdings
                                        queryType={HoldingQueryType.INVENTORY_CERTIFICATES}
                                        columnDefinitions={[
                                            {key: 'accreditationCode', showByDefault: true},
                                            {key: 'fuelSource', showByDefault: true},
                                            {key: 'creationYear', showByDefault: true},
                                            {key: 'generationYear', showByDefault: true},
                                            {key: 'generationState', showByDefault: true},
                                            {key: 'serialNumRange', showByDefault: true},
                                            {key: 'amount', showByDefault: true},
                                            {key: 'state', showByDefault: true},
                                            {key: 'greenpowerAccredited', showByDefault: true},
                                        ]}
                                        // Filters:
                                        accountId={appConfigState.getAccount('INVENTORY_ISSUER').id}
                                        product={productId}
                                        accreditationCode={certificateData!.accreditationCode}
                                        fuelSource={certificateData!.fuelSource}
                                        generationState={certificateData!.generationState}
                                        // Other:
                                        selectedHoldings={[]}
                                    />
                                </>
                            )}
                    </>
            }
        </>
    )
}

export default CertificateDetails