import {Navigate, useNavigate} from "react-router-dom";

/**
 * Context URL for this app
 * @type {string}
 */
const homepage= "/app"

const getDestination = (url) => {
    if (url.startsWith("/") && url !== homepage) {
        return homepage+url
    } else {
        return url
    }

}

const useAppNavigate = () => {
    const navigate= useNavigate()
    return (url) => url && navigate(getDestination(url))
}

function AppNavigate( {to} )
{
    return <Navigate to={to && getDestination(to)}/>
}

export {homepage, useAppNavigate, AppNavigate}