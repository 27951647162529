import { AppConfigContextType } from "../../state/AppConfig";

/**
 * Aggregator Attribute data structure
 * @property {string} displayName The name to display an attribute as in the UI
 * @property {string} cortenName The actual corten attribute name
 */
interface AggregatorAttribute {
    displayName: string;
    cortenName: string;
}

/**
 * Function to get an array of @see AggregatorAttribute to use in our app
 * @param {AppConfigContextType} appConfigState the state from which to derive the project attributes
 * @returns an array of @see AggregatorAttribute
 */
const getAttributes = (appConfigState: AppConfigContextType): AggregatorAttribute[] => {
    return [
        {
            displayName: 'Project Type',
            cortenName: appConfigState.getAttribute('PROJECT', 'TYPE').key
        },
        {
            displayName: 'Project Vintage',
            cortenName: appConfigState.getAttribute('PROJECT', 'VINTAGE').key
        },
        {
            displayName: 'Project Name',
            cortenName: appConfigState.getAttribute('PROJECT', 'NAME').key
        },
        {
            displayName: 'Country Code',
            cortenName: appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key
        },
        {
            displayName: 'Serial Number Start',
            cortenName: appConfigState.getAttribute('PROJECT', 'SERIAL_NUMBER_START').key
        }
    ]
}

export { type AggregatorAttribute, getAttributes}