import { GridPaginationModel } from '@mui/x-data-grid';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useAppConfigState } from '../../../state/AppConfig';
import { fetchLockedHolding } from '../../../utility/Fetch';
import { useProductState } from './ProductState';

type LockedHoldingProps = {
    accountId: string;
    attributes: {
        mbl_transaction_type_inventory: string;
    };
    controllerAccountId: string;
    escrowId: string;
    productId: string;
    remainingAmount: string;
};

type APIResponse = {
    list: LockedHoldingProps[];
    count: number;
    nextPage: string | undefined;
};

type State = {
    isLoading: boolean;
    data: LockedHoldingProps[];
    combinedData: LockedHoldingProps[][];
    total: number;
    page: number;
    pageSize: number;
    nextPage: string | undefined;
};

type Actions = {
    handlePagination: (newPagination: GridPaginationModel) => void;
    onSuccess: (itemId: string) => void;
    fetchLockedHoldings: () => void;
};

const LockedHoldingContext = createContext<(State & Actions) | null>(null);

/**
 * This Provider  defines the state and actions for displaying a list of locked holdings for a specific account and product.
 * The state includes information about the loading status, the data to be displayed, and the pagination settings.
 * The actions include handling pagination changes, onSuccess and fetchLockedHoldings function.
 *
 * @param isLoading - The loading status of the data
 * @param data - The data to be displayed
 * @param combinedData - The combined data to be displayed for pagination
 * @param total - The total number of items
 * @param page - The current page
 * @param pageSize - The page size
 * @param nextPage - The next page
 * @param handlePagination - The function to handle pagination changes
 * @param onSuccess - The function that need to be trigger after onSuccess
 * @param fetchLockedHoldings - The function that fetches the locked holding data
 *
 */

const LockedHoldingsProvider = ({ children }: PropsWithChildren) => {
    const appConfigState = useAppConfigState();
    const { mblSelectedProduct } = useProductState();

    const [state, setState] = useState<{
        isLoading: boolean;
        combinedData: LockedHoldingProps[][];
        data: LockedHoldingProps[];
        total: number;
        page: number;
        pageSize: number;
        nextPage: string | undefined;
    }>({
        isLoading: false,
        combinedData: [],
        data: [],
        total: 0,
        page: 0,
        pageSize: 20,
        nextPage: undefined
    });

    const loadData = async (nextPage?: string | undefined): Promise<APIResponse | undefined> => {
        try {
            // Creating URL params
            const params = new URLSearchParams();
            params.set('holderAccountId', appConfigState.getAccount('INVENTORY_ISSUER').id);
            params.set('productId', mblSelectedProduct.id);
            params.set('page.withCount', 'true');

            // page.from param
            if (nextPage) params.set('page.from', nextPage);

            // This is required to get  actual value for the product with ":",
            const array: any = [];
            params.forEach((value, key) => {
                array.push(`${key}=${value}`);
            });
            const res = await fetchLockedHolding(array.join('&'));
            return res as APIResponse;
        } catch (error) {}
        return undefined;
    };

    // Handle pagination
    const handlePagination = async (newPagination: GridPaginationModel) => {
        const hasPreviousDataAvailable = state.combinedData[newPagination.page];

        // If there is previous data available, update the display data from the previous data
        if (hasPreviousDataAvailable) {
            setState((prev) => ({
                ...prev,
                page: newPagination.page,
                data: prev.combinedData[newPagination.page] || []
            }));
            return;
        }
        // If there is no previous data available
        setState((prev) => ({
            ...prev,
            isLoading: true
        }));
        const res = await loadData(state.nextPage);
        if (res) {
            // Combined Prop is to handle and store paginated data
            const combinedData = state.combinedData;
            combinedData.push(res?.list || []);
            setState((prev) => ({
                ...prev,
                total: res?.count,
                data: res?.list || [],
                combinedData: combinedData,
                isLoading: false,
                page: newPagination.page,
                nextPage: res?.nextPage
            }));
        } else {
            setState((prev) => ({
                ...prev,
                isLoading: false
            }));
        }
    };

    // On Success : Function to be called after a successful unlocked transaction
    const onSuccess = async (itemId: string | undefined) => {
        if (!itemId) return;
        fetchLockedHoldings();
    };

    const fetchLockedHoldings = async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        const res = await loadData();
        if (res) {
            // To handle pagination  data
            const combinedData: LockedHoldingProps[][] = [];
            combinedData.push(res?.list);
            setState((prev) => ({
                ...prev,
                total: res?.count,
                data: res?.list || [],
                combinedData,
                isLoading: false,
                page: 0,
                nextPage: res?.nextPage
            }));
        } else {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    };

    // On LockedHoldings Component mount, Load the data
    useEffect(() => {
        // Onload fetch initial data
        fetchLockedHoldings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LockedHoldingContext.Provider
            value={{
                isLoading: state.isLoading,
                data: state.data,
                combinedData: state.combinedData,
                total: state.total,
                page: state.page,
                pageSize: state.pageSize,
                nextPage: state.nextPage,
                handlePagination,
                onSuccess,
                fetchLockedHoldings
            }}
        >
            {children}
        </LockedHoldingContext.Provider>
    );
};

/**
 * This hooks provides LockedHoldingContext which defines the state and
 * actions for displaying a list of locked holdings for a specific account and product.
 * @param isLoading - The loading status of the data
 * @param data - The data to be displayed
 * @param combinedData - The combined data to be displayed for pagination
 * @param total - The total number of items
 * @param page - The current page
 * @param pageSize - The page size
 * @param nextPage - The next page
 * @param handlePagination - The function to handle pagination changes
 * @param onSuccess - The function that need to be trigger after onSuccess
 * @param fetchLockedHoldings - The function that fetches the locked holding data
 */
const useLockedHoldings = () => {
    const context = useContext(LockedHoldingContext);
    if (!context) {
        throw new Error('useLockedHoldings must be used within a LockedHoldingsProvider');
    }
    return { ...context };
};

export { LockedHoldingsProvider, useLockedHoldings, type LockedHoldingProps };
