import { Box, Button, Tabs, Typography, Tab } from '@mui/material';
import { Link, Navigate, Routes, Route, useLocation } from 'react-router-dom';
import {
    useEmissionsState,
    EmissionTableType,
    useEmissionsDispatch,
    TabPaths
} from './state/EmissionsState';
import EmissionsForm from './EmissionsForm';
import { SyntheticEvent, useEffect, useState } from 'react';
import EmissionsTable from './EmissionsTable';
import { useLayoutState } from '../../state/Layout';
import { useDecarboniseState, useDecarboniseDispatch } from './state/DecarboniseState';
import DecarboniseForm from './DecarboniseForm';
import { useUnderlyingDispatch } from './state/UnderlyingState';
import UnderlyingDrawer from './UnderlyingDrawer';

const Emissions = () => {
    const {
        handleEmissionsFormOpen,
        emissionPendingItems,
        fetchItemsForEmissionAsset,
        getCarbonProductData
    } = useEmissionsState();
    const { setLoadingTable } = useEmissionsDispatch();

    const { decarbonisedItems, fetchItemsForDecarbonisedAsset, handleDecarboniseFormOpen } =
        useDecarboniseState();
    const { handleOpenCertificate } = useDecarboniseDispatch();
    const { handleUnderlyingDrawerOpen } = useUnderlyingDispatch();
    // sub tabs / nested routes
    const [tabValue, setTabValue] = useState(0);

    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const subTab = splitLocation[3];

    // select the sub tab based on url
    useEffect(() => {
        setLoadingTable(true);
        // set nested tabs according to url
        if (subTab === EmissionTableType.PENDING) {
            setTabValue(0);
            // request pending emission items
            fetchItemsForEmissionAsset();
        }
        if (subTab === EmissionTableType.DECARBONISED) {
            setTabValue(1);
            // request decarbonised emission items
            fetchItemsForDecarbonisedAsset();
        }
    }, [subTab]);

    // init carbon unit product data from config
    useEffect(() => {
        getCarbonProductData();
    }, []);

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setLoadingTable(true);

        setTabValue(newValue);
    };
    const { customTheme } = useLayoutState();

    // tab item text highlighting
    const hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };

    return (
        <>
            <Typography variant="h2">Emission Offset</Typography>

            <Button
                variant="outlined"
                onClick={handleEmissionsFormOpen}
                sx={{ marginBottom: 5, marginRight: 2 }}
            >
                Create New Asset
            </Button>
            {/* Form to issue an emission asset */}
            <EmissionsForm />

            {/* Form to decarbonise an emission asset */}
            <DecarboniseForm />

            {/* Dialog to show underlying baked product items */}
            <UnderlyingDrawer />

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    {TabPaths.map((item, index) => (
                        <Tab
                            key={index}
                            label={item.label}
                            component={Link}
                            to={item.pathName}
                            sx={{
                                ...hoverTabTextColor,
                                transition: customTheme.customProps.navHoverTransition,
                                '&:hover': {
                                    backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                }
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            <Routes>
                <Route index element={<Navigate to={EmissionTableType.PENDING} replace />} />
                <Route
                    path={`/${EmissionTableType.PENDING}`}
                    element={
                        <EmissionsTable
                            data={emissionPendingItems}
                            tableType={EmissionTableType.PENDING}
                            action={handleDecarboniseFormOpen}
                        />
                    }
                />
                <Route
                    path={`/${EmissionTableType.DECARBONISED}`}
                    element={
                        <EmissionsTable
                            data={decarbonisedItems}
                            tableType={EmissionTableType.DECARBONISED}
                            action={handleUnderlyingDrawerOpen}
                            openCertificate={handleOpenCertificate}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default Emissions;
