import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useAppConfigState } from "./AppConfig";
import { fetchProducts } from "../utility/Fetch";
import { ProductData } from "../model/Shared";

type ProductDataContextType = {
    productData: Map<String,ProductData>
}

const ProductDataContext = createContext<ProductDataContextType|null>(null);

const ProductDataProvider = ({ children }: { children?: ReactNode }) => {

    const appConfigState = useAppConfigState();
    const [productData, setProductData] = useState<Map<String,ProductData>>(new Map())

    useEffect(()=> {
        let newProductData = new Map();
        fetchProducts(
            appConfigState.getAccount('INVENTORY_ISSUER').id,
            appConfigState.getProducts()
        ).then((foundProducts) => {
            const productIds = appConfigState.getProducts().map(product => product.id); 
            foundProducts?.forEach(item => {
                if (productIds.includes(item.productId)) {
                    newProductData.set(item.productId, item.data);
                }
            });
            setProductData(newProductData);
        });
    },[]);

    return (
        <ProductDataContext.Provider
            value={{
                productData
            }}
        >
            {children}
        </ProductDataContext.Provider>
    );

};


function useProductDataState() {
    const context = useContext(ProductDataContext);
    if (!context) {
        throw new Error('no provider for ProductDataState');
    }
    return context;
};

export { ProductDataProvider, useProductDataState };