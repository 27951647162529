import { Box, ClickAwayListener, Fade, Paper, Popper, Typography } from '@mui/material';
import { GridTypeFilterInputValueProps } from '@mui/x-data-grid';
import { useState } from 'react';
import { InputRangePicker } from './InputRangePicker';

type InputRangeType = 'Integer' | 'Decimal';
type InputRangeView = 'InLine' | 'PopOver';

type Props = {
    type?: InputRangeType;
    view?: InputRangeView;
} & GridTypeFilterInputValueProps;

/**
 * Custom Input Range Filter for columns that accept integer or decimal values, such as Price, Quantity and Vintage.
 * @param type - type of input range filter either Integer or Decimal
 * @param view - view of input range filter either InLine or PopOver
 * @returns {InputRangeFilter} Filter component which only accepts integer or decimal
 */

const InputRangeFilter = (props: Props) => {
    const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(popoverAnchorEl);
    const { item, applyValue, type, view } = props;

    const values = {
        start: item?.value?.split('..')?.[0],
        end: item?.value?.split('..')?.[1]
    };

    const currentView = view || 'InLine';

    const handleApply = (value: string) => {
        applyValue({ ...item, value });
        setPopoverAnchorEl(null);
    };

    const renderLabel = () => {
        if (!item?.value) {
            return `Enter ${item.field} range`;
        }

        return (
            <Box>
                <Typography>From : {values.start}</Typography>
                <Typography>To : {values.end}</Typography>
            </Box>
        );
    };

    // Note: Default view is Inline
    // If view is InLine, then render the  component in the same filter panel.
    if (currentView === 'InLine') {
        return (
            <InputRangePicker
                startInput={values.start}
                endInput={values.end}
                type={type}
                onApply={handleApply}
            />
        );
    }

    // If view is PopOver, then render the  component in the PopOver style.
    return (
        <ClickAwayListener onClickAway={() => setPopoverAnchorEl(null)}>
            <Box>
                <Box
                    onClick={(e) => setPopoverAnchorEl(open ? null : e?.currentTarget)}
                    sx={{
                        margin: '15px 15px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 13,
                        gap: 2,
                        cursor: 'pointer'
                    }}
                >
                    {renderLabel()}
                </Box>

                <Popper
                    placement="right"
                    open={open}
                    anchorEl={popoverAnchorEl}
                    disablePortal={false}
                    transition
                    sx={{ zIndex: 1300 }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper elevation={24} sx={{ padding: '20px' }}>
                                <Typography variant="h3" sx={{ margin: '0px 0px 15px 0px' }}>
                                    {item.field?.toUpperCase()}
                                </Typography>
                                <InputRangePicker
                                    startInput={values.start}
                                    endInput={values.end}
                                    type={type}
                                    onApply={handleApply}
                                />
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    );
};

export { InputRangeFilter, type InputRangeType, type InputRangeView };
