import { Box, Checkbox, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridPaginationModel,
    GridSortModel,
    GridToolbar,
    getGridSingleSelectOperators,
} from '@mui/x-data-grid';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ProductBase, useAppConfigState } from '../state/AppConfig';
import { fetchWithCortenAndHandleAbort } from '../state/CortenClient';
import { SerialNumber } from '../model/SerialNumber';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { HoldingDetails, HoldingDisplayDetails } from './HoldingDetails';
import {
    extendedMuiFilterToCoreFilter,
    extendedNumericFilterOperators,
    extendedNumericFloatFilterOperators,
    getComparedFilterModel,
    inputRangeFilterOperators,
    isBlankFilterItem,
    numericFilterOperators,
    singleMatchStringFilterOperator,
    stringFilterOperators,
    dateRangeFilterOperators,
    truncateHumanName
} from '../utility/ProjectUtils';
import { AttributeCriteria, fetchHolding } from '../utility/Fetch';
import { withStyles } from '@mui/styles';
import { useProductDataState } from '../state/ProductDataProvider';
import { renderFieldValue } from '../utility/DialogFieldRenderUtil';
import { wrapFilterValueWithQuotes } from '../utility/CoreFilterUtil';
import { DATE_DISPLAY_FORMAT, utcToLocalFormat } from '../utility/utcToLocalFormat';

enum HoldingQueryType {
    PRODUCT_PROJECT_VINTAGE = 'PRODUCT_PROJECT_VINTAGE', // Used to display holdings under a specific input list of product items
    ACCOUNT_ID = 'ACCOUNT_ID', // Used to display all holdings owned by a specific account.
    ACCOUNT_ID_PRODUCT_AND_TYPE = 'ACCOUNT_ID_PRODUCT_AND_TYPE', // Used to display all holdings that makes up a position
    INVENTORY_CERTIFICATES = 'INVENTORY_CERTIFICATES', // Used to display all holdings for a certificate based product e.g. LGC
    FORWARDS = 'FORWARDS' // Used to display forward trades
}

// We currently use fixed table height with a fixed number of items per page
const PAGE_SIZE = 20;
const INITIAL_PAGINATION_MODEL = { page: 0, pageSize: PAGE_SIZE };

interface HoldingFilters {
    accountId: string | undefined,
    state: string | undefined,
    attributeFilters: AttributeCriteria[]
}

interface HoldingsColumnDefinition {
    key: string;
    showByDefault: boolean;
}

const holdingsDisplayDetailsInit: HoldingDisplayDetails = {
    holdingId: '',
    accountId: '',
    amount: '',
    productCode: '',
    productBase: ProductBase.Project,
    projectId: '',
    projectName: '',
    projectType: '',
    projectCountry: '',
    projectState: '',
    projectUri: '',
    projectVintage: 0,
    certificateAccreditationCode: '',
    certificateFuelSource: '',
    certificateGenerationYear: 0,
    certificateCreationYear: 0,
    certificateGenerationState: '',
    certificateGreenpowerAccredited: '',
    serialNumbers: '',
    maxDecimalPos: 0,
    minDecimalPos: 0
}

const Holdings = ({
    queryType,
    columnDefinitions,
    defaultOrdering,
    // Filters:
    accountId,
    holdingState,
    product,
    projectType,
    project,
    vintage,
    countryCode,
    projectState,
    accreditationCode,
    fuelSource,
    creationYear,
    generationYear,
    generationState,
    greenPowerAccredited,
    // Other:
    selectedHoldings,
    onHoldingSelectionUpdated,
    refreshSignal
}: {
    queryType: HoldingQueryType,
    columnDefinitions: HoldingsColumnDefinition[],
    defaultOrdering?: string,
    // Filters:
    accountId?: string,
    holdingState?: string,
    product?: string,
    projectType?: string,
    project?: string,
    vintage?: string,
    countryCode?: string,
    projectState?: string,
    accreditationCode?: string,
    fuelSource?: string,
    creationYear?: string,
    generationYear?: string,
    generationState?: string,
    greenPowerAccredited?: string,
    // Other:
    selectedHoldings: any[],
    onHoldingSelectionUpdated?: any,
    refreshSignal?: number  // Used to force the holdings table to refresh its contents (e.g. when exiting dialogs)
}) => {

    const getInitialColumnVisibilityModel = (): GridColumnVisibilityModel => {
        const visibiltyModel = columnDefinitions.reduce(function(map, obj) {
            map[obj.key] = obj.showByDefault;
            return map;
        }, {} as GridColumnVisibilityModel);
        return visibiltyModel;
    }
    
    const [holdings, setHoldings] = useState<any>([]);
    const abortController = useRef(new AbortController());
    const [nextPage, setNextPage] = useState<string | undefined>(undefined);
    const lastLoadedPage = useRef<number>(0);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
    const [totalAvailableRowCount, setTotalAvailableRowCount] = useState(0);
    const combinedHoldings = useRef<any[]>([]);
    const [initTableReady, setInitTableReady] = useState(false);
    const currentFilters = useRef<HoldingFilters | undefined>(undefined);
    const [ filterModel, setFilterModel ] = useState( {items: []} as GridFilterModel );
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [loading, setLoading] = useState(true);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [holdingDetails, setHoldingDetails] = useState<HoldingDisplayDetails>(holdingsDisplayDetailsInit)
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(getInitialColumnVisibilityModel());
    const currentSelectedHoldingIds = useRef<Set<string>>(new Set())

    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();

    useEffect(() => {
        handleFetchHoldings(currentFilters.current, sortModel);
        setInitTableReady(true);
    }, [refreshSignal]);

    useEffect(() => {
        if (!loading && nextPage) {
            mapPageToNextCursor.current[paginationModel.page] = nextPage;
        }
    }, [paginationModel.page, loading, nextPage]);

    useEffect(() => {
        if (selectedHoldings.length === 0) {
            currentSelectedHoldingIds.current = new Set();
        }
    }, [selectedHoldings]);

    const handleFetchHoldings = async (holdingFilters: HoldingFilters | undefined, sortItems: GridSortModel | undefined) => {
        combinedHoldings.current = [];
        currentSelectedHoldingIds.current = new Set();
        // reset pagination on reload (from retirement or anything else), but do not reset filters
        lastLoadedPage.current = 0;
        mapPageToNextCursor.current = {};
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        // load data
        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchHoldings(holdingFilters, sortItems, undefined, abortController.current.signal);
    };

    const loadMore = async (newPaginationModel: GridPaginationModel) => {
        setLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchHoldings(currentFilters.current, sortModel, mapPageToNextCursor.current[newPaginationModel.page - 1], abortController.current.signal);
    };

    const fetchHoldings = async (
        holdingFilters: HoldingFilters | undefined,
        sortItems: GridSortModel | undefined,
        nextPage: string | undefined,
        abortSignal: AbortSignal
    ) => {

        try {

            // Choose the query parameters based on whether we are searching by PRODUCT_PROJECT_VINTAGE, ACCOUNT_ID, or ACCOUNT_ID_PRODUCT_AND_TYPE
            let queryComponents = [];

            if (accountId) {
                queryComponents.push(`accountId=${accountId}`);
            }
            if (product) {
                queryComponents.push(`productId=${product}`);
            } else {
                // Filter for holdings belonging to products that are declared in our config
                queryComponents.push(`productId=${appConfigState.getProducts(true).map(p => p.id).join(',')}`);
            }
            if (projectType) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'TYPE').key}]=${wrapFilterValueWithQuotes(projectType)}`);
            }
            if (project) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'ID').key}]=${wrapFilterValueWithQuotes(project)}`);
            }
            if (vintage) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'VINTAGE').key}]=${wrapFilterValueWithQuotes(vintage)}`);
            }
            if (countryCode) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key}]=${wrapFilterValueWithQuotes(countryCode)}`);
            }
            if (projectState) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'STATE').key}]=${wrapFilterValueWithQuotes(projectState)}`);
            }
            if (fuelSource) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key}]=${wrapFilterValueWithQuotes(fuelSource)}`);
            }
            if (generationYear) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key}]=${wrapFilterValueWithQuotes(generationYear)}`);
            }
            if (creationYear) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key}]=${wrapFilterValueWithQuotes(creationYear)}`);
            }
            if (generationState) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key}]=${wrapFilterValueWithQuotes(generationState)}`);
            }
            if (greenPowerAccredited) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key}]=${wrapFilterValueWithQuotes(greenPowerAccredited)}`);
            }
            if (accreditationCode) {
                queryComponents.push(`attributes[${appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key}]=${wrapFilterValueWithQuotes(accreditationCode)}`);
            }

            let filters = '';
            filters += holdingFilters?.accountId ? `&accountId=${holdingFilters.accountId}` : '';
            if (holdingState) {
                filters += `&state=${holdingState}`;
            } else {
                filters += `&state=${holdingFilters?.state ? holdingFilters.state : 'Unspent,Escrowed'}`;
            }
            filters += holdingFilters?.attributeFilters?.map(attr => `&attributes[${attr.code}]=${attr.value}`).join('') ?? ``;

            let sortArgs = '';
            if ((sortItems !== undefined && sortItems.length > 0) || defaultOrdering) {
                sortArgs += `&sort=${buildSortURI(sortItems, defaultOrdering)}`;
            }

            let nextPageQueryString: string = '';
            if (nextPage !== undefined) {
                nextPageQueryString = `&page.from=${nextPage}`;
            }

            let queryComponentString = '';
            if (queryComponents.length > 0) {
                queryComponentString = '&' + queryComponents.join('&')
            }
            const holdingsData = await fetchWithCortenAndHandleAbort(
                `/api/pub/holdings?includeProductItemData=true${queryComponentString}${filters}${nextPageQueryString}${sortArgs}&page.withCount=true`,
                {
                    method: 'GET'
                },
                abortSignal
            );
            if (holdingsData === null) {
                return; // The request was aborted
            }

            setLoading(false);
            setNextPage(holdingsData.nextPage);
            setTotalAvailableRowCount(holdingsData.count);

            // enrich holdings data
            for (let holding of holdingsData.list) {
                holding.product =  appConfigState.getProduct(holding.productItem.data.productId)?.displayCode;
                holding.projectType = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'TYPE').key];
                holding.projectName = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'NAME').key];
                holding.projectId = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'ID').key];
                holding.vintage = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key];
                holding.serialNumRange = SerialNumber.fromHolding(holding, appConfigState)?.getRange(holding.index, Number(holding.amount));
                if (queryType === HoldingQueryType.FORWARDS) {
                    holding.forwardId = holding.holdingId;
                    holding.tradeDate = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'TIMESTAMP').key];
                    holding.tradeId = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'ID').key];
                    holding.valueDate = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'VALUE_DATE').key];
                    holding.currency = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'CURRENCY').key];
                    holding.price = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'PRICE').key];
                    holding.trader = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'TRADER_NAME').key];
                    holding.salesPerson = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'SALES_PERSON').key];
                    holding.salesCredits = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'SALES_CREDITS').key];
                    holding.brokerName = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'BROKER_NAME').key];
                    holding.brokerage = holding.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').key];
                }
                if (appConfigState.getProduct(holding.productId)?.productBase === ProductBase.Certificate) {
                    holding.accreditationCode = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key];
                    holding.fuelSource = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key];
                    holding.creationYear = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key];
                    holding.generationYear = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key];
                    holding.generationState = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key];
                    holding.greenpowerAccredited = holding.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key];
                }
            }

            combinedHoldings.current = [...combinedHoldings.current, ...holdingsData.list];

            // set state for final data to display in holdings table
            setHoldings(combinedHoldings.current.slice(lastLoadedPage.current * PAGE_SIZE, (lastLoadedPage.current + 1) * PAGE_SIZE));

        } catch (error) {
            console.error('Some Promise All requests failed', error);
        }
    };

    const buildSortURI = (items: GridSortModel | undefined, defaultSort?: string | undefined): string => {
        const sortItems: string[] = []

        if (items) {
            items
                .filter((item) => item.sort !== undefined && item.sort !== null)
                .forEach((item) => {
                    let directionChar = item.sort === 'asc' ? '+' : '-';

                    switch (item.field) {
                        case 'holdingId':
                            sortItems.push(`${directionChar}id`)
                            break;
                        case 'amount':
                            sortItems.push(`${directionChar}amount`)
                            break;
                        case 'accreditationCode':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key}`)
                            break;
                        case 'fuelSource':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key}`)
                            break;
                        case 'generationYear':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key}`)
                            break;
                        case 'creationYear':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key}`)
                            break;
                        case 'generationState':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key}`)
                            break;
                        case 'greenpowerAccredited':
                            sortItems.push(`${directionChar}${appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key}`)
                            break;
                    }
                })
        }

        // If a default sort is specified and we are not sorting by any custom columns, then use default sort
        if (defaultSort !== undefined && sortItems.length === 0) {
            sortItems.push(defaultSort);
        }

        return sortItems.join(',')
    }

    const handleRowSelectEvent = (event: ChangeEvent<HTMLInputElement>, holdingId: string) => {
        if (event.target.checked) {
            currentSelectedHoldingIds.current.add(holdingId);
        } else {
            currentSelectedHoldingIds.current.delete(holdingId);
        }
        onHoldingSelectionUpdated(combinedHoldings.current.filter((h: any) => currentSelectedHoldingIds.current.has(h.holdingId)));
    };

    // on open of holdings details dialog
    const handleOpenHoldingDetails = async (holdingId: string, serialNumRange: string) => {
        // start modal loader
        setLoadingModal(true)
        // open modal
        setIsDetailsOpen(true)

        const data = await fetchHolding(holdingId);
        // get product code from productDisplayData Map
        const product = data.productId ?  appConfigState.getProduct(data.productId)?.displayCode : ''
        // get client name from account ID
        let clientName = appConfigState.getDisplayNameForAddress(data.accountId);
        let displayData: HoldingDisplayDetails = {
            holdingId: queryType === HoldingQueryType.FORWARDS ? '' : data.holdingId,
            forwardId: queryType === HoldingQueryType.FORWARDS ? data.holdingId : '',
            valueDate: queryType === HoldingQueryType.FORWARDS ? utcToLocalFormat(data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'VALUE_DATE').key], DATE_DISPLAY_FORMAT) : undefined,
            accountId: clientName!,
            amount: data.amount,
            productCode: product!,
            productBase: appConfigState.getProduct(data.productId)!.productBase,
            projectId: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'ID').key],
            projectName: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'NAME').key],
            projectType: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'TYPE').key],
            projectCountry: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key],
            projectState: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'STATE').key],
            projectUri: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'URI').key],
            projectVintage: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key],
            certificateAccreditationCode: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key],
            certificateFuelSource: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key],
            certificateGenerationYear: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key],
            certificateCreationYear: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key],
            certificateGenerationState: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key],
            certificateGreenpowerAccredited: data.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key]?.toString(),
            // TODO: Trade timestamp is currently not saved with time information. When it is, remove the date format from the display below.
            tradeTimestamp: queryType === HoldingQueryType.FORWARDS ? utcToLocalFormat(data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'TIMESTAMP').key], DATE_DISPLAY_FORMAT): '',
            tradeId: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'ID').key]: '',
            tradeCurrency: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'CURRENCY').key]: '',
            tradePrice: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'PRICE').key]: '',
            traderName: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'TRADER_NAME').key]: '',
            salesPerson: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'SALES_PERSON').key]: '',
            salesCredits: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'SALES_CREDITS').key]: '',
            brokerName: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'BROKER_NAME').key]: '',
            brokerFee: queryType === HoldingQueryType.FORWARDS ? data.productItem.data.attributes[appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').key]: '',
            serialNumbers: serialNumRange,
            maxDecimalPos: data.maxDecimalPos,
            minDecimalPos: data.minDecimalPos
        }

        // set data to state
        setHoldingDetails(displayData)
        // stop loader
        setLoadingModal(false)
    }

    // on close of holding details modal
    const handleCloseDetails = () => {
        setIsDetailsOpen(false)
    }

    const holdingAttributeNames = {
        projectId: appConfigState.getAttribute('PROJECT', 'ID').key,
        vintage: appConfigState.getAttribute('PROJECT', 'VINTAGE').key,
        accreditationCode: appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key,
        fuelSource: appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key,
        generationYear: appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key,
        creationYear: appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key,
        generationState: appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key,
        greenpowerAccredited: appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key
    };

    const getFilters = (items: GridFilterItem[]): HoldingFilters => {
        return {
            accountId: items.find(item => item.field === 'accountId')?.value,
            state: items.find(item => item.field === 'state')?.value,
            attributeFilters: items
                .filter(item => !isBlankFilterItem(item))
                .filter(item=> item.field in holdingAttributeNames)
                .map((item) => ({
                    code: holdingAttributeNames[item.field as keyof typeof holdingAttributeNames],
                    value: extendedMuiFilterToCoreFilter({item: item})
                } as AttributeCriteria))
        } as HoldingFilters;
    }

    const filtersEqual = (a: HoldingFilters, b: HoldingFilters | undefined): boolean => {
        if (b === undefined) return false;
        return a.accountId === b.accountId && a.state === b.state &&
            a.attributeFilters.length === b.attributeFilters.length &&
            a.attributeFilters.every((val, index) => val === b.attributeFilters[index]);
    }

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        if (loading) {
            return; // Since we use block based pagination we cannot skip ahead to future pages, we need to wait for the current page to load
        }
        if (newPaginationModel.page > lastLoadedPage.current) {
            lastLoadedPage.current = newPaginationModel.page;
            loadMore(newPaginationModel);
        } else {
            setHoldings(combinedHoldings.current.slice(newPaginationModel.page * PAGE_SIZE, (newPaginationModel.page + 1) * PAGE_SIZE));
        }
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    const WideTooltip = withStyles({ tooltip: { maxWidth: 'none' } })(Tooltip);

    const filterAndOrderColumnsByDefinition = (columns: GridColDef<any>[]): GridColDef<any>[] => {
        let selectedColumns: GridColDef<any>[] = [];
        for (let columnDefinition of columnDefinitions) {
            let matchingColumn = columns.find(column => column.field === columnDefinition.key);
            if (matchingColumn !== undefined) {
                selectedColumns.push(matchingColumn);
            }
        }
        return selectedColumns;
    }

    return (
        <>
            {!initTableReady ? (
                <LinearProgress />
            ) : (
                <>
                    <Box style={{ width: '100%', height: 'auto', overflow: "auto"}}>
                        <DataGrid
                            autoHeight={true}
                            getRowHeight={() => 'auto'}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            }}
                            slots={{ toolbar: GridToolbar }}
                            rows={holdings}
                            getRowId={row => row.holdingId}
                            disableRowSelectionOnClick
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => {
                                setColumnVisibilityModel(newModel);
                            }}
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={( newFilterModel ) => {
                                const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
                                setFilterModel(comparedFilterModel);
                                const newFilters = getFilters(comparedFilterModel.items);
                                if (!filtersEqual(newFilters, currentFilters.current)) {
                                    currentFilters.current = newFilters;
                                    handleFetchHoldings(newFilters, sortModel);
                                }
                            }}
                            // Sort props
                            sortingMode='server'
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                setSortModel(newSortModel);
                                handleFetchHoldings(currentFilters.current, newSortModel);
                            }}
                            pageSizeOptions={[PAGE_SIZE]}
                            rowCount={totalAvailableRowCount}
                            paginationMode="server"
                            onPaginationModelChange={handlePaginationModelChange}
                            paginationModel={paginationModel}
                            loading={loading}
                            // TODO: When the server supports exports (server side) then configure the export button to use that instead of exporting the current page only
                            columns={filterAndOrderColumnsByDefinition([
                                {
                                    field: 'selectbox',
                                    headerName: '',
                                    filterable: false,
                                    sortable: false,
                                    disableExport: true,
                                    renderCell: (params: any) => {
                                        return <Checkbox
                                            checked={selectedHoldings!.includes(params.row)}
                                            onChange={(event) => handleRowSelectEvent(event, params.row.holdingId)}
                                            // Disable any rows that have a different product ID from the first selected one so that the holdings are all from one product
                                            // or if the state is not Unspent
                                            // or if holdings are opened from inventory management and holding is not held by inventory
                                            // or if holdings belong to a certificate based product
                                            disabled={
                                                (selectedHoldings.length > 0 && selectedHoldings[0].productId !== params.row.productId) ||
                                                params.row.state !== 'Unspent' ||
                                                (queryType === HoldingQueryType.PRODUCT_PROJECT_VINTAGE && params.row.accountId !== appConfigState.getAccount('INVENTORY_ISSUER').id) ||
                                                (appConfigState.getProduct(params.row.productId)?.productBase === ProductBase.Certificate)
                                            }
                                        />
                                    }
                                },
                                {
                                    field: 'tradeDate',
                                    headerName: 'Trade Date',
                                    minWidth: 180,
                                    flex: 1,
                                    type: 'date',
                                    filterOperators:dateRangeFilterOperators({showTimeStamp:false}),
                                    valueGetter: ({ value }) => value && new Date(value),
                                    valueFormatter: ({ value }) => value && utcToLocalFormat(value, DATE_DISPLAY_FORMAT)
                                },
                                {
                                    field: 'tradeId',
                                    headerName: 'Trade ID',
                                    minWidth: 150,
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: stringFilterOperators
                                },
                                {
                                    field: 'valueDate',
                                    headerName: 'Value Date',
                                    filterable: false,
                                    minWidth: 145,
                                    flex: 1,
                                    type: 'date',
                                    valueGetter: ({value}) => value && new Date(value),
                                    renderCell: (params) => {
                                        return <Typography>{params.value && utcToLocalFormat(new Date(params.value).toDateString(), DATE_DISPLAY_FORMAT)}</Typography>
                                    }
                                },
                                {
                                    field: 'holdingId',
                                    headerName: 'ID',
                                    filterable: false,
                                    minWidth: 100,
                                    flex: 1
                                },
                                {
                                    field: 'forwardId',
                                    headerName: 'Forward ID',
                                    filterable: false,
                                    minWidth: 145,
                                    flex: 1
                                },
                                {
                                    field: 'amount',
                                    headerName: queryType === HoldingQueryType.INVENTORY_CERTIFICATES ? 'Quantity' : 'Amount',
                                    type: 'number',
                                    filterable: false,
                                    minWidth: queryType === HoldingQueryType.INVENTORY_CERTIFICATES ? 130 : 120,
                                    flex: 1,
                                    renderCell: (params) => {
                                        return <AmountFormatWrapper
                                        amount={params.row.amount as number}
                                        minDecimalPos={productData.get(params.row.productItem.data.productId)?.minDecimalPos!}
                                        maxDecimalPos={productData.get(params.row.productItem.data.productId)?.maxDecimalPos!} />;
                                    }
                                },
                                {
                                    field: 'currency',
                                    headerName: 'Currency',
                                    minWidth: 130,
                                    flex: 1,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    valueOptions: [
                                        { value: 'AUD', label: 'AUD' },
                                        { value: 'USD', label: 'USD' }
                                    ]
                                },
                                {
                                    field: 'price',
                                    headerName: 'Price',
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'number',
                                    filterOperators: [
                                        ...extendedNumericFloatFilterOperators,
                                        ...inputRangeFilterOperators({type:'Decimal'})
                                    ],
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'product',
                                    headerName: 'Product',
                                    filterable: false,
                                    sortable: false, // TODO: Re-enable when available
                                    minWidth: 120,
                                    flex: 1
                                },
                                {
                                    field: 'projectType',
                                    headerName: 'Project Type',
                                    filterOperators: stringFilterOperators,
                                    sortable: false, // TODO: Re-enable when available
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !(projectType != undefined && projectType != ''),
                                    valueFormatter: (params: any) => renderFieldValue(params.value)
                                },
                                {
                                    field: 'projectId',
                                    headerName: 'Project',
                                    filterOperators: stringFilterOperators,
                                    sortable: false, // TODO: Re-enable when available
                                    minWidth: 150,
                                    flex: 1,
                                    filterable: !(queryType === HoldingQueryType.ACCOUNT_ID_PRODUCT_AND_TYPE && project != undefined && project != ''),
                                    valueFormatter: (params: any) => renderFieldValue(params.value)
                                },
                                {
                                    field: 'projectName',
                                    headerName: 'Project Name',
                                    filterOperators: stringFilterOperators,
                                    sortable: false, // TODO: Re-enable when available
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !(project != undefined && project != ''),
                                    valueFormatter: (params: any) => renderFieldValue(params.value)
                                },
                                {
                                    field: 'vintage',
                                    headerName: 'Vintage',
                                    filterOperators: numericFilterOperators,
                                    sortable: false, // TODO: Re-enable when available
                                    filterable: !(queryType === HoldingQueryType.ACCOUNT_ID_PRODUCT_AND_TYPE && vintage != undefined && vintage != ''),
                                    valueFormatter: (params: any) => renderFieldValue(params.value),
                                    minWidth: 120,
                                    flex: 1
                                },
                                {
                                    field: 'state',
                                    headerName: 'State',
                                    type: 'singleSelect',
                                    filterOperators: singleMatchStringFilterOperator,
                                    sortable: false, // TODO: Re-enable when available
                                    flex: 1,
                                    minWidth: 150,
                                    valueOptions: [
                                        // Values in this list should be kept in sync with core10 HoldingState.kt
                                        { value: 'Unassigned', label: 'Unassigned' },
                                        { value: 'Unspent', label: 'Unspent' },
                                        { value: 'Escrowed', label: 'Locked' },
                                    ]
                                },
                                {
                                    field: 'accreditationCode',
                                    headerName: 'Accreditation Code',
                                    flex: 1,
                                    minWidth: 200,
                                    filterOperators: stringFilterOperators,
                                    filterable: !accreditationCode
                                },
                                {
                                    field: 'fuelSource',
                                    headerName: 'Fuel Source',
                                    flex: 1,
                                    minWidth: 150,
                                    filterOperators: stringFilterOperators,
                                    filterable: !fuelSource,
                                    valueFormatter: (params: any) => renderFieldValue(params.value)
                                },
                                {
                                    field: 'creationYear',
                                    headerName: 'Creation Year',
                                    flex: 1,
                                    minWidth: 160,
                                    filterable: !creationYear,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({})
                                    ]
                                },
                                {
                                    field: 'generationYear',
                                    headerName: 'Generation Year',
                                    flex: 1,
                                    minWidth: 180,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({})
                                    ],
                                    filterable: !generationYear,
                                    valueFormatter: (params: any) => renderFieldValue(params.value)
                                },
                                {
                                    field: 'generationState',
                                    headerName: 'Generation State',
                                    flex: 1,
                                    minWidth: 180,
                                    filterOperators: stringFilterOperators,
                                    filterable: !generationState
                                },
                                {
                                    field: 'greenpowerAccredited',
                                    headerName: 'GreenPower Accredited',
                                    flex: 1,
                                    minWidth: 230,
                                    filterable: !greenPowerAccredited,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    renderCell: (params: any) => {
                                        switch (params.row.greenpowerAccredited) {
                                            case true:
                                                return <FontAwesomeIcon icon={faCheck}/>;
                                            case false:
                                                return <FontAwesomeIcon icon={faXmark}/>;
                                            default:
                                                return '-';
                                        }
                                    },
                                    valueOptions: [
                                        { value: 'true', label: 'true' },
                                        { value: 'false', label: 'false' }
                                    ],
                                    valueFormatter: ({ value }) => value !== undefined ? `${value}` : '-'
                                },
                                {
                                    field: 'serialNumRange',
                                    headerName: 'Serial Numbers',
                                    filterable: false,
                                    sortable: false,
                                    minWidth: 150,
                                    flex: 5,
                                    valueFormatter: (params: any) => `${params.value}`,
                                    renderCell: (params: any) =>
                                        <WideTooltip arrow title={
                                            <div onClick={() => navigator.clipboard.writeText(params.value)}
                                                 style={{ whiteSpace: 'pre-line' }}>
                                                {params.value}
                                            </div>
                                        }>
                                            <span>{renderFieldValue(params.value)}</span>
                                        </WideTooltip>
                                },
                                {
                                    field: 'accountId',
                                    headerName: 'Held By',
                                    type: 'singleSelect',
                                    filterOperators: singleMatchStringFilterOperator,
                                    sortable: false,
                                    minWidth: 200,
                                    flex: 2,
                                    valueOptions: [
                                        {
                                            value: appConfigState.getAccount('INVENTORY_ISSUER').id,
                                            label: appConfigState.getAccount('INVENTORY_ISSUER').display
                                        }
                                    ].concat(appConfigState.getClients().map((client) => (
                                        {
                                            value: client.id,
                                            label: client.display
                                        }
                                    )))
                                },
                                {
                                    field: 'trader',
                                    headerName: 'Trader',
                                    minWidth: 150,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'salesPerson',
                                    headerName: 'Sales Person',
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'salesCredits',
                                    headerName: 'Sales Credits',
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'brokerName',
                                    headerName: 'Broker Name',
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'brokerage',
                                    headerName: 'Brokerage',
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'info',
                                    headerName: 'Info',
                                    filterable: false,
                                    sortable: false,
                                    disableExport: true,
                                    renderCell: (params: any) => {
                                        return <IconButton color="primary" size='small' onClick={() => handleOpenHoldingDetails(params.row.holdingId, params.row.serialNumRange) }><FontAwesomeIcon icon={faInfoCircle}/></IconButton>;
                                    }
                                },
                            ])}
                            density="compact"
                            disableDensitySelector
                            aria-label="holdings table"
                        />
                    </Box>

                    <HoldingDetails open={isDetailsOpen} onClose={handleCloseDetails} loading={loadingModal} data={holdingDetails} />
                </>
            )}
        </>
    );
};

export { Holdings, HoldingQueryType };
