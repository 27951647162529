import dayjs from 'dayjs';

const TIMESTAMP_DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_DISPLAY_FORMAT = 'YYYY-MM-DD';

/**
 * format UTC time to local time using dayjs library
 *
 * @param UTCTimestamp
 * @returns formatted local date time string
 */
const utcToLocalFormat = (
    UTCTimestamp: string,
    format: string | undefined = TIMESTAMP_DISPLAY_FORMAT
) => {
    return dayjs(UTCTimestamp).format(format);
};

const currentDateExportFormat = () => {
    const currentDate = new Date();
    return utcToLocalFormat(currentDate.toUTCString())
        .replace(/[/:]/g, '')
        .replace(/[/-]/g, '')
        .replace(/[ ]/g, 'T')
        .split('+')[0];
};

const isValidDate = (dateString: string | null | undefined): boolean => {
    if (!dateString) {
        return false;
    }
    const date = new Date(dateString);
    return !isNaN(date.getTime());
};

export {
    utcToLocalFormat,
    currentDateExportFormat,
    DATE_DISPLAY_FORMAT,
    TIMESTAMP_DISPLAY_FORMAT,
    isValidDate
};
