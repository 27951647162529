import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";

enum AlertType {
    Error = 'Error',
    Warning = 'Warning'
}

const AlertDialogContent = ({
    alertType,
    alertMessage,
    errorCode,
    handleDialogClose,
}: {
    alertType: AlertType;
    alertMessage: string;
    errorCode: string | null;
    handleDialogClose: () => void;
}) => {
    return (
        <>
            <DialogContent>
                <Typography variant='h2'> {alertType === AlertType.Error ? "Error" : alertType === AlertType.Warning ? "Warning" : ""}</Typography>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ marginRight: '4px' }}>
                        {alertMessage}
                    </Typography>
                    {alertType === AlertType.Error && errorCode
                        && (<Typography>Error code: {errorCode}</Typography>)
                    }
                    {alertType === AlertType.Error && !errorCode
                        && (<Typography>Please try again later.</Typography>)
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color='primary' variant='outlined'>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export { AlertType, AlertDialogContent }