/**
 * This file is for UI layout state
 */

import { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useAuth } from '../state/AuthProvider';
import { CustomTheme } from '../Themes';

const drawerWidth = '300px';
// This is the set minHeight of MUI nav bar 'dense' variant.
const navBarHeight = '50px';

/**
 * Open and close collapsable side menu ***************************************
 */

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: `calc(100vh - ${navBarHeight})`,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}`,
    overflow: 'hidden',
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth})`,
        marginLeft: `${drawerWidth}`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    height: navBarHeight,
    // this is an override
    '@media (min-width: 600px)': {
        minHeight: navBarHeight
    }
}));

/**
 * UI Layout provider *************************************
 */

interface LayoutStateType {
    open: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    customTheme: CustomTheme;
}

const LayoutStateContext = createContext<LayoutStateType | null>(null);

const LayoutProvider = ({ theme, children }: { 
    theme: CustomTheme;
    children?: ReactNode;
 }) => {
    const [open, setOpen] = useState(false);
    const { user, isTokenExpired} = useAuth();
    const [customTheme, setCustomTheme] = useState<CustomTheme>(theme);

    useEffect(() => {
        if ((!user || isTokenExpired(user)) && !open) {
           handleDrawerOpen();
        }
    }, [user]);

    useEffect(() => {
        setCustomTheme(theme);
    }, [theme])

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <LayoutStateContext.Provider
            value={{
                open: open,
                handleDrawerOpen: handleDrawerOpen,
                handleDrawerClose: handleDrawerClose,
                customTheme: customTheme
            }}
        >
            {children}
        </LayoutStateContext.Provider>
    );
};

function useLayoutState() {
    const context = useContext(LayoutStateContext);
    if (!context) {
        throw new Error('no provider for useLayoutState');
    }
    return context;
}

export { LayoutProvider, useLayoutState, drawerWidth, navBarHeight, Main, DrawerHeader, AppBar };
