import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from '@mui/material';

import { renderDialogField } from '../../../utility/DialogFieldRenderUtil';
import { useAppConfigState } from '../../../state/AppConfig';
import { PhysicalTradeForm } from './PhysicalTrade';
import { executeTransaction } from '../../../state/CortenClient';
import { useAuth } from '../../../state/AuthProvider';
import { useEffect, useRef, useState } from 'react';
import { TransactionOverview } from '../../../component/TransactionOverview';
import { useTransactionMonitor } from '../../../state/transaction/TransactionMonitor';
import { AlertDialogContent, AlertType } from '../../../component/AlertDialogContent';
import { TradeDirection } from '../TradeUtil';
import {
    getOrderedProjectsAndAmounts,
    renderOrderedProjectVintageAmounts,
    TradeOptimiser,
    TradeOptimiserAmountType
} from '../../../utility/TradeOptimiserUtil';
import dayjs from 'dayjs';
import { DATE_DISPLAY_FORMAT } from '../../../utility/utcToLocalFormat';
import { TransactionType } from '../../../state/Variables';
import { useProductDataState } from '../../../state/ProductDataProvider';

enum DialogState {
    REVIEW,
    SUCCESS,
    ERROR,
}

const PhysicalTradeSubmitDialog = ({ open, onClose, form, projectName}: {
    open: boolean,
    onClose: (resetForm: boolean) => void,
    form: PhysicalTradeForm,
    projectName: string | undefined,
}) => {
    const [dialogState, setDialogState] = useState<DialogState | null>(null);
    const [submitting, setSubmitting] = useState(false);
    const [transactionErrorCode, setTransactionErrorCode] = useState<string | null>(null);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [projectAmounts, setProjectAmounts] = useState<any[]>();

    const productItemIds = useRef<Promise<string[]>>();
    const user = useAuth();
    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => setDialogState(DialogState.SUCCESS),
        (tx) => onTransactionError(tx.errorCode),
        () => onTransactionError('TIMEOUT'),
    );

    useEffect(() => {
        if (open) {
            setDialogState(DialogState.REVIEW);
            getOrderedProjectsAndAmounts(
                form,
                getAccountIds(form).from,
                setProjectAmounts,
                productItemIds,
                user,
                appConfigState,
                handleError
            );

        }
    }, [open]);
    

    const handleError = () => {
        setDialogState(DialogState.ERROR);
    };

    const handleDialogClose = () => {
        unsubscribe();
        setDialogState(null);
        setSubmitting(false);
        setProjectAmounts(undefined);
        setTransactionId(null);
        setTimeout(() => setTransactionErrorCode(null), 1_000); // wait for animation to finish
        // if the transaction succeeded - reset trade form
        onClose(DialogState.SUCCESS === dialogState);
    };

    const confirmAndSendRequest = async () => {
        setSubmitting(true);
        executeTransaction(await buildUnsignedRequest(form), user)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(result => {
                        setTransactionId(result.txId);
                        subscribe(result.txId);
                    });
                } else {
                    onTransactionError();
                }
            }).catch(console.error);
    };


    const onTransactionError = (code: string | null = null) => {
        setTransactionErrorCode(code);
        setDialogState(DialogState.ERROR);
    };

      const getValueDate = (): any => {
        let forwardDate = dayjs();
        let forwardDateOption = '';
        if (form.valueDateOption === undefined) {
            return {
                value: undefined,
                display: ''
            }
        } else if (form.valueDateOption === 'Custom') {
            forwardDate = form.customValueDate;
        } else {
            forwardDate = forwardDate.add(parseInt(form.valueDateOption), 'day');
            forwardDateOption = ` (T + ${form.valueDateOption} day${form.valueDateOption === '1' ? '' : 's'})`
        }
        return {
            value: forwardDate,
            display: `${dayjs(forwardDate.toDate()).format(DATE_DISPLAY_FORMAT)}${forwardDateOption}`
        }
    };

    const buildUnsignedRequest = async (data: PhysicalTradeForm) => {
        return {
            type: 'TransferRequest',
            fromAccountId: getAccountIds(form).from,
            nonce: new Date().getTime(),
            outputList: [{
                from: {
                    type: 'ProductItemsAmount',
                    productItemIds: await productItemIds.current,
                    amount: data.quantity,
                },
                toAccountId: getAccountIds(form).to,
            }],
            attributes: {
                ...data.tradeId && { [`${appConfigState.getAttribute('TRADE', 'ID').key}`]: data.tradeId },
                // TODO MBL-201: keep time component
                [`${appConfigState.getAttribute('TRADE', 'TIMESTAMP').key}`]: data.tradeDate.startOf('day').toDate().getTime(),
                [`${appConfigState.getAttribute('TRADE', 'TRADER_NAME').key}`]: data.trader,
                [`${appConfigState.getAttribute('TRADE', 'PRICE').key}`]: data.price,
                [`${appConfigState.getAttribute('TRADE', 'CURRENCY').key}`]: data.currency.toString(),
                [`${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`]: getValueDate().value.startOf('day').toDate().getTime(),
                ...data.salesPerson && { [`${appConfigState.getAttribute('TRADE', 'SALES_PERSON').key}`]: data.salesPerson },
                ...data.salesCredits && { [`${appConfigState.getAttribute('TRADE', 'SALES_CREDITS').key}`]: data.salesCredits },
                ...data.broker && { [`${appConfigState.getAttribute('TRADE', 'BROKER_NAME').key}`]: data.broker },
                ...data.brokerageFee && { [`${appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').key}`]: data.brokerageFee },
                [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key}`]:
                    data.tradeDirection === TradeDirection.BUY ?
                        TransactionType.PhysicalBuy :
                        TransactionType.PhysicalSell,
                [`${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key}`]:
                    data.tradeDirection === TradeDirection.BUY ?
                        TransactionType.PhysicalSell :
                        TransactionType.PhysicalBuy
            }
        };
    };

    const getAccountIds = (form: PhysicalTradeForm): {from: string, to: string} => {
        let issuerAccount = appConfigState.getAccount('INVENTORY_ISSUER').id;
        return {
            from: form.tradeDirection === TradeDirection.SELL ? issuerAccount : form.counterparty,
            to: form.tradeDirection === TradeDirection.SELL ? form.counterparty : issuerAccount,
        }
    };

    return (
        <Container>
            <Dialog open={DialogState.REVIEW === dialogState} onClose={handleDialogClose} fullWidth maxWidth='sm'>
                <DialogContent>
                    <Typography variant='h2'>Review Details Below</Typography>
                    {form.tradeId && renderDialogField('Trade ID', form.tradeId)}
                    {renderDialogField('Trade Date', dayjs(form.tradeDate).format(DATE_DISPLAY_FORMAT))}
                    {renderDialogField('Trader', form.trader)}
                    {renderDialogField('Trade Direction', form.tradeDirection)}
                    {renderDialogField('Counterparty', appConfigState.getClientForAddress(form.counterparty))}
                    {renderDialogField('Product', appConfigState.getProduct(form.product)?.displayCode)}
                    {form.projectType && renderDialogField('Project Type', form.projectType)}
                    {form.project && renderDialogField('Project', projectName)}
                    {form.vintage && renderDialogField('Vintage', form.vintage)}
                    {form.state && renderDialogField('State', form.state)}
                    {form.country && renderDialogField('Country', form.country)}
                    {form.fuelSource && renderDialogField('Fuel Source', form.fuelSource)}
                    {form.creationYear && renderDialogField('Creation Year', form.creationYear)}
                    {form.generationYear && renderDialogField('Generation Year', form.generationYear)}
                    {form.generationState && renderDialogField('Generation State', form.generationState)}
                    {form.greenpowerAccredited && renderDialogField('GreenPower Accredited', form.greenpowerAccredited)}
                    {renderDialogField('Quantity', form.quantity, {
                        minDecimalPos: productData.get(form.product)?.minDecimalPos!,
                        maxDecimalPos: productData.get(form.product)?.maxDecimalPos!,
                    })}
                    {renderDialogField('Value Date', getValueDate().display)}
                    {renderDialogField('Currency', form.currency)}
                    {renderDialogField('Price', form.price, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesCredits && renderDialogField('Sales Credits', form.salesCredits, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.salesPerson && renderDialogField('Sales Person', form.salesPerson)}
                    {form.brokerageFee && renderDialogField('Brokerage Fee', form.brokerageFee, {
                        minDecimalPos: 2,
                        maxDecimalPos: 2,
                    })}
                    {form.broker && renderDialogField('Broker Name', form.broker)}
                    {form.tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(form.product)!!, TradeOptimiserAmountType.Price)}
                    {form.tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(form.product)!!, TradeOptimiserAmountType.Score)}
                    {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(form.tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < form.quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color='primary' variant='outlined'>Cancel</Button>
                    <Button onClick={confirmAndSendRequest}
                            color='primary'
                            variant='outlined'
                            disabled={
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(form.tradeOptimiser) && 
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < form.quantity)
                            }>
                        {submitting ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '2px',
                            }}>
                                <CircularProgress size={20} />
                            </div>
                        ) : ('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
            {DialogState.SUCCESS === dialogState && <TransactionOverview
                open={DialogState.SUCCESS === dialogState}
                onClose={handleDialogClose}
                title='Transaction Submitted Successfully'
                attributes={{
                    ...form.tradeId && {'Trade ID': {value: form.tradeId, shorterFormId: true, key:'tradeId' }},
                    'Transaction ID': { value: transactionId!, shorterFormId: true, key:'transactionId' },
                    'Trade Date': { value: dayjs(form.tradeDate).format(DATE_DISPLAY_FORMAT), key:'tradeDate' },
                    'Trader': { value: form.trader, key:'trader' },
                    'Trade Direction': { value: form.tradeDirection, key:'tradeDirection' },
                    'Counterparty': { value: appConfigState.getClientForAddress(form.counterparty), key:'counterParty' },
                    'Product Code' : { value: appConfigState.getProduct(form.product)?.displayCode, key:'productId' },
                    ...form.projectType && {'Project Type': { value: form.projectType, key:'projectType' }},
                    ...form.project && {'Project': { value: projectName,    key:'projectName' }},
                    ...form.vintage && {'Vintage': { value: form.vintage, key:'vintage' }},
                    ...form.state && {'State': { value: form.state, key:'state' }},
                    ...form.country && {'Country': { value: form.country, key:'country' }},
                    ...form.fuelSource && {'Fuel Source': { value: form.fuelSource, key:'fuelSource' }},
                    ...form.creationYear && {'Creation Year': { value: form.creationYear,key:'creationYear' }},
                    ...form.generationYear && {'Generation Year': { value: form.generationYear, key:'generationYear' }},
                    ...form.generationState && {'Generation State': { value: form.generationState, key:'generationState' }},
                    ...form.greenpowerAccredited && {'GreenPower Accredited': { value: form.greenpowerAccredited, key:'greenpowerAccredited' }},
                    'Quantity': {
                        value: form.quantity.toString(),
                        key:'quantity',
                        renderProp: { 
                            minDecimalPos: productData.get(form.product)?.minDecimalPos!, 
                            maxDecimalPos: productData.get(form.product)?.maxDecimalPos! 
                        },
                    },
                    'Value Date': { value: getValueDate().display, key:'valueDate'},
                    'Currency': { value: form.currency, key:'currency' },
                    'Price': { value: form.price, renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }, key:'price' },
                    ...form.salesCredits && {'Sales Credits': { value: form.salesCredits, renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }, key:'salesCredits' }},
                    ...form.salesPerson && {'Sales Person': { value: form.salesPerson }},
                    ...form.brokerageFee && {'Brokerage Fee': { value: form.brokerageFee, renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }, key:'brokerage' }},
                    ...form.broker && {'Broker Name': { value: form.broker, key:'brokerName' }},
                }}
            />}

            <Dialog open={DialogState.ERROR === dialogState} onClose={handleDialogClose}>
                {transactionErrorCode !== 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Error}
                        alertMessage='An error occurred while submitting the form.'
                        errorCode={transactionErrorCode}
                        handleDialogClose={handleDialogClose}
                    />
                )}

                {transactionErrorCode === 'TIMEOUT' && (
                    <AlertDialogContent
                        alertType={AlertType.Warning}
                        alertMessage='The transaction timed out. Please check back later to confirm whether the transaction was
                    processed.'
                        errorCode={null}
                        handleDialogClose={handleDialogClose}
                    />
                )}
            </Dialog>
        </Container>
    );
};

export { PhysicalTradeSubmitDialog };
