// truncate URI
const truncateUri = (uri: string) => {
    if (!uri) return null;
    if (uri.length > 39) {
        return `${uri.slice(0, 40)}...`;
    }
    return uri;
};

export { truncateUri };
