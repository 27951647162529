import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ReactNode } from 'react';

type DialogState = 'REVIEW' | 'SUCCESS' | 'ERROR' | undefined;

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    handleAfterSuccess: () => void;
    isConfirmLoading: boolean;
    confirmLabel?: string;
    currentState: DialogState;
    render: (dialogState: DialogState) => ReactNode;
};

/**
 * Custom dialog component for confirmation dialogs.
 *
 * @param  isOpen Prop a boolean indicating whether the dialog is open or not
 * @param  onCancel Prop a function to be called when the user cancels the dialog
 * @param  onConfirm Prop a function to be called when the user confirms the dialog
 * @param  handleAfterSuccess Prop a function to be called after the dialog is successfully confirmed
 * @param  isConfirmLoading Prop a boolean indicating whether the confirmation is loading or not
 * @param  confirmLabel Prop a string representing the label of the confirmation button
 * @param  currentState Prop a string representing the current state of the dialog
 * @param  render Prop a function to render the content of the dialog based on its state
 */

const ConfirmDialog = ({
    isOpen = false,
    onCancel,
    onConfirm,
    handleAfterSuccess,
    isConfirmLoading,
    confirmLabel = 'CONFIRM',
    currentState,
    render
}: Props) => {
    if (!isOpen) {
        return null;
    }

    return (
        <Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="xs">
            <DialogContent>{render(currentState)}</DialogContent>
            <DialogActions>
                {currentState === 'REVIEW' && (
                    <>
                        <Button onClick={onCancel} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button onClick={onConfirm} color="primary" variant="outlined">
                            {isConfirmLoading ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '2px 5px'
                                    }}
                                >
                                    <CircularProgress size={15} />
                                </span>
                            ) : null}
                            {confirmLabel}
                        </Button>
                    </>
                )}
                {currentState === 'SUCCESS' && (
                    <>
                        <Button onClick={handleAfterSuccess} color="primary" variant="outlined">
                            Close
                        </Button>
                    </>
                )}
                {currentState === 'ERROR' && (
                    <>
                        <Button onClick={onCancel} color="primary" variant="outlined">
                            Close
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export { ConfirmDialog, type DialogState };
