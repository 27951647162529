import {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    CircularProgress
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { useAuth } from '../state/AuthProvider';
import { useAccessKey } from '../state/AccessKeyManager';

export const OidcLogin = () => {
    const { user, signIn, signOut, loading, enabled } = useAuth();
    const [ currentKeyId, setCurrentKeyId]= useState()

    function shortAccountId(id) {
        if (id) {
            return id.substring(0, 10) + "..." + id.substring(id.length - 8)
            } else
            return "..."
    }

    function handleCopy(id) {
        navigator.clipboard.writeText(id);
    }

    const {currentKey} = useAccessKey()

    useEffect(() => {
        setCurrentKeyId(currentKey)
    }, [currentKey]);

    return (
        <Box sx={{ marginTop: 'auto', paddingTop: 2, borderTop: 'solid 1px #D3D3D3' }}>
            {enabled ? (
                !user ? (
                    <>
                        <Typography sx={{ marginBottom: '1rem' }}>
                            Log in with a configured OIDC provider:
                        </Typography>

                        <Button onClick={signIn} variant="outlined" disabled={loading}>
                            <Typography sx={{ fontSize: '1em' }}>Login</Typography>
                            {loading && (
                                <CircularProgress size={'1.5em'} sx={{ marginLeft: '10px' }} />
                            )}
                        </Button>
                    </>
                ) : (
                    <Box>
                        <Typography sx={{ marginBottom: '1rem' }}> You are logged in as {user.profile.username} </Typography>
                        <Card
                            variant={'outlined'}
                            sx={{
                                display: 'flex',
                                outlineStyle: 'double',
                                marginTop: 1,
                                marginBottom: 2
                            }}
                        >
                            <CardActionArea>
                                <Tooltip title="Authenticated account">
                                    <CardContent>
                                        <Typography component="div" variant="h8">
                                            Account id
                                        </Typography>
                                        {
                                            currentKeyId === ''
                                            ?
                                            <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="div"
                                            >
                                                Account ID unavailable
                                            </Typography>
                                            :
                                            <Typography
                                                variant="subtitle2"
                                                color="text.secondary"
                                                component="div"
                                            >
                                                {shortAccountId(currentKeyId)}
                                            </Typography>
                                        }
                                    </CardContent>
                                </Tooltip>
                            </CardActionArea>
                            <CardActions>
                                <Grid container>
                                    <Grid item>
                                        <Tooltip title="Copy id to clipboard">
                                            <IconButton
                                                size="small"
                                                sx={{ color: 'primary.main' }}
                                                onClick={() => handleCopy(currentKeyId)}
                                            >
                                                <FontAwesomeIcon icon={faCopy} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                        <Button onClick={signOut} variant="outlined">
                            <Typography sx={{ fontSize: '1em' }}>Logout</Typography>
                        </Button>
                    </Box>
                )
            ) : (
                <div>
                    <Typography>OIDC login disabled.</Typography>
                </div>
            )}
        </Box>
    );
};
