import { createTheme, Theme } from '@mui/material/styles';
import { darken, lighten, alpha } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

// space between sections on a page
export const sectionSpace = '2.5rem';

const trovioDark = '#1d201c';
const trovioDarker = '#151815';
const trovioOrange = '#f8834d';
const trovioBeige = '#e2e8c0';
const trovioBlue = '#9ba7c0';
const trovioLavender = '#cbc5ea';
const trovioGreen = '#71b771';
// const trovioTeal='#a0d1c8'
const defaultBlue = '#1975D2';

export type CustomTheme = {
    theme: Theme,
    customProps: any
}

// use these typographic variants, as directed
const typographyOptions: TypographyOptions = {
    fontFamily: 'PPPangramSans, Roboto, Arial',
    h2: { // page heading
        fontSize: '1.188rem', // =19px
    },
    h3: { // sub heading
        fontSize: '0.938rem', // =15px
    },
    body1: { // body
        fontSize: '0.875rem', // =14px
    },
};

export const defaultTheme: CustomTheme = {
    theme: createTheme({
        typography: typographyOptions,
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    color: 'default',
                },
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem',
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                    },
                },
            },
        },
    }),
    customProps: {
        name: 'default',
        navHoverBackgroundColor: alpha(defaultBlue, 0.3),
        navHoverTransition: 'background-color 0.2s ease'
    },
};

export const trovioDarkTheme: CustomTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'dark',
            primary: {
                main: trovioOrange,
            },
            secondary: {
                main: trovioBeige,
            },
            background: {
                default: trovioDark,
                paper: trovioDarker,
            },
            text: {
                primary: trovioBeige,
            },
            success: {
                main: trovioGreen,
            },
        },
        components: {
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem',
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                    },
                },
            },
        },
    }),
    customProps: {
        name: 'trovio-dark',
        navHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navHoverTransition: 'background-color 0.2s ease'
    }
};

export const trovioLightTheme: CustomTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'light',
            primary: {
                main: trovioOrange,
            },
            secondary: {
                main: trovioDark,
            },
            background: {
                default: lighten(trovioBeige, 0.8),
                paper: lighten(trovioBeige, 0.7),
            },
            text: {
                primary: trovioDark,
                secondary: trovioDarker,
                disabled: trovioDark,
            },
            success: {
                main: trovioGreen,
            },
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    color: 'secondary',
                },
                styleOverrides: {
                    colorSecondary: {
                        backgroundColor: trovioBeige,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem',
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                    },
                },
            },
        },
    }),
    customProps: {
        name: 'trovio-light',
        navHoverBackgroundColor: alpha(trovioOrange, 0.2),
        navHoverTransition: 'background-color 0.2s ease'
    },
};

const white = '#ffffff';
const cms1 = '#281B39';
const cms2 = '#2F147A';
// const cms3= '#4720B7'
const cms4 = '#40C0F2';
const cms5 = '#70F0A6';
// const cms6= '#B0F0CB'
// const cms7= '#D1F8E0'

export const cmsTheme: CustomTheme = {
    theme: createTheme({
        typography: typographyOptions,
        palette: {
            mode: 'light',
            primary: {
                main: darken(cms4, 0.2),
            },
            secondary: {
                main: cms2,
            },
            background: {
                paper: '#ffffff',
                default: lighten(cms4, 0.9),
            },
            text: {
                primary: cms1,
            },
            success: {
                main: cms5,
            },
        },
        components: {
            MuiAppBar: {
                defaultProps: {
                    variant: 'elevation',
                    color: 'secondary',
                },
            },
            MuiTypography: {
                styleOverrides: {
                    h2: {
                        fontWeight: 'bold',
                        marginBottom: '2rem',
                    },
                    h3: {
                        fontWeight: 'bold',
                        marginBottom: '0.5rem',
                    },
                },
            },
        },
    }),
    customProps: {
        name: 'cms',
        darkHeader: true,
        navHoverBackgroundColor: alpha(cms4, 0.3),
        navHoverTransition: 'background-color 0.2s ease'
    },
};

export const appThemes = {
    'cms': cmsTheme,
    'trovio-dark': trovioDarkTheme,
    'trovio-light': trovioLightTheme,
    'material': defaultTheme,
};
