import { createContext, Fragment, useContext, useEffect, useState } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { useAccessKey } from './AccessKeyManager';

const NotificationContext = createContext<any>({});

/**
 * Subscribe to the topic for the current access key and sets notifications as state.
 * Downstream components can get the 'current notification' from the state using `useNotification()`
 * @param children
 * @returns {JSX.Element}
 */
const NotificationProvider = ({ children }: { children: any }) => {
    const [notification, setNotification] = useState<any>({});
    const {currentKey} = useAccessKey()
    const topic = currentKey ? `/topic/account.${currentKey}` : undefined;
    const clearNotification = () => setNotification({});

    return (
        <NotificationContext.Provider value={{ notification, clearNotification }}>
            {topic && <NotificationSubscription topic={topic} onNotification={setNotification} />}
            {children}
        </NotificationContext.Provider>
    );
};

const NotificationSubscription = ({ topic, onNotification }: { topic: any, onNotification: any }) => {
    useEffect(() => {
        console.log(`Subscribing topic: ${topic}`);
    }, [topic]);

    useSubscription(topic, (rawMessage) => {
        // Handle response data in binary message body
        const message = rawMessage.isBinaryBody
            ? JSON.parse(new TextDecoder().decode(rawMessage.binaryBody))
            : rawMessage.body;
        onNotification(message);
    });

    return <Fragment />;
};

function useNotification() {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification without provider');
    }
    return context;
}

export { useNotification, NotificationProvider };
