import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useProjectDetailsState } from './state/ProjectDetailsState';
import {useTheme} from "@mui/material/styles";
import { useAppConfigState } from '../../state/AppConfig';
import { sectionSpace } from '../../Themes';

const ProjectAttributes = ({title, showVintage, vintage}: 
    {
        title:string;
        showVintage: boolean;
        vintage: string;
    }) => {
    const { selectedProjectDetails, registryId, selectedIds} = useProjectDetailsState();
    const theme = useTheme();
    const appConfigState = useAppConfigState();

    const tableRowStyle = { '&:last-child td, &:last-child th': { border: 0 } };

    return (
        <Box sx={{ marginBottom: sectionSpace }}>
            <Typography variant='h3'>{title}</Typography>
            <TableContainer component={Paper} sx={{ minWidth: 750 }}>
                <Table sx={{ minWidth: 750 }} size='small' aria-label="project attributes table">
                    <TableBody>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {'Product Code'}
                            </TableCell>
                            <TableCell>{ appConfigState.getProduct(selectedIds.current.productID)?.displayCode}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {'Product Name'}
                            </TableCell>
                            <TableCell>{ appConfigState.getProduct(selectedIds.current.productID)?.displayName}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell sx={{ width: 250 }}>
                                {appConfigState.getAttribute('REGISTRY', 'ID').display}
                            </TableCell>
                            <TableCell>{registryId}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{appConfigState.getAttribute('PROJECT', 'ID').display}</TableCell>
                            <TableCell>{selectedProjectDetails?.projectId}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{appConfigState.getAttribute('PROJECT', 'NAME').display}</TableCell>
                            <TableCell>{selectedProjectDetails?.name}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{appConfigState.getAttribute('PROJECT', 'TYPE').display}</TableCell>
                            <TableCell>{selectedProjectDetails?.type}</TableCell>
                        </TableRow>
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').display}</TableCell>
                            <TableCell>
                                {selectedProjectDetails?.countryCode}
                            </TableCell>
                        </TableRow>
                        { selectedProjectDetails?.state && (
                            <TableRow hover sx={tableRowStyle}>
                                <TableCell>{appConfigState.getAttribute('PROJECT', 'STATE').display}</TableCell>
                                <TableCell>
                                    {selectedProjectDetails?.state}
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow hover sx={tableRowStyle}>
                            <TableCell>{appConfigState.getAttribute('PROJECT', 'URI').display}</TableCell>
                            <TableCell>
                                {selectedProjectDetails != undefined && (
                                    <a
                                        href={selectedProjectDetails?.uri}
                                        target="_blank"
                                        style={{
                                            color: theme.palette.text.primary
                                        }}
                                    >
                                        {selectedProjectDetails?.uri}
                                    </a>
                                )}
                            </TableCell>
                        </TableRow>
                        {showVintage && (
                           <TableRow hover sx={tableRowStyle}>
                           <TableCell>{appConfigState.getAttribute('PROJECT', 'VINTAGE').display}</TableCell>
                           <TableCell>
                               {vintage}
                           </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ProjectAttributes;
