import { Box, Tab, Tabs, Typography, LinearProgress, Stack, Button } from '@mui/material';
import { useProjectDetailsState, useProjectDetailsDispatch } from './state/ProjectDetailsState';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAppConfigState } from '../../state/AppConfig';
import { Holdings, HoldingQueryType } from '../../component/Holdings';
import Vintages from './Vintages';
import {SyntheticEvent, useState, useEffect} from 'react';
import { HoldingsSummary } from './HoldingsSummary';
import ProjectAttributes from './ProjectAttributes';
import { HoldingRetirement } from '../../component/HoldingRetirement';
import { ProductDataRaw} from '../../component/HoldingRetirement';
import { fetchProductAttributesWithId } from '../../utility/Fetch';
import { sectionSpace } from '../../Themes';
import Error from '../../component/Error';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { useLayoutState } from '../../state/Layout';
import { useProductDataState } from '../../state/ProductDataProvider';

const ProjectDetails = () => {
    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();
    const { projectBalances, handleProjectDetailsView, selectedIds, loading, selectedProjectDetails, fetchProjectBalanceAndDetails, isPathError } =
        useProjectDetailsState();
    const { setIsPathError } = useProjectDetailsDispatch()

    // sub tabs / nested routes
    const [tabValue, setTabValue] = useState(0);
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [currentProduct, setCurrentProduct] = useState<ProductDataRaw[] | undefined>(undefined);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [holdingRefreshSignal, setHoldingRefreshSignal] = useState<number | any>(0);
    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        // If we change to the Vintages Tab then reset the selected holdings
        if (newValue == 1) {
            setSelectedHoldings([]);
        }
        setTabValue(newValue);
    };
    const {customTheme} = useLayoutState()

    // tab item text highlighting
    const hoverTabTextColor = {color: customTheme.theme.palette.secondary.main}
    
    // extract productID & projectID from url for use in fetch requests for this view
    // incase we input url directly in browser rather than come from a double click on the previous page
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productID = splitLocation[4];
    const projectID = splitLocation[6];
    const subTab = splitLocation[7];

    // select the sub tab based on url
    useEffect(() => {
        // set nested tabs according to url
        if (subTab === 'holdings') {
            setTabValue(0);
        }
        if (subTab === 'vintages') {
            setTabValue(1);
        }
    }, [subTab]);

    // entry point for view
    useEffect(() => {
        // clear path error
        setIsPathError(false)

        // check that product ID is valid before making any requests
        if (productID && appConfigState.getProducts().map(p => p.id).includes(productID)) {
            // set global state for productID and projectID
            selectedIds.current = {
                productID: productID,
                projectID: projectID,
                vintage: ''
            };

            handleProjectDetailsView();
            fetchProductAttributesWithId(productID).then((foundProducts) => {
                // convert to array format (state variable, holdings retirement & lock components expect an array)
                const filteredProducts = [foundProducts]
                
                setCurrentProduct(filteredProducts);
            });
        } else {
            // show an error
            setIsPathError(true)
        }
    }, []);

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setRetirementDialogActive(false);
        fetchProjectBalanceAndDetails([{ code: appConfigState.getAttribute('PROJECT', 'ID').key, value: selectedIds.current.projectID }]);
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setSelectedHoldings([]);
        setHoldingRefreshSignal(holdingRefreshSignal + 1);
        setLockToMarketDialogActive(false);
        fetchProjectBalanceAndDetails([{ code: appConfigState.getAttribute('PROJECT', 'ID').key, value: selectedIds.current.projectID }]);
    };

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    return (
        <>
            <Typography variant="h2">
                Project Details
            </Typography>

            {
                isPathError ?
                    <Error message='Error - Product ID not recognised' />
                :
                <>
                        {loading ? (
                            <LinearProgress />
                        ) : (
                            <>
                                {/* HOLDINGS *********************/}
                                {projectBalances && Object.keys(projectBalances).length > 0 ? (
                                    <HoldingsSummary
                                        issuerAmount={projectBalances?.issuerAmount}
                                        assignedAmount={projectBalances?.assignedAmount}
                                        unassignedAmount={projectBalances?.unassignedAmount}
                                        escrowAmount={projectBalances?.escrowAmount}
                                        title="Project Holding Summary"
                                        minDecimalPos={productData.get(productID)?.minDecimalPos!!}
                                        maxDecimalPos={productData.get(productID)?.maxDecimalPos!!}
                                    />
                                ) : (
                                    <Box sx={{ marginBottom: sectionSpace }}>
                                        <Typography variant='h3'>
                                            Project Holding Summary
                                        </Typography>
                                        <Typography>No holding summary to display</Typography>
                                    </Box>
                                )}
                                {/* PROJECT ATTRIBUTES *********************/}
                                <ProjectAttributes
                                    title='Project Summary'
                                    showVintage={false}
                                    vintage=''
                                />

                                {/* HOLDINGS & VINTAGE *********************/}
                                <Typography variant='h3'>
                                    Holdings and Vintages
                                </Typography>

                                <Stack direction="row" spacing={2} marginBottom={0} alignItems="baseline">
                                    <Button variant='outlined' onClick={openRetirementDialog} disabled={currentProduct === undefined}>Retire</Button>
                                    {retirementDialogActive && (
                                        <HoldingRetirement
                                            useDialog={true}
                                            accountId={appConfigState.getAccount('INVENTORY_ISSUER').id}
                                            holdings={selectedHoldings}
                                            products={currentProduct}
                                            preSelectedProjectId={projectID}
                                            preSelectedVintage={undefined}
                                            preSelectedProjectType={selectedProjectDetails?.type}
                                            retirementDialogActive={retirementDialogActive}
                                            onRetirementDialogClosed={onRetirementDialogClosed}
                                        />
                                    )}
                                    <Button disabled={currentProduct === undefined} variant='outlined' onClick={openLockToMarketDialog} >Lock to Market</Button>
                                    {lockToMarketDialogActive && (
                                        <HoldingLockToMarket
                                            lockToMarketDialogActive={lockToMarketDialogActive}
                                            products={currentProduct}
                                            preSelectedProjectId={projectID}
                                            preSelectedProjectType={selectedProjectDetails?.type}
                                            preSelectedVintage={undefined}
                                            onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                                        />
                                    )}

                                </Stack>

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                                <Tabs value={tabValue} onChange={handleTabChange}>
                                    <Tab
                                        label="Holdings"
                                        component={Link}
                                        to="holdings"
                                        sx={{
                                            ...hoverTabTextColor,
                                            transition: customTheme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            },
                                        }}
                                    />
                                    <Tab
                                        label="Vintages"
                                        component={Link}
                                        to="vintages"

                                        sx={{
                                            ...hoverTabTextColor,
                                            transition: customTheme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            },
                                        }}
                                    />
                                </Tabs>
                            </Box>

                            <Routes>
                                <Route index element={<Navigate to="holdings" replace />} />
                                <Route
                                    path="/holdings"
                                    element={
                                        <Holdings
                                            queryType={HoldingQueryType.PRODUCT_PROJECT_VINTAGE}
                                            columnDefinitions={[
                                                {key: 'selectbox', showByDefault: true},
                                                {key: 'holdingId', showByDefault: true},
                                                {key: 'amount', showByDefault: true},
                                                {key: 'vintage', showByDefault: true},
                                                {key: 'serialNumRange', showByDefault: true},
                                                {key: 'accountId', showByDefault: true},
                                                {key: 'state', showByDefault: true},
                                                {key: 'info', showByDefault: true}
                                            ]}
                                            // Filters:
                                            product={productID}
                                            project={projectID}
                                            // Other:
                                            selectedHoldings={selectedHoldings}
                                            onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                                            refreshSignal={holdingRefreshSignal}
                                        />
                                    }
                                />
                                <Route path="/vintages" element={<Vintages />} />
                            </Routes>
                        </>
                    )}
                </>
            }
        </>
    )
};

export default ProjectDetails;
