import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { fetchWithCorten } from '../../../state/CortenClient';
import { EmissionDisplay } from './EmissionsState';
import { useAppConfigState } from '../../../state/AppConfig';
import { SerialNumber } from '../../../model/SerialNumber';

interface UnderlyingHoldingDisplay {
    isEmission: boolean;
    state: string;
    holdingId: string;
    accountId: string;
    amount: string;
    productCode: string;
    projectId?: string;
    projectName?: string;
    projectType?: string;
    projectCountry?: string;
    projectState?: string;
    projectUri?: string;
    projectVintage?: string;
    serialNumRange?: string;
    maxDecimalPos: number;
    minDecimalPos: number;
    emissionName?: string;
    emissionIntensity?: string;
    emissionDocument?: string;
}

const defaultUnderlyingDisplay = {
    isEmission: false,
    state: '',
    holdingId: '',
    accountId: '',
    amount: '',
    productCode: '',
    maxDecimalPos: 0,
    minDecimalPos: 0
};

interface UnderlyingStateType {
    isUnderlyingDrawerOpen: boolean;
    selectedDecarbItem: EmissionDisplay | undefined;
    underlyingAssets: any[];
    loadingUnderlying: boolean;
    holdingData: UnderlyingHoldingDisplay;
    isUnderlyingHoldingDialogOpen: boolean;
    loadingHoldings: boolean;
}

interface UnderlyingDispatchType {
    setIsUnderlyingDrawerOpen: Dispatch<SetStateAction<boolean>>;
    handleUnderlyingDrawerOpen: (item: any) => void;
    handleOpenHoldingDetails: (item: any) => void;
    setIsUnderlyingHoldingDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const UnderlyingStateContext = createContext<UnderlyingStateType | null>(null);
const UnderlyingDispatchContext = createContext<UnderlyingDispatchType | null>(null);

const UnderlyingProvider = ({ children }: { children?: ReactNode }) => {
    const [isUnderlyingDrawerOpen, setIsUnderlyingDrawerOpen] = useState<boolean>(false);
    const [isUnderlyingHoldingDialogOpen, setIsUnderlyingHoldingDialogOpen] =
        useState<boolean>(false);

    const [selectedDecarbItem, setSelectedDecarbItem] = useState<EmissionDisplay | undefined>(
        undefined
    );
    const [underlyingAssets, setUnderlyingAssets] = useState<any>([]);
    const [loadingUnderlying, setLoadingUnderlying] = useState<boolean>(false);
    const [holdingData, setHoldingData] =
        useState<UnderlyingHoldingDisplay>(defaultUnderlyingDisplay);
    const [loadingHoldings, setLoadingHoldings] = useState<boolean>(false);

    const appConfigState = useAppConfigState();

    // open dialog for underlying assets
    const handleUnderlyingDrawerOpen = async (item: EmissionDisplay) => {
        // table loader
        setLoadingUnderlying(true);
        // open dialog
        setIsUnderlyingDrawerOpen(true);

        setSelectedDecarbItem(item);
        // perform fetch underlying
        await fetchUnderlying(item.id);
        // stop table loader
        setLoadingUnderlying(false);
    };

    // fetch decarbonsied assets underlying items
    const fetchUnderlying = async (productItemId: string) => {
        // clear next page
        let nextPageItems = undefined;
        // clear accumulated product Item batches
        let combinedUnderlyingItems = [];

        try {
            do {
                const pageFromQueryString: string = nextPageItems
                    ? `&page.from=${nextPageItems}`
                    : '';

                const url = `/api/pub/productitem/${productItemId}/underlying${pageFromQueryString}`;

                const response = await fetchWithCorten(url, { method: 'GET' });

                if (!response.ok) {
                    throw new Error('Failed to fetch underlying items');
                }

                const productItems = await response.json();

                // combine all batches
                combinedUnderlyingItems.push(...productItems.list);

                // set next batch page
                nextPageItems = productItems.nextPage;
            } while (nextPageItems);

            // enrich data with the product display code
            for (const item of combinedUnderlyingItems) {
                if (appConfigState.getProduct(item.productId) !== undefined) {
                    // carbon product
                    item.displayCode = appConfigState.getProduct(item.productId)?.displayCode;
                } else if (item.productId === appConfigState.getEmissionProductId('EMISSION_ASSET')) {
                    // emission product
                    item.displayCode = appConfigState.getEmissionProductDisplayCode()
                } else {
                    item.displayCode = ''
                }
            }

            setUnderlyingAssets(combinedUnderlyingItems);
        } catch (error) {
            console.log('error', error);
        }
    };

    // find serial number range
    const findSerialNumRange = (item: any) => {
        // check if we have serial number attribute
        const serialNumberStart =
            item.productItem.data.attributes[
                appConfigState.getAttribute('PROJECT', 'SERIAL_NUMBER_START').key
            ];

        if (serialNumberStart) {
            return SerialNumber.fromHolding(item, appConfigState)?.getRange(
                item.index,
                Number(item.amount)
            );
        }
    };

    // open holding details for underlying item
    const handleOpenHoldingDetails = async (item: any) => {
        // clear data
        setHoldingData(defaultUnderlyingDisplay);

        setLoadingHoldings(true);

        // open dialog
        setIsUnderlyingHoldingDialogOpen(true);

        let displaydata: UnderlyingHoldingDisplay = {
            isEmission: appConfigState.getProduct(item.productId) === undefined ? true : false, // check if it is an emission item or carbon product

            state: item.state,
            holdingId: item.holdingId,
            accountId: appConfigState.getAccount('INVENTORY_ISSUER').display,
            amount: item.amount,
            productCode: item.displayCode,
            projectId:
                item.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'ID').key],
            projectName:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('PROJECT', 'NAME').key
                ],
            projectType:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('PROJECT', 'TYPE').key
                ],
            projectCountry:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key
                ],
            projectState:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('PROJECT', 'STATE').key
                ],
            projectUri:
                item.productItem.data.attributes[appConfigState.getAttribute('PROJECT', 'URI').key],
            projectVintage:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('PROJECT', 'VINTAGE').key
                ],
            serialNumRange: findSerialNumRange(item),
            maxDecimalPos: item.product.data.maxDecimalPos,
            minDecimalPos: item.product.data.minDecimalPos,
            emissionName:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('EMISSION_ASSET', 'NAME').key
                ],
            emissionIntensity:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('EMISSION_ASSET', 'INTENSITY').key
                ],
            emissionDocument:
                item.productItem.data.attributes[
                    appConfigState.getAttribute('EMISSION_ASSET', 'DOCUMENT').key
                ]?.url
        };

        // set holding item to state
        setHoldingData(displaydata);

        // stop loader
        setLoadingHoldings(false);
    };

    return (
        <UnderlyingStateContext.Provider
            value={{
                isUnderlyingDrawerOpen,
                selectedDecarbItem,
                underlyingAssets,
                loadingUnderlying,
                holdingData,
                isUnderlyingHoldingDialogOpen,
                loadingHoldings
            }}
        >
            <UnderlyingDispatchContext.Provider
                value={{
                    setIsUnderlyingDrawerOpen,
                    handleUnderlyingDrawerOpen,
                    handleOpenHoldingDetails,
                    setIsUnderlyingHoldingDialogOpen
                }}
            >
                {children}
            </UnderlyingDispatchContext.Provider>
        </UnderlyingStateContext.Provider>
    );
};

function useUnderlyingState() {
    const context = useContext(UnderlyingStateContext);
    if (!context) {
        throw new Error('no provider for useUnderlyingState');
    }
    return context;
}

function useUnderlyingDispatch() {
    const context = useContext(UnderlyingDispatchContext);
    if (!context) {
        throw new Error('no provider for useUnderlyingDispatch');
    }
    return context;
}

export { UnderlyingProvider, useUnderlyingState, useUnderlyingDispatch };
