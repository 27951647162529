import {
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    IconButton
} from '@mui/material';
import Link from '@mui/material/Link';
import { useAppConfigState } from '../../state/AppConfig';
import { useEmissionsState, EmissionTableType, EmissionDisplay } from './state/EmissionsState';
import InfoIcon from '@mui/icons-material/Info';
import CertificateIcon from '@mui/icons-material/Description';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const EmissionsTable = ({
    data,
    tableType,
    action,
    openCertificate
}: {
    data: EmissionDisplay[];
    tableType: EmissionTableType;
    action: any;
    openCertificate?: any;
}) => {
    const appConfigState = useAppConfigState();
    const { loadingTable } = useEmissionsState();
    const theme = useTheme();

    return (
        <>
            {loadingTable ? (
                <LinearProgress />
            ) : (
                <>
                    {data?.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>
                                            {
                                                appConfigState.getAttribute(
                                                    'EMISSION_ASSET',
                                                    'NAME'
                                                ).display
                                            }
                                        </TableCell>
                                        {tableType === EmissionTableType.PENDING && (
                                            <>
                                                <TableCell>
                                                    {
                                                        appConfigState.getAttribute(
                                                            'EMISSION_ASSET',
                                                            'INTENSITY'
                                                        ).display
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        appConfigState.getAttribute(
                                                            'EMISSION_ASSET',
                                                            'DOCUMENT'
                                                        ).display
                                                    }
                                                </TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </>
                                        )}

                                        {tableType === EmissionTableType.DECARBONISED && (
                                            <>
                                                <TableCell align="center">Underlying</TableCell>
                                                {/* Hidden until core changes config for SaveTransactionProofDisabled for cddemo & mblpilot envs */}
                                                {/* <TableCell align="center">Certificate</TableCell> */}
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((item, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            {tableType === EmissionTableType.PENDING && (
                                                <>
                                                    <TableCell>{item.intensity}</TableCell>
                                                    {/* documents open in new tab when clicked */}

                                                    <TableCell>
                                                        {item.document !== undefined ? (
                                                            <Link
                                                                href={item.document}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    size="lg"
                                                                    icon={faUpRightFromSquare}
                                                                    style={{ paddingRight: '2rem' }}
                                                                />
                                                            </Link>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </TableCell>
                                                </>
                                            )}

                                            {tableType === EmissionTableType.PENDING && (
                                                <TableCell align="center">
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => action(item)}
                                                    >
                                                        Offset Asset
                                                    </Button>
                                                </TableCell>
                                            )}
                                            {tableType === EmissionTableType.DECARBONISED && (
                                                <>
                                                    <TableCell align="center">
                                                        <IconButton
                                                            aria-label="info"
                                                            sx={{
                                                                color: theme.palette.primary.main
                                                            }}
                                                            onClick={() => action(item)}
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    {/* Hidden until core changes config for SaveTransactionProofDisabled for cddemo & mblpilot envs */}
                                                    {/* <TableCell align="center">
                                                        <IconButton
                                                            aria-label="info"
                                                            sx={{
                                                                color: theme.palette.primary.main
                                                            }}
                                                            onClick={() => openCertificate(item.id)}
                                                        >
                                                            <CertificateIcon />
                                                        </IconButton>
                                                    </TableCell> */}
                                                </>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography>No emissions to show</Typography>
                    )}
                </>
            )}
        </>
    );
};

export default EmissionsTable;
