/**
 * Simple interface that defines some configs associated with the Locale.
 */
interface LocaleConfig {
    amountFormatGroupSeparator: string;
    amountFormatDecimalPoint: string;
}

/**
 * Function that returns the default locale. 
 * TODO - this function needs to return Locale config based on browsers 
 * locale settings in a future iteration
 * 
 * @returns @see LocaleConfig
 */
const getDefaultLocaleConfig = (): LocaleConfig => {
    return {
        amountFormatGroupSeparator: ',',
        amountFormatDecimalPoint: '.'
    };
};

export { getDefaultLocaleConfig };
