import {
    Control,
    Controller,
    FieldErrors,
    FieldValues,
    Path,
    UseFormClearErrors,
    UseFormResetField,
    useWatch,
} from 'react-hook-form';
import { IconButton, ListItemText, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { Clear } from '@mui/icons-material';
import { Option, Options } from '../../state/ProductItemFilters';

enum TradeDirection {
    SELL = 'SELL',
    BUY = 'BUY',
}

enum Currency {
    AUD = 'AUD',
    USD = 'USD',
}

// Some attributes aren't used by forward trades. However, we still need to save these attributes with a placeholder
// value instead of leaving it missing, otherwise we will not be able to filter by these values later (required for
// the position holdings views, among others). If / when core10 is updated to allow filtering on missing attributes
// we can consider removing these placeholder values.
const UNDEFINED_CODE_STRING = 'Undefined'; // Stores the value taken by unused string-type attributes in forward trades
const UNDEFINED_CODE_INTEGER = '-1'; // Stores the value taken by unused integer-type attributes in forward trades
const CORTEN_NEGATION_EXPRESSION = '!*';

// Display value for trade attributes that are undefined.
// Used as a column header on the Risk page and in some other elements on other pages.
const UNCLASSIFIED_LABEL = 'Unclassified'

const containsUndefinedPlaceholder = (rawValue: string): boolean => {
    return [UNDEFINED_CODE_STRING, UNDEFINED_CODE_INTEGER, CORTEN_NEGATION_EXPRESSION].some(u => rawValue.includes(u))
}

const displayAttributeValue = (rawValue: string) => {
    return containsUndefinedPlaceholder(rawValue) ? UNCLASSIFIED_LABEL: rawValue;
}

/**
 * generic MUI field component properly wrapped by an RHF controller
 * @param options List of {@link Option} objects for select fields with balance included where applicable.
 * @param balanceDisplayMinDecimals Minimum decimals to use when showing balance in option select fields.
 * @param balanceDisplayMaxDecimals Maximum decimals to use when showing balance in option select fields.
 * @returns 
 */
const ControlledTextField = <T extends FieldValues>(
    {
        name,
        label,
        type = null,
        integer = false,
        options = null,
        rules,
        disabled = false,
        control,
        errors,
        reset,
        customOnChange = () => {},
        balanceDisplayMinDecimals = 0,
        balanceDisplayMaxDecimals = 0,
    }: {
        name: Path<T>;
        label: string;
        type?: any;
        integer?: boolean;
        options?: Options | null;
        rules?: any;
        disabled?: boolean;
        control: Control<T>;
        errors: FieldErrors<T>;
        reset?: UseFormResetField<T>;
        customOnChange?: () => void;
        balanceDisplayMinDecimals?: number;
        balanceDisplayMaxDecimals?: number;
    }) => {
    const textFieldProps = (onChange: any, field: any) => ({
        onChange(e: any) {
            onChange(e);
            customOnChange();
        },
        id: name,
        label: label,
        type: integer ? 'number' : type,
        select: !!options,
        error: !!errors[name],
        helperText: errors[name]?.message as string,
        required: !!rules?.required,
        disabled: !rules?.alwaysEnabled && (disabled || options?.disabled || options?.values.length === 0),
        size: 'small' as 'small' | 'medium' | undefined,
        fullWidth: true,
        ...(type === 'number' && { inputProps: { step: 'any' } }),
        ...(options && {
            SelectProps: {
                role: 'select',
                // override value that is displayed for the selected item
                renderValue: ((selected: any) => {
                    let option = options?.values.find(opt => opt.id === selected);
                    // Use field label until the option has loaded, after which use the option label
                    return option?.label ?? label;
                }),
                // when the user has selected a value, a "clear" button
                // is displayed, and the select indicator is removed
                ...(reset && field.value && !disabled && {
                    endAdornment: (
                        <IconButton sx={{ marginRight: -1.5 }} size='small' onClick={() => {
                            reset(name);
                            customOnChange();
                        }}>
                            <Clear color='secondary' sx={{ fontSize: '20px' }} />
                        </IconButton>),
                    IconComponent: () => null,
                }),
            },
        }),
        ...(integer && {
            onKeyDown(e: any) {
                // Most of the "filtering" is done by typing the input element as "number", but since
                // we don't need scientific notation, nor singed or decimal values, here we add further
                // restrictions to the input.
                // As of right now this seems to be the only way to make an input ONLY accept numbers.
                if (['e', 'E', '-', '+', '.'].includes(e.key)) e.preventDefault();
            },
        }),
    });

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange, ...field } }) => <TextField
                {...field}
                {...textFieldProps(onChange, field)}
            >
                {options && options.values.map(option => (
                    <MenuItem disabled={option.disabled} key={option.id} value={option.id}>
                        <ListItemText>{option.label}</ListItemText>
                        {option.balance &&
                            <ListItemText
                                primaryTypographyProps={{
                                    color: 'textSecondary',
                                    typography: 'caption',
                                }}
                                style={{ textAlign: 'right' }}
                            >
                                <AmountFormatWrapper
                                    amount={option.balance}
                                    minDecimalPos={balanceDisplayMinDecimals}
                                    maxDecimalPos={balanceDisplayMaxDecimals} />
                            </ListItemText>}
                    </MenuItem>
                ))}
            </TextField>} />
    );
};

const useRequired = function <T extends FieldValues>(
    field: Path<T>,
    dependsOn: Path<T>,
    control: Control<T>,
    clearErrors: UseFormClearErrors<T>,
) {
    const [isRequired, setRequired] = useState(false);
    const dependsOnWatch = useWatch({ name: dependsOn, control });

    useEffect(() => {
        // the field is required when the one it depends on has a value specified
        let required = ![null, ''].includes(dependsOnWatch as any);
        // clear error messages, in case we are transitioning from "required" to "not required"
        if (!required) clearErrors(field);
        setRequired(required);
    }, [dependsOnWatch]);

    return isRequired;
};

export { 
    useRequired, 
    ControlledTextField, 
    TradeDirection, 
    Currency,
    UNDEFINED_CODE_STRING,
    UNDEFINED_CODE_INTEGER,
    UNCLASSIFIED_LABEL,
    containsUndefinedPlaceholder,
    displayAttributeValue
}
