import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Typography,
} from '@mui/material';

import { useAppConfigState } from '../state/AppConfig';
import { useEffect, useRef, useState } from 'react';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import { useAuth } from '../state/AuthProvider';
import { executeTransaction } from '../state/CortenClient';
import { renderDialogField } from '../utility/DialogFieldRenderUtil';
import { TransactionOverview } from './TransactionOverview';
import { useTransactionMonitor } from '../state/transaction/TransactionMonitor';
import { renderEntry } from '../utility/HoldingUtil';
import { AlertDialogContent, AlertType } from './AlertDialogContent';
import { ControlledTextField } from '../view/trade/TradeUtil';
import {
    getOrderedProjectsAndAmounts,
    getTradeOptimiserOptions,
    renderOrderedProjectVintageAmounts,
    TradeOptimiser,
    TradeOptimiserAmountType
} from '../utility/TradeOptimiserUtil';
import { useForm, useWatch } from 'react-hook-form';
import { Option, Options, ResettableFormFields, useProductItemFilters } from '../state/ProductItemFilters';
import { TransactionType } from '../state/Variables';
import { useProductDataState } from '../state/ProductDataProvider';


interface LockToMarketForm {
    product: string,
    projectType: string,
    project: string,
    vintage: string,
    state: string,
    country: string,
    fuelSource: string,
    creationYear: string,
    generationYear: string,
    generationState: string,
    greenpowerAccredited: string,
    quantity: number,
    market: string,
    tradeOptimiser: TradeOptimiser
}

const defaultValues: LockToMarketForm = { 
    product: '',
    projectType: '',
    project: '',
    vintage: '',
    state: '',
    country: '',
    fuelSource: '',
    creationYear: '',
    generationYear: '',
    generationState: '',
    greenpowerAccredited: '',
    quantity: 0,
    market: '',
    tradeOptimiser: TradeOptimiser.DEFAULT
};


const HoldingLockToMarket = ({
    lockToMarketDialogActive,
    preSelectedProjectId,
    preSelectedProjectType,
    preSelectedVintage,
    products,
    onLockToMarketDialogClosed
}: {
    lockToMarketDialogActive: boolean;
    preSelectedProjectId: string | undefined;
    preSelectedProjectType: string | undefined;
    preSelectedVintage: string | undefined;
    products: any[] | undefined;
    onLockToMarketDialogClosed: any;
}) => {

    const {
        control,
        getValues,
        resetField,
        reset,
        trigger,
        formState: { errors, isValid }
    } = useForm<LockToMarketForm>({ mode: 'onChange', defaultValues: defaultValues });

    const balanceErrorWatch = useWatch({ name: ['product', 'market'], control });

    const {
        productOptions,
        projectTypeOptions,
        projectOptions,
        vintageOptions,
        stateOptions,
        countryOptions,
        availableBalance,
        onFilterChange,
        resetProductFilters
    } = useProductItemFilters({});

    const appConfigState = useAppConfigState();
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);
    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const user = useAuth();
    const [transactionInReview, setTransactionInReview] = useState<boolean>(false);
    const [balanceError, setBalanceError] = useState<string>();
    const [marketOptions, setMarketOptions] = useState<Option[]>([]);
    const [projectAmounts, setProjectAmounts] = useState<any[]>();
    const { productData } = useProductDataState();

    const productItemIds = useRef<Promise<string[]>>();

    useEffect(() => {
        if (lockToMarketDialogActive) {
            setMarketOptions(appConfigState.getMarkets().map((market) => (
                new Option(market.name, market.name, null, !market.enabled)
            )));
            let preSelectedData = getValues();
            if (products !== undefined && products.length === 1) {
                preSelectedData.product = products![0].productId;
            } else {
                preSelectedData.product = "";
            }
            if (preSelectedProjectId !== undefined) {
                preSelectedData.project = preSelectedProjectId;
            } else {
                preSelectedData.project = "";
            }
            if (preSelectedProjectType !== undefined) {
                preSelectedData.projectType = preSelectedProjectType;
            } else {
                preSelectedData.projectType = "";
            }
            if (preSelectedVintage !== undefined) {
                preSelectedData.vintage = preSelectedVintage;
            } else {
                preSelectedData.vintage = "";
            }
            reset(preSelectedData, { keepDefaultValues: true });
            resetProductFilters(resetField, {...preSelectedData, account: appConfigState.getAccount('INVENTORY_ISSUER').id});
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [lockToMarketDialogActive]);

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    useEffect(() => {
        let form = getValues();
        let error = undefined;
        if (!form.product) {
            error = 'Please select a Product';
        }
        if (!form.market) {
            error = 'Please select a Market';
        }
        setBalanceError(error);
    }, [balanceErrorWatch]);

    // re-validate quantity when available balance changes
    useEffect(() => {
        if (availableBalance) trigger('quantity').then();
    }, [availableBalance, trigger]);

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
        },
        (tx) => {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when locking holdings to market.');
            setTransactionErrorCode(tx.errorCode);
        },
        () => {
            setIsTransactionInProgress(false);
            setTransactionWarnMessage('The transaction timed out. Please check back later to confirm whether the transaction was processed.');
        }
    );

    const handleLockToMarketDialogClose = () => {
        unsubscribe();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        reset();
        resetProductFilters(resetField);
        if (transactionInReview) {
            closeReviewDialog();
        }
        // update state in the parent view
        onLockToMarketDialogClosed();
    }

    const performLockToMarket = async() => {
        setIsTransactionInProgress(true);
        setTransactionInReview(false);
        let requestFrom = null;
        requestFrom = {
                type: 'ProductItemsAmount',
                productItemIds: await productItemIds.current,
                amount: getValues().quantity
            }
        const unsignedRequestData = {
            type: 'CreateEscrowRequest',
            fromAccountId: appConfigState.getAccount('INVENTORY_ISSUER').id,
            controllerAccountId: appConfigState.getAccount('MARKET_CONTROLLER').id,
            from: requestFrom,
            nonce: new Date().getTime(),
            attributes: {[`${appConfigState.getAttribute('SHARED','TRANSACTION_TYPE_INVENTORY').key}`]: TransactionType.LockToMarket}
        };
        executeTransaction(unsignedRequestData, user)
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    console.log("Successfully submitted CreateEscrowRequest, waiting for notification.");
                    response.json().then(r => {
                        pendingTransaction.current = r.txId;
                        subscribe(r.txId);
                });
                } else {
                    console.log(response);
                    setIsTransactionInProgress(false);
                    setTransactionErrorMessage("Could not complete the transaction - the request was rejected")
                }
            }).catch(console.error);
    };

    const onOptionSelect = (field: keyof ResettableFormFields) => {
        onFilterChange(resetField, {...getValues(), account: appConfigState.getAccount('INVENTORY_ISSUER').id}, field);
    };

    const openReviewDialog = () => {
        setTransactionInReview(true);
        getOrderedProjectsAndAmounts(
            getValues(),
            appConfigState.getAccount('INVENTORY_ISSUER').id,
            setProjectAmounts,
            productItemIds,
            user,
            appConfigState,
            handleError
        );
    };

    const handleError = () => {
        setTransactionInReview(false);
        setTransactionErrorMessage("Unable to get productItems for transaction.");
    };

    const closeReviewDialog = () => {
        setTransactionInReview(false);
        productItemIds.current = undefined;
        setProjectAmounts(undefined);
    };

    return (
        <Dialog
            open={lockToMarketDialogActive}
            onClose={handleLockToMarketDialogClose}
            fullWidth
            maxWidth="sm"
        >

            {!transactionInReview && !transactionSuccess && !isTransactionInProgress && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Lock to Market
                        </Typography>

                        {
                            renderEntry(
                                'Market:',
                                (
                                    <ControlledTextField name='market' label='Market'
                                                 options={new Options(marketOptions, false)}
                                                 rules={{ required: 'Market is required' }}
                                                 control={control} errors={errors}
                                                 reset={resetField} />
                                ),
                                true, true
                            )
                        }

                        {
                            renderEntry(
                                'Product:',
                                (
                                    <>
                                        {
                                            <ControlledTextField name='product' label='Product'
                                                options={productOptions}
                                                customOnChange={() => onOptionSelect('product')}
                                                rules={{ required: 'Product is required' }}
                                                control={control} errors={errors} reset={resetField}
                                                />
                                        }
                                    </>
                                ),
                                true, true
                            )
                        }

                        {renderEntry('Project Type:', (
                            <>
                                {
                                    <ControlledTextField name='projectType' label='Project Type'
                                        options={projectTypeOptions}
                                        customOnChange={() => onOptionSelect('projectType')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                        />
                                }
                            </>
                        ), true, true)}

                        {renderEntry('Project:', (
                            <>
                                {
                                    <ControlledTextField name='project' label='Project'
                                        options={projectOptions}
                                        customOnChange={() => onOptionSelect('project')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                        />
                                }
                            </>
                        ), true, true)}
                        {renderEntry('Vintage:', (
                            <>
                                {
                                    <ControlledTextField name='vintage' label='Vintage'
                                        options={vintageOptions}
                                        customOnChange={() => onOptionSelect('vintage')}
                                        control={control} errors={errors} reset={resetField}
                                        balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                        balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                        />
                                }
                            </>
                        ), true, true)}
                        {appConfigState.getProduct(getValues('product'))?.displayCode === 'ACCU' && 
                            renderEntry('State:', (
                                <ControlledTextField name='state' label='State'
                                    options={stateOptions}
                                    customOnChange={() => onOptionSelect('state')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), true, true)}
                        {appConfigState.getProduct(getValues('product'))?.displayCode === 'VCU' && 
                            renderEntry('Country:', (
                                <ControlledTextField name='country' label='Country'
                                    options={countryOptions}
                                    customOnChange={() => onOptionSelect('country')}
                                    control={control} errors={errors} reset={resetField}
                                    balanceDisplayMinDecimals={productData.get(getValues().product)?.minDecimalPos}
                                    balanceDisplayMaxDecimals={productData.get(getValues().product)?.maxDecimalPos}
                                />
                            ), true, true)}

                        {renderEntry('Quantity :', (
                            <>
                                <ControlledTextField name='quantity' label='Quantity' integer
                                    rules={{
                                        required: 'Quantity is required',
                                        pattern: {
                                            value: /^\d*$/,
                                            message: 'Quantity must be a whole number',
                                        },
                                        min: {
                                            value: 1,
                                            message: 'Quantity must be greater than 0',
                                        },
                                        max: {
                                            value: availableBalance,
                                            message: 'Quantity exceeds available balance',
                                        },
                                    }}
                                    control={control} errors={errors} />
                                {!balanceError && availableBalance == null
                                    ? (<LinearProgress sx={{ mt: 1.75, mb: 1.75, height: 8 }} />)
                                    : (<Typography variant='caption'
                                        color={balanceError ? 'error' : 'textSecondary'}
                                        mt={1} mb={1}
                                        sx={{ float: 'right' }}>
                                        {balanceError
                                            ? <div>{balanceError}</div>
                                            : <div>Available Balance: <AmountFormatWrapper
                                                amount={availableBalance}
                                                minDecimalPos={productData.get(getValues().product)?.minDecimalPos!}
                                                maxDecimalPos={productData.get(getValues().product)?.maxDecimalPos!}
                                            /></div>}
                                    </Typography>)}
                            </>
                        ), true, true)}
                        {(renderEntry('Trade Optimiser:', (
                            <ControlledTextField name='tradeOptimiser' label='Trade Optimiser'
                                options={getTradeOptimiserOptions()}
                                rules={{ required: 'Trade optimiser is required' }}
                                control={control} errors={errors} reset={resetField} />
                        ), true, true))}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleLockToMarketDialogClose} color="primary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => openReviewDialog()}
                            color="primary"
                            variant="outlined"
                            disabled={
                                !isValid || !availableBalance
                            }
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Submit'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            {(transactionInReview || isTransactionInProgress) && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>Review Details Below</Typography>
                        {renderDialogField('Market', getValues().market)}
                        {getValues().product && renderDialogField('Product', appConfigState.getProduct(getValues().product)?.displayCode)}
                        {getValues().projectType && renderDialogField('Project Type', getValues().projectType)}
                        {getValues().project && renderDialogField(
                            'Project',
                            projectOptions?.values.find(opt => opt.id === getValues().project)?.label)
                        }
                        {getValues().vintage && renderDialogField('Vintage', getValues().vintage)}
                        {getValues().state && renderDialogField('State', getValues().state)}
                        {getValues().country && renderDialogField('Country', getValues().country)}
                        {renderDialogField('Quantity', getValues().quantity, {
                            minDecimalPos: productData.get(getValues().product)?.minDecimalPos!,
                            maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos!,
                        })}
                        {getValues().tradeOptimiser === TradeOptimiser.CHEAPEST_FIRST && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Price)}
                        {getValues().tradeOptimiser === TradeOptimiser.INVENTORY_SCORE_BASED && renderOrderedProjectVintageAmounts(projectAmounts, productData.get(getValues().product)!!, TradeOptimiserAmountType.Score)}
                        {[TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity && (<Typography variant='h3' sx={{marginTop: '10px'}}>Insufficient balance to complete transaction</Typography>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => closeReviewDialog()} color='primary' variant='outlined'>Back</Button>
                        <Button 
                            onClick={performLockToMarket} 
                            color='primary' 
                            variant='outlined'
                            disabled={
                                ([TradeOptimiser.CHEAPEST_FIRST, TradeOptimiser.INVENTORY_SCORE_BASED].includes(getValues().tradeOptimiser) && 
                                (!projectAmounts?.length) || projectAmounts?.map(p => p.balance).reduce((a,b) => a + b) < getValues().quantity)
                            }
                        >
                            {isTransactionInProgress ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '2px',
                                }}
                                >
                                    <CircularProgress size={20} />
                                </div>
                            ) : ('Confirm')}
                        </Button>
                    </DialogActions>
                </>
            )}
            { transactionSuccess && (<TransactionOverview
                open={transactionSuccess}
                onClose={handleLockToMarketDialogClose}
                title='Transaction Submitted successfully'
                attributes={{
                    ...(pendingTransaction.current !== null) && {
                        'Transaction ID': {
                            value: pendingTransaction.current,
                            key:'transactionId',
                            shorterFormId: true,
                        }
                    },
                    'Market': { value: getValues().market, key:'market' },
                    'Product': { value: getValues().product, key:'productId' },
                    ...getValues().projectType && {'Project Type': { value: getValues().projectType, key:'projectType' }},
                    ...getValues().product && {'Project': {
                        value: projectOptions?.values.find(opt => opt.id === getValues().project)?.label,
                        key:'projectName'
                    }},
                    ...getValues().vintage && {'Vintage': { value: getValues().vintage ,key:'vintage'}},
                    ...getValues().state && {'State': { value: getValues().state ,key:'state'}},
                    ...getValues().country && {'Country': { value: getValues().country }},
                    'Quantity': {
                        value: getValues().quantity.toString(),
                        key:'quantity',
                        renderProp: { 
                            minDecimalPos: productData.get(getValues().product)?.minDecimalPos!, 
                            maxDecimalPos: productData.get(getValues().product)?.maxDecimalPos! },
                    }
                }}
            />)}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onLockToMarketDialogClosed}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onLockToMarketDialogClosed}
                />
            )}


        </Dialog>
    );

};

export {HoldingLockToMarket}
