import { Box, Typography } from '@mui/material';
import React from 'react';

export const NotFound = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '15%'
            }}
        >
            <Typography variant="h1">404</Typography>
            <Typography>The page you are looking for is not found</Typography>
        </Box>
    );
};
