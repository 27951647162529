import { backendUrl } from './AppConfig';

const fetchFromBackend = async (
  path: string, 
  options: RequestInit, 
  user: any, 
  abortSignal: AbortSignal | undefined = undefined
) => {
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${user.user.access_token}`
  };
  const requestOptions: RequestInit = {
    ...options,
    ...(abortSignal && { signal: abortSignal }),
    headers
  };

  return fetch(`${backendUrl}${encodeURI(path)}`, requestOptions);
};

export default fetchFromBackend;
