import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

let backendUrl = ''; // Do not change this. Change the value below when testing.
if (process.env["REACT_APP_DEV_MODE"] === "true") {
    // When testing locally, use the integ1 back end
    // This can be changed to any development env or alternatively 'http://localhost:8080' if running a local backend.
    backendUrl = 'https://carbon-desk-integ1.dev.trovio.io';
    // To use this back end, but apply the app config for another environment, see instructions below.
}

// To overwrite the app config used for local front end development, run the command below in the 'mbl-frontend' directory:
// yq -o=json '.FRONTEND' "../config/env-{env}.yaml" > public/dev-app-config-override.json
// Then start npm with: npm run start_custom_app_config
let settingsUrl = `${backendUrl}/frontend-app-config`; // Do not change this
if (process.env["REACT_APP_DEV_MODE"] === "true" && process.env["REACT_APP_CUSTOM_APP_CONFIG"] === "true") {
    settingsUrl = 'http://localhost:3000/dev-app-config-override.json'
}

// These variables are set later when AppConfigProvider loads.
// They are kept as global static variables for ease of access in helper functions including in .js libs imported from core10.
let cortenApiUrl = '';
let cortenApiKey = '';
let cortenWebsocketUrl = '';

type AppConfigContextType = {
    settingsLoaded: () => boolean;
    getAccount: (accountName: string) => Account;
    getClients: () => Client[];
    getClientForAddress: (address: string) => string | undefined;
    getDisplayNameForAddress: (address: string | undefined) => string | undefined;
    getAddressForClient: (clientName: string) => string | undefined;
    getMarkets: () => Market[];
    getThemes: () => ThemeConfig[];
    getProducts: (includeCertificateBased?: boolean) => ProductDisplayData[];
    getProduct: (productId: string) => ProductDisplayData | undefined;
    getProjectAttributes: () => string[];
    getAttribute: (group: string, attributeName: string) => Attribute;
    enableAggregator: () => boolean;
    emissionsFeatureEnabled: () => boolean;
    getEmissionProductId: (productType: string) => any;
    getEmissionProductDisplayCode: () => void;
    getCarbonProducts: () => any[];
};

interface Account {
    id: string;
    display: string;
}

interface Client {
    id: string;
    display: string;
}

interface Market {
    name: string;
    enabled: boolean;
}

interface ThemeConfig {
    name: string;
    displayName: string;
    default: boolean;
}

enum ProductBase {
    Project = 'Project',
    Certificate = 'Certificate'
}

interface ProductDisplayData {
    id: string;
    displayName: string;
    displayCode: string;
    displayComment: string;
    queryCode: string;
    productBase: ProductBase;
}

interface Attribute {
    key: string;
    display: string;
}

const fetchAppConfig = async ()=> {
    return fetch(settingsUrl, {headers: 
        {'Content-Type': 'application/json','Accept': 'application/json'}
    });
}

const AppConfigStateContext = createContext<AppConfigContextType | null>(null);

const AppConfigProvider = ({ children, appConfig }: { children?: ReactNode, appConfig: any }) => {
    const [settings, setSettings] = useState<any>(undefined);
    const clientToAddressMap = useRef<any>({});
    const addressToClientMap = useRef<any>({});

    useEffect(()=> {
        cortenApiUrl = appConfig.GENERAL.CORTEN_URL;
        cortenApiKey = appConfig.GENERAL.CORTEN_API_KEY;
        cortenWebsocketUrl = appConfig.GENERAL.CORTEN_WEBSOCKET_URL;
        setSettings(appConfig);
        clientToAddressMap.current = new Map<string, string>(appConfig['GENERAL']['ACCOUNT']['CLIENTS'].map((client: any) => {
            return [`${client.DISPLAY}`, client.ID]
        }));
        addressToClientMap.current = new Map<string, string>(appConfig['GENERAL']['ACCOUNT']['CLIENTS'].map((client: any) => {
            return [`${client.ID}`, client.DISPLAY]
        }));
    },[])

    const settingsLoaded = () => {
        return settings !== undefined;
    }

    const getAccount = (accountName: string): Account => {
        const acc: any = settings['GENERAL']['ACCOUNT'][accountName];
        return {
            id: acc['ID'],
            display: acc['DISPLAY']
        }
    };

    const getClients = (): Client[] => {
        const clients: any = settings['GENERAL']['ACCOUNT']['CLIENTS'];
        return clients.map((client: any) => {
            return {
                id: client['ID'],
                display: client['DISPLAY']
            };
        });
    };

    const getDisplayNameForAddress = (address: string | undefined): string | undefined => {
        if (address === undefined) {
            return undefined;
        } else if (address === getAccount('INVENTORY_ISSUER').id) {
            return getAccount('INVENTORY_ISSUER').display;
        } else {
            return addressToClientMap.current.get(address.toLowerCase());
        }
    };

    const getClientForAddress = (address: string): string | undefined => {
        if (address === undefined) {
            return undefined;
        }
        return addressToClientMap.current.get(address.toLowerCase());
    };

    const getAddressForClient = (clientName: string): string | undefined => {
        if (clientName === undefined) {
            return undefined;
        }
        return clientToAddressMap.current.get(clientName);
    };

    const getMarkets = (): Market[] => {
        return settings['GENERAL']['MARKETS'].map((market: any) => {
            return {
                name: market['NAME'],
                enabled: market['ENABLED']
            };
        });
    };

    const getThemes = (): ThemeConfig[] => {
        return Object.entries(settings['GENERAL']['THEMES']).map(([name, config]: [string, any]) => ({
            name: name,
            displayName: config['DISPLAY_NAME'],
            default: config['DEFAULT'] === true ?? false,
        }));
    };

    const getProducts = (includeCertificateBased?: boolean): ProductDisplayData[] => {
        const allProducts: ProductDisplayData[] =  settings['PRODUCTS']['INVENTORY_PRODUCTS'].map((product: any) => {
            return {
                id: product['ID'],
                displayName: product['DISPLAY_NAME'],
                displayCode: product['DISPLAY_CODE'],
                displayComment: product['DISPLAY_COMMENT'],
                queryCode: product['QUERY_CODE'],
                productBase: ProductBase[product['PRODUCT_BASE'] as keyof typeof ProductBase] 
            };
        });

        if (!includeCertificateBased) {
            return allProducts.filter(p => p.productBase !== ProductBase.Certificate);
        }

        return allProducts;
    };

    const getProduct = (productId: string): ProductDisplayData | undefined => {
        const product: any = (settings['PRODUCTS']['INVENTORY_PRODUCTS'] as any[]).find(p => p['ID'] === productId);
        if (product === undefined) {
            return undefined;
        }
        return {
            id: product['ID'],
            displayName: product['DISPLAY_NAME'],
            displayCode: product['DISPLAY_CODE'],
            displayComment: product['DISPLAY_COMMENT'],
            queryCode: product['QUERY_CODE'],
            productBase: ProductBase[product['PRODUCT_BASE'] as keyof typeof ProductBase] 
        }
    };

    const getProjectAttributes = (): string[] => {
        return [
            getAttribute('PROJECT', 'ID').key,
            getAttribute('PROJECT', 'NAME').key,
            getAttribute('PROJECT', 'TYPE').key,
            getAttribute('PROJECT', 'COUNTRY_CODE').key,
            getAttribute('PROJECT', 'STATE').key,
            getAttribute('PROJECT', 'URI').key,
            getAttribute('PROJECT', 'ACCREDITATION_CODE').key,
            getAttribute('PROJECT', 'FUEL_SOURCE').key,
            getAttribute('PROJECT', 'GENERATION_STATE').key,
            getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key
        ]
    }

    const getAttribute = (group: string, attributeName: string): Attribute => {
        const attr: any = settings['ATTRIBUTES'][group][attributeName];
        return {
            key: attr['KEY'],
            display: attr['DISPLAY']
        }
    };

    const enableAggregator = (): boolean => {
        return settings['GENERAL']['ENABLE_AGGREGATOR']
    }

    const emissionsFeatureEnabled = (): boolean => {
        return settings['GENERAL']['EMISSIONS_FEATURE_ENABLED']
    }

    /**
     *
     * @returns An emission product's ID
     * @param productType - includes: EMISSION_ASSET, DECARBONISED_EMISSION_ASSET
     */
    const getEmissionProductId = (productType: string) => {
        return settings['PRODUCTS']['EMISSION_PRODUCTS'][productType]['ID'];
    };

    /**
     *
     * @returns Emission product's display code
     */
    const getEmissionProductDisplayCode = () => {
        return settings['PRODUCTS']['EMISSION_PRODUCTS']['EMISSION_ASSET']['DISPLAY_CODE'];
    };

    const getCarbonProducts = () => {
        return settings['PRODUCTS']['EMISSION_PRODUCTS']['CARBON_PRODUCT'].map((product: any) => {
            return {
                id: product['ID'],
            };
        })
    };

    return (
        <AppConfigStateContext.Provider
            value={{
                settingsLoaded,
                getAccount,
                getClients,
                getClientForAddress,
                getDisplayNameForAddress,
                getAddressForClient,
                getMarkets,
                getThemes,
                getProducts,
                getProduct,
                getProjectAttributes,
                getAttribute,
                enableAggregator,
                emissionsFeatureEnabled,
                getEmissionProductId,
                getEmissionProductDisplayCode,
                getCarbonProducts
            }}
        >
            {children}
        </AppConfigStateContext.Provider>
    );
};

function useAppConfigState() {
    const context = useContext(AppConfigStateContext);
    if (!context) {
        throw new Error('no provider for AppConfigState');
    }
    return context;
}

export {
    backendUrl,
    fetchAppConfig,
    cortenApiUrl,
    cortenApiKey,
    cortenWebsocketUrl,
    type Account,
    type Client,
    type ProductDisplayData,
    type Attribute,
    type AppConfigContextType,
    ProductBase,
    AppConfigProvider,
    useAppConfigState
};
