import {
    getGridDateOperators,
    getGridNumericOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    GridFilterItem,
    GridFilterModel,
    GridFilterOperator,
    GridSortItem,
} from '@mui/x-data-grid';
import { TransactionDetail } from '../model/Shared';
import { TransactionIdAnyOfInput, TransactionIdInput } from '../component/TransactionIdInput';
import { DatePickerFilter } from '../component/date-picker/DatePickerFilter';
import { AppConfigContextType } from '../state/AppConfig';
import IntegerInput from '../component/IntegerInput';
import {InputRangeFilter, InputRangeType, InputRangeView} from '../component/input-range-picker/InputRangeFilter';
import { wrapFilterValueWithQuotes } from './CoreFilterUtil';
import { utcToLocalFormat } from './utcToLocalFormat';

// A special value that indicates that we want to filter for non null values 
// of a boolean attribute. The value is arbitrarily chosen to be something 
// that is unlikely to be a value in a filter
const BOOLEAN_NOT_NULL = '+-#BooleanNotNull+-#'

/**
 * Essentially a Product Item with project and product information in one level for ease of querying
 */
interface ProjectProductItemData {
    productId: string;
    productCode: string;
    productItemId: string;
    projectId: string;
    projectType: string;
    projectName: string;
    issuerId: string;
}

/**
 * Function to return all unique values of a property from a given set of ProjectProductItemData
 *
 * @param arr Array of ProjectProductItemData
 * @param property the property to find unique values of
 * @returns An array of unique values of the property
 */
const getUniqueValues = (
    arr: ProjectProductItemData[],
    property: keyof ProjectProductItemData
): Array<ProjectProductItemData[keyof ProjectProductItemData]> => {
    const uniqueValues = new Set<ProjectProductItemData[keyof ProjectProductItemData]>();

    arr.forEach((obj) => {
        uniqueValues.add(obj[property]);
    });

    return Array.from(uniqueValues);
};

const truncateTransactionId = (id: string): string => {
    if (id.length > 10) {
        return `${id.slice(0, 12)}...`;
    }
    return id;
};

const truncateHumanName = (name: string) => {
    if (name.length > 20) {
        return `${name.slice(0, 21)}...`;
    }
    return name;
};

const truncateProjectName = (name: string) => {
    if (name === undefined) return name;
    if (name.length > 25) {
        return `${name.slice(0, 26)}...`;
    }
    return name;
};

// Utility functions to test whether the input value is valid integer
const isValidInteger = (inputValue: string): boolean => {
    // To accept only numbers and ensure that it cannot start with 0
    return /^[1-9]\d*$/.test(inputValue);
};

/**
 * Return a list of product item IDs retired from the transaction details response.
 * We do this by comparing spent holdings and issued holdings, and see which product item has spent holding amount
 * greater than issued holding amount.
 * @param data  Transaction details response
 * @returns list of product item IDs
 */
const getRetiredProductItemIds = (data: TransactionDetail): string[] => {
    const { issuedHoldings, spentHoldings } = data;

    const productItemAmounts: Record<string, number> = {};

    // Iterate over spentHoldings and add their amounts to productItemAmounts
    for (const spentHolding of spentHoldings) {
        const { productItemId, amount } = spentHolding;
        const numAmount = parseInt(amount);

        if (productItemAmounts[productItemId]) {
            productItemAmounts[productItemId] += numAmount;
        } else {
            productItemAmounts[productItemId] = numAmount;
        }
    }

    // Iterate over issuedHoldings and subtract their amounts from productItemAmounts
    for (const issuedHolding of issuedHoldings) {
        const { productItemId, amount } = issuedHolding;
        const numAmount = parseInt(amount);

        if (productItemAmounts[productItemId]) {
            productItemAmounts[productItemId] -= numAmount;
        } else {
            productItemAmounts[productItemId] = -numAmount;
        }
    }

    // Filter out productItemIds with non-zero resulting amounts
    const nonZeroProductItemIds: string[] = [];
    for (const productItemId in productItemAmounts) {
        if (productItemAmounts[productItemId] !== 0) {
            nonZeroProductItemIds.push(productItemId);
        }
    }

    return nonZeroProductItemIds;
};

/**
 * Function to convert MUI Datagrid filter values to corten API filter values
 *
 * Note: this function extends upon the muiFilterToCoreFilter to avoid spending
 * time refactoring other parts of the code
 *
 * @param {GridFilterItem} item the item to inspect and derive corten equivalent
 * filter values for
 * @param {number} unitAmount used for numeric operators, it denotes the smallest 
 * increment in the unit that the item is refering to. This is used to get around 
 * the range limitation in corten API. Currently, it only supports 'less than' and
 * 'greater than or equal to' comparison operators. The unitAmount is then added 
 * to the boundary condition for cases where we want to use 'less than or equal to' 
 * and the 'greater than' operators.
 * @param {boolean} isDate indicates whether the item should be treated as a Date
 * @param {boolean} containsTime only valid if isDate is set to true. Indicates
 * whether the date value has time component
 *
 * @returns {string} returns a string that is the filter value notation supported
 * in corten
 */
const extendedMuiFilterToCoreFilter = ({
    item,
    unitAmount,
    isDate,
    containsTime
}: {
    item: GridFilterItem;
    unitAmount?: number;
    isDate?: boolean;
    containsTime?: boolean;
}): string => {
    if (isDate) {
        // If Date is of Range type, returns its value, Range date picker return IOSString, hence conversion for this case is not required
        if (item.operator === 'range') {
            const value = item?.value?.split('..');
            const startDate=value[0];
            const endDate=value[1];
            if(startDate && endDate) {
                return `${startDate}..${endDate}`;
            }
            return `${startDate}`;
        }

        // MUI datagrid seems to provide date time values as YYYY-mm-DDTHH:MM but
        // date as YYYY-mm-DD
        // https://github.com/mui/mui-x/blob/ffc58b377c91d2d991d59e34d407ca4fdde119f8/packages/grid/x-data-grid/src/colDef/gridDateOperators.ts#L7
        // 
        // JS Date seems to parse Date in local time if we supply the hours and minutes 
        // but UTC time if only the date component is supplied. Therefore, we need to add
        // the 'T00:00' part here
        let dateValue = new Date(containsTime ? item.value : `${item.value}T00:00`);

        // If we are working with time component then the smallest unit will be a
        // millisecond otherwise it will be a day
        unitAmount = containsTime ? 1 : 24 * 60 * 60 * 1000;

        if (item.operator === 'onOrAfter') {
            return `${dateValue.toISOString()}..`;
        } else if (item.operator === 'after') {
            // adding the unitAmount here since 'greater than' is not supported in corten
            return `${new Date(dateValue.getTime() + unitAmount).toISOString()}..`;
        } else if (item.operator === 'before') {
            return `..${dateValue.toISOString()}`;
        } else if (item.operator === 'onOrBefore') {
            // adding the unitAmount here since 'less than or equal to' is not supported in corten
            return `..${new Date(dateValue.getTime() + unitAmount).toISOString()}`;
        } else if (item.operator === 'is') {
            const startOfDay = new Date(dateValue);
            startOfDay.setHours(0, 0, 0, 0);

            const startOfNextDay = new Date(dateValue);
            startOfNextDay.setDate(startOfNextDay.getDate() + 1);
            startOfNextDay.setHours(0, 0, 0, 0);

            return `${startOfDay.toISOString()}..${startOfNextDay.toISOString()}`;
        } else if (item.operator === '!=') {
            return `!${dateValue.toISOString()}`;
        }

        throw Error(`Unexpected Operator Type for Date: ${item.operator}`);
    }

    let filterValue = item.value;

    if (['>', '<='].includes(item.operator)) {
        // round up the amount (including any fractional amounts) to the NEXT unit amount here
        // since 'greater than' and 'less than or equal to' are not supported in corten
        unitAmount = unitAmount ?? 1;
        let adjustedValue = Math.floor((+filterValue + unitAmount) / unitAmount) * unitAmount;
        return item.operator === '>' ? `${adjustedValue}..` : `..${adjustedValue}`;
    } else if (item.operator === 'not') {
        return `!${filterValue}`;
    }
    // Responsible for handling custom input range filter
    if(item.operator === 'range'){
        // Adding unitAmount to the end value, as corten treats all ranges as end exclusive
        // Input Range filter value is in the format of 'start..end', so we need to split it to get the start and end value
        const value = item?.value?.split('..');
        const startValue = value[0];
        const endValue = Number(value[1]) + (unitAmount ?? 1);
        return `${startValue}..${endValue}`;
    }

    // For other cases fallback to muiFilterToCoreFilter function
    return muiFilterToCoreFilter(item);
};

const muiFilterToCoreFilter = (item: GridFilterItem): string => {
    let filterValue = item.value; // leave as is for 'equals'
    if (filterValue === undefined || filterValue === '') {
        return '';
    }
    if (item.operator === 'equals' && filterValue === '*') {
        // Temporary fix to handle historic 'Undefined' values, Remove when no longer needed.
        filterValue = `*&!Undefined`
    } else if (item.operator === 'equals' && filterValue === BOOLEAN_NOT_NULL) {
        // Filter for boolean attributes that are not null
        filterValue = '*'
    } else if (item.operator === 'equals') {
        filterValue = wrapFilterValueWithQuotes(filterValue);
    } else if (item.operator === 'contains') {
        filterValue = `*${wrapFilterValueWithQuotes(filterValue)}*`;
    }
    else if (item.operator === 'startsWith') {
        filterValue = `${wrapFilterValueWithQuotes(filterValue)}*`;
    } else if (item.operator === 'endsWith') {
        filterValue = `*${wrapFilterValueWithQuotes(filterValue)}`;
    } else if (item.operator === 'isAnyOf') {
        filterValue = (item.value as string[]).map(value => wrapFilterValueWithQuotes(value)).join(',');
    } else if (item.operator === '!=') {
        filterValue = '!' + filterValue;
    } else if (item.operator === '>=') {
        filterValue = filterValue + '..';
    } else if (item.operator === '<') {
        filterValue = '..' + filterValue;
    }
    return filterValue;
};

// For a 'string' type column in MUI datagrid allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const stringFilterOperators = getGridStringOperators().filter(
    (operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
);

// For a 'single-select' type column in MUI datagrid only allow the 'is' operator for filtering.
const singleMatchStringFilterOperator = getGridSingleSelectOperators().filter(
    (operator) => operator.value === 'is'
);

// For a 'numeric' type column in MUI datagrid allow all filtering operations except '>' and '<=', 'isEmpty' and 'isNotEmpty'.
const numericFilterOperators = getGridNumericOperators().filter(
    (operator) =>
        operator.value !== '>' &&
        operator.value !== '<=' &&
        operator.value !== 'isEmpty' &&
        operator.value !== 'isNotEmpty'
);

// For a 'numeric' type column in MUI datagrid, where we are also making use of the extendedMuiFilterToCoreFilter
// function, allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const extendedNumericFilterOperators = getGridNumericOperators()
    .filter((operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty')
    .map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? operator.value === 'isAnyOf' ? operator.InputComponent: IntegerInput : undefined
    }));

// For a 'numeric float' type column in MUI datagrid
// Allow all filtering operations except 'isEmpty' and 'isNotEmpty'.
const extendedNumericFloatFilterOperators = getGridNumericOperators()
    .filter((operator) => operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty')

// For a 'date' or 'datetime' type column in MUI datagrid allow all filtering operations except 'isEmpty', 'isNotEmpty' and 'not'.
const dateFilterOperators = (showTime?: boolean): GridFilterOperator<any, Date, any>[] => {
    return getGridDateOperators(showTime).filter(
        (operator) =>
            operator.value !== 'isEmpty' &&
            operator.value !== 'isNotEmpty' &&
            operator.value !== 'not'
    );
};

const dateRangeFilterOperators = ({showTimeStamp=false}): GridFilterOperator<any, Date, any>[] => {
    return [
        {
            label: 'range',
            value: 'range',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return String(params.value) >= String(filterItem.value);
                };
            },
            InputComponent: DatePickerFilter,
            InputComponentProps: {
                showTimeStamp: showTimeStamp
            },
            getValueAsString: (value) => {
                const values = value?.split('..');
                const startDate = utcToLocalFormat(values[0]);
                const endDate = utcToLocalFormat(values[1]);
                if (startDate && endDate) {
                    return `${startDate}..${endDate}`;
                }
                return `${startDate}`;
            },
        }
    ];
};

/**
 * Utility function that returns a list of supported custom filters for Transaction ID field
 * @returns a list of supported operators for the Trasnaction ID field
 */
const getTransactionIdFilterOperators = () => {
    const transactionIdEqualsFilter = getGridStringOperators().filter((operator) =>
        operator.value === 'equals'
    ).map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? TransactionIdInput : undefined
    }))

    const transactionIdIsAnyFilter = getGridStringOperators().filter((operator) =>
        operator.value === 'isAnyOf'
    ).map((operator) => ({
        ...operator,
        InputComponent: operator.InputComponent ? TransactionIdAnyOfInput : undefined
    }))

    return [...transactionIdEqualsFilter, ...transactionIdIsAnyFilter]
};

/**
 * Determine whether the given GridFilterItem is blank.
 * We generally want to skip the filter item when this is the case
 * @param item The GridFilterItem to check whether it is blank
 * @returns a boolean indicating whether it is blank
 */
const isBlankFilterItem = (item: GridFilterItem) => {
    return (
        item.value == null || // undefined, null
        (item.value.hasOwnProperty('length') && item.value.length === 0) || // '', []
        (item.value.constructor === Object && Object.keys(item.value).length === 0) // {}
    )
}

/**
 * Function to construct the filtering component of the /history API request.
 *
 * @param {GridFilterItem[]} items a list of items based on which to construct the
 * filtering URI component
 * @param productId An optional product ID to use to filter the result
 * @param appConfigState application config state
 * @param isProductItemApiQuery Whether we are building filters for a search using the product item API
 *
 * @returns {string} a string that will be the filtering component of the request URI
 */
const buildFilterUri = (
    items: GridFilterItem[],
    productId: string | undefined,
    appConfigState: AppConfigContextType,
    isProductItemApiQuery: boolean = false
): string => {
    const filterItems: string[] = [];
    let productFilterApplied = false;

    /**
     * Enum class for determining the type of query key
     * @enum {string}
     */
    enum FilterKeyType {
        /**
         * @member {string}
         * The key is a stand alone item and will be used as is
         */
        Standalone = 'Standalone',

        /**
         * @member {string}
         * The key is a transaction attribute and will be wrapped with transactionAttribute[]
         */
        TransactionAttribute = 'TransactionAttribute',

        /**
         * @member {string}
         * The key is a product item attribute and will be wrapped with productItemAttribute[]
         */
        ProductItemAttribute = 'ProductItemAttribute'
    }

    /**
     * Helper function to construct the query string
     * 
     * @param {FilterKeyType} keyType The type of the key @see FilterKeyType
     * @param {string} key The key to use for the query string
     * @param {string} value The value to use for the query string
     * @param {boolean} disableValueEncoding Optional boolean to indicate whether we should avoid 
     *  encoding the supplied value. False by default.
     */
    const addToFilterItems = ({
        keyType,
        key,
        value,
        disableValueEncoding = false
    }: {
        keyType: FilterKeyType,
        key: string,
        value: string,
        disableValueEncoding?: boolean
    }) => {
        let keyString: string
        if (isProductItemApiQuery) {
            keyString = `attributes%5B${key}%5D`;
        } else {
            switch (keyType) {
                case FilterKeyType.Standalone:
                    keyString = key;
                    break;
                case FilterKeyType.TransactionAttribute:
                    keyString = `transactionAttribute%5B${key}%5D`;
                    break;
                case FilterKeyType.ProductItemAttribute:
                    keyString = `productItemAttribute%5B${key}%5D`;
                    break;
            }
        }
        
        filterItems.push(`${keyString}=${disableValueEncoding ? value : encodeURIComponent(value)}`);
    }

    items.filter(item => !isBlankFilterItem(item)).forEach((item) => {
            switch (item.field) {
                case 'transactionTimestamp':
                    addToFilterItems({
                        keyType: FilterKeyType.Standalone,
                        key: 'timestamp',
                        value: extendedMuiFilterToCoreFilter({item: item, isDate: true, containsTime: true}),
                    });
                    break;
                case 'tradeDate':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'TIMESTAMP').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, isDate: true })
                    });
                    break;
                case 'tradeId':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'ID').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'transactionId':
                    addToFilterItems({
                        keyType: FilterKeyType.Standalone,
                        key: 'transactionId',
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'tradeType':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'transactionTypeClient':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'counterParty':
                    addToFilterItems({
                        keyType: FilterKeyType.Standalone,
                        key: 'accountId',
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'productId':
                    addToFilterItems({
                        keyType: FilterKeyType.Standalone,
                        key: 'productId',
                        value: extendedMuiFilterToCoreFilter({ item: item }),
                        disableValueEncoding: true // ProductId contains colon char which we don't want to escape
                    });
                    productFilterApplied = true;
                    break;
                case 'projectType':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'TYPE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'projectId':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'ID').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'projectName':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'NAME').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'vintage':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'VINTAGE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 })
                    });
                    break;
                case 'country':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'state':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'STATE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'accreditationCode':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'fuelSource':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'generationYear':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 })
                    });
                    break;
                case 'creationYear':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 })
                    });
                    break;
                case 'generationState':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'greenpowerAccredited':
                    addToFilterItems({
                        keyType: FilterKeyType.ProductItemAttribute,
                        key: appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'quantity':
                    addToFilterItems({
                        keyType: FilterKeyType.Standalone,
                        key: 'amount',
                        value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 1 })
                    });
                    break;
                case 'currency':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'CURRENCY').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'price':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'PRICE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, unitAmount: 0.01  })
                    });
                    break;
                case 'valueDate':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'VALUE_DATE').key,
                        value: extendedMuiFilterToCoreFilter({ item: item, isDate: true })
                    });
                    break;
                case 'trader':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'TRADER_NAME').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'salesPerson':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'SALES_PERSON').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
                case 'brokerName':
                    addToFilterItems({
                        keyType: FilterKeyType.TransactionAttribute,
                        key: appConfigState.getAttribute('TRADE', 'BROKER_NAME').key,
                        value: extendedMuiFilterToCoreFilter({ item: item })
                    });
                    break;
            }
        });

    // If we have not applied any of the productId filter then default to filtering by the default products
    if (!productFilterApplied && !isProductItemApiQuery) {
        let productIds = appConfigState
            .getProducts(true)
            .map((p) => p.id)
            .join(',');
        if (productId) {
            // If we want to show a single product for this page, use this instead of all products (the default)
            productIds = productId;
        }
        addToFilterItems({
            keyType: FilterKeyType.Standalone,
            key: 'productId',
            value: productIds,
            disableValueEncoding: true // ProductId contains colon char which we don't want to escape
        });
    }

    return filterItems.join('&');
};

/**
 * Function to construct the sorting component of the /history API request.
 *
 * @param {GridSortItem[]} items a list of items based on which to construct the
 * sorting URI component
 * @param appConfigState application config state
 * @param isProductItemApiQuery A boolean indicating whether this function is being used for a product item API query.
 *                              When set, some of the keywords used to reference attributes change, as the API uses
 *                              slightly different naming.
 *
 * @returns {string} a string that will be the sorting component of the request URI
 */
const buildSortUri = ({
    items,
    defaultSort,
    appConfigState,
    isProductItemApiQuery
}: {
    items: GridSortItem[],
    defaultSort?: string | undefined,
    appConfigState: AppConfigContextType,
    isProductItemApiQuery?: boolean | undefined
}) => {
    const sortItems: string[] = []
    let transactionSortApplied = false;

    items.filter((item) => item.sort != null)
        .forEach((item) => {
            let directionChar = item.sort === 'asc' ? '+' : '-';
            const productItemAttributeLabel = 'productItemAttribute';
            let transactionAttributeLabel = 'transactionAttribute';
            if (isProductItemApiQuery) {
                // For the product item API, which queries product items, all transaction / trade level attributes
                // apply to the product item itself, alongside the usual product level attributes. The API requires 
                // the use of the productItemAttribute label to sort on any attribute.
                transactionAttributeLabel = 'productItemAttribute';
            }

            switch (item.field) {
                case 'transactionTimestamp':
                    sortItems.push(`${directionChar}transaction`);
                    transactionSortApplied = true;
                    break;
                case 'tradeDate':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'TIMESTAMP').key}`);
                    break;
                case 'transactionTypeClient':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_CLIENT').key}`);
                    break;
                case 'projectType':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'TYPE').key}`);
                    break;
                case 'projectId':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'ID').key}`);
                    break;
                case 'projectName':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'NAME').key}`);
                    break;
                case 'vintage':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'VINTAGE').key}`);
                    break;
                case 'state':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'STATE').key}`);
                    break;
                case 'country':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key}`);
                    break;
                case 'accreditationCode':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key}`);
                    break;
                case 'fuelSource':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key}`);
                    break;
                case 'generationYear':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key}`);
                    break;
                case 'creationYear':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key}`);
                    break;
                case 'generationState':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key}`);
                    break;
                case 'greenpowerAccredited':
                    sortItems.push(`${directionChar}${productItemAttributeLabel}.${appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key}`);
                    break;
                case 'quantity':
                    if (isProductItemApiQuery) {
                        sortItems.push(`${directionChar}unassignedAmount`);
                    } else {
                        sortItems.push(`${directionChar}amount`);
                    }
                    break;
                case 'currency':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'CURRENCY').key}`)
                    break;
                case 'price':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'PRICE').key}`)
                    break;
                case 'valueDate':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`)
                    break;
                case 'trader':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'TRADER_NAME').key}`)
                    break;
                case 'salesPerson':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'SALES_PERSON').key}`)
                    break;
                case 'brokerName':
                    sortItems.push(`${directionChar}${transactionAttributeLabel}.${appConfigState.getAttribute('TRADE', 'BROKER_NAME').key}`)
                    break;
            }
        });

    // If a default sort is specified and we are not sorting by any custom columns, then use default sort
    if (defaultSort !== undefined && sortItems.length === 0) {
        sortItems.push(defaultSort);
    }

    if (!transactionSortApplied && !isProductItemApiQuery) {
        // We want to apply a default sort of reverse chronological order if
        // - no sorting is being applied
        // - sorting on fields other than timestamp is applied (so that items
        //   with the same value are then further sorted)
        // This is only done for the history API and not for the product item API
        sortItems.push('-transaction')
    }

    return sortItems.join(',')
};


/**
 * Function to returns a new filter model that is compared to the previous filter model.
 * 
 * 
 * If the field of the new filter model is different from the previous filter model,
 * then the value of the new filter model is set to undefined.
 * @param prevFilterModel The previous filter model to compare.
 * @param newFilterModel The new filter model to compare.
 * @returns The compared filter model with  value = undefined if the field is different.
 */
const getComparedFilterModel = (prevFilterModel: GridFilterModel, newFilterModel: GridFilterModel) => {
    const prevFilterField = prevFilterModel?.items[0]?.field;
    const newFilterField = newFilterModel?.items[0]?.field || undefined;
    const filterData = {...newFilterModel}
    if (prevFilterField && prevFilterField !== newFilterField) {
        if (newFilterField) {
            filterData.items[0].value = undefined;
        }
    }
    return filterData;
};

/**
 * Input Range Filter Operators
 * @param {type} type - type of input range filter
 * @param {view} view - view of input range filter
 * @returns input range filter UI with start and end value
 */
const inputRangeFilterOperators = ({
    type,
    view
}: {
    type?: InputRangeType;
    view?:InputRangeView
}): GridFilterOperator<any, Date, any>[] => {
    return [
        {
            label: 'range',
            value: 'range',
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                    return null;
                }
                return (params): boolean => {
                    return String(params.value) >= String(filterItem.value);
                };
            },
            InputComponent: InputRangeFilter,
            InputComponentProps: {
                type,
                view
            }
        }
    ];
};

export {
    getUniqueValues,
    type ProjectProductItemData,
    truncateTransactionId,
    getRetiredProductItemIds,
    truncateHumanName,
    truncateProjectName,
    muiFilterToCoreFilter,
    extendedMuiFilterToCoreFilter,
    stringFilterOperators,
    dateFilterOperators,
    numericFilterOperators,
    extendedNumericFilterOperators,
    extendedNumericFloatFilterOperators,
    singleMatchStringFilterOperator,
    dateRangeFilterOperators,
    getTransactionIdFilterOperators,
    isBlankFilterItem,
    buildFilterUri,
    buildSortUri,
    inputRangeFilterOperators,
    getComparedFilterModel,
    isValidInteger,
    BOOLEAN_NOT_NULL
};
