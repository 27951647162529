import React from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper
} from '@mui/material';
import { useAppConfigState } from '../../state/AppConfig';
import { projectKeyNames } from '../../state/Variables';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { sectionSpace } from '../../Themes';

interface HoldingsSummaryProps {
    issuerAmount: any;
    assignedAmount: any;
    unassignedAmount: any;
    escrowAmount: any;
    title: string;
    minDecimalPos: number;
    maxDecimalPos: number;
}

const HoldingsSummary: React.FC<HoldingsSummaryProps> = ({
    issuerAmount,
    assignedAmount,
    unassignedAmount,
    escrowAmount,
    title,
    minDecimalPos,
    maxDecimalPos
}) => {

  const appConfigState = useAppConfigState();

  return (
    <Box sx={{ marginBottom: sectionSpace }}>
      <Typography variant='h3'>
        {title}
      </Typography>
      <TableContainer component={Paper} sx={{ width: '520px' }}>
        <Table size='small' aria-label="holding summary table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ paddingLeft: 0 }} align="right">{`${appConfigState.getAccount('INVENTORY_ISSUER').display} Holdings`}</TableCell>
              <TableCell align="right">{projectKeyNames.marketHoldings}</TableCell>
              <TableCell align="right">{projectKeyNames.clientAmountAssigned}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="right">
                <AmountFormatWrapper amount={issuerAmount} minDecimalPos={minDecimalPos} maxDecimalPos={maxDecimalPos}/>
              </TableCell>
              <TableCell align="right">
                <AmountFormatWrapper amount={escrowAmount} minDecimalPos={minDecimalPos} maxDecimalPos={maxDecimalPos}/>
              </TableCell>
              <TableCell align="right">
                {/* Client holdings is assignedAmount minus issuerAmount */}
                <AmountFormatWrapper amount={assignedAmount - issuerAmount} minDecimalPos={minDecimalPos} maxDecimalPos={maxDecimalPos}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export {
    HoldingsSummary,
    type HoldingsSummaryProps
}
