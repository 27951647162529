import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { CurveAmount, CurveTableCell } from "./CurveComponents";
import MapUtils from "./MapUtils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { DATE_DISPLAY_FORMAT } from "../../utility/utcToLocalFormat";
import React from "react";

/**
 * Styled static table component for the curve screen showing
 * calculated project prices, both CURRENT and EOD
 *
 * @param prices Table values
 * @param endOfDay Currently selected EOD option
 * @param endOfDayOptions List of available EOD options
 * @param projectNames Map from Project ID to Project Name, for column label translation
 * @param onEndOfDayChange Function to trigger when the user selects a different EOD value
 * @constructor
 */
const CurvePricesTable = (
    {
        prices,
        endOfDay,
        endOfDayOptions,
        projectNames,
        onEndOfDayChange,
    }: {
        prices: Map<string, Map<string, number>>,
        endOfDay: string | undefined,
        endOfDayOptions: string[],
        projectNames: Map<string, string>,
        onEndOfDayChange: (date: string | undefined) => void,
    }
) => {
    const format = (date: unknown | null) => (date as Dayjs)?.format(DATE_DISPLAY_FORMAT);

    return (
        <Table size='small'>
            <TableHead>
                <TableRow>
                    <CurveTableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                            <DatePicker
                                value={endOfDay ? dayjs(endOfDay) : null}
                                label='EOD Date'
                                slotProps={{
                                    textField: { size: 'small' },
                                    actionBar: { actions: ['accept', 'clear'] },
                                }}
                                shouldDisableDate={date => {
                                    const dateString = format(date)!;
                                    return !endOfDayOptions.includes(dateString);
                                }}
                                onChange={(date, context) => {
                                    if (context.validationError == null) {
                                        onEndOfDayChange(format(date));
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </CurveTableCell>
                    {MapUtils.innerKeys(prices).map(header =>
                        <CurveTableCell key={header} align='right'>{projectNames.get(header) ?? header}</CurveTableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {MapUtils.entries(prices).map(([rowKey, rowValue]) =>
                    <TableRow key={rowKey}>
                        <CurveTableCell key={rowKey}>{rowKey}</CurveTableCell>
                        {MapUtils.entries(rowValue).map(([columnKey, columnValue]) =>
                            <CurveTableCell key={columnKey} align='right'>
                                <div style={{ color: columnValue <= 0 ? 'red' : 'inherit' }}>
                                    <CurveAmount value={columnValue} />
                                </div>
                            </CurveTableCell>
                        )}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

export { CurvePricesTable }
