import { Box, Button, LinearProgress, Stack, Tab, Tabs, Typography } from '@mui/material';
import { DataGrid, GridFilterItem, GridFilterModel, GridToolbar } from '@mui/x-data-grid';

import { useEffect, useState } from 'react';
import { useAppNavigate } from '../../component/AppNavigate';
import { useAppConfigState } from '../../state/AppConfig';
import { Currency, projectKeyNames } from '../../state/Variables';
import { useInventoryManagementState } from './state/InventoryManagementState';
import { useProductState } from './state/ProductState';
import { HoldingRetirement, ProductDataRaw } from '../../component/HoldingRetirement';
import { AttributeCriteria, fetchProducts, fetchProjectPrices, ProjectPrices } from '../../utility/Fetch';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { currentDateExportFormat } from '../../utility/utcToLocalFormat';
import { getComparedFilterModel, isBlankFilterItem, muiFilterToCoreFilter, stringFilterOperators } from '../../utility/ProjectUtils';
import { HoldingLockToMarket } from '../../component/HoldingLockToMarket';
import { useLayoutState } from '../../state/Layout';
import { useLockedHoldings } from './state/LockedHoldingState';
import LockedHoldings from './LockedHoldings';
import { useProductDataState } from '../../state/ProductDataProvider';
import { TradePageType, Trades } from '../../component/Trades';
import { useAuth } from "../../state/AuthProvider";

const Projects = () => {
    const { mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const { uniqueProjectsDisplay, findUniqueProjects, loadingProjects } = useInventoryManagementState();
    const [ filterModel, setFilterModel ] = useState( {items: []} as GridFilterModel );
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const [lockToMarketDialogActive, setLockToMarketDialogActive] = useState(false);
    const [products, setProducts] = useState<ProductDataRaw[] | undefined>(undefined);
    
    const navigate = useAppNavigate();
    const appConfigState = useAppConfigState();
    const { productData } = useProductDataState();
    const [tabValue, setTabValue] = useState<'Projects' | 'LockedHoldings' | 'ForwardTrades'>('Projects');
    const { customTheme } = useLayoutState();
    const { fetchLockedHoldings } = useLockedHoldings();
    // tab item text highlighting
    const hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };

    const [prices, setPrices] = useState<ProjectPrices>();
    const user = useAuth();

    useEffect(() => {
        fetchProducts(
            appConfigState.getAccount('INVENTORY_ISSUER').id,
            appConfigState.getProducts()
        ).then((foundProducts) => {
            if (foundProducts) {
                const filteredProducts = foundProducts.filter(
                    (item: ProductDataRaw) =>
                    mblSelectedProduct.id === item.productId
                );
                setProducts(filteredProducts);    
            } else {
                setProducts([])
            }
        });
        fetchProjectPrices(mblSelectedProduct.id, user).then(setPrices);
    }, [mblSelectedProduct]);

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onRetirementDialogClosed = () => {
        setRetirementDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
    };

    const openLockToMarketDialog = () => {
        setLockToMarketDialogActive(true);
    };

    const onLockToMarketDialogClosed = () => {
        setLockToMarketDialogActive(false);
        fetchAndInitProducts(mblSelectedProduct.id);
        fetchLockedHoldings()
    };

    const generateExportFileName = () => {
        const currentDate = new Date();
        const productDisplayCode = appConfigState.getProduct(mblSelectedProduct.id)?.displayCode
        return `${productDisplayCode}Projects${currentDateExportFormat()}`;
    };

    // Defines the mapping of Projects table columns to the attribute name in core. Used for 
    // server side filtering in the Projects table
    const projectAttributeNames = {
        projectId: appConfigState.getAttribute('PROJECT', 'ID').key,
        name: appConfigState.getAttribute('PROJECT', 'NAME').key,
        countryCode: appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key,
        state: appConfigState.getAttribute('PROJECT', 'STATE').key,
        type: appConfigState.getAttribute('PROJECT', 'TYPE').key,
        uri: appConfigState.getAttribute('PROJECT', 'URI').key
    };

    const getAttributeFilters = (items: GridFilterItem[]): AttributeCriteria[] => {
        return items.filter(item => !isBlankFilterItem(item)).map((item) => ({
            code: projectAttributeNames[item.field as keyof typeof projectAttributeNames],
            value: muiFilterToCoreFilter(item)
        } as AttributeCriteria));
    }

    const productToCurrencyMap = (productCode: string): Currency => {
        switch (productCode) {
            case 'ACCU':
                return 'AUD';
            case 'VCU':
                return 'USD';
            default:
                throw Error(`Unexpected productCode: ${productCode}`);
        }
    }

    const onTabChange = (event: React.SyntheticEvent, value: any): void => {
        setTabValue(value);
        // If we are moving away from Projects tab then reset the filters
        if (value !== 'Projects') {
            setFilterModel({items: []});
        }
    }

    const getProjectPrice = (projectId: string): string => {
        const projectPrices = prices?.projectPrices.find(project => project.projectId === projectId);
        return projectPrices?.price?.toString() ?? "-";
    }

    return (
        <Box style={{ marginBottom: '5rem' }}>
            <Typography variant="h3">
                Projects, Locked Holdings and Forward Trades
            </Typography>
            <Stack direction="row" spacing={2} marginBottom={1} alignItems="baseline">
                <Button
                    disabled={products === undefined}
                    variant="outlined"
                    onClick={openRetirementDialog}
                >
                    Retire
                </Button>
                {retirementDialogActive && (
                    <HoldingRetirement
                        useDialog={true}
                        accountId={appConfigState.getAccount('INVENTORY_ISSUER').id}
                        holdings={[]}
                        products={products}
                        preSelectedProjectId={undefined}
                        preSelectedProjectType={undefined}
                        preSelectedVintage={undefined}
                        retirementDialogActive={retirementDialogActive}
                        onRetirementDialogClosed={onRetirementDialogClosed}
                    />
                )}
                <Button
                    disabled={products === undefined}
                    variant="outlined"
                    onClick={openLockToMarketDialog}
                >
                    Lock to Market
                </Button>
                {lockToMarketDialogActive && (
                    <HoldingLockToMarket
                        lockToMarketDialogActive={lockToMarketDialogActive}
                        products={products}
                        preSelectedProjectId={undefined}
                        preSelectedProjectType={undefined}
                        preSelectedVintage={undefined}
                        onLockToMarketDialogClosed={onLockToMarketDialogClosed}
                    />
                )}
            </Stack>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                <Tabs
                    value={tabValue}
                    onChange={onTabChange}
                >
                    <Tab label="Projects"
                         value={'Projects'}
                         sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Locked Holdings"
                        value="LockedHoldings"
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                    <Tab
                        label="Forward Trades"
                        value="ForwardTrades"
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                </Tabs>
            </Box>
            {loadingProjects && <LinearProgress />}

            {tabValue === 'Projects' && (
                <Box style={{ width: '100%', height: 'auto', overflow: 'auto' }}>
                    <DataGrid
                        autoHeight={true}
                        getRowHeight={() => 'auto'}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                                py: '22px'
                            }
                        }}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: { csvOptions: { fileName: generateExportFileName() } }
                        }}
                        rows={uniqueProjectsDisplay}
                        getRowId={(row) => row.projectId}
                        disableRowSelectionOnClick
                        filterMode="server"
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => {
                            const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
                            setFilterModel( comparedFilterModel );
                            findUniqueProjects(getAttributeFilters(comparedFilterModel.items));
                        }}
                        loading={loadingProjects}
                        // we continue to use client-side sorting, and can only implement server side sorting when the API supports it
                        // We do not enable pagination yet since the API used here doesn't yet support this option
                        columns={[
                            {
                                field: 'projectId',
                                headerName: appConfigState.getAttribute('PROJECT', 'ID').display,
                                filterOperators: stringFilterOperators,
                                minWidth: 150,
                                flex: 1
                            },
                            {
                                field: 'name',
                                headerName: appConfigState.getAttribute('PROJECT', 'NAME').display,
                                filterOperators: stringFilterOperators,
                                minWidth: 200,
                                flex: 2
                            },
                            mblSelectedProduct.code === 'ACCU' ? { 
                                field: 'state', 
                                headerName: appConfigState.getAttribute('PROJECT', 'STATE')
                                    .display, 
                                filterOperators: stringFilterOperators, 
                                minWidth: 150 
                            } : { 
                                field: 'countryCode', 
                                headerName: appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE')
                                    .display, 
                                filterOperators: stringFilterOperators, 
                                minWidth: 150 
                            },
                            {
                                field: 'type',
                                headerName: appConfigState.getAttribute('PROJECT', 'TYPE').display,
                                filterOperators: stringFilterOperators,
                                minWidth: 160,
                                flex: 1
                            },
                            {
                                field: 'inventoryAmount',
                                headerName: `${
                                    appConfigState.getAccount('INVENTORY_ISSUER').display
                                } Holdings`,
                                type: 'number',
                                filterable: false, // Note: The API doesn't support filtering by aggregate result columns. When it does, we can enable these
                                minWidth: 200,
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <AmountFormatWrapper
                                            amount={params.value as number}
                                            minDecimalPos={productData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                            maxDecimalPos={productData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                        />
                                    );
                                }
                            },
                            {
                                field: 'escrowAmount',
                                headerName: projectKeyNames.marketHoldings,
                                type: 'number',
                                filterable: false, // Note: The API doesn't support filtering by aggregate result columns. When it does, we can enable these
                                minWidth: 200,
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <AmountFormatWrapper
                                            amount={params.value as number}
                                            minDecimalPos={productData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                            maxDecimalPos={productData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                        />
                                    );
                                }
                            },
                            {
                                field: 'clientAmountAssigned',
                                headerName: projectKeyNames.clientAmountAssigned,
                                type: 'number',
                                filterable: false,
                                minWidth: 200,
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <AmountFormatWrapper
                                            amount={params.value as number}
                                            minDecimalPos={productData.get(mblSelectedProduct.id)?.minDecimalPos!}
                                            maxDecimalPos={productData.get(mblSelectedProduct.id)?.maxDecimalPos!}
                                        />
                                    );
                                }
                            },
                            {
                                field: 'price',
                                headerName: `Price (${productToCurrencyMap(
                                    mblSelectedProduct.code
                                )})`,
                                type: !prices ? 'text' : 'number',
                                filterable: false,
                                valueGetter: (params) => {
                                    return !prices ? 'Loading' : getProjectPrice(params.row.projectId as string);
                                },
                                renderCell: (params) => {
                                    if (!prices) {
                                        return params.value;
                                    } else {
                                        const price = params.value;
                                        return typeof price === 'string' ? (
                                            price
                                        ) : (
                                            <AmountFormatWrapper
                                                amount={price}
                                                minDecimalPos={2}
                                                maxDecimalPos={2}
                                            />
                                        );
                                    }
                                },
                                minWidth: 150,
                                flex: 1
                            }
                        ]}
                        density="compact"
                        disableDensitySelector
                        onRowDoubleClick={(row) =>
                            navigate(
                                `/inventory-management/product/${mblSelectedProduct.id}/project/${row.id}`
                            )
                        }
                        aria-label="projects table"
                    />
                </Box>
            )}
            {tabValue === 'LockedHoldings' && <LockedHoldings />}
            {tabValue === 'ForwardTrades' && (
                <Trades
                    pageType={TradePageType.Forwards}
                    columnDefinitions={[
                        {key: "valueDate", showByDefault: true},
                        {key: "tradeDate", showByDefault: false},
                        {key: "forwardId", showByDefault: true},
                        {key: "tradeId", showByDefault: true},
                        {key: "quantity", showByDefault: true},
                        {key: "productId", showByDefault: false},
                        {key: "projectType", showByDefault: true},
                        {key: "projectId", showByDefault: false},
                        {key: "projectName", showByDefault: false},
                        {key: "vintage", showByDefault: true},
                        {key: "currency", showByDefault: true},
                        {key: "price", showByDefault: true},
                        {key: "counterParty", showByDefault: true},
                        {key: "trader", showByDefault: false},
                        {key: "salesPerson", showByDefault: false},
                        {key: "salesCredits", showByDefault: false},
                        {key: "brokerName", showByDefault: false},
                        {key: "brokerage", showByDefault: false},
                    ]}
                    defaultOrdering={`productItemAttribute.${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`}
                    // Filters
                    product={mblSelectedProduct.id}
                />
            )}
        </Box>
    );
};

export default Projects;
