import './App.css';
import {
    CssBaseline
} from "@mui/material";
import { appThemes, CustomTheme } from "./Themes";
import { ThemeProvider } from "@mui/material/styles";
import { useAppConfigState } from './state/AppConfig';
import { AuthProvider } from './state/AuthProvider';
import { AccessKeyProvider } from './state/AccessKeyManager';
import AppContainer from './component/AppContainer';
import { StompSessionProvider } from 'react-stomp-hooks';
import { NotificationProvider } from './state/NotificationProvider';
import { cortenWebsocketUrl } from './state/AppConfig';
import { useState } from "react";
import { AuthExtension } from './component/AuthExtension';
import { ADMIN_GROUP_ID } from './state/Variables';

function App({ cortenAppConfig }: any) {

    var themeName: keyof typeof appThemes = cortenAppConfig.defaultTheme
    if( !themeName || !appThemes.hasOwnProperty(themeName))
    {
        themeName= "trovio-dark"
    }

    const appConfigState = useAppConfigState();
    const [theme, setTheme]= useState<CustomTheme>( appThemes[ themeName ] )
    const [themeAnchorEl, setThemeAnchorEl] = useState();

    return (
        <ThemeProvider theme={theme.theme}>
            <CssBaseline/>
            { appConfigState.settingsLoaded() && (
                <AccessKeyProvider>
                    <AuthProvider appConfig={cortenAppConfig}>
                        <AuthExtension cognitoGroupId={ADMIN_GROUP_ID}>
                            <StompSessionProvider url={cortenWebsocketUrl}>
                                <NotificationProvider>
                                    <AppContainer theme={theme} setTheme={setTheme} themeAnchorEl={themeAnchorEl} setThemeAnchorEl={setThemeAnchorEl} />
                                </NotificationProvider>
                            </StompSessionProvider>
                        </AuthExtension>
                    </AuthProvider>
                </AccessKeyProvider>
            )}
        </ThemeProvider>
    );
}

export default App;
