import { Autocomplete, TextField } from "@mui/material";
import useId from "@mui/material/utils/useId";
import { GridFilterInputMultipleValueProps, GridFilterInputValueProps, GridFilterItem, useGridRootProps } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

/**
 * Copied from {@link https://github.com/mui/mui-x/blob/ffc58b377c91d2d991d59e34d407ca4fdde119f8/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterInputValue.tsx#L21}
 * Seems to be used in clearing out the value in the form when the user clicks 
 * the 'X' button on the left hand side of the panel
 */
type ItemPlusTag = GridFilterItem & { fromInput?: string };

/**
 * Function to determine whether a supplied value is a valid Transaction ID. Currently 
 * this is a hexadecimal number with a length of 64 chars (excluding the 0x prefix)
 * 
 * @param {string} input A string to validate
 * @returns {boolean} true if the input is a valid transaction id, false otherwise
 */
const isValidHexadecimal = (input: string): boolean => {
    return /^0x[0-9A-Fa-f]{64}$/.test(input);
};

/**
 * Custom equals filter input for Transaction ID field. This is a modified version of the 
 * default input component in MUI datagrid filter. 
 * See {@link https://github.com/mui/mui-x/blob/ffc58b377c91d2d991d59e34d407ca4fdde119f8/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterInputValue.tsx#L23}
 * 
 * @param {GridFilterInputValueProps} props Props that are supplied to the component by MUI datagrid
 * 
 * @returns {JSX.Element} The component to render for Transaction ID equals filter input
 */
const TransactionIdInput = (props: GridFilterInputValueProps) => {
    const { item, applyValue, focusElementRef } = props;
    const [inputValue, setInputValue] = useState('');
    const id = useId();
    const rootProps = useGridRootProps();

    useEffect(() => {
        item.value ? setInputValue(item.value) : setInputValue('')
    }, [])

    useEffect(() => {
        const itemPlusTag = item as ItemPlusTag;
        if (itemPlusTag.fromInput !== id) {
          setInputValue(String(item.value ?? ''));
        }
    }, [id, item]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setInputValue(value);

        if (value === '' || isValidHexadecimal(value)) {
            applyValue({ ...item, value: value });
        }
    };
    
    return (
        <TextField
            id="transaction-id-input"
            variant="standard"
            label='Value'
            value={inputValue}
            onChange={handleChange}
            helperText={inputValue && !isValidHexadecimal(inputValue) && 'Invalid Transaction ID'}
            inputRef={focusElementRef}
            {...rootProps.slotProps?.baseTextField}
        />
    )
}

/**
 * Custom anyOf filter input for Transaction ID field. This is a modified version of default multi value 
 * input for MUI datagrid filter. 
 * See {@link https://github.com/mui/mui-x/blob/ffc58b377c91d2d991d59e34d407ca4fdde119f8/packages/grid/x-data-grid/src/components/panel/filterPanel/GridFilterInputMultipleValue.tsx#L13}
 * 
 * @param {GridFilterInputMultipleValueProps} props Props that are supplied to the component by MUI datagrid
 * 
 * @returns {JSX.Element} The component to render for Transaction ID anyOf filter input
 */
const TransactionIdAnyOfInput = (props: GridFilterInputMultipleValueProps) => {
    const { 
        item,
        applyValue,
        type,
        apiRef,
        focusElementRef,
        color,
        helperText,
        size,
        variant,
        ...other
    } = props;
    const [inputValue, setInputValue] = useState([]);
    const rootProps = useGridRootProps();
    const TextFieldProps = {
        color,
        helperText,
        size,
        variant,
    };
    const id = useId();

    useEffect(() => {
        item.value ? setInputValue(item.value.map(String)) : setInputValue([])
    }, [])

    const handleChange = (event: any, value: any) => {
        setInputValue(value.map(String));
        if (value.length === 0 || value.every((v: string) => isValidHexadecimal(v))) {
            applyValue({ ...item, value: [...value] });
        }
    };

    return (
        <Autocomplete<string, true, false, true>
            multiple
            freeSolo
            options={[]}
            filterOptions={(options, params) => {
                const { inputValue } = params;
                return inputValue == null || inputValue === '' ? [] : [inputValue];
            }}
            id={id}
            value={inputValue}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <rootProps.slots.baseChip
                      variant="outlined"
                      size="small"
                      label={option}
                      {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => (
                <rootProps.slots.baseTextField
                    {...params}
                    label={apiRef.current.getLocaleText('filterPanelInputLabel')}
                    placeholder={apiRef.current.getLocaleText('filterPanelInputPlaceholder')}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                    }}
                    inputRef={focusElementRef}
                    type='text'
                    {...TextFieldProps}
                    helperText={inputValue.some((v) => !isValidHexadecimal(v)) && 'Invalid Transaction ID(s)'}
                    {...rootProps.slotProps?.baseTextField}
                />
            )}
            {...other}
        />
    )
}

export { TransactionIdInput, TransactionIdAnyOfInput };