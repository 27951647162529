import { useNotification } from '../NotificationProvider';
import { useEffect, useRef } from 'react';

const transactionTimeoutMillis = 16_000;

// simple custom hook to help us monitor results of transactions we perform
const useTransactionMonitor = (
    onSuccess: (notification: any) => void,
    onError: (notification: any) => void,
    onTimeout: () => void
) => {
    const { notification: notification } = useNotification();
    const transactionId = useRef<string | null>(null);
    const transactionTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        let txId = transactionId.current;
        if (!txId) {
            return;
        } else if (notification.hasOwnProperty('mintTransactionResponse')) {
            if (notification['mintTransactionResponse'].txId === txId) {
                unsubscribe();
                onSuccess(notification['mintTransactionResponse']);
            }
        } else if (notification.hasOwnProperty('mintTransactionFailed')) {
            if (notification['mintTransactionFailed'].txId === txId) {
                unsubscribe();
                onError(notification['mintTransactionFailed']);
            }
        }
    }, [notification]);

    const subscribe = (txId: string) => {
        unsubscribe(); // silently reset, if already subscribed
        transactionId.current = txId;
        transactionTimeout.current = setTimeout(() => {
            unsubscribe();
            onTimeout();
        }, transactionTimeoutMillis);
    };

    const unsubscribe = () => {
        clearTimeout(transactionTimeout.current);
        transactionId.current = null;
    };

    return {subscribe, unsubscribe};
};

export { useTransactionMonitor };
