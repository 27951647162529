import { AmountFormatWrapper } from './AmountFormatWrapper';
import { Box, Typography } from '@mui/material';
import { UNDEFINED_CODE_STRING, UNDEFINED_CODE_INTEGER } from '../view/trade/TradeUtil';

interface DialogFieldAmountRenderProp {
    minDecimalPos: number;
    maxDecimalPos: number;
}

const renderDialogField = (
    fieldName: string,
    fieldValue?: string | number,
    amountRenderProp?: DialogFieldAmountRenderProp
): JSX.Element => {

    let renderValue;

    if (amountRenderProp && fieldValue) {
        renderValue = (
            <AmountFormatWrapper
                amount={fieldValue}
                minDecimalPos={amountRenderProp.minDecimalPos}
                maxDecimalPos={amountRenderProp.maxDecimalPos} />
        );
    } else if (fieldValue) {
        renderValue = fieldValue;
    } else {
        renderValue = '-';
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
            <Typography sx={{ flex: '1' }}>
                {fieldName}:
            </Typography>
            <Typography sx={{ flex: '1', textAlign: 'right' }}>{renderValue}</Typography>
        </Box>
    );
};

const renderFieldValue = (fieldValue: any) => {
    if (fieldValue === undefined || fieldValue === null || fieldValue === '' || fieldValue === UNDEFINED_CODE_STRING || fieldValue === parseInt(UNDEFINED_CODE_INTEGER)) {
        return '-';
    }
    return fieldValue;
};

export { renderDialogField, renderFieldValue }
