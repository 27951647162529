import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useAppConfigState } from '../../../state/AppConfig';
import { fetchProductItemsWithId } from '../../../utility/Fetch';
import { executeTransaction, fetchWithCorten } from '../../../state/CortenClient';
import { useAuth } from '../../../state/AuthProvider';
import {
    DialogState,
    useEmissionsDispatch,
    useEmissionsState,
    EmissionDisplay,
    EmissionTableType
} from './EmissionsState';
import { useTransactionMonitor } from '../../../state/transaction/TransactionMonitor';
import { useNavigate } from 'react-router-dom';
import { EMISSION_ASSET_AMOUNT, EMISSION_UNIT_AMOUNT } from '../../../state/Variables';
import { APP_ROUTES } from '../../../utility/AppRoutes';

interface TransactionErrorInfo {
    errorMessage: string;
    errorCode: string;
    warningMessage: string;
}

interface DecarboniseStateType {
    fetchItemsForDecarbonisedAsset: () => void;
    decarbonisedItems: any[];
    isDecarboniseFormOpen: boolean;
    handleDecarboniseFormOpen: (item: EmissionDisplay) => void;
    dialogState: DialogState;
    performDecarboniseEmissionAsset: (filters: any[]) => void;
    isRequesting: boolean;
    txId: string;
    unsubscribe: () => void;
    errorInfo: TransactionErrorInfo;
    selectedPendingEmission: EmissionDisplay | undefined;
}

interface DecarboniseDispatchType {
    setIsDecarboniseFormOpen: Dispatch<SetStateAction<boolean>>;
    setDialogState: Dispatch<SetStateAction<DialogState>>;
    setTxId: Dispatch<SetStateAction<string>>;
    handleOpenCertificate: (productItemId: string) => void;
}

const DecarboniseStateContext = createContext<DecarboniseStateType | null>(null);
const DecarboniseDispatchContext = createContext<DecarboniseDispatchType | null>(null);

const DecarboniseProvider = ({ children }: { children?: ReactNode }) => {
    const appConfigState = useAppConfigState();
    const user = useAuth();
    const navigate = useNavigate();
    const decarbProductId = appConfigState.getEmissionProductId('DECARBONISED_EMISSION_ASSET');

    const { createEmissionsTableDisplay, carbonProduct } = useEmissionsState();
    const { setLoadingTable } = useEmissionsDispatch();
    const [decarbonisedItems, setDecarbonisedItems] = useState<any>([]);
    const [isDecarboniseFormOpen, setIsDecarboniseFormOpen] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.FORM);
    const [isRequesting, setIsRequesting] = useState<boolean>(false);
    const [txId, setTxId] = useState<string>('');
    const [errorInfo, setErrorInfo] = useState<TransactionErrorInfo>({
        errorMessage: '',
        errorCode: '',
        warningMessage: ''
    });
    const [selectedPendingEmission, setSelectedPendingEmission] = useState<
        EmissionDisplay | undefined
    >(undefined);

    // open form
    const handleDecarboniseFormOpen = (item: EmissionDisplay) => {
        // re-init form
        setDialogState(DialogState.FORM);

        setIsDecarboniseFormOpen(true);
        setSelectedPendingEmission(item);
    };

    // fetch product items for decarbonised emission product
    const fetchItemsForDecarbonisedAsset = async () => {
        let combinedProductItems = await fetchProductItemsWithId(decarbProductId);

        // create a display format from emission response data
        if (combinedProductItems && combinedProductItems.length > 0) {
            let displayFormat = createEmissionsTableDisplay(combinedProductItems);

            setDecarbonisedItems(displayFormat);
        } else {
            setDecarbonisedItems([]);
        }
        setLoadingTable(false);
    };

    // POST request to create decarbonised product item
    const performDecarboniseEmissionAsset = async (itemIds: string[]) => {
        setIsRequesting(true);

        // calculate the amount for the carbon product using intensity and emission asset amount (hard coded)
        let totalAmount: Number = 0;
        if (selectedPendingEmission?.intensity !== undefined) {
            // round decimal intensity values up to next whole number
            totalAmount = Math.ceil(+selectedPendingEmission.intensity) * +EMISSION_ASSET_AMOUNT;
        }

        let unsignedRequestBody = {
            type: 'CreateProductItemRequest',
            issuerId: appConfigState.getAccount('INVENTORY_ISSUER').id,
            productId: decarbProductId,
            canInflate: false,
            canFractionalize: false,
            unitAmount: EMISSION_UNIT_AMOUNT,
            initialAmount: EMISSION_ASSET_AMOUNT,
            // For now we will only copy over the name attribute,
            // ideally no attributes should be copied between pending emission and decarbonised emission items
            attributes: {
                [`${appConfigState.getAttribute('EMISSION_ASSET', 'NAME').key}`]:
                    selectedPendingEmission?.name
            },
            underlying: [
                {
                    // carbon product
                    type: 'ProductItemsAmount',
                    productItemIds: itemIds,
                    amount: totalAmount.toString()
                },
                {
                    // emission product
                    type: 'ProductItemsAmount',
                    productItemIds: [selectedPendingEmission!.id],
                    amount: EMISSION_ASSET_AMOUNT
                }
            ]
        };

        try {
            let response = await executeTransaction(unsignedRequestBody, user);

            if (!response.ok) {
                throw new Error('Failed to perform request');
            }

            let data = await response.json();
            setTxId(data.txId);

            // subscribe to transaction status monitor
            subscribe(data.txId);
        } catch (error) {
            console.error(error);
            // set error info state
            setErrorInfo({
                errorMessage: 'Could not complete the transaction - the request was rejected',
                errorCode: '',
                warningMessage: ''
            });

            // show error dialog
            setDialogState(DialogState.ERROR);

            setIsRequesting(false);
        }
    };

    // monitor results of transactions using notifications
    const { subscribe, unsubscribe } = useTransactionMonitor(
        // success
        () => {
            // show success dialog
            setDialogState(DialogState.SUCCESS);
            // stop button spinner
            setIsRequesting(false);

            // clear error state
            setErrorInfo({
                errorMessage: '',
                errorCode: '',
                warningMessage: ''
            });
            // redirect to decarbonised emission table
            navigate(`${APP_ROUTES.dashboard.emissions.path}/${EmissionTableType.DECARBONISED}`);
        },
        // error
        (tx) => {
            setErrorInfo({
                errorMessage: 'An error occurred when retiring transactions.',
                errorCode: tx.errorCode,
                warningMessage: ''
            });

            // show error dialog
            setDialogState(DialogState.ERROR);
            // stop button spinner
            setIsRequesting(false);
        },
        // timeout
        () => {
            setErrorInfo({
                errorMessage: '',
                errorCode: '',
                warningMessage:
                    'The transaction timed out. Please check back later to confirm whether the transaction was processed.'
            });

            // show error dialog
            setDialogState(DialogState.ERROR);
            // stop button spinner
            setIsRequesting(false);
        }
    );

    // fetch request for certificate URL
    const handleOpenCertificate = async (productItemId: string) => {
        const options = {
            includeBakedHoldings: true,
            includeRequest: true
        };

        try {
            const url = `/api/wallet/save-proof/transaction/${productItemId}?includeBakedHoldings=${options.includeBakedHoldings}&includeRequest=${options.includeRequest}`;

            const response = await fetchWithCorten(url, { method: 'GET' });

            if (!response.ok) {
                throw new Error('Failed to fetch certificate data');
            }

            const data = await response.json();
            // open certificate in new tab
            const newTab = window.open(data.viewUrl, '_blank');

            if (newTab) {
                // focus on the new tab
                newTab.focus();
            } else {
                // If new tab was blocked
                alert('Popup blocked. Please enable popups to view certificate.');
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <DecarboniseStateContext.Provider
            value={{
                fetchItemsForDecarbonisedAsset,
                decarbonisedItems,
                isDecarboniseFormOpen,
                handleDecarboniseFormOpen,
                dialogState,
                performDecarboniseEmissionAsset,
                isRequesting,
                txId,
                unsubscribe,
                errorInfo,
                selectedPendingEmission
            }}
        >
            <DecarboniseDispatchContext.Provider
                value={{ setIsDecarboniseFormOpen, setDialogState, setTxId, handleOpenCertificate }}
            >
                {children}
            </DecarboniseDispatchContext.Provider>
        </DecarboniseStateContext.Provider>
    );
};

function useDecarboniseState() {
    const context = useContext(DecarboniseStateContext);
    if (!context) {
        throw new Error('no provider for useDecarboniseState');
    }
    return context;
}

function useDecarboniseDispatch() {
    const context = useContext(DecarboniseDispatchContext);
    if (!context) {
        throw new Error('no provider for useDecarboniseDispatch');
    }
    return context;
}

export { DecarboniseProvider, useDecarboniseState, useDecarboniseDispatch };
