import Emissions from './Emissions';
import { DecarboniseProvider } from './state/DecarboniseState';
import { EmissionsProvider } from './state/EmissionsState';
import { UnderlyingProvider } from './state/UnderlyingState';

const ContainEmissions = () => {
    return (
        <EmissionsProvider>
            <DecarboniseProvider>
                <UnderlyingProvider>
                    <Emissions />
                </UnderlyingProvider>
            </DecarboniseProvider>
        </EmissionsProvider>
    );
};

export default ContainEmissions;
