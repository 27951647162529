/**
 * This file is the app UI entry point
 */
import { Box } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CustomTheme } from '../Themes';
import { useAppConfigState } from '../state/AppConfig';
import { useAuth } from '../state/AuthProvider';
import { LayoutProvider } from '../state/Layout';
import { APP_ROUTES } from '../utility/AppRoutes';
import MainView from '../view/viewContainer/MainView';
import LoginRedirectHandler from './LoginRedirectHandler';
import LogoutHandler from './LogoutHandler';
import Navigation from './Navigation';
import { NotFound } from './NotFound';
import { LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH } from '../state/Variables';
import { homepage } from './AppNavigate';
import { ProductDataProvider } from '../state/ProductDataProvider';

const dashboardRoutes = APP_ROUTES.dashboard;

const AppContainer = ({
    theme,
    setTheme,
    themeAnchorEl,
    setThemeAnchorEl
}: {
    theme: CustomTheme;
    setTheme: any;
    themeAnchorEl: any;
    setThemeAnchorEl: any;
}) => {
    const appConfigState = useAppConfigState();

    return (
        <>
            <Routes>
                {/* Public Routes */}
                <Route
                    path="/"
                    element={<AuthRedirect redirect={dashboardRoutes.inventoryManagement.path} />}
                />
                <Route path={LOGOUT_CALLBACK_PATH} element={<LogoutHandler />} />
                <Route path={LOGIN_CALLBACK_PATH} element={<LoginRedirectHandler />} />
                <Route path="*" element={<NotFound />} />

                {/* Protected App Routes, Only accessible when user is authenticated */}
                <Route
                    path={homepage}
                    element={
                        <RequireAuth>
                            <DashboardWrapper>
                                <Box sx={{ flexGrow: 1 }}>
                                    <LayoutProvider theme={theme}>
                                        <Navigation
                                            theme={theme}
                                            setTheme={setTheme}
                                            themeAnchorEl={themeAnchorEl}
                                            setThemeAnchorEl={setThemeAnchorEl}
                                        />
                                        <ProductDataProvider>
                                            <MainView/>
                                        </ProductDataProvider>
                                    </LayoutProvider>
                                </Box>
                            </DashboardWrapper>
                        </RequireAuth>
                    }
                >
                    {/* Inventory Management */}
                    <Route path={dashboardRoutes.inventoryManagement.path} element={<Outlet />}>
                        <Route
                            index
                            path={`${dashboardRoutes.inventoryManagement.path}/*`}
                            element={dashboardRoutes.inventoryManagement.component}
                        ></Route>
                    </Route>
                    {/* Project Details Route */}
                    <Route
                        index
                        path={dashboardRoutes.inventoryProjectDetails.path}
                        element={dashboardRoutes.inventoryProjectDetails.component}
                    />
                    {/* Vintage Details Route  */}
                    <Route
                        path={dashboardRoutes.inventoryVintageDetails.path}
                        element={dashboardRoutes.inventoryVintageDetails.component}
                    />
                    {/* Certificate Details Route */}
                    <Route
                        path={dashboardRoutes.certificateDetails.path}
                        element={dashboardRoutes.certificateDetails.component}
                    />
                    {/* Physical Trade Route */}
                    <Route
                        path={dashboardRoutes.physicalTrade.path}
                        element={dashboardRoutes.physicalTrade.component}
                    />
                    {/* Forward Trade Route */}
                    <Route
                        path={dashboardRoutes.forwardTrade.path}
                        element={dashboardRoutes.forwardTrade.component}
                    />
                    {/* Retirement Trade Route */}
                    <Route
                        path={dashboardRoutes.retirementTrade.path}
                        element={dashboardRoutes.retirementTrade.component}
                    />
                    {/* Risk Route Route */}
                    <Route
                        path={dashboardRoutes.risk.path}
                        element={dashboardRoutes.risk.component}
                    />
                    {/* Risk Holdings / Forwards Route */}
                    <Route
                        path={dashboardRoutes.riskPosition.path}
                        element={dashboardRoutes.riskPosition.component}
                    />
                    {/* Trade Blotter Route */}
                    <Route
                        path={dashboardRoutes.tradeBlotter.path}
                        element={dashboardRoutes.tradeBlotter.component}
                    />
                    {/* Client Management Route */}
                    <Route
                        path={dashboardRoutes.clientManagement.path}
                        element={dashboardRoutes.clientManagement.component}
                    />
                    {/* Curve Route */}
                    <Route
                        path={dashboardRoutes.curve.path}
                        element={dashboardRoutes.curve.component}
                    />
                    {/* Emissions Route */}
                    <Route
                        path={`${dashboardRoutes.emissions.path}/*`}
                        element={
                            <IsEnabled logic={appConfigState.emissionsFeatureEnabled()}>
                                <>{dashboardRoutes.emissions.component}</>
                            </IsEnabled>
                        }
                    />
                    {/* Aggregator Route */}
                    <Route
                        path={dashboardRoutes.aggregator.path}
                        element={
                            <IsEnabled logic={appConfigState.enableAggregator()}>
                                <>{dashboardRoutes.aggregator.component}</>
                            </IsEnabled>
                        }
                    />
                    {/* Inventory Score Marking Route */}
                    <Route
                        path={dashboardRoutes.inventoryScore.path}
                        element={dashboardRoutes.inventoryScore.component}
                    />
                    {/* Catch other Routes */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </>
    );
};

export default AppContainer;

// Wrapper component to check if user is authenticated
const RequireAuth = ({ children }: PropsWithChildren) => {
    const { user, isTokenExpired } = useAuth();
    if (user && !isTokenExpired(user)) {
        return <>{children}</>;
    }
    return null;
};

// Wrapper component to redirect (Authenticated user) to any page based on redirect props passed
const AuthRedirect = ({ redirect }: { redirect: string }) => {
    const { user, isTokenExpired } = useAuth();
    if (user && !isTokenExpired(user)) {
        return <Navigate to={redirect} />;
    }
    return <></>;
};

// Component to Enabled/Disblaed any Routes based on config
const IsEnabled = ({ children, logic }: { logic: boolean } & PropsWithChildren) => {
    if (!logic) {
        return <Navigate to="*" />;
    }
    return <>{children}</>;
};

// Dashboard Root Wrapper , which redirect user to inventory page if user is authenticated
const DashboardWrapper = ({ children }: PropsWithChildren) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    useEffect(() => {
        if (pathname === homepage) {
            navigate(dashboardRoutes.inventoryManagement.path);
        }
    }, [navigate, pathname]);

    return <>{children}</>;
};
