import { LinearProgress, Tooltip, Typography } from '@mui/material';
import {
    DataGrid,
    getGridSingleSelectOperators,
    GridColDef,
    GridColumnVisibilityModel,
    GridFilterItem,
    GridFilterModel,
    GridPaginationModel,
    GridSortModel,
    GridToolbar,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import 'dayjs/locale/en-au';
import { useEffect, useRef, useState } from 'react';
import { useAppConfigState } from '../state/AppConfig';
import { TransactionOverview, ActionType } from '../component/TransactionOverview';
import { fetchWithCortenAndHandleAbort } from '../state/CortenClient';
import { currentDateExportFormat, DATE_DISPLAY_FORMAT, utcToLocalFormat } from '../utility/utcToLocalFormat';
import { AmountFormatWrapper } from '../utility/AmountFormatWrapper';
import {
    BOOLEAN_NOT_NULL,
    buildFilterUri,
    buildSortUri,
    dateRangeFilterOperators,
    extendedNumericFilterOperators,
    extendedNumericFloatFilterOperators,
    getComparedFilterModel,
    getTransactionIdFilterOperators,
    inputRangeFilterOperators,
    stringFilterOperators,
    truncateHumanName,
    truncateProjectName,
    truncateTransactionId,
} from '../utility/ProjectUtils';
import { isTrade, TransactionType } from '../state/Variables';
import { getTableColumnsStorageItemKey } from '../state/StorageUtils';
import { useAuth } from '../state/AuthProvider';
import { renderFieldValue } from '../utility/DialogFieldRenderUtil';
import { containsUndefinedPlaceholder } from '../view/trade/TradeUtil';
import { useProductDataState } from '../state/ProductDataProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { wrapFilterValueWithQuotes } from '../utility/CoreFilterUtil';
import { fetchTransactionsByProductItem } from '../utility/Fetch';
import { ForwardTradeDeliverDialog } from '../view/trade/forward/ForwardTradeDeliverDialog';
import { ForwardTradeCancelDialog } from '../view/trade/forward/ForwardTradeCancelDialog';

enum TradePageType {
    TradeBlotter = 'TradeBlotter',
    Forwards = 'Forwards'
}

interface TradesColumnDefinition {
    key: string;
    showByDefault: boolean;
}

interface TradeRow {
    transactionTimestamp: string;
    tradeDate: string;
    tradeId: string;
    transactionId: string;
    forwardId: string;
    tradeType: TransactionType | undefined;
    counterParty: string;
    counterPartyToolTipText: string;
    productId: string;
    registry: string;
    projectType: string;
    projectId: string;
    projectName: string;
    projectNameTruncated: string;
    vintage: string;
    country: string;
    state?: string;
    accreditationCode: string;
    fuelSource: string;
    generationYear: string;
    creationYear: string;
    generationState: string;
    greenpowerAccredited: string;
    quantity: number;
    currency: string;
    valueDate: string;
    price?: number;
    trader?: string;
    salesPerson?: string;
    salesCredits?: number;
    brokerName?: string;
    brokerage?: number;
}

enum ForwardTradeState {
    Initialisation = 'Initialisation',
    CheckingProductItem = 'CheckingProductItem',
    AlreadyDelivered = 'AlreadyDelivered',
    AlreadyCanceled = 'AlreadyCanceled',
    AvailableForDeliveryOrCancelation = 'AvailableForDeliveryOrCancelation',
    ConfirmingToDeliver = 'ConfirmingToDeliver',
    ConfirmingToCancel = 'ConfirmingToCancel',
    // Subsequent states are managed by ForwardTradeDeliverDialog or ForwardTradeCancelDialog
}

const shortenAddress = (address: string): string => {
    if (address === undefined) return address;
    if (address.length > 10) {
        return `${address.slice(0, 8)}...${address.slice(address.length - 6, address.length)}`;
    }
    return address;
};

// We currently use fixed table height with a fixed number of items per page
const PAGE_SIZE = 25;
const INITIAL_PAGINATION_MODEL = { page: 0, pageSize: PAGE_SIZE };

/**
 * A general type of trade table, that includes any kind of trade including physical trades, forward
 * trades, and retirements. Also includes the option to show data in a format specific to forward
 * trades (by product item) that can display the forward ID
 * 
 * @param pageType           The type of page that will be displaying these trades. Affects the type
 *                           of data in the table (i.e. transactions or product items)
 * @param columnDefinitions  The set of columns that are available, along with whether each one should
 *                           be shown by default or not, and the order that they should be displayed in.
 * @param defaultOrdering    The default ordering that should apply to the table rows
 * // Filters which apply at the whole page level to restrict the data shown in the table. Cannot be changed.
 * @param product        Apply a page level filter on a specific product
 * @param projectType    Apply a page level filter on a specific project type
 * @param project        Apply a page level filter on a specific project
 * @param vintage        Apply a page level filter on a specific vintage
 * @param countryCode    Apply a page level filter on a specific country code
 * @param projectState   Apply a page level filter on a specific project state
 * @param valueDateStart Apply a page level filter on a specific value date start
 * @param valueDateEnd   Apply a page level filter on a specific value date end
 * @returns 
 */
const Trades = ({
    pageType,
    columnDefinitions,
    defaultOrdering,
    // Page level filters:
    product,
    projectType,
    project,
    vintage,
    countryCode,
    projectState,
    accreditationCode,
    fuelSource,
    generationYear,
    creationYear,
    generationState,
    greenPowerAccredited,
    valueDateStart,
    valueDateEnd
}: {
    pageType: TradePageType,
    columnDefinitions: TradesColumnDefinition[],
    defaultOrdering?: string,
    // Page level filters:
    product?: string,
    projectType?: string,
    project?: string,
    vintage?: string,
    countryCode?: string,
    projectState?: string,
    accreditationCode?: string,
    fuelSource?: string,
    generationYear?: string,
    creationYear?: string,
    generationState?: string,
    greenPowerAccredited?: string,
    valueDateStart?: string,
    valueDateEnd?: string,
}) => {

    const getInitialColumnVisibilityModel = () => {
        const visibiltyModel = columnDefinitions.reduce(function(map, obj) {
            map[obj.key] = obj.showByDefault;
            return map;
        }, {} as GridColumnVisibilityModel);
        const storageKey = getTableColumnsStorageItemKey(getTableNameForColumnVisibilityKey());
        const model = storageKey.getItem();
        try {
            return model ? JSON.parse(model) : visibiltyModel;
        } catch (error) {
            storageKey.setItem('');
            return visibiltyModel;
        }
    }

    /**
     * Get the table name component to be used in the column visibility model storage key.
     * If page type is TradePageType.Forwards, return {TradePageType.Forwards + productId}
     * Else return TradePageType
     * @returns 
     */
    const getTableNameForColumnVisibilityKey = () => {
        let tableName: string = pageType;
        if (pageType == TradePageType.Forwards && product) {
            tableName = `${pageType}-${product}`;
        }
        return tableName;
    };

    const [rows, setRows] = useState<TradeRow[]>([]);
    const abortController = useRef(new AbortController());
    const [rowCount, setRowCount] = useState<number>(0);
    const mapPageToNextCursor = useRef<{ [page: number]: string | undefined }>({});
    const [paginationModel, setPaginationModel] = useState(INITIAL_PAGINATION_MODEL);
    const [filterModel, setFilterModel] = useState({ items: [] } as GridFilterModel);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(getInitialColumnVisibilityModel());
    const [nextPage, setNextPage] = useState<any>(null);
    const [selectedRow, setSelectedRow] = useState<TradeRow | undefined>(undefined); // State to track selected row
    const [dialogActionsTaken, setDialogActionsTaken] = useState(false);
    const [forwardTradeState, setForwardTradeState] = useState<ForwardTradeState>(ForwardTradeState.Initialisation);
    const [pageLoading, setPageLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const appConfigState = useAppConfigState();
    const {productData} = useProductDataState();
    const user = useAuth();

    useEffect(() => {
        abortController.current.abort();
        abortController.current = new AbortController();
        fetchTransactions({abortSignal: abortController.current.signal}).then(() => {
            setPageLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!tableLoading && nextPage) {
            mapPageToNextCursor.current[paginationModel.page] = nextPage;
        }
    }, [tableLoading]);

    const handlePaginationModelChange = async (newPaginationModel: GridPaginationModel) => {
        if (tableLoading) {
            return; // Since we use block based pagination we cannot skip ahead to future pages, we need to wait for the current page to load
        }
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setTableLoading(true);
            abortController.current.abort();
            abortController.current = new AbortController();
            setPaginationModel(newPaginationModel);
            await fetchTransactions({
                nextPage: mapPageToNextCursor.current[newPaginationModel.page - 1],
                filterItems: filterModel.items,
                sortItems: sortModel,
                abortSignal: abortController.current.signal
            });
        }
    };

    const handleFilterModelChange = async (newFilterModel: GridFilterModel) => {
        const comparedFilterModel = getComparedFilterModel(filterModel, newFilterModel);
        setFilterModel(comparedFilterModel);
        // Reset the pagination model since we are now applying filter which will have its own pages
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        setTableLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchTransactions({
            filterItems: comparedFilterModel.items,
            sortItems: sortModel,
            abortSignal: abortController.current.signal
        });
    };

    const handleSortModelChange = async (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
        // Reset the pagination model due to applying a new sorting
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        setTableLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchTransactions({
            filterItems: filterModel.items,
            sortItems: newSortModel,
            abortSignal: abortController.current.signal
        });
    }

    const refreshTable = async () => {
        setPaginationModel(INITIAL_PAGINATION_MODEL);

        setTableLoading(true);
        abortController.current.abort();
        abortController.current = new AbortController();
        await fetchTransactions({
            filterItems: filterModel.items,
            sortItems: sortModel,
            abortSignal: abortController.current.signal
        });
    }

    /**
     * Function to adjust filters for certain sort fields. Since these fields
     * are optional, a lot of transactions will be missing values for these
     * fields. Corten places all of the empty fields in one end of the result
     * set when sorting. This makes viewing the results difficult .e.g searching
     * for the highest price. To remedy this problem, we add an additional
     * negation filter for these fields which has the effect of removing all
     * entries that have null values for these fields.
     *
     * @param {GridFilterItem[]} filterItems a list of filter items currently
     *  applied by the user
     * @param {GridSortModel} sortItems a list of sort items applied by the user
     * @param {string[]} pageFilters a list page filters that currently apply
     *
     * @returns {GridFilterItem[]} a list of filter items containing any additional
     *  filter items applied to adjust for sorting on optional fields
     */
    const adjustForSort = ({
        filterItems,
        sortItems,
        pageFilters
    }:{
        filterItems: GridFilterItem[],
        sortItems: GridSortModel,
        pageFilters: string[]
    }): GridFilterItem[] => {
        const modifiedFilterItems = [...filterItems];

        // Apply negation filters to remove nulls
        // However, if any table filters or page filters currently apply, we must skip applying
        // negation filters on these columns, as they aren't needed in this situation, since 
        // we already have a filter on the column (which already would ignore nulls).
        sortItems
            // Filter out undefined values
            .filter((item) => item.sort != null)
            // Skip applying negation filters on any columns that are currently used in table filters
            .filter((item) => !filterItems.filter(fi => fi.value).some(fi => fi.field === item.field))
            // Also skip applying negation filters if any page filters apply on the same columns
            .filter((item) => !pageFilters.some(pf => pf === item.field))
            .forEach((item) => {
                switch (item.field) {
                    case 'projectType':
                    case 'projectId':
                    case 'projectName':
                    case 'country':
                    case 'state':
                    case 'accreditationCode':
                    case 'fuelSource':
                    case 'generationState':
                    case 'greenPowerAccredited':
                    case 'currency':
                    case 'trader':
                    case 'brokerName':
                    case 'salesPerson':
                        modifiedFilterItems.push({field: item.field, value: '*', operator: 'equals'});
                        break;
                    case 'price':
                    case 'vintage':
                    case 'generationYear':
                    case 'creationYear':
                        modifiedFilterItems.push({field: item.field, value: 0, operator: '>'});
                        break;
                    case 'tradeDate':
                    case 'valueDate':
                        modifiedFilterItems.push({field: item.field, value: '*', operator: 'range'});
                        break;
                    case 'greenpowerAccredited':
                        modifiedFilterItems.push({field: item.field, value: BOOLEAN_NOT_NULL, operator: 'equals'});
                        break;
                }
            })

        return modifiedFilterItems;
    }

    interface GroupByField {
        attributeKey: string;
        attributeType: 'productItemAttribute' | 'transactionAttribute';
        group?: string;
    }

    const buildGroupByUri = (fields: GroupByField[]): string => {
        return fields.map(f =>
            `${f.attributeType}.${appConfigState.getAttribute(
                f.group !== undefined ? f.group : f.attributeType === 'productItemAttribute' ? 'PROJECT' : 'TRADE',
                f.attributeKey
            ).key}`
        ).join(",");
    }

    const fetchTransactions = async ({
        nextPage,
        filterItems = [],
        sortItems = [],
        abortSignal
    }: {
        nextPage?: string;
        filterItems?: GridFilterItem[];
        sortItems?: GridSortModel; // GridSortModel is an array of GridSortItems
        abortSignal: AbortSignal;
    }) => {
        const handlePageFilterWrapping = (unwrappedValue: string) => {
            return containsUndefinedPlaceholder(unwrappedValue) ? unwrappedValue : wrapFilterValueWithQuotes(unwrappedValue);
        }

        try {
            // Base url
            let url = '';
            let isProductItemApiQuery = false;
            if (pageType === TradePageType.TradeBlotter) {
                url = '/api/pub/history';
                // Filter for TransferRequest, BakeHoldingRequest, CreateProductItemRequest, and AssignHoldingRequest
                url += '?transactionType=TransferRequest,BakeHoldingRequest,CreateProductItemRequest,AssignHoldingRequest';
                url += '&unassignedAmount=0..' // Required to exclude 'Forward Delivered' rows from separate deflation / delete actions
            } else if (pageType === TradePageType.Forwards) {
                url = `/api/pub/productitems?productId=${product}&isUnassigned=true`;
                isProductItemApiQuery = true;
            }

            // Apply the filters from the table if any. Include the page filter on product if it applies
            const pageFilters = [
                valueDateStart !== undefined ? 'valueDate' : '',
                valueDateEnd !== undefined ? 'valueDate' : ''
            ]
            const filterUrl = buildFilterUri(adjustForSort({filterItems: filterItems, sortItems: sortItems, pageFilters}), product, appConfigState, isProductItemApiQuery);
            if (filterUrl) {
                url += `&${filterUrl}`;
            }

            if (!isProductItemApiQuery) {
                // Required to filter out CreateProductItemRequest from new physical issuance (Only apply if a filter does not already exist)
                if (!url.includes(`transactionAttribute%5B${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key}%5D=`)) {
                    url += encodeURI(`&transactionAttribute[${appConfigState.getAttribute('SHARED', 'TRANSACTION_TYPE_INVENTORY').key}]=${TransactionType.PhysicalBuy},${TransactionType.PhysicalSell},${TransactionType.ForwardSell},${TransactionType.ForwardSellDeliver},${TransactionType.InventoryRetirement},${TransactionType.ClientRetirement},${TransactionType.LockToMarket}`);
                }
            }

            // Apply page filters if relevant
            let attributeField = '';
            if (!isProductItemApiQuery) {
                attributeField = 'transactionAttribute'
            } else {
                attributeField = 'attributes'
            }
            
            if (projectType) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'TYPE').key}%5D=${handlePageFilterWrapping(projectType)}`;
            }
            if (project) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'ID').key}%5D=${handlePageFilterWrapping(project)}`;
            }
            if (vintage) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'VINTAGE').key}%5D=${handlePageFilterWrapping(vintage)}`;
            }
            if (countryCode) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key}%5D=${handlePageFilterWrapping(countryCode)}`;
            }
            if (projectState) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'STATE').key}%5D=${handlePageFilterWrapping(projectState)}`;
            }
            if (accreditationCode) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key}%5D=${handlePageFilterWrapping(accreditationCode)}`;
            }
            if (fuelSource) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key}%5D=${handlePageFilterWrapping(fuelSource)}`;
            }
            if (generationYear) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key}%5D=${handlePageFilterWrapping(generationYear)}`;
            }
            if (creationYear) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key}%5D=${handlePageFilterWrapping(creationYear)}`;
            }
            if (generationState) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key}%5D=${handlePageFilterWrapping(generationState)}`;
            }
            if (greenPowerAccredited) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key}%5D=${handlePageFilterWrapping(greenPowerAccredited)}`;
            }
            if (valueDateStart && valueDateEnd) {
                url += `&${attributeField}%5B${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}%5D=${valueDateStart}..${valueDateEnd}`
            }

            if (!isProductItemApiQuery) {
                // Attributes to group our response by
                const attributesToGroupBy = buildGroupByUri([
                    { attributeType: 'productItemAttribute', attributeKey: 'ID' },
                    { attributeType: 'productItemAttribute', attributeKey: 'NAME' },
                    { attributeType: 'productItemAttribute', attributeKey: 'VINTAGE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'COUNTRY_CODE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'STATE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'TYPE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'ACCREDITATION_CODE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'FUEL_SOURCE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'GENERATION_YEAR' },
                    { attributeType: 'productItemAttribute', attributeKey: 'CREATION_YEAR' },
                    { attributeType: 'productItemAttribute', attributeKey: 'GENERATION_STATE' },
                    { attributeType: 'productItemAttribute', attributeKey: 'GREENPOWER_ACCREDITED' },
                    { attributeType: 'transactionAttribute', attributeKey: 'TIMESTAMP' },
                    { attributeType: 'transactionAttribute', attributeKey: 'VALUE_DATE' },
                    { attributeType: 'transactionAttribute', attributeKey: 'CURRENCY' },
                    { attributeType: 'transactionAttribute', attributeKey: 'PRICE' },
                    { attributeType: 'transactionAttribute', attributeKey: 'TRADER_NAME' },
                    { attributeType: 'transactionAttribute', attributeKey: 'SALES_PERSON' },
                    { attributeType: 'transactionAttribute', attributeKey: 'BROKER_NAME' },
                    // Used to avoid merging the forward holding deflation with physical transfer for forward trade delivery.
                    // This works because forward delivery consists of 3 events for the transaction:
                    // (1) Physical inventory product items are transferred to the forward product item
                    // (2) The forward product item is deflated
                    // (3) The forward product item is deleted
                    // Events (1) and (2) can be grouped together by the /api/pub/history API if they have exactly the same attributes,
                    // since they are part of the same transaction, and their amounts cancel out (e.g. +3 vs -3). We avoid this situation
                    // by ensuring they have different attributes. This is done by adding a group by on the TRANSACTION_TYPE_INVENTORY
                    // attribute as a product item attribute. This attribute is always present on the forward trade product item, with
                    // the value 'ForwardSell'. However, we never set this attribute on the physical product item. When this attribute
                    // is part of the group by, it ensures that these two events are never merged and left out of the results.
                    { attributeType: 'productItemAttribute', attributeKey: 'TRANSACTION_TYPE_INVENTORY', group: 'SHARED' }
                ]);
                url += `&groupBy=${attributesToGroupBy},transaction`;
            }

            // Apply sorting from the table if any
            // Note: The /productitems API used by forwards doesn't seem to support sorts on attributes
            url += `&sort=${buildSortUri({
                items: sortItems,
                defaultSort: defaultOrdering,
                appConfigState: appConfigState,
                isProductItemApiQuery: isProductItemApiQuery
            })}`;

            // Query params related to pagination
            const pageFromQueryString: string = nextPage ? `&page.from=${nextPage}` : '';
            url += `&page.withCount=true${pageFromQueryString}&page.limit=${PAGE_SIZE}`;

            const response = await fetchWithCortenAndHandleAbort(url, {}, abortSignal, false);
            if (response === null) {
                return; // the request was aborted
            }
            const derivedRows: TradeRow[] = [];

            response.list.forEach((data: any) => {
                const newRow: TradeRow = transformToRow(data, isProductItemApiQuery);

                derivedRows.push(newRow);
            });

            setRows(derivedRows);
            setRowCount(response.count);
            setNextPage(response?.nextPage);
            setTableLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Function to calculate the weight of a particular value for transactions that involve
     * multiple different project vintage combinations. Currently only used for salesCredits
     * and Brokerage.
     *
     * @param totalValue The value to calculate weight for
     * @param weightedAmount The Amount to use for calculation of the weight
     * @param totalAmount
     *
     * @returns A weighted value of the totalValue based on the amounts or undefined
     */
    const getWeightedValue = ({
        totalvalue,
        weightedAmount,
        totalAmount
    }: {
        totalvalue: number | undefined;
        weightedAmount: number;
        totalAmount: number;
    }): number | undefined => {

        // If the total value in the request is already undefined to begin with then no need
        // to calculate anything
        if (totalvalue === undefined) {
            return totalvalue;
        }

        // Calculate the percentage weight of the value based on the amount associated with this
        // transfer vs the total amount in the transaction
        return (weightedAmount / totalAmount) * (totalvalue as number);
    };

    const transformToRow = (data: any, isProductItemApiQuery: boolean): TradeRow => {
        let productItemAttributes: any;
        let transactionAttributes: any;
        if (isProductItemApiQuery) {
            productItemAttributes = data.productItem.data.attributes;
            transactionAttributes = data.productItem.data.attributes;
        } else {
            productItemAttributes = data.productItemAttributes;
            transactionAttributes = data.transaction.request.attributes;
        }

        let quantity = 0;
        if (isProductItemApiQuery) {
            quantity = data.unassignedAmount;
        } else {
            quantity = data.balances.amount;
        }

        let tradeType: TransactionType | undefined;
        if (isProductItemApiQuery) {
            // Currently we only ever use product item queries to fetch forward sell trades.
            tradeType = TransactionType.ForwardSell;
        } else {
            switch (data.transaction.request.type) {
                case 'TransferRequest':
                    tradeType = data.transaction.request.fromAccountId === appConfigState.getAccount('INVENTORY_ISSUER').id ? TransactionType.PhysicalSell : TransactionType.PhysicalBuy;
                    break;
                case 'BakeHoldingRequest':
                    tradeType = data.transaction.request.fromAccountId === appConfigState.getAccount('INVENTORY_ISSUER').id ? TransactionType.InventoryRetirement : TransactionType.ClientRetirement;
                    break;
                case 'CreateProductItemRequest':
                    // All should be isUnassigned=True. False would be for physical creation rows, which should be filtered out above
                    tradeType = TransactionType.ForwardSell;
                    break;
                case 'AssignHoldingRequest':
                    tradeType = TransactionType.ForwardSellDeliver;
                    break;
            }
        }

        let transactionTimestamp = '';
        let tradeDate = '';
        let tradeId = '';
        let transactionId = '';
        let forwardId = '';
        let counterParty = '';
        let productId = '';
        let brokerage;
        let salesCredits;
        let currency = '';
        let valueDate = '';
        let price;
        let trader = '';
        let salesPerson = '';
        let brokerName = '';

        if (isTrade(tradeType)) {
            if (isProductItemApiQuery) {
                transactionTimestamp = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'TIMESTAMP').key];
                productId = data.productItem.data.productId;
                transactionId = data.productItem.productItemId;
                forwardId = data.productItem.productItemId;
            } else {
                transactionTimestamp = data.transaction.timestamp;
                productId = data.balances.productId;
                transactionId = data.transaction.txId;
                if (tradeType === TransactionType.ForwardSell) {
                    forwardId = data.transaction.blockAndIndex;
                }
            }
            tradeId = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'ID').key];

            counterParty = isProductItemApiQuery
                ? data.productItem.data.toAccountId
                : tradeType === TransactionType.ForwardSell
                ? data.transaction.request.toAccountId
                : tradeType === TransactionType.PhysicalSell
                ? data.transaction.request.outputList[0].toAccountId
                : data.transaction.request.fromAccountId;

            let amount = isProductItemApiQuery
                ? quantity
                : tradeType === TransactionType.ForwardSell
                ? data.transaction.request.initialAmount
                : tradeType === TransactionType.ForwardSellDeliver
                ? data.balances.amount
                : data.transaction.request.outputList[0].from.amount;

            brokerage = getWeightedValue({
                totalvalue:
                    transactionAttributes?.[appConfigState.getAttribute('TRADE', 'BROKERAGE_FEE').key],
                weightedAmount: quantity,
                totalAmount: parseFloat(amount)
            });

            salesCredits = getWeightedValue({
                totalvalue:
                    transactionAttributes?.[appConfigState.getAttribute('TRADE', 'SALES_CREDITS').key],
                weightedAmount: quantity,
                totalAmount: parseFloat(amount)
            });

            const maybeTradeDate = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'TIMESTAMP').key];
            if (maybeTradeDate) {
                tradeDate = maybeTradeDate;
            }

            currency = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'CURRENCY').key];
            valueDate = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'VALUE_DATE').key];
            price = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'PRICE').key];
            trader = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'TRADER_NAME').key];
            salesPerson = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'SALES_PERSON').key];
            brokerName = transactionAttributes?.[appConfigState.getAttribute('TRADE', 'BROKER_NAME').key];
        } else {
            productId = data.balances.productId
            transactionTimestamp = data.transaction.timestamp;
            transactionId = data.transaction.txId;
        }

        const counterpartyDisplay = appConfigState.getClientForAddress(counterParty) || shortenAddress(counterParty);

        return {
            transactionTimestamp: transactionTimestamp,
            tradeDate: tradeDate,
            tradeId: tradeId,
            transactionId: transactionId,
            forwardId: forwardId,
            tradeType: tradeType,
            counterParty: counterpartyDisplay,
            counterPartyToolTipText: counterParty,
            productId: productId,
            registry: productData.get(productId)?.attributes[appConfigState.getAttribute('REGISTRY', 'ID').key] || '',
            projectType: productItemAttributes[appConfigState.getAttribute('PROJECT', 'TYPE').key],
            projectId: productItemAttributes[appConfigState.getAttribute('PROJECT', 'ID').key],
            projectName: productItemAttributes[appConfigState.getAttribute('PROJECT', 'NAME').key],
            projectNameTruncated: truncateProjectName(productItemAttributes[appConfigState.getAttribute('PROJECT', 'NAME').key]),
            vintage: productItemAttributes[appConfigState.getAttribute('PROJECT', 'VINTAGE').key],
            country: productItemAttributes[appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key],
            state: productItemAttributes[appConfigState.getAttribute('PROJECT', 'STATE').key],
            accreditationCode: productItemAttributes[appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key],
            fuelSource: productItemAttributes[appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key],
            generationYear: productItemAttributes[appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key],
            creationYear: productItemAttributes[appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key],
            generationState: productItemAttributes[appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key],
            greenpowerAccredited: productItemAttributes[appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key],
            quantity: quantity,
            currency: currency,
            valueDate: valueDate,
            price: price,
            trader: trader,
            salesPerson: salesPerson,
            salesCredits: salesCredits,
            brokerName: brokerName,
            brokerage: brokerage
        };
    };

    const handleRowClick = (row: any) => {
        setSelectedRow(row); // Update the selected row state

        if (row.tradeType === TransactionType.ForwardSell) {
            checkForwardStatus(row);
        }
    };

    const checkForwardStatus = (row: any) => {
        setForwardTradeState(ForwardTradeState.CheckingProductItem);
        fetchTransactionsByProductItem(row.forwardId).then(forwardTransactions => {
            let transactionTypes = forwardTransactions.list.map((tx: any) => tx.request.type).filter((txType: string) => txType != 'CreateProductItemRequest');
            if (transactionTypes.length === 0) {
                setForwardTradeState(ForwardTradeState.AvailableForDeliveryOrCancelation);
            } else if (transactionTypes[0] === 'AssignHoldingRequest') {
                setForwardTradeState(ForwardTradeState.AlreadyDelivered);
            } else if (transactionTypes[0] === 'DeleteProductItemRequest') {
                setForwardTradeState(ForwardTradeState.AlreadyCanceled);
            }
        });
    }

    const handleCloseDialog = () => {
        setSelectedRow(undefined); // Close the dialog by resetting the selected row
        if (dialogActionsTaken) {
            // If any actions were taken on this trade in the dialog then refresh the table of data
            refreshTable();
        }
        setDialogActionsTaken(false);
        setForwardTradeState(ForwardTradeState.Initialisation);
    };

    const requestDeliverToForwardTrade = () => {
        setForwardTradeState(ForwardTradeState.ConfirmingToDeliver);
    }

    const requestCancelForwardTrade = () => {
        setForwardTradeState(ForwardTradeState.ConfirmingToCancel);
    }

    const actionsTakenWhenActingOnForwardTrade = () => {
        setDialogActionsTaken(true);
    }

    const generateExportFileName = () => {
        return `${pageType}${currentDateExportFormat()}`;
    };

    const filterAndOrderColumnsByDefinition = (columns: GridColDef<TradeRow>[]): GridColDef<TradeRow>[] => {
        let selectedColumns: GridColDef<TradeRow>[] = [];
        for (let columnDefinition of columnDefinitions) {
            let matchingColumn = columns.find(column => column.field === columnDefinition.key);
            if (matchingColumn !== undefined) {
                selectedColumns.push(matchingColumn);
            }
        }
        return selectedColumns;
    }

    const tradeTypeToDisplayTradeField: any = [
        TransactionType.PhysicalSell,
        TransactionType.PhysicalSell,
        TransactionType.ForwardBuy,
        TransactionType.ForwardSell,
    ];
    const isShowTrader = selectedRow?.trader
        ? true
        : Boolean(tradeTypeToDisplayTradeField.includes(selectedRow?.tradeType || ''));

    return (
        <>
            {
                pageLoading ?
                    <LinearProgress />
                    :
                    <div style={{width: '100%', height: 'auto', overflow: "auto", marginBottom: '5rem'}}>
                        <DataGrid
                            autoHeight={true}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{ toolbar: {
                                 csvOptions: { fileName: generateExportFileName() },
                                 printOptions: { disableToolbarButton: true },
                             } }}
                            rows={rows}
                            getRowId={row => `${row.transactionId}-${row.projectId}-${row.vintage}-${row.tradeType}-${row.accreditationCode}-${row.creationYear}`}
                            disableRowSelectionOnClick
                            getRowHeight={() => 'auto'}
                            columnHeaderHeight={100}
                            sx={{
                                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                            }}
                            loading={tableLoading}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => {
                                const storageKey = getTableColumnsStorageItemKey(getTableNameForColumnVisibilityKey());
                                storageKey.setItem(JSON.stringify(newModel));
                                setColumnVisibilityModel(newModel);
                            }}

                            // Pagination props
                            paginationMode="server"
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            pageSizeOptions={[PAGE_SIZE]}
                            rowCount={rowCount}

                            // Filter props
                            filterMode="server"
                            filterModel={filterModel}
                            onFilterModelChange={(newFilterModel) => {
                                handleFilterModelChange(newFilterModel);
                            }}

                            // Sort props
                            sortingMode='server'
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                handleSortModelChange(newSortModel)
                            }}

                            columns={filterAndOrderColumnsByDefinition([
                                {
                                    field: 'transactionTimestamp',
                                    headerName: 'Transaction Timestamp',
                                    minWidth: 220,
                                    flex: 1,
                                    type: 'dateTime',
                                    filterOperators:dateRangeFilterOperators({showTimeStamp:true}),
                                    valueGetter: ({ value }) => new Date(value),
                                    valueFormatter: ({ value }) => utcToLocalFormat(value)
                                },
                                {
                                    field: 'tradeDate',
                                    headerName: 'Trade Date',
                                    minWidth: 180,
                                    flex: 1,
                                    type: 'date',
                                    filterOperators:dateRangeFilterOperators({showTimeStamp:false}),
                                    valueGetter: ({ value }) => value && new Date(value),
                                    valueFormatter: ({ value }) => value && utcToLocalFormat(value, DATE_DISPLAY_FORMAT)
                                },
                                {
                                    field: 'tradeId',
                                    headerName: 'Trade ID',
                                    minWidth: 150,
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return (
                                            params.value ?
                                                <Tooltip title={params.value} arrow>
                                                    <Typography>{truncateTransactionId(params.value)}</Typography>
                                                </Tooltip>
                                            : <></>
                                        )
                                    }
                                },
                                {
                                    field: 'transactionId',
                                    headerName: 'Transaction ID',
                                    minWidth: 150,
                                    flex: 1,
                                    sortable: false,
                                    filterOperators: getTransactionIdFilterOperators(),
                                    renderCell: (params) => {
                                        return <Tooltip title={params.value} arrow>
                                            <Typography>{truncateTransactionId(params.value)}</Typography>
                                        </Tooltip>;
                                    }
                                },
                                {
                                    field: 'forwardId',
                                    headerName: 'Forward ID',
                                    minWidth: 150,
                                    flex: 1,
                                    sortable: false,
                                    filterable: false
                                },
                                {
                                    field: 'tradeType',
                                    headerName: 'Trade Type',
                                    minWidth: 150,
                                    flex: 1,
                                    type: 'singleSelect',
                                    sortable: false,
                                    valueOptions: [
                                        { value: TransactionType.PhysicalBuy, label: TransactionType.PhysicalBuy },
                                        { value: TransactionType.PhysicalSell, label: TransactionType.PhysicalSell },
                                        { value: TransactionType.ForwardSell, label: TransactionType.ForwardSell },
                                        { value: TransactionType.ForwardSellDeliver, label: TransactionType.ForwardSellDeliver },
                                        { value: TransactionType.ClientRetirement, label: TransactionType.ClientRetirement },
                                        { value: TransactionType.InventoryRetirement, label: TransactionType.InventoryRetirement },
                                    ]
                                },
                                {
                                    field: 'counterParty',
                                    headerName: 'Counterparty',
                                    minWidth: 160,
                                    flex: 1,
                                    type: 'singleSelect',
                                    sortable: false,
                                    filterable: pageType !== TradePageType.Forwards,
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) => operator.value !== 'not'
                                    ).map((operator) => {
                                        return {
                                            ...operator,
                                            getValueAsString: (value) => {
                                                let valueArray: string[] = [];
                                                if (!Array.isArray(value)) {
                                                    valueArray = [value];
                                                } else {
                                                    valueArray = value;
                                                }
                                                return appConfigState.getClients().
                                                    filter((data) => valueArray.includes(data.id))
                                                    .map(data => data.display).join(", ");
                                            },
                                        }
                                    }),
                                    renderCell: (params) => {
                                        return <Tooltip title={params.row.counterPartyToolTipText} arrow>
                                            <Typography>{params.value}</Typography>
                                        </Tooltip>
                                    },
                                    valueOptions: appConfigState.getClients().map((client) => ({
                                        value: client.id,
                                        label: client.display
                                    })),
                                    valueFormatter: ({ value }) => `${value}`
                                },
                                {
                                    field: 'productId',
                                    headerName: 'Product',
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'singleSelect',
                                    sortable: false,
                                    filterable: !product,
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) => operator.value !== 'not'
                                    ).map((operator) => {
                                        return {
                                            ...operator,
                                            getValueAsString: (value) => {
                                                let valueArray: string[] = [];
                                                if (!Array.isArray(value)) {
                                                    valueArray = [value];
                                                } else {
                                                    valueArray = value;
                                                }
                                                return appConfigState.getProducts(true).
                                                    filter((data) => valueArray.includes(data.id))
                                                    .map(data => data.displayCode).join(", ");
                                            },
                                        }
                                    }),
                                    valueOptions: appConfigState.getProducts(true).map((data) => ({
                                        value: data.id,
                                        label: data.displayCode
                                    }))
                                },
                                {
                                    field: 'registry',
                                    headerName: 'Registry',
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: false,
                                    sortable: false
                                },
                                {
                                    field: 'projectType',
                                    headerName: 'Project Type',
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !projectType,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'projectId',
                                    headerName: 'Project ID',
                                    minWidth: 150,
                                    flex: 1,
                                    filterable: !project,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'projectName',
                                    headerName: 'Project Name',
                                    minWidth: 200,
                                    flex: 1,
                                    filterable: !project,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return <Tooltip title={params.value} arrow>
                                            <Typography>{renderFieldValue(params.row.projectNameTruncated)}</Typography>
                                        </Tooltip>
                                    }
                                },
                                {
                                    field: 'vintage',
                                    headerName: 'Vintage',
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'number',
                                    filterable: !vintage,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({type:'Integer'})
                                    ],
                                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                                        return `${params.value}`;
                                    },
                                    renderCell: (params) => {
                                        return `${renderFieldValue(params.value)}`;
                                    }
                                },
                                {
                                    field: 'country',
                                    headerName: 'Country',
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: !countryCode,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'state',
                                    headerName: 'State',
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: !projectState,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'accreditationCode',
                                    headerName: 'Accreditation Code',
                                    minWidth: 160,
                                    flex: 1,
                                    filterable: !accreditationCode,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'fuelSource',
                                    headerName: 'Fuel Source',
                                    minWidth: 150,
                                    flex: 1,
                                    filterable: !fuelSource,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'creationYear',
                                    headerName: 'Creation Year',
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'number',
                                    filterable: !creationYear,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({type:'Integer'})
                                    ],
                                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                                        return `${params.value}`;
                                    },
                                    renderCell: (params) => {
                                        return `${renderFieldValue(params.value)}`;
                                    }
                                },
                                {
                                    field: 'generationYear',
                                    headerName: 'Generation Year',
                                    minWidth: 150,
                                    flex: 1,
                                    type: 'number',
                                    filterable: !generationYear,
                                    filterOperators: [
                                        ...extendedNumericFilterOperators,
                                        ...inputRangeFilterOperators({type:'Integer'})
                                    ],
                                    valueFormatter: (params: GridValueFormatterParams<number>) => {
                                        return `${params.value}`;
                                    },
                                    renderCell: (params) => {
                                        return `${renderFieldValue(params.value)}`;
                                    }
                                },
                                {
                                    field: 'generationState',
                                    headerName: 'Generation State',
                                    minWidth: 130,
                                    flex: 1,
                                    filterable: !generationState,
                                    filterOperators: stringFilterOperators,
                                    valueGetter: ({ value }) => renderFieldValue(value)
                                },
                                {
                                    field: 'greenpowerAccredited',
                                    headerName: 'GreenPower Accredited',
                                    minWidth: 150,
                                    filterable: !greenPowerAccredited,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    renderCell: (params: any) => {
                                        switch (params.row.greenpowerAccredited) {
                                            case true:
                                                return <FontAwesomeIcon icon={faCheck}/>;
                                            case false:
                                                return <FontAwesomeIcon icon={faXmark}/>;
                                            default:
                                                return '-';
                                        }
                                    },
                                    valueOptions: [
                                        { value: 'true', label: 'true' },
                                        { value: 'false', label: 'false' }
                                    ],
                                    valueFormatter: ({ value }) => value !== undefined ? `${value}` : '-'
                                },
                                {
                                    field: 'quantity',
                                    headerName: 'Quantity',
                                    minWidth: 160,
                                    flex: 1,
                                    type: 'number',
                                    filterable: pageType !== TradePageType.Forwards,
                                    // Multiple negation operation is not supported by Corten which is required for this field
                                    filterOperators: [
                                        ...extendedNumericFilterOperators.filter(
                                            (operator) => operator.value !== '!='
                                        ),
                                        ...inputRangeFilterOperators({type:'Integer'})
                                    ],
                                    renderCell: (params) => {
                                        return <AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={productData.get(params.row.productId)?.minDecimalPos!}
                                            maxDecimalPos={productData.get(params.row.productId)?.maxDecimalPos!}
                                        />
                                    }
                                },
                                {
                                    field: 'currency',
                                    headerName: 'Currency',
                                    minWidth: 130,
                                    flex: 1,
                                    type: 'singleSelect',
                                    filterOperators: getGridSingleSelectOperators().filter(
                                        (operator) =>
                                            operator.value !== 'not' && operator.value !== 'isAnyOf'
                                    ),
                                    valueOptions: [
                                        { value: 'AUD', label: 'AUD' },
                                        { value: 'USD', label: 'USD' }
                                    ]
                                },
                                {
                                    field: 'price',
                                    headerName: 'Price',
                                    minWidth: 120,
                                    flex: 1,
                                    type: 'number',
                                    filterOperators: [
                                        ...extendedNumericFloatFilterOperators,
                                        ...inputRangeFilterOperators({type:'Decimal'})
                                    ],
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'valueDate',
                                    headerName: 'Value Date',
                                    minWidth: 145,
                                    flex: 1,
                                    type: 'date',
                                    filterable: !valueDateStart && !valueDateEnd,
                                    filterOperators:dateRangeFilterOperators({showTimeStamp:false}),
                                    valueGetter: ({value}) => value && new Date(value),
                                    renderCell: (params) => {
                                        return <Typography>{params.value && utcToLocalFormat(new Date(params.value).toDateString(), DATE_DISPLAY_FORMAT)}</Typography>
                                    }
                                },
                                {
                                    field: 'trader',
                                    headerName: 'Trader',
                                    minWidth: 150,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'salesPerson',
                                    headerName: 'Sales Person',
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'salesCredits',
                                    headerName: 'Sales Credits',
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                                {
                                    field: 'brokerName',
                                    headerName: 'Broker Name',
                                    minWidth: 160,
                                    flex: 1,
                                    filterOperators: stringFilterOperators,
                                    renderCell: (params) => {
                                        return params.value !== undefined ?
                                            <Tooltip title={params.value} arrow>
                                                <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}>{truncateHumanName(params.value)}</Typography>
                                            </Tooltip>
                                            :
                                            <Typography sx={{ minWidth: '150px', maxWidth: '180px' }}></Typography>
                                    }
                                },
                                {
                                    field: 'brokerage',
                                    headerName: 'Brokerage',
                                    minWidth: 140,
                                    flex: 1,
                                    type: 'number',
                                    sortable: false,
                                    filterable: false,
                                    renderCell: (params) => {
                                        return <>{params.value && (<AmountFormatWrapper
                                            amount={params.value}
                                            minDecimalPos={2}
                                            maxDecimalPos={2}
                                        />)}</>
                                    }
                                },
                            ])}
                            density="compact"
                            disableDensitySelector
                            onRowClick={params => handleRowClick(params.row)}
                        />

                    </div>
            }
            {selectedRow && forwardTradeState === ForwardTradeState.ConfirmingToDeliver && (
                <ForwardTradeDeliverDialog
                    dialogActive={true}
                    forwardTradeRow={selectedRow!}
                    onActionTaken={actionsTakenWhenActingOnForwardTrade}
                    onClose={handleCloseDialog}
                />
            )}
            {selectedRow && forwardTradeState === ForwardTradeState.ConfirmingToCancel && (
                <ForwardTradeCancelDialog
                    dialogActive={true}
                    forwardTradeRow={selectedRow!}
                    onActionTaken={actionsTakenWhenActingOnForwardTrade}
                    onClose={handleCloseDialog}
                />
            )}
            {selectedRow && forwardTradeState !== ForwardTradeState.ConfirmingToDeliver && forwardTradeState !== ForwardTradeState.ConfirmingToCancel && (
                <TransactionOverview
                    open={!!selectedRow}
                    onClose={handleCloseDialog}
                    title="Transaction Details"
                    actions={
                        selectedRow!.tradeType !== TransactionType.ForwardSell ? []
                        : forwardTradeState === ForwardTradeState.Initialisation ? []
                        : forwardTradeState === ForwardTradeState.CheckingProductItem ? [{
                            actionType: ActionType.Info,
                            label: "Checking forward status..."
                        }]
                        : forwardTradeState === ForwardTradeState.AlreadyDelivered ? [{
                            actionType: ActionType.Info,
                            label: "This forward has been delivered"
                        }]
                        : forwardTradeState === ForwardTradeState.AlreadyCanceled ? [{
                            actionType: ActionType.Info,
                            label: "This forward has been canceled"
                        }]
                        : forwardTradeState === ForwardTradeState.AvailableForDeliveryOrCancelation ? [{
                            actionType: ActionType.Button,
                            label: "Deliver Units",
                            action: requestDeliverToForwardTrade
                        },
                        {
                            actionType: ActionType.Button,
                            label: "Cancel Forward",
                            action: requestCancelForwardTrade
                        }]
                        : []
                    }
                    

                    attributes={{
                        ...(pageType !== TradePageType.Forwards) ? {'Transaction Timestamp': {
                            value: utcToLocalFormat(selectedRow!.transactionTimestamp),
                            key:'transactionTimestamp'
                        }} : {},
                        ...(selectedRow?.tradeDate && {
                            'Trade Date': {
                                value: selectedRow!.tradeDate && utcToLocalFormat(selectedRow!.tradeDate, DATE_DISPLAY_FORMAT),
                                key:'tradeDate'
                            },
                        }),
                        ...(selectedRow?.tradeId && {'Trade ID': { shorterFormId: true, value: selectedRow!.tradeId, key:'tradeId' },}),
                        ...pageType === TradePageType.Forwards ? {
                            'Forward ID': {
                                shorterFormId: true,
                                value: selectedRow!.forwardId,
                                key:'forwardId'
                            }
                        } : {
                            'Transaction ID': {
                                shorterFormId: true,
                                value: selectedRow!.transactionId,
                                key:'transactionId'
                            }
                        },
                        'Trade Type': { value: selectedRow!.tradeType, key:'tradeType' },
                        ...(selectedRow?.counterParty && {
                            Counterparty: { value: selectedRow!.counterParty, key:'counterParty' },
                        }),
                        'Product Code': { value: appConfigState.getProduct(selectedRow!.productId)?.displayCode, key:'productId' },
                        Registry: { value: selectedRow!.registry, key:'registry' },
                        'Project Type': { value: renderFieldValue(selectedRow!.projectType), key:'projectType' },
                        'Project ID': { value: renderFieldValue(selectedRow!.projectId), key:'projectId' },
                        'Project Name': { value: renderFieldValue(selectedRow!.projectName), key:'projectName' },
                        'Vintage': { value: renderFieldValue(selectedRow!.vintage).toString(), key:'vintage' },
                        'Country': { value: renderFieldValue(selectedRow!.country), key:'country' },
                        'State': { value: renderFieldValue(selectedRow!.state), key:'state' },
                        'Accreditation Code': { value: renderFieldValue(selectedRow!.accreditationCode) , key:'accreditationCode' },
                        'Fuel Source': { value: renderFieldValue(selectedRow!.fuelSource), key:'fuelSource' },
                        'Generation Year': { value: renderFieldValue(selectedRow!.generationYear).toString(), key:'generationYear' },
                        'Creation Year': { value: renderFieldValue(selectedRow!.creationYear).toString(), key:'creationYear' },
                        'Generation State': { value: renderFieldValue(selectedRow!.generationState), key:'generationState' },
                        'GreenPower Accredited': { value: renderFieldValue(selectedRow!.greenpowerAccredited).toString(), key:'greenpowerAccredited' },
                        Quantity: {
                            value: selectedRow!.quantity.toString(),
                            key:'quantity',
                            renderProp: { 
                                minDecimalPos: productData.get(selectedRow!.productId)?.minDecimalPos!, 
                                maxDecimalPos: productData.get(selectedRow!.productId)?.maxDecimalPos! 
                            }
                        },
                        Currency: { value: selectedRow!.currency, key:'currency' },
                        Price: {
                            value: selectedRow!.price?.toString(),
                            key:'price',
                            renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }
                        },
                        'Value Date': { value: selectedRow!.valueDate && utcToLocalFormat(new Date(selectedRow!.valueDate).toDateString(), DATE_DISPLAY_FORMAT), key:'valueDate'},   

                        ...(isShowTrader && {
                            Trader: { value: selectedRow!.trader?.toString(), key: 'trader' }
                        }),

                        'Sales Person': { value: selectedRow!.salesPerson?.toString(), key:'salesPerson' },
                        'Sales Credits': {
                            value: selectedRow!.salesCredits?.toString(),
                            key:'salesCredits',
                            renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }
                        },
                        'Broker Name': { value: selectedRow!.brokerName?.toString(), key:'brokerName' },
                        Brokerage: {
                            value: selectedRow!.brokerage?.toString(),
                            key:'brokerage',
                            renderProp: { minDecimalPos: 2, maxDecimalPos: 2 }
                        }
                    }}

                    
                />
            )}
        </>
    );
};

export { Trades, type TradeRow, TradePageType, type TradesColumnDefinition, ForwardTradeState };
