import { IconButton, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Add, Clear } from '@mui/icons-material';
import { useLayoutState } from '../../../state/Layout';
import { DraftsMetadata } from './FormDraftsModel';

const FormDrafts = (
    { draftsMetadata, onChange, onDelete }: {
        draftsMetadata: DraftsMetadata | undefined,
        onChange: (event: any, value: string) => void,
        onDelete: (value: string) => void,
    },
) => {
    const { customTheme } = useLayoutState();

    const renderDraftTab = (id: string, name: string) => {
        let labelElement =
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>{name}</Typography>
                <IconButton
                    color='primary'
                    size='small'
                    tabIndex={-1} // prevent highlighting via Tab button
                    component='span' // nested buttons are not allowed, so use a different component
                    onMouseDown={(event) => {
                        // stop the ripple animation from propagating to the tab itself
                        event.stopPropagation();
                    }}
                    onClick={(event) => {
                        // stop clicks from propagating to the tab itself
                        event.stopPropagation();
                        onDelete(id);
                    }}
                    sx={{
                        width: 20,
                        height: 20,
                        marginLeft: 1.5,
                        marginTop: -0.25,
                        marginRight: -0.5,
                        '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
                    }}>
                    <Clear color={id === draftsMetadata?.currentDraftId ? 'primary' : 'secondary'}
                           sx={{ fontSize: 20 }} />
                </IconButton>
            </div>;

        return (
            <Tab label={labelElement}
                 value={id}
                 key={id}
                 sx={{
                     color: customTheme.theme.palette.secondary.main,
                     transition: customTheme.customProps.navHoverTransition,
                     '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
                 }} />
        );
    };

    const renderAddDraftTab = () => {
        let id = 'ADD';
        return (
            <Tab label={<Add />}
                 value={id}
                 key={id}
                 sx={{
                     width: 48,
                     minWidth: 48,
                     color: customTheme.theme.palette.primary.main,
                     transition: customTheme.customProps.navHoverTransition,
                     '&:hover': { backgroundColor: customTheme.customProps.navHoverBackgroundColor },
                 }} />
        );
    };

    return (
        <Tabs value={draftsMetadata?.currentDraftId}
              onChange={onChange}
              variant={'scrollable'}
              scrollButtons
              sx={{ width: '729px' }}>
            {renderAddDraftTab()}
            {draftsMetadata?.drafts.map(({ id, name }) => renderDraftTab(id, name))}
        </Tabs>
    );
};

export { FormDrafts };
