import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { useAppConfigState } from '../../../state/AppConfig';
import { executeTransaction, fetchWithCorten } from '../../../state/CortenClient';
import { useAuth } from '../../../state/AuthProvider';
import { EMISSION_ASSET_AMOUNT } from '../../../state/Variables';

interface EmissionsForm {
    emissionName: string;
    emissionIntensity: string;
    document?: any;
}

const defaultEmissionsForm = {
    emissionName: '',
    emissionIntensity: ''
};

interface EmissionDisplay {
    id: string;
    name: string;
    intensity: string;
    document?: any;
}

interface CarbonProductDisplay {
    id: string;
    displayCode: string;
}

enum DialogState {
    FORM,
    REVIEW,
    SUCCESS,
    ERROR
}

enum EmissionTableType {
    PENDING = 'pending',
    DECARBONISED = 'offset'
}

const TabPaths = [
    {
        label: EmissionTableType.PENDING,
        pathName: EmissionTableType.PENDING
    },
    {
        label: EmissionTableType.DECARBONISED,
        pathName: EmissionTableType.DECARBONISED
    }
];

interface EmissionsStateType {
    isEmissionsFormOpen: boolean;
    handleEmissionsFormOpen: () => void;
    emissionPendingItems: EmissionDisplay[];
    submitEmissionForm: (data: any) => void;
    fetchItemsForEmissionAsset: () => void;
    loadingTable: boolean;
    formData: EmissionsForm;
    uploadFile: ({ target }: any) => void;
    uploadError: boolean;
    dialogState: DialogState;
    performIssueEmissionAsset: () => void;
    requesting: boolean;
    createEmissionsTableDisplay: (combinedProductItems: any) => void;
    carbonProduct: CarbonProductDisplay[];
    getCarbonProductData: () => void;
    emissionProductId: string;
}

interface EmissionsDispatchType {
    setIsEmissionsFormOpen: Dispatch<SetStateAction<boolean>>;
    setFormData: Dispatch<SetStateAction<EmissionsForm>>;
    setUploadError: Dispatch<SetStateAction<boolean>>;
    setDialogState: Dispatch<SetStateAction<DialogState>>;
    setLoadingTable: Dispatch<SetStateAction<boolean>>;
}

const EmissionsStateContext = createContext<EmissionsStateType | null>(null);
const EmissionsDispatchContext = createContext<EmissionsDispatchType | null>(null);

const EmissionsProvider = ({ children }: { children?: ReactNode }) => {
    const [isEmissionsFormOpen, setIsEmissionsFormOpen] = useState(false);
    const [emissionPendingItems, setEmissionPendingItems] = useState<EmissionDisplay[]>([]);
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [formData, setFormData] = useState<EmissionsForm>(defaultEmissionsForm);
    const [uploadError, setUploadError] = useState<boolean>(false);
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.FORM);
    const [requesting, setRequesting] = useState<boolean>(false);
    const [carbonProduct, setCarbonProduct] = useState<CarbonProductDisplay[]>([
        { id: '', displayCode: '' }
    ]);

    const appConfigState = useAppConfigState();
    const user = useAuth();
    const emissionProductId = appConfigState.getEmissionProductId('EMISSION_ASSET');

    // get display code and details of all carbon products
    const getCarbonProductData = () => {
        const carbonProducts: any[] = appConfigState.getCarbonProducts();

        let display: any[] = [];

        for (const carbonProduct of carbonProducts) {
            // if the regular Product ID matches the carbon product ID, use its display code
            if (appConfigState.getProduct(carbonProduct.id)) {
                display.push({
                    id: carbonProduct.id,
                    displayCode: appConfigState.getProduct(carbonProduct.id)!.displayCode ?? ''
                });
            }
        }

        setCarbonProduct(display);
    };

    // open form
    const handleEmissionsFormOpen = () => {
        setIsEmissionsFormOpen(true);
    };

    // fetch UNSPENT product items for emission product
    const fetchItemsForEmissionAsset = async () => {
        const combinedProductItems = await fetchUnspentHoldings();

        // create a display format from emission response data
        if (combinedProductItems && combinedProductItems.length > 0) {
            const displayFormat = createEmissionsTableDisplay(combinedProductItems);

            setEmissionPendingItems(displayFormat);
        } else {
            setEmissionPendingItems([]);
        }
        setLoadingTable(false);
    };

    // request for UNSPENT emission product items
    const fetchUnspentHoldings = async () => {
        // clear next page
        let nextPageItems = undefined;
        // clear accumulated product Item batches
        let combinedProductItems = [];

        const options = {
            emissionState: 'Unspent',
            isProductItemDataIncluded: true,
            method: 'GET'
        };

        try {
            do {
                const pageFromQueryString: string = nextPageItems
                    ? `&page.from=${nextPageItems}`
                    : '';

                const url =
                    `/api/pub/holdings/spent?productId=${emissionProductId}&state=${options.emissionState}&includeProductItemData=${options.isProductItemDataIncluded}` +
                    `${pageFromQueryString}`;

                const response = await fetchWithCorten(url, { method: options.method });

                if (!response.ok) {
                    throw new Error('Failed to fetch product items');
                }

                const productItems = await response.json();

                // combine all batches
                combinedProductItems.push(...productItems.list);

                // set next batch page
                nextPageItems = productItems.nextPage;
            } while (nextPageItems);

            return combinedProductItems;
        } catch (error) {
            console.log('error', error);
        }
    };

    // display format for emissions table
    const createEmissionsTableDisplay = (combinedProductItems: any) => {
        let displayFormat: EmissionDisplay[] = [];
        for (const item of combinedProductItems) {
            displayFormat.push({
                id: item.productItemId,
                name: item.productItem.data.attributes[
                    appConfigState.getAttribute('EMISSION_ASSET', 'NAME').key
                ],
                intensity:
                    item.productItem.data.attributes[
                        appConfigState.getAttribute('EMISSION_ASSET', 'INTENSITY').key
                    ],
                document: item.productItem.data.attributes[
                    appConfigState.getAttribute('EMISSION_ASSET', 'DOCUMENT').key
                ]
                    ? item.productItem.data.attributes[
                          appConfigState.getAttribute('EMISSION_ASSET', 'DOCUMENT').key
                      ].url
                    : undefined
            });
        }

        return displayFormat;
    };

    // upload document to form
    const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // upload file here
        const file = event.target.files?.[0];

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const url = `/api/issuer/${
                    appConfigState.getAccount('INVENTORY_ISSUER').id
                }/upload`;

                const options = {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${user.user.access_token}`
                    },
                    body: formData
                };

                const response = await fetchWithCorten(url, options);

                if (!response.ok) {
                    throw new Error('Failed to upload the document');
                }

                const data = await response.json();

                setFormData((prevState) => ({
                    ...prevState,
                    document: data
                }));
            } catch (error) {
                // failed to upload the document
                console.error(error);
                // display error
                setUploadError(true);
            }
        }
    };

    /**
     * set review dialog
     *
     * @param data value of each input coming from react hook form controller
     */
    const submitEmissionForm = async (data: any) => {
        // update state
        setFormData((prevState) => ({
            ...prevState,
            emissionName: data.emissionName,
            emissionIntensity: data.emissionIntensity
        }));

        // update dialog view
        setDialogState(DialogState.REVIEW);
    };

    // POST request to create product item
    const performIssueEmissionAsset = async () => {
        setRequesting(true);

        const unsignedRequestBody = {
            type: 'CreateProductItemRequest',
            issuerId: appConfigState.getAccount('INVENTORY_ISSUER').id,
            productId: emissionProductId,
            canInflate: false,
            canFractionalize: false,
            unitAmount: '1',
            initialAmount: EMISSION_ASSET_AMOUNT,
            attributes: {
                [`${appConfigState.getAttribute('EMISSION_ASSET', 'NAME').key}`]:
                    formData.emissionName,
                [`${appConfigState.getAttribute('EMISSION_ASSET', 'INTENSITY').key}`]:
                    formData.emissionIntensity
            }
        };

        // include the optional document if one has been uploaded
        if (formData.document) {
            unsignedRequestBody.attributes[
                `${appConfigState.getAttribute('EMISSION_ASSET', 'DOCUMENT').key}`
            ] = formData.document;
        }

        try {
            let response = await executeTransaction(unsignedRequestBody, user);

            if (!response.ok) {
                throw new Error('Failed to perform request');
            }

            // show success dialog
            setDialogState(DialogState.SUCCESS);
        } catch (error) {
            console.error(error);

            // show error dialog
            setDialogState(DialogState.ERROR);
        }

        setRequesting(false);
    };

    return (
        <EmissionsStateContext.Provider
            value={{
                isEmissionsFormOpen,
                handleEmissionsFormOpen,
                emissionPendingItems,
                submitEmissionForm,
                fetchItemsForEmissionAsset,
                loadingTable,
                formData,
                uploadFile,
                uploadError,
                dialogState,
                performIssueEmissionAsset,
                requesting,
                createEmissionsTableDisplay,
                getCarbonProductData,
                carbonProduct,
                emissionProductId
            }}
        >
            <EmissionsDispatchContext.Provider
                value={{
                    setIsEmissionsFormOpen,
                    setFormData,
                    setUploadError,
                    setDialogState,
                    setLoadingTable
                }}
            >
                {children}
            </EmissionsDispatchContext.Provider>
        </EmissionsStateContext.Provider>
    );
};

function useEmissionsState() {
    const context = useContext(EmissionsStateContext);
    if (!context) {
        throw new Error('no provider for useEmissionsState');
    }
    return context;
}

function useEmissionsDispatch() {
    const context = useContext(EmissionsDispatchContext);
    if (!context) {
        throw new Error('no provider for useEmissionsDispatch');
    }
    return context;
}

export {
    EmissionsProvider,
    useEmissionsState,
    useEmissionsDispatch,
    defaultEmissionsForm,
    DialogState,
    EmissionTableType,
    type EmissionDisplay,
    TabPaths
};
