import { AggregatorTableProps } from './AggregatorTable';

/**
 * Header Values will be a map where the key represents the attribute name and the value
 * is a set of all unique values of the attributes e.g.
 *
 *  Map {"project_type" => Set {"WASTE", "AGRICULTURE", "VEGETATION", "SAVANNA_BURNING"}}
 * */
type HeaderValues = Map<string, Set<any>>;

// Seperator for header fields to separate the different types of attribute values
const HEADER_SEPERATOR = ' | ';

/**
 * Aggregator input values after they are processed
 * @see processAggregatorInput function for details
 */
interface ProcessedAggregatorInputs {
    rowHeaders: Set<string>;
    colHeaders: Set<string>;
}

/**
 * Data that has been processed for and is awaiting convertion to Aggregator Table props
 * @see processData function for details
 */
interface ProcessedData {
    dataPoints: Map<string, any>;
    rowHeaderValues: HeaderValues;
    colHeaderValues: HeaderValues;
}

/**
 * Take the raw inputs from the user and process them so that there is a set of attribute names
 * @param {string[]} rowHeaders an array of string representing the row headers
 * @param {string[]} colHeaders an array of string representing the column headers
 * @returns @see ProcessedAggregatorInputs
 */
const processAggregatorInput = (
    rowHeaders: string[],
    colHeaders: string[]
): ProcessedAggregatorInputs => {
    return {
        rowHeaders: new Set(rowHeaders),
        colHeaders: new Set(colHeaders)
    };
};

/**
 * Function that will take the response from corten and processed inputs from the user
 * and structure it in a way so that it can be used to generate props for the
 * AggregatorTable component
 *
 * @param data The response from corten to the product attribute aggregation API
 * @param inputs the processed set of inputs from the user
 * @returns ProcessedData
 */
const processData = (data: any, inputs: ProcessedAggregatorInputs): ProcessedData => {
    /**
     * The Data Points will be a mapping of a attribute value combination to the corresponding
     * balance amount. eg.
     *
     *  Map {"2022 | EOP100105 | WASTE" => "360110", "2022 | AGRICULTURE | EOP100112" => "451", …}
     */
    let dataPoints: Map<string, any> = new Map();
    let rowHeaderValues: HeaderValues = new Map();
    let colHeaderValues: HeaderValues = new Map();

    data.list.forEach((d: any) => {
        /**
         * Iterate through each item in the response. Get all the attributes values for the item,
         * combine it and put a mapping it for it with the value being the issuerAmount. e.g.
         *  For the following set of attributes
         *    "attributes": {
         *        "project_type": "WASTE",
         *        "project_id": "EOP100105",
         *        "project_vintage": 2022
         *    },
         *    "balances": {
         *        "unassignedAmount": "0",
         *        "assignedAmount": "7",
         *        "issuerAmount": "360110",
         *        "escrowAmount": "0"
         *    }
         *
         *  We will end up with
         *    "2022 | EOP100105 | WASTE" => "360110"
         */
        dataPoints.set(combineHeaders(Object.values(d.attributes)), d.balances.issuerAmount);

        // Convert the value of the attributes property to a Map
        const attributeMap = new Map(Object.entries(d.attributes));

        // Iterate through the Map and sort the attribute name and their corresponding values into
        // either the row or the column headers. See documentation on HeaderValues type alias for
        // example on how they would look like
        attributeMap.forEach((value: any, key: string) => {
            if (inputs.colHeaders.has(key)) {
                if (colHeaderValues.has(key)) {
                    colHeaderValues.get(key)?.add(value);
                } else {
                    colHeaderValues.set(key, new Set([value]));
                }
            } else if (inputs.rowHeaders.has(key)) {
                if (rowHeaderValues.has(key)) {
                    rowHeaderValues.get(key)?.add(value);
                } else {
                    rowHeaderValues.set(key, new Set([value]));
                }
            } else {
                console.error(
                    `Encountered attribute that is not part of the rows or columns: '${key}'`
                );
            }
        });
    });

    return {
        dataPoints: dataPoints,
        rowHeaderValues: rowHeaderValues,
        colHeaderValues: colHeaderValues
    };
};

/**
 * Combine an array of headers by first sorting it then joining them using the @see HEADER_SEPERATOR
 * This will help ensure that we have deterministic results regradless of the order of the
 * header values supplied
 *
 * @param headers an array of header values
 * @returns a joined string of the header values
 */
const combineHeaders = (headers: string[]): string => {
    return headers.sort().join(HEADER_SEPERATOR);
};

/**
 * Split the joined headers back
 *
 * @param combined the joined headers string
 * @returns an array of the individual headers
 */
const splitHeaders = (combined: string): string[] => {
    return combined.split(HEADER_SEPERATOR);
};

/**
 * Derive an array of header field value based on the supplied HeaderValues such that there is
 * a unique combination of atribute values for each type which will ultimately be used for the
 * table row and column headers. e.g.
 *
 *  for the headerValues below
 *    Map {"project_id" => Set {"EOP100105", "EOP100112", "EOP100580", "EOP100944", "EOP100959", …}, "project_vintage" => Set {2022, 2021}}
 *
 *  we will get
 *  [
 *      "EOP100105 | 2022",
 *      "EOP100105 | 2021",
 *      "EOP100112 | 2022",
 *      "EOP100112 | 2021",
 *      "EOP100580 | 2022",
 *      "EOP100580 | 2021",
 *      "EOP100944 | 2022",
 *      "EOP100944 | 2021",
 *      "EOP100959 | 2022",
 *      "EOP100959 | 2021",
 *      "ERF102090 | 2022",
 *      "ERF102090 | 2021"
 *  ]
 *
 * @param headerValues
 * @returns
 */
const getHeaderPermutations = (headerValues: HeaderValues): string[] => {
    // Helper function to compute permutations
    function getPermutations(arr: string[][]): string[] {
        if (arr.length === 0) return [''];

        const result: string[] = [];
        const rest = getPermutations(arr.slice(1));

        for (const value of arr[0]) {
            for (const permutation of rest) {
                result.push(`${value}${permutation ? HEADER_SEPERATOR + permutation : ''}`);
            }
        }

        return result;
    }

    const attributeValues = Array.from(headerValues.values()).map((set) => Array.from(set));

    const permutations = getPermutations(attributeValues);

    return permutations.map((permutation) =>
        permutation.split(HEADER_SEPERATOR).join(HEADER_SEPERATOR)
    );
};

/**
 * Get the value to render at a cell for a given row and column
 *
 * @param processedData The dataset that will contain the value
 * @param rowHeader the row for cell
 * @param columnHeader the column for the cell
 * @returns the value
 */
const getValueForCell = (
    processedData: ProcessedData,
    rowHeader: string,
    columnHeader: string
): any => {
    const rowHeaderValues = splitHeaders(rowHeader);
    const columnHeaderValues = splitHeaders(columnHeader);
    const combinedHeader = combineHeaders([...rowHeaderValues, ...columnHeaderValues]);

    return processedData.dataPoints.get(combinedHeader);
};

/**
 * Convert the ProcessedData into AggregatorTableProps that can be fed into the AggregatorTable
 * component. @see AggregatorTableProps documentation for details on how data values correspond
 * to the table values
 *
 * @param processedData @see ProcessedData
 * @returns @see AggregatorTableProps
 */
const convertToAggregatorTableProps = (processedData: ProcessedData): AggregatorTableProps => {
    const columnsHeaders = getHeaderPermutations(processedData.colHeaderValues);
    const rowsHeaders = getHeaderPermutations(processedData.rowHeaderValues);

    const data: { [key: string]: any }[] = [];

    rowsHeaders.forEach((r) => {
        const row = new Map();
        row.set('rowHeader', r);

        columnsHeaders.forEach((c) => {
            const cellValue = getValueForCell(processedData, r, c);
            row.set(c, cellValue);
        });

        data.push(row);
    });

    return {
        data: data,
        columns: columnsHeaders
    };
};

export { processAggregatorInput, processData, convertToAggregatorTableProps };
