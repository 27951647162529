import dayjs, { Dayjs } from 'dayjs';

const DRAFTS_MODEL_VERSION = '0';

interface DraftData {
    version: string,
}

interface Draft<T extends DraftData> {
    id: string,
    data: T,
    modifiedDate: Dayjs,
}

interface Drafts<T extends DraftData> {
    version: string,
    list: Draft<T>[],
    currentDraftId: string,
}

interface DraftsMetadata {
    drafts: { id: string, name: string }[],
    currentDraftId: string,
}

const draftsReviver = (key: string, value: any) => {
    if (key.endsWith('Date')) {
        return dayjs(value);
    } else {
        return value;
    }
};

export { DRAFTS_MODEL_VERSION, type DraftData, type Draft, type Drafts, type DraftsMetadata, draftsReviver };
