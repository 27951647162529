/**
 * This file holds the main UI layout for the main viewport and collapsable side menu
 */
import { Box, Drawer, LinearProgress } from '@mui/material';
import SideDrawer from './SideDrawer';
import { navBarHeight, drawerWidth, useLayoutState, DrawerHeader, Main } from '../../state/Layout';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { useAuth } from '../../state/AuthProvider';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

const MainView = () => {
    const { user, isTokenExpired } = useAuth();

    const layoutState = useLayoutState();
    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', marginTop: navBarHeight }}>
            {/* Collapsable side menu ***********/}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box'
                    }
                }}
                variant="persistent"
                anchor="left"
                open={layoutState.open}
            >
                <DrawerHeader>
                    <IconButton onClick={layoutState.handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {/******** 'SideDrawer' holds all collapsable side menu contents */}
                <SideDrawer />
            </Drawer>

            {/********* 'Main' holds all main viewport content/ views ***********************/}
            <Main open={layoutState.open}>
                {(!user || isTokenExpired(user)) && (
                    /* Show a Linear Progress Bar while the user is being signed in or redirected to login page */
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                )}
                <Outlet />
            </Main>

            <ToastContainer
                    position='bottom-center'
                    autoClose={3_000}
                    newestOnTop
                    hideProgressBar
                    theme='dark'
                    style={{ width: 'auto', maxWidth: '600px' }}
                />
        </Box>
    );
};

export default MainView;
