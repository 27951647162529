import { useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, LinearProgress, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';
import { AppConfigContextType, ProductBase, useAppConfigState } from '../../state/AppConfig';
import { fetchBalances, fetchByCriteria, ProductCriteria, AttributeCriteria } from '../../utility/Fetch';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { currentDateExportFormat } from '../../utility/utcToLocalFormat';
import { homepage } from '../../component/AppNavigate';
import { ExportToCsv } from 'export-to-csv';
import { useLayoutState } from '../../state/Layout';
import { CustomTheme } from '../../Themes';
import { useProductDataState } from '../../state/ProductDataProvider';
import dayjs from 'dayjs';
import { UNDEFINED_CODE_STRING, UNDEFINED_CODE_INTEGER, UNCLASSIFIED_LABEL, containsUndefinedPlaceholder } from '../trade/TradeUtil'
import { wrapFilterValueWithQuotes } from '../../utility/CoreFilterUtil';

/**
 * Each {@link DeltaLevel} instance represents a distinct row in the Trading Risk table.
 * The rows correspond to specific risk periods, like 'Physical', '1M', '2M', etc.
 *
 * @privateRemarks
 * The class also holds a static array of these instances, enabling access to all defined rows.
 * Essentially, we are emulating a Java enum.
 */
class DeltaLevel {
    private static VALUES: Array<DeltaLevel> = new Array<DeltaLevel>();

    static PHYSICAL = new DeltaLevel(0, 'Physical');
    static MONTHS_01 = new DeltaLevel(1, '1M', 1);
    static MONTHS_02 = new DeltaLevel(2, '2M', 2);
    static MONTHS_03 = new DeltaLevel(3, '3M', 3);
    static MONTHS_06 = new DeltaLevel(4, '6M', 6);
    static MONTHS_09 = new DeltaLevel(5, '9M', 9);
    static MONTHS_12 = new DeltaLevel(6, '12M', 12);
    static MONTHS_18 = new DeltaLevel(7, '18M', 18);
    static MONTHS_24 = new DeltaLevel(8, '24M', 24);
    static MONTHS_36 = new DeltaLevel(9, '36M', 36);
    static NET_DELTA = new DeltaLevel(10, 'Net Delta');

    readonly index: number;
    readonly label: string;
    readonly months: number | undefined;

    private constructor(index: number, label: string, months: number | undefined = undefined) {
        this.index = index;
        this.label = label;
        this.months = months;
        DeltaLevel.VALUES.push(this);
    }

    static values(): Array<DeltaLevel> {
        return DeltaLevel.VALUES;
    }

    static forwardProjections(): Array<DeltaLevel> {
        return DeltaLevel.VALUES.filter(value => value.months !== undefined);
    }
}

/**
 * Definition of the three delta ladder levels.
 * Used to drive column selection logic.
 */
enum SummaryLevel {
    L1, // Root (list of products)
    L2, // Product (list by attribute value)
    L3, // Attribute (list of projects)
}

/**
 * A representation of a particular delta ladder level (see {@link SummaryLevel}).
 *
 * Each {@link Summary} holds data necessary to fully populate the Trading Risk table and can be
 * "plugged-in" into the view via `setPrimarySummary` or `setSecondarySummary`.
 */
class Summary {
    readonly key: string;
    readonly label: string;
    readonly aggregation?: string;
    readonly columns: Array<Column>;
    readonly total: Column;
    readonly level: SummaryLevel;
    readonly parent?: Summary;

    constructor(columns: Array<Column>, level: SummaryLevel, key: string, label?: string, aggregation?: string, parent?: Summary) {
        // sort columns alphabetically, for consistent presentation between loads
        this.columns = columns.sort((a, b) => {
            // but ensure any unclassified columns are listed last
            if (a.label === UNCLASSIFIED_LABEL) return 1;
            if (b.label === UNCLASSIFIED_LABEL) return -1;
            return a.label.toString().localeCompare(b.label);
        });
        this.level = level;
        this.key = key;
        this.label = label ?? key;
        this.aggregation = aggregation;
        this.total = Column.fromColumnSum('Total', columns);
        this.parent = parent;
    }

    updateColumn = (columnKey: string, newValue: Column, columnLabel: string) => {
        let columns = this.columns.map(col => col.key === columnKey ? newValue.rename(columnKey, columnLabel) : col);
        return new Summary(columns, this.level, this.key, this.label, this.aggregation, this.parent);
    };

    is = (level: SummaryLevel) => this.level === level;
}

/**
 * A column represents the physical balance and a set of monthly projections,
 * aggregated on an arbitrary "axis", e.g.: Product, Project Type, Vintage etc.
 */
class Column {
    readonly key: string;
    readonly label: string;
    private readonly ladder: Array<number>;

    private constructor(key: string, ladder: Array<number>, label?: string) {
        this.key = key.toString();
        this.ladder = ladder;
        this.label = label?.toString() ?? key.toString();
    }

    /**
     * Construct a Column from a set of input balances
     * @param key              The key for this column
     * @param physicalBalance  The input physical balance
     * @param forwardBalances  An array of forward balances
     * @param label            The display label for the column
     * @returns                A Column
     */
    static fromBalances = (key: string, physicalBalance: number, forwardBalances: number[], label?: string) => {
        let ladder = new Array<number>(DeltaLevel.values().length);
        ladder[DeltaLevel.PHYSICAL.index] = physicalBalance;

        let idx = 0;
        for (let level of DeltaLevel.forwardProjections()) {
            ladder[level.index] = -forwardBalances[idx];
            idx += 1;
        }

        ladder[DeltaLevel.NET_DELTA.index] = ladder.reduce((sum, item) => sum + +item, 0);
        return new Column(key, ladder, label);
    };

    /**
     * Construct a Column by summing an input set of columns
     * @param key      The key for the new summary column
     * @param columns  The input set of columns used to create the summary column
     * @returns        A summary Column
     */
    static fromColumnSum = (key: string, columns: Array<Column>) => {
        let ladder = new Array<number>(DeltaLevel.values().length);
        for (let level of DeltaLevel.values()) {
            ladder[level.index] = columns
                .map(col => col.get(level))
                .reduce((sum, item) => sum + +item, 0);
        }
        return new Column(key, ladder);
    };

    get = (level: DeltaLevel) => this.ladder[level.index];

    rename = (name: string, label: string) => new Column(name, this.ladder, label);
}

/**
 * {@link ProductState} maintains last selected attribute per product, as well as code <-> ID mapping.
 * Since this is all we need to interact with the Corten API, {@link ProductState} also holds the fetch logic.
 */
class ProductState {
    code: string;
    productBase: ProductBase;
    cortenId: string;
    attribute: string;
    label: string;
    appConfigState: AppConfigContextType;

    constructor(code: string, productBase: ProductBase, cortenId: string, attribute: string, appConfigState: any) {
        this.code = code;
        this.productBase = productBase;
        this.cortenId = cortenId;
        this.attribute = attribute;
        this.label = appConfigState.getProduct(cortenId)?.displayCode ?? code;
        this.appConfigState = appConfigState;
    }

    private baseProductCriteria = (isUnassigned: boolean): ProductCriteria => {
        return {
            productIds: [this.cortenId],
            includeBalances: true,
            axes: [this.attribute],
            attributes: [],
            isUnassigned: isUnassigned
        };
    };

    /**
     * Transform a set of balance data from core10 into a single array of numbers, representing the forward balances for each delta level
     *
     * Each element the [forwardResponse] array contains forward balances for a specific row. This function takes all
     * forward responses and returns the forward balances for a desired column (defined by the aggregation attribute).
     *
     * @param forwardResponse      The core10 response from the /queryBalance API for this set of data. Contains one promise result per delta level.
     *                             Each promise result contains multiple rows of data, grouped by an attribute, one row per attribute value
     * @param attributeKey         The key of the attribute that we are grouping by for this set of data. E.g. Project type or project ID
     * @param attributeValue       The value of the attribute that we want to extract from this set of data. E.g. AGRICULTURE or EOP0001
     * @param l3Breakdown          A boolean indicating whether we are parsing data for the lowest level of data (the L3 project breakdown table)
     * @param isUnclassifiedColumn Indicates whether we are deriving forward balances for an unclassified column
     * @returns                An array of numbers representing the forward balances for each delta level, for the attributeValue that we requested
     */
    private getForwardBalances = ({
        forwardResponse,
        attributeKey,
        attributeValue,
        l3Breakdown,
        isUnclassifiedColumn
    }: {
        forwardResponse: any[],
        attributeKey: string,
        attributeValue: string | number | undefined,
        l3Breakdown?: boolean,
        isUnclassifiedColumn?: boolean
    }): number[] => {
        let forwardBalances: number[] = [];
        let idx = 0;
        const matchVariables = [attributeValue]

        // If we are deriving forward balances for unclassified column then also 
        // match against undefined values as they could be missing from the response
        if (isUnclassifiedColumn) {
            matchVariables.push(undefined);
        }

        const findMatch = (entry: any): boolean => {
            if (l3Breakdown) {
                const l3BaseAttribute = getBaseL3Attribute({
                    productBase: this.productBase,
                    appConfigState: this.appConfigState
                });
                return matchVariables.includes(entry.attributes[l3BaseAttribute]);
            } else {
                return matchVariables.includes(entry.attributes[attributeKey]);
            }
        }

        for (let level of DeltaLevel.forwardProjections()) {
            let balance = 0;

            for (let entry of forwardResponse[idx].list) {
                if (findMatch(entry)) {
                    balance += +entry.balances.unassignedAmount;
                }
            }

            forwardBalances.push(balance);
            idx += 1;
        }
        return forwardBalances;
    };

    getBackwardsCompatibleFilterValueForAttribute = (attributeKey: string, attributeValue: any): string => {
        if (attributeValue && !containsUndefinedPlaceholder(attributeValue)) {
            return wrapFilterValueWithQuotes(attributeValue);
        }

        switch (attributeKey) {
            case this.appConfigState.getAttribute('PROJECT', 'VINTAGE').key:
                return `!*,${UNDEFINED_CODE_INTEGER}`;
            case this.appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key:
            case this.appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key:
            case this.appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key:
                return `!*`;
            default:
                return `!*,${UNDEFINED_CODE_STRING}`;
        }
    }

    /**
     * Fetch data for L2, for a specific product (chosen from L1)
     * @returns An array of column data
     */
    fetchColumnsByProductCriteria = () => {
        let physicalPromise = fetchByCriteria(this.baseProductCriteria(false));
        let forwardPromises = fetchForwardData(this.baseProductCriteria(true), this.appConfigState.getAttribute('TRADE', 'VALUE_DATE').key);
        return Promise.all([physicalPromise].concat(forwardPromises)).then((promiseResponse) => {
            const physicalBalances = promiseResponse[0];
            const forwardBalances = promiseResponse.slice(1);

            let columns = physicalBalances.list.map(
                (item: any) => Column.fromBalances(
                    item.attributes[this.attribute],
                    parseInt(item.balances.issuerAmount) + parseInt(item.balances.escrowAmount),
                    this.getForwardBalances({
                        forwardResponse: forwardBalances, 
                        attributeKey: this.attribute, 
                        attributeValue: item.attributes[this.attribute]
                    })
                )
            );
            this.addUnclassifiedColumn(columns, forwardBalances, false, '');
            return columns;
        });
    };

    /**
     * Fetch data for L3 (by attribute)
     * @param attributeValue The value of the attribute we are fetching data for
     * @returns An array of column data
     */
    fetchColumnsByAttributeCriteria = (attributeValue: string) => {
        const l3BaseGroupingAttribute = getBaseL3Attribute({
            productBase: this.productBase,
            appConfigState: this.appConfigState
        })

        let physicalCriteria = this.baseProductCriteria(false);
        physicalCriteria.axes!.push(l3BaseGroupingAttribute);
        physicalCriteria.attributes!.push({ code: this.attribute, value: this.getBackwardsCompatibleFilterValueForAttribute(this.attribute, attributeValue) });
        let physicalPromise = fetchByCriteria(physicalCriteria);
        
        let forwardCriteria = this.baseProductCriteria(true);
        forwardCriteria.axes!.push(l3BaseGroupingAttribute);
        forwardCriteria.attributes!.push({ code: this.attribute, value: this.getBackwardsCompatibleFilterValueForAttribute(this.attribute, attributeValue) });
        let forwardPromises = fetchForwardData(forwardCriteria, this.appConfigState.getAttribute('TRADE', 'VALUE_DATE').key);

        return Promise.all([physicalPromise].concat(forwardPromises)).then((promiseResponse) => {
            const physicalBalances = promiseResponse[0];
            const forwardBalances = promiseResponse.slice(1);

            let columns = physicalBalances.list.map(
                (item: any) => Column.fromBalances(
                    item.attributes[l3BaseGroupingAttribute],
                    parseInt(item.balances.issuerAmount) + parseInt(item.balances.escrowAmount),
                    this.getForwardBalances({
                        forwardResponse: forwardBalances, 
                        attributeKey: l3BaseGroupingAttribute, 
                        attributeValue: item.attributes[l3BaseGroupingAttribute]
                    })
                )
            );

            // If we've drilled into the Unclassified group then we need to determine the project columns
            if (containsUndefinedPlaceholder(attributeValue)) {
                this.addColumnsForUnclassifiedL3Table(columns, forwardBalances, l3BaseGroupingAttribute);
            }

            this.addUnclassifiedColumn(columns, forwardBalances, true, l3BaseGroupingAttribute);
            return columns;
        });
    };

    /**
     * Logic to add columns that exist only in unclassified data, and not in assigned data. Used in the L3 table only.
     *
     * Applies when clicking on the 'Unclassified' column in the L2 summary when grouping by attributes such as
     * Vintage, which can be unclassified but can contain product items that do have a Project Type and Project ID.
     * 
     * @param columns                The existing set of columns that we want to add to
     * @param forwardResponses       The response data for forward trades
     * @param projectIdAttributeKey  The key of the project ID attribute (from app config)
     */
    private addColumnsForUnclassifiedL3Table = (columns: Column[], forwardResponses: any[], projectIdAttributeKey: string) => {
        for (let undefinedCode of [UNDEFINED_CODE_STRING, parseInt(UNDEFINED_CODE_INTEGER), undefined]) {
            // add individual projects if they don't already exist, for the project breakdown view only
            let projects: string[] = [];
            for (let response of forwardResponses) {
                for (let item of response.list) {
                    const projectId = item.attributes?.[projectIdAttributeKey];
                    if (
                        item.attributes?.[this.attribute] === undefinedCode
                        && projectId !== UNDEFINED_CODE_STRING && projectId !== undefined
                        && !columns.map(c => c.key).includes(projectId) && !projects.includes(projectId)
                    ) {
                        projects.push(projectId);
                    }
                }
            }
            for (let projectId of projects) {
                columns.push(Column.fromBalances(
                    projectId, 
                    0, 
                    this.getForwardBalances({
                        forwardResponse: forwardResponses, 
                        attributeKey: projectIdAttributeKey, 
                        attributeValue: projectId
                    }), 
                    projectId
                ));
            }
        }
    }

    /**
     * Logic to add the 'Unclassified' column, if applicable, on any view, including the L2 view or L3
     * @param columns                The existing set of columns that we want to add to
     * @param forwardResponses       The response data for forward trades
     * @param l3Breakdown            A boolean indicating whether this is the L3 view which contains the breakdown by project
     * @param projectIdAttributeKey  The key of the project ID attribute (from app config)
     */
    private addUnclassifiedColumn = (columns: Column[], forwardResponses: any[], l3Breakdown: boolean, projectIdAttributeKey: string) => {
        const searchAttribute = l3Breakdown ? projectIdAttributeKey: this.attribute;
        const legacySearchValue = searchAttribute === this.appConfigState.getAttribute('PROJECT', 'VINTAGE').key ? parseInt(UNDEFINED_CODE_INTEGER) : UNDEFINED_CODE_STRING;

        const matchItem = (item: any): boolean => {
            const searchValue = item.attributes[searchAttribute];
            return [legacySearchValue, undefined].includes(searchValue);
        }

        if (forwardResponses.some(response => response.list.some((item: any) => matchItem(item)))) {
            columns.push(Column.fromBalances(
                this.getBackwardsCompatibleFilterValueForAttribute(searchAttribute, undefined),
                0,
                this.getForwardBalances({
                    forwardResponse: forwardResponses,
                    attributeKey: this.attribute,
                    attributeValue: legacySearchValue,
                    l3Breakdown: l3Breakdown,
                    isUnclassifiedColumn: true
                }),
                UNCLASSIFIED_LABEL
            ));
        }
    }
}

/**
 * Get the risk level for the given label, plus the previous level as well
 * @param label  The label to fetch the risk level for (plus the previous level)
 * @returns      The corresponding risk level
 */
const getRiskLevelAndPrevious = (label: string):  [DeltaLevel | undefined, DeltaLevel | undefined] => {
    const filteredLevels = DeltaLevel.forwardProjections();
    for (var i=0; i<filteredLevels.length; i++) {
        const level = filteredLevels[i];
        if (level.label === label) {
            const previousLevel = filteredLevels[i-1];
            return [level, previousLevel];
        }
    }
    return [undefined, undefined]
}

/**
 * Get the forward data range for a specific delta level plus previous level
 * @param level          The level to fetch the forward date range for
 * @param previousLevel  The level before the requested level to fetch the forward date range for
 * @returns              An array containing the start and end date
 */
const getForwardDateRange = (level: DeltaLevel, previousLevel: DeltaLevel | undefined): [string, string] => {
    // We add one day to ensure that new trades exist in the chosen range (rather than in the following range for 1 day)
    const start = dayjs().add(previousLevel?.months ?? 0, 'month').add(1, 'days').startOf('day').toISOString();
    const end = dayjs().add(level.months!, 'month').add(1, 'days').startOf('day').toISOString();
    return [start, end];
}

/**
 * Fetch forward positions for all rows (1M..36M)
 * @param criteria       The product criteria or filters that apply to the current view
 * @param dateAttribute  The value date attribute key, taken from the app config
 * @returns              The forward data response
 */
const fetchForwardData = (criteria: ProductCriteria, dateAttribute: string): any[] => {
    let forwardData = [];
    let originalAttributes = criteria.attributes!;
    const filteredLevels = DeltaLevel.forwardProjections();
    for (let i=0; i<filteredLevels.length; i++) {
        const level = filteredLevels[i];
        const previousLevel = filteredLevels[i-1];
        const [startDate, endDate] = getForwardDateRange(level, previousLevel);
        const dateRange = `${startDate}..${endDate}`;
        let dateRangeCondition = [{code: dateAttribute, value: dateRange} as AttributeCriteria];
        criteria.attributes = dateRangeCondition.concat(originalAttributes);
        forwardData.push(fetchByCriteria(criteria));
    }
    return forwardData;
}

const getBaseL3Attribute = ({
    productBase,
    appConfigState
}: {
    productBase: ProductBase,
    appConfigState: AppConfigContextType
}) => {
    switch (productBase) {
        case ProductBase.Project:
            return appConfigState.getAttribute('PROJECT', 'ID').key;
        case ProductBase.Certificate:
            return appConfigState.getAttribute('PROJECT', 'ACCREDITATION_CODE').key;
    }
}

const TradingRisk = () => {
    const appConfigState = useAppConfigState();
    const [primarySummary, setPrimarySummary] = useState<Summary>();
    const [secondarySummary, setSecondarySummary] = useState<Summary>();
    const [loadingL2View, setLoadingL2View] = useState<boolean>(false);
    const [snap, setSnap] = useState<string | undefined>(undefined);

    const productStateRegistry = useRef<Map<string, ProductState>>(new Map());
    const selectedProductState = useRef<ProductState>();

    const {customTheme} = useLayoutState()

    useEffect(() => {
        loadL1Table();
    }, []);

    /**
     * Populates L1 Delta Ladder table, whenever the page is first loaded, or when we are
     * on the L2 table and click the X next to the product button to go back up to L1
     */
    const loadL1Table = () => {
        let updatedRegistry = new Map<string, ProductState>();
        let columns = new Array<Column>();

        const physicalPromise = fetchBalances({
            criteria: {sumProductItems: true}, 
            appConfigState: appConfigState, 
            includeCertificateBased: true
        })

        const forwardPromises = fetchForwardData(
            {
                productIds: appConfigState.getProducts(true).map(p => p.id),
                includeBalances: true,
                axes: [],
                isUnassigned: true, attributes: []
            },
            appConfigState.getAttribute('TRADE', 'VALUE_DATE').key
        );

        Promise.all([physicalPromise].concat(forwardPromises)).then((promiseResponse) => {
            const physicalBalances = promiseResponse[0];
            const forwardBalances = promiseResponse.slice(1);
            for (const product of appConfigState.getProducts(true)) {
                const physicalBalance = 
                    physicalBalances.find((b: any) => b.productId === product.id)?.issuerAmount ?? 0 + 
                    physicalBalances.find((b: any) => b.productId === product.id)?.escrowAmount ?? 0;

                const forwardBalance = forwardBalances.map(u => u.list.find((b: any) => b.balances.productId === product.id)?.balances?.unassignedAmount ?? 0);
                // default selected attribute to PROJECT TYPE
                const attributeFromRegistry = productStateRegistry.current.get(product.displayCode)?.attribute;
                const defaultAttribute = product.productBase === ProductBase.Certificate 
                    ? appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key 
                    : appConfigState.getAttribute('PROJECT', 'TYPE').key;
                let selectedAttribute = attributeFromRegistry ?? defaultAttribute;
                let productState = new ProductState(product.displayCode, product.productBase, product.id, selectedAttribute, appConfigState);

                columns.push(Column.fromBalances(product.displayCode, physicalBalance, forwardBalance, productState.label));
                updatedRegistry.set(product.displayCode, productState);
            }
            // update selected product state instance
            let selectedProduct = selectedProductState.current?.code
                ? updatedRegistry.get(selectedProductState.current.code)
                : undefined;
            selectedProductState.current = selectedProduct;
            productStateRegistry.current = updatedRegistry;
            // if the previously selected product is no longer available, hide secondary summary
            if (!selectedProduct) setSecondarySummary(undefined);
            setPrimarySummary(new Summary(columns, SummaryLevel.L1, "ROOT"));
            setSnap(new Date().toLocaleTimeString());
        });
    };

    /**
     * Populates L2 Delta Ladder table, whenever a Product is selected in L1, or whenever we are
     * already on the L2 table and we change the breakdown attribute from the drop-down select box
     * @param code              The code for the selected product
     * @param attributeSummary  The attribute summary data that currently applies
     */
    const loadL2Table = (code: string, attributeSummary: Summary | undefined) => {
        let productState = productStateRegistry.current.get(code)!;
        setLoadingL2View(true);
        productState.fetchColumnsByProductCriteria().then(columns => {
            let productSummary = new Summary(columns, SummaryLevel.L2, code, productState.label, productState.attribute);
            if (selectedProductState.current?.code !== productState.code) {
                // if we have switched products, hide secondary summary
                setSecondarySummary(undefined);
            } else if (attributeSummary !== undefined) {
                // else make sure secondary summary is up-to-date
                // TODO would be better to trigger this together with the product fetch to avoid delay
                loadL3Table(attributeSummary.key!, productSummary);
            }
            // set primary summary AFTER setting product state to ensure the selected attribute value renders correctly
            selectedProductState.current = productState;
            setPrimarySummary(productSummary);
            setLoadingL2View(false);
        });
    };

    /**
     * Populates L3 Delta Ladder table, whenever an attribute column is selected in the L2 table
     * @param code              The code for the selected product
     * @param attributeSummary  The attribute summary data that currently applies
     */
    const loadL3Table = (code: string, productSummary: Summary) => {
        let productState = selectedProductState.current!;
        productState.fetchColumnsByAttributeCriteria(code).then(columns => {
            let secondary = new Summary(
                columns,
                SummaryLevel.L3,
                code,
                productSummary.columns.find(i => i.key === code)?.label,
                productState.attribute,
                productSummary
            );
            setSecondarySummary(secondary);
            // also update the corresponding column in the primary summary (L2)
            setPrimarySummary(productSummary.updateColumn(
                code, secondary.total, containsUndefinedPlaceholder(code) ? UNCLASSIFIED_LABEL : code
            ));
        });
    };

    /**
     * Opens a link to the inventory management page when a base attribute is clicked
     * @param code  The code for the selected base attribute
     */
    const selectL3ProductBaseAttribute = (code: string) => {
        let baseAttribute: string;
        switch (selectedProductState.current!.productBase) {
            case ProductBase.Project:
                baseAttribute = 'project';
                break;
            case ProductBase.Certificate:
                baseAttribute = 'accreditationCode';
                break;
        }
        const url = `${homepage}/inventory-management/product/${selectedProductState.current!.cortenId}/${baseAttribute}/${code}`;
        window.open(url, '_blank');
    }

    /**
     * Carries out the relevant actions when a column label is clicked
     * @param key           The key of the column that was clicked
     * @param summaryLevel  The summary level that applies to the table
     */
    const selectColumn = (key: string, summaryLevel: SummaryLevel) => {
        switch (summaryLevel) {
            case SummaryLevel.L1:
                loadL2Table(key, secondarySummary);
                break;
            case SummaryLevel.L2:
                loadL3Table(key, primarySummary!);
                break;
            case SummaryLevel.L3:
                selectL3ProductBaseAttribute(key);
                break;
        }
    };

    /**
     * Actions that take place when the group-by attribute selection box is changed
     * @param event  The change event
     */
    const attributeChanged = (event: SelectChangeEvent) => {
        selectedProductState.current!.attribute = event.target.value;
        setSecondarySummary(undefined);
        // reload L2 table, grouped by the new attribute
        loadL2Table(selectedProductState.current!.code, undefined);
    };

    /**
     * Open a link to the position risk holding page when any relevant cell in the table is clicked
     * @param summary    The current summary data
     * @param columnKey  The column key, i.e. the attribute value for that column, or the string 'Total'
     * @param rowKey     The row key, e.g. '1M', or the string 'Physical'
     */
    const handleRowClick = (summary: any, columnKey: string, rowKey: string) => {
        const getRiskPositionParamValue = (originalValue: string): string => {
            if (originalValue === UNDEFINED_CODE_STRING || originalValue === UNDEFINED_CODE_INTEGER) {
                return `!*,${originalValue}`
            }

            return originalValue;
        }

        if (window.getSelection() !== null && window.getSelection()?.toString() !== '') {
            return;
        }
        let parameters = [];
        if (summary.level === 0) {
            if (columnKey !== 'Total') {

                parameters.push(`product=${productStateRegistry.current.get(columnKey)!.cortenId}`);
                parameters.push(`productBase=${productStateRegistry.current.get(columnKey)!.productBase}`)
            }
        } else {
            parameters.push(`product=${selectedProductState.current!.cortenId}`);
            parameters.push(`productBase=${selectedProductState.current!.productBase}`)
            if (summary.level === 1) {
                if (columnKey !== 'Total') {
                    const positionValue = getRiskPositionParamValue(columnKey);
                    if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'TYPE').key) {
                        parameters.push(`type=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'VINTAGE').key) {
                        parameters.push(`vintage=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key) {
                        parameters.push(`countryCode=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'STATE').key) {
                        parameters.push(`projectState=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key) {
                        parameters.push(`fuelSource=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key) {
                        parameters.push(`generationYear=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key) {
                        parameters.push(`creationYear=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key) {
                        parameters.push(`generationState=${positionValue}`);
                    } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key) {
                        parameters.push(`greenPowerAccredited=${positionValue}`);
                    }
                }
            } else if (summary.level === 2) {
                const positionValue = getRiskPositionParamValue(summary.key);
                if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'TYPE').key) {
                    parameters.push(`type=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'VINTAGE').key) {
                    parameters.push(`vintage=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key) {
                    parameters.push(`countryCode=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'STATE').key) {
                    parameters.push(`projectState=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key) {
                    parameters.push(`fuelSource=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key) {
                    parameters.push(`generationYear=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key) {
                    parameters.push(`creationYear=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key) {
                    parameters.push(`generationState=${positionValue}`);
                } else if (selectedProductState.current?.attribute === appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key) {
                    parameters.push(`greenPowerAccredited=${positionValue}`);
                }
                if (columnKey !== 'Total') {
                    if (columnKey === UNDEFINED_CODE_STRING || columnKey === UNDEFINED_CODE_INTEGER) {
                        switch (selectedProductState.current!.productBase) {
                            case ProductBase.Project:
                                parameters.push(`project=!*,${UNDEFINED_CODE_STRING}`);
                                break;
                            case ProductBase.Certificate:
                                parameters.push(`accreditationCode=!*`)
                                break;
                        }
                    } else {
                        switch (selectedProductState.current!.productBase) {
                            case ProductBase.Project:
                                parameters.push(`project=${columnKey}`);
                                break;
                            case ProductBase.Certificate:
                                parameters.push(`accreditationCode=${columnKey}`)
                                break;
                        }
                    }
                }
            }
        }
        const [level, previousLevel] = getRiskLevelAndPrevious(rowKey);
        if (level !== undefined) {
            const [startDate, endDate] = getForwardDateRange(level, previousLevel);
            parameters.push(`valueDateStart=${startDate}`);
            parameters.push(`valueDateEnd=${endDate}`);
        }
        const parameterString = parameters.length > 0 ? '?' + parameters.join('&') : ''
        window.open(encodeURI(`${homepage}/risk/position${parameterString}`), '_blank');
    };

    /**
     * Download a CSV file
     * @param summary  The current summary data
     */
    const downloadCsv = (summary: Summary) => {
        let filename = 'delta-ladder'
        if (summary.is(SummaryLevel.L1)) {
            filename += '-l1';
        }
        if (summary.is(SummaryLevel.L2)) {
            filename += `-l2-${summary.label}-${attributeDisplayValue(summary.aggregation!)}`;
        }
        if (summary.is(SummaryLevel.L3)) {
            filename += `-l3-${summary.parent!.label}-${summary.label}`;
        }
        filename += `-${currentDateExportFormat()}`;
        var data = renderDeltaBody(summary);
        const options = {
            filename: filename,
            fieldSeparator: ',',
            decimalSeparator: '.',
            quoteStrings: '"',
            showLabels: true,
            useKeysAsHeaders: true,
            useBom: true
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    }

    /**
     * The display value for an attribute, used in the filename for CSV exports
     * @param attribute  The raw attribute value
     * @returns          An attribute value that is cleaned for display
     */
    const attributeDisplayValue = (attribute: string) => {
        return attribute.replace('mbl_', '');
    }

    /**
     * Get a list of attributes to aggregate by on L2
     * @returns A list of attributes to agggregate by on
     */
    const getL2AttributeOptions = () => {
        const menuItems = []
        switch (selectedProductState.current?.productBase) {
            case ProductBase.Project:
                menuItems.push(
                    <MenuItem key="project-type" value={appConfigState.getAttribute('PROJECT', 'TYPE').key}>PROJECT TYPE</MenuItem>,
                    <MenuItem key="vintage" value={appConfigState.getAttribute('PROJECT', 'VINTAGE').key}>VINTAGE</MenuItem>
                )
                break
            case ProductBase.Certificate:
                menuItems.push(
                    <MenuItem key="fuel-source" value={appConfigState.getAttribute('PROJECT', 'FUEL_SOURCE').key}>FUEL SOURCE</MenuItem>,
                    <MenuItem key="creation-year" value={appConfigState.getAttribute('PROJECT', 'CREATION_YEAR').key}>CREATION YEAR</MenuItem>,
                    <MenuItem key="generation-year" value={appConfigState.getAttribute('PROJECT', 'GENERATION_YEAR').key}>GENERATION YEAR</MenuItem>,
                    <MenuItem key="generation-state" value={appConfigState.getAttribute('PROJECT', 'GENERATION_STATE').key}>GENERATION STATE</MenuItem>,
                    <MenuItem key="greenpower-accredited" value={appConfigState.getAttribute('PROJECT', 'GREENPOWER_ACCREDITED').key}>GREENPOWER ACCREDITED</MenuItem>
                )
                break;
        }

        if (selectedProductState.current?.code == 'ACCU') {
            menuItems.push(<MenuItem key="state" value={appConfigState.getAttribute('PROJECT', 'STATE').key}>STATE</MenuItem>)
        } 
        
        if (selectedProductState.current?.code == 'VCU') {
            menuItems.push(<MenuItem key="country-code" value={appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key}>COUNTRY CODE</MenuItem>)
        }

        return menuItems
    }

    return (
        <>
            <Typography variant='h2'>Delta Ladder</Typography>
            <Typography>Last Snap: {snap} SNAP</Typography>

            {primarySummary === undefined ? <LinearProgress/> : <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        disabled={primarySummary.is(SummaryLevel.L1)}
                                        startIcon={!primarySummary.is(SummaryLevel.L1) && <CloseIcon />}
                                        onClick={() => loadL1Table()}>
                                    {primarySummary.is(SummaryLevel.L2) ? primarySummary.label : 'Product'}
                                </Button>
                                {primarySummary.is(SummaryLevel.L2) && <FormControl size='small'>
                                    <Select sx={{ fontSize: '0.875rem', marginRight: 1 }}
                                            value={selectedProductState.current?.attribute} onChange={attributeChanged}>
                                        {getL2AttributeOptions()}
                                    </Select>
                                </FormControl>}
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        onClick={() => downloadCsv(primarySummary)}>
                                    {'Export CSV'}
                                </Button>
                            </TableCell>
                            {primarySummary.columns.map(column => (
                                <TableCell key={column.key} align='right'>
                                    <Button variant={column.key === secondarySummary?.key ? 'outlined' : 'text'}
                                            onClick={() => selectColumn(column.key, primarySummary.level)}
                                            disabled={loadingL2View}
                                            sx={{
                                                justifyContent: 'right',
                                                '&:hover': {
                                                    backgroundColor: customTheme.customProps.navHoverBackgroundColor
                                                }
                                            }}> 
                                        {column.label}
                                    </Button>
                                </TableCell>
                            ))}
                            <TableCell align='right' sx={{ fontWeight: 'bold' }}><Box sx={{paddingRight: '8px'}}>Total</Box></TableCell>
                        </TableRow>
                    </TableHead>
                    <DeltaLadderBody summary={primarySummary} handleRowClick={handleRowClick} customTheme={customTheme} productId={selectedProductState.current?.cortenId}/>
                </Table>
            </TableContainer>}

            {secondarySummary && <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table size='small'>
                    <TableHead>
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell>
                                <Button sx={{ marginRight: 1 }} 
                                        variant="outlined"
                                        disabled={secondarySummary.is(SummaryLevel.L1)}
                                        startIcon={!secondarySummary.is(SummaryLevel.L1) && <CloseIcon />}
                                        onClick={() => setSecondarySummary(undefined)}>
                                    {secondarySummary.label}
                                </Button>
                                <Button sx={{ marginRight: 1 }}
                                        variant="outlined"
                                        onClick={() => downloadCsv(secondarySummary)}>
                                    {'Export CSV'}
                                </Button>
                            </TableCell>
                            {secondarySummary.columns.map(column => (
                                <TableCell key={column.key} align='right'>
                                    { (containsUndefinedPlaceholder(column.key)) ? (
                                        <Typography>
                                            {column.label}
                                        </Typography>
                                    ) : (
                                        <Button onClick={() => selectColumn(column.key, secondarySummary.level)}
                                                endIcon={<LaunchIcon />}
                                                sx={{'&:hover': {
                                                    backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                                }}}>
                                            {column.label}
                                        </Button>
                                    )}
                                </TableCell>
                            ))}
                            <TableCell align='right' sx={{ fontWeight: 'bold' }}><Box sx={{paddingRight: '8px'}}>Total</Box></TableCell>
                        </TableRow>
                    </TableHead>
                    <DeltaLadderBody summary={secondarySummary} handleRowClick={handleRowClick} customTheme={customTheme} productId={selectedProductState.current?.cortenId}/>
                </Table>
            </TableContainer>}
        </>
    );
};

// When this function is changed, you must also update the renderDeltaBody function to match (That function is used to render CSV data for export)
const DeltaLadderBody = ({summary, handleRowClick, customTheme, productId} : 
    {summary: Summary;  
        handleRowClick: (level: any, columnKey: string, rowKey: string) => void, 
        customTheme: CustomTheme,
        productId?:String|undefined
    }) => {

    
    const { productData } = useProductDataState();
    const formatDeltaAmount = (value: number) => {
        let minDecimalPos = 0;
        let maxDecimalPos = 0;
        if (productId !== undefined) {
            minDecimalPos = productData.get(productId)?.minDecimalPos!;
            maxDecimalPos = productData.get(productId)?.maxDecimalPos!;
        }
        return <AmountFormatWrapper amount={value} minDecimalPos={minDecimalPos} maxDecimalPos={maxDecimalPos} />;
    }

    const shouldRenderAsButton = (level: DeltaLevel, column: Column): boolean => {
        return level.label !== 'Net Delta' && column.get(level) !== 0;
    }

    return (
        <TableBody>
            {DeltaLevel.values().map(level => (
                <TableRow hover key={level.index}>
                    <TableCell variant='head' sx={{ fontWeight: 'bold' }}>{level.label}</TableCell>
                    {summary.columns.map(column => (
                        <TableCell
                            key={column.key}
                            align='right'
                        >


                            {/* All cells are clickable except for the 'Net Delta' row and those with no data (displaying 0) */}
                            
                            {!shouldRenderAsButton(level, column)
                                ? (<Box sx={{ paddingRight: '8px' }}>{formatDeltaAmount(column.get(level))}</Box>)
                                : (
                                    <Button
                                        onClick={() => handleRowClick(summary, column.key, level.label)}
                                        color='inherit'
                                        sx={{
                                            cursor: 'pointer',
                                            justifyContent: 'right',
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            }
                                        }}
                                    >{formatDeltaAmount(column.get(level))}
                                    </Button>
                                )}
                        </TableCell>
                    ))}
                    <TableCell
                        align='right'
                    >
                        {/* All cells in the totals column are clickable except for the 'Net Delta' row and those with no data (displaying 0)
                        Temporarily disable clicking on Total row for Root level as we cannot combiine LGC and the other products 
                        in one page yet
                        TODO remove the logic to not render total row as a clickable button when LGCs can be displayed alongside 
                        other products in the same page */}
                        {
                            ((!shouldRenderAsButton(level, summary.total) || summary.level === SummaryLevel.L1))
                                ? (<Box sx={{ paddingRight: '8px' }}>{formatDeltaAmount(summary.total.get(level))}</Box>)
                                : (
                                    <Button
                                        onClick={() => handleRowClick(summary, 'Total', level.label)}
                                        color='inherit'
                                        sx={{
                                            cursor: 'pointer',
                                            justifyContent: 'right',
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            }
                                        }}
                                    >
                                        {formatDeltaAmount(summary.total.get(level))}
                                    </Button>
                                )}
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
}

// Used for CSV export. Must match structure of DeltaLadderBody function above
const renderDeltaBody = (summary: Summary): any[] => {
    return DeltaLevel.values().map(level => (
            {
                ...{
                    '': level.label
                },
                ...summary.columns.reduce(function(map: any, column) {
                    map[`${wrapWithQuotesForExport(column.label)}`] = column.get(level); 
                    return map;
                }, {}),
                ...{
                    'Total': summary.total.get(level)
                },
            }
        )
    );
};

const wrapWithQuotesForExport = (value: string): string => {
    return `"${value.replace(/"/g, match => `""`)}"`;
};

export default TradingRisk;
