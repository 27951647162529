import { LOGIN_CALLBACK_PATH, LOGOUT_CALLBACK_PATH } from "./Variables"

/**
 * Utility function to transform the corten config so that it is overriden 
 * with values from this app
 * @param {any} config the original corten config
 * @returns {any} transformed config with the overrides from this app
 */
const transformConfig = (config: any): any => {
    config.redirectUri = LOGIN_CALLBACK_PATH
    config.postLogoutRedirectUri = LOGOUT_CALLBACK_PATH
    return config;
}

export { transformConfig }