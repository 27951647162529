import { ReactNode, useEffect } from "react";
import { useAuth } from "../state/AuthProvider";
import { StorageItemKey } from "../state/StorageUtils";
import { COGNITO_GROUPS_KEY } from "../state/Variables";

/**
 * This is a extension for AuthProvider. Any auth related logic in addition to what is included in core10 Authprovider,
 * such as MBL specific auth event callbacks should be included here.
 * @returns ReactNode : children wrapped within this component
 */
export function AuthExtension({ children, cognitoGroupId }: { children: ReactNode, cognitoGroupId: string }) {

    const { userManager, user, signOut } = useAuth()

    useEffect(() => {
        if (userManager) {
            userManager.events.addUserUnloaded(() => {
                removeStorageItems();
            });

            userManager.events.addUserSignedOut(() => {
                removeStorageItems();
            });
        }

    }, [userManager]);
    useEffect(() => {
        if (user) {
            if(!user.profile[COGNITO_GROUPS_KEY] || !user.profile[COGNITO_GROUPS_KEY].includes(cognitoGroupId)) {
                console.log("Unexpected User, Signing Out!");
                signOut();
            }
        }
    }, [user]);

    const removeStorageItems = () => {
        StorageItemKey.LOCAL_STORAGE_KEYS.forEach(key => localStorage.removeItem(key));
        StorageItemKey.SESSION_STORAGE_KEYS.forEach(key => sessionStorage.removeItem(key));
    }

    return <>{children}</>;

}