import { Box, Grid, Typography } from '@mui/material';

const renderEntry = (
    label: string,
    props: any,
    useDialog: boolean,
    controlledTextField: boolean = false
): JSX.Element => {
    if (useDialog) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                {!controlledTextField && (<Typography>
                    {label}
                </Typography>)}
                <Box sx={{  width: '20em', textAlign: 'left', flex: '1'}}>
                    {props}
                </Box>
            </Box>
        )
    } else {
        
        return (
            <Grid item xs={12}>
                {props}
            </Grid>
        )
    }
};

export { renderEntry }
