import { Box, Tab, Tabs, Typography } from "@mui/material";
import { HoldingQueryType, Holdings } from "../Holdings";
import { useState } from "react";
import { useLayoutState } from "../../state/Layout";
import { TradePageType, Trades } from "../Trades";
import { useAppConfigState } from "../../state/AppConfig";
import { useProductState } from "../../view/inventoryManagement/state/ProductState";

const Certificates = ({ productId }: { productId: string }) => {

    const { mblSelectedProduct } = useProductState();
    const appConfigState = useAppConfigState();
    const [tabValue, setTabValue] = useState<'Certificates' | 'ForwardTrades'>('Certificates');
    const { customTheme } = useLayoutState();
    // tab item text highlighting
    const hoverTabTextColor = { color: customTheme.theme.palette.secondary.main };

    return (
        <Box style={{ marginBottom: '5rem' }}>
            <Typography variant="h3">
                Certificates and Forward Trades
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                <Tabs
                    value={tabValue}
                    onChange={(_event: React.SyntheticEvent, value: any): void => {
                        setTabValue(value);
                    }}
                >
                    <Tab label="Certificates" value={'Certificates'} />
                    <Tab
                        label="Forward Trades"
                        value="ForwardTrades"
                        sx={{
                            ...hoverTabTextColor,
                            transition: customTheme.customProps.navHoverTransition,
                            '&:hover': {
                                backgroundColor: customTheme.customProps.navHoverBackgroundColor
                            }
                        }}
                    />
                </Tabs>
            </Box>

            {tabValue === 'Certificates' && (
                <Holdings
                    queryType={HoldingQueryType.INVENTORY_CERTIFICATES}
                    columnDefinitions={[
                        {key: 'accreditationCode', showByDefault: true},
                        {key: 'fuelSource', showByDefault: true},
                        {key: 'creationYear', showByDefault: true},
                        {key: 'generationYear', showByDefault: true},
                        {key: 'generationState', showByDefault: true},
                        {key: 'serialNumRange', showByDefault: true},
                        {key: 'amount', showByDefault: true},
                        {key: 'state', showByDefault: true},
                        {key: 'greenpowerAccredited', showByDefault: true},
                    ]}
                    // Filters:
                    accountId={appConfigState.getAccount('INVENTORY_ISSUER').id}
                    product={productId}
                    // Other:
                    selectedHoldings={[]}
                />
            )}
            {tabValue === 'ForwardTrades' && (
                <Trades
                    pageType={TradePageType.Forwards}
                    columnDefinitions={[
                        {key: "valueDate", showByDefault: true},
                        {key: "tradeDate", showByDefault: false},
                        {key: "forwardId", showByDefault: true},
                        {key: "tradeId", showByDefault: true},
                        {key: "quantity", showByDefault: true},
                        {key: "productId", showByDefault: true},
                        {key: "accreditationCode", showByDefault: false},
                        {key: "fuelSource", showByDefault: true},
                        {key: "creationYear", showByDefault: false},
                        {key: "generationYear", showByDefault: true},
                        {key: "generationState", showByDefault: false},
                        {key: "greenpowerAccredited", showByDefault: false},
                        {key: "currency", showByDefault: true},
                        {key: "price", showByDefault: true},
                        {key: "counterParty", showByDefault: true},
                        {key: "trader", showByDefault: false},
                        {key: "salesPerson", showByDefault: false},
                        {key: "salesCredits", showByDefault: false},
                        {key: "brokerName", showByDefault: false},
                        {key: "brokerage", showByDefault: false},
                    ]}
                    defaultOrdering={`productItemAttribute.${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`}
                    // Filters
                    product={mblSelectedProduct.id}
                />
            )}
        </Box>
    );
}

export default Certificates;