import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { accessKeyStore, useAccessKeyDispatch } from './AccessKeyManager';
import axios from 'axios';
import { cortenApiKey, cortenApiUrl } from './AppConfig';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children, appConfig }) {
  const [userManager, setUserManager] = useState(null);
  const [user, setUser] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState('');
  const signingOut = useRef(false);
  const keyDispatch = useAccessKeyDispatch()
  const location = useLocation();

  useEffect(() => {

    const settings = {
      authority: appConfig.authority,
      client_id: appConfig.clientId,
      redirect_uri: window.location.origin + appConfig.redirectUri,
      response_type: appConfig.responseType,
      scope: appConfig.scope,
      post_logout_redirect_uri: window.location.origin + appConfig.postLogoutRedirectUri,
      automaticSilentRenew: appConfig.automaticSilentRenew,
      loadUserInfo: true,
      // store the token in localStorage so that it is persisted even when the browser is closed
      userStore: new WebStorageStateStore({ store: localStorage })
    };
    if (appConfig.authority) {

      const trySilentSignIn = async () => {
        while (true) {
          try {
            await manager.signinSilent();
            console.log('Silent sign-in succeeded');
            break; // Break the loop on success
          } catch (err) {
            console.log('Error while trying to sign in silently', err);
            if (err.message === 'Network Error') {
              console.log('Network error. Retrying in 5 seconds...');
            } else {
              // Redirect user to manually sign in if silent sign in fails (maybe refresh
              // token expired as well)
              console.log('Non-network error - redirecting to sign in');
              signIn(manager);
              break; 
            }
            await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds delay
          }
        }
      };

      setEnabled(true)
      const manager = new UserManager(settings);
      manager.events.addUserLoaded((loadedUser) => {
        setUser(loadedUser);
        loadUserKey(loadedUser);
      });

      manager.events.addSilentRenewError((err) => {
        console.log('Silent renew error:', err);
        // Silent silent (using refresh token to get new access token) failed 
        // so we need the user to log in again
        if (err.message !== 'Network Error') {
          signIn(manager);
        }
      });

      manager.events.addUserUnloaded(() => {
        console.log('User signed out');
        setUser(null);
        setAccountId('');
      });

      manager.events.addUserSignedOut(() => {
        console.log('User signed out');
        setUser(null);
        setAccountId('');
      });

      manager.getUser().then((currentUser) => {
        if (currentUser) {
          loadUserKey(currentUser);
          if (isTokenExpired(currentUser)) {
            console.log('User exists but the access token is expired')

            // Perform a silent sign-in (getting new access token from refresh token)
            trySilentSignIn();

            return;
          }
          console.log('User exists and token is valid')

        } else {
          console.log('User does not exist - redirecting to sign in')
          signIn(manager)
          return;
        }

        setUser(currentUser);
      });

      setUserManager(manager);
    }


  }, [keyDispatch, appConfig]);

  useEffect(() => {
    const onCurrentKeyChange = event => {
      if (!signingOut.current && event.key === "currentKeyId" && event.oldValue && !event.newValue) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", onCurrentKeyChange);
    return () => window.removeEventListener("storage", onCurrentKeyChange);
  }, []);

  const signIn = (manager = userManager) => {
    if (signingOut.current) {
      // This check is needed as we have a number of automatic sign in redirects on a few pages that interfere with the logout process.
      // Ideally we wouldn't have these.
      return;
    }
    if (location.pathname === appConfig.redirectUri) {
      return;
    }
    if (manager) {
      localStorage.setItem("currentKeyId", "")
      setLoading(true)
      manager.signinRedirect({state: appConfig.redirectUri});
    }
  };

  const signOut = () => {
    signingOut.current = true;
    if (userManager) {
      userManager.removeUser()
        .then(() => {
          setUser(null);
          const currentKey = localStorage.getItem("currentKeyId");
          if (currentKey !== undefined) {
            accessKeyStore.removeKey(currentKey);
          }
          keyDispatch({ type: 'removeKey', id: currentKey });
          if (userManager?._settings?._metadata?.end_session_endpoint) {
            userManager.signoutRedirect()
                .catch((error) => console.error('Error during sign out:', error))
                .then(() => localStorage.setItem("currentKeyId", ""));
          } else {
            let logoutUri = `${encodeURIComponent(window.location.origin + appConfig.postLogoutRedirectUri)}`;
            window.location.href = `${appConfig.clientDomain}/logout?client_id=${appConfig.clientId}&logout_uri=${logoutUri}`;
            localStorage.setItem("currentKeyId", "");
          }
        })
        .catch((error) => {
          console.error('Error during sign out:', error);
        });
    }
  };

  const isTokenExpired = (user) => {
    const expirationDate = new Date(user.expires_at * 1000);
    const currentTime = new Date();
    return currentTime > expirationDate;
  }

  async function loadUserKey(loadedUser) {
    let userInfoUrl = `${cortenApiUrl}/api/user`;

    try {
      const response = await axios.get(userInfoUrl, {
        headers: {
          Authorization: `Bearer ${loadedUser.access_token}`,
          'X-Trovio-DemoAuthorization': cortenApiKey
        }
      });

      keyDispatch({
        type: 'addKey',
        key: {
          id: response.data.accountId,
          name: loadedUser.profile.username,
          type: 'auth'
        }
      });

      loadedUser.profile.accountId = response.data.accountId;
      setUser(loadedUser);

    } catch (error) {
      console.error('Error getting user info:', error);

      try {
        await userManager.removeUser();
        setUser(null);
        if (accountId) {
            keyDispatch({ type: 'removeKey', id: accountId });
        }
        setAccountId('');
      } catch (error) {
        console.error('Error during sign out:', error);
      }
    }

    setLoading(false);
  }

  const value = {
    user,
    setUser,
    userManager,
    signIn,
    signOut,
    loading,
    setLoading,
    enabled,
    accountId,
    setAccountId,
    isTokenExpired,
    loadUserKey
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
