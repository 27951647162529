import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography, } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTransactionMonitor } from '../../../state/transaction/TransactionMonitor';
import { TransactionOverview } from '../../../component/TransactionOverview';
import { AlertDialogContent, AlertType } from '../../../component/AlertDialogContent';
import { useAppConfigState } from '../../../state/AppConfig';
import { useAuth } from '../../../state/AuthProvider';
import { executeTransaction } from '../../../state/CortenClient';
import { DATE_DISPLAY_FORMAT } from "../../../utility/utcToLocalFormat";
import dayjs from 'dayjs';

const ForwardTradeCancelDialog = ({
    dialogActive,
    forwardTradeRow,
    onActionTaken,
    onClose
}: {
    dialogActive: boolean;
    forwardTradeRow: any;
    onActionTaken: () => void;
    onClose: () => void;
}) => {

    const appConfigState = useAppConfigState();
    const user = useAuth();

    const [transactionSuccess, setTransactionSuccess] = useState<boolean>(false);
    const [transactionErrorMessage, setTransactionErrorMessage] = useState<string>("");
    const [transactionErrorCode, setTransactionErrorCode] = useState<string>("");
    const [transactionWarnMessage, setTransactionWarnMessage] = useState<string>("");
    const [isTransactionInProgress, setIsTransactionInProgress] = useState<boolean>(false);
    const pendingTransaction = useRef<string | null>(null);

    useEffect(() => {
        if (dialogActive) {
            setIsTransactionInProgress(false);
            pendingTransaction.current = null;
            setTransactionSuccess(false);
        }
    }, [dialogActive]);

    const { subscribe, unsubscribe } = useTransactionMonitor(
        () => {
            setIsTransactionInProgress(false);
            setTransactionSuccess(true);
            onActionTaken();
        },
        (tx) => {
            setIsTransactionInProgress(false);
            setTransactionErrorMessage('An error occurred when canceling the forward trade.');
            setTransactionErrorCode(tx.errorCode);
        },
        () => {
            setIsTransactionInProgress(false);
            setTransactionWarnMessage('The transaction timed out. Please check back later to confirm whether the forward was canceled.');
            onActionTaken();
        }
    );

    const handleDialogClose = () => {
        unsubscribe();
        setIsTransactionInProgress(false);
        pendingTransaction.current = null;
        setTransactionSuccess(false);
        // update state in the parent view
        onClose();
    }

    const performTransaction = async() => {
        setIsTransactionInProgress(true);

        const deleteProductItemRequest = {
            type: 'DeleteProductItemRequest',
            issuerId: appConfigState.getAccount('INVENTORY_ISSUER').id,
            productItemId: forwardTradeRow.forwardId,
            deflationMode: 'DeflateAndDelete'
        };

        executeTransaction(deleteProductItemRequest, user).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(r => {
                    pendingTransaction.current = r.txId;
                    subscribe(r.txId);
                });
            } else {
                console.error(response);
                setIsTransactionInProgress(false);
                setTransactionErrorMessage("Could not complete the transaction - the request was rejected")
            }
        }).catch(console.error);
    };

    return (
        <Dialog open={dialogActive} onClose={onClose} fullWidth maxWidth="xs">

            {!transactionSuccess && !transactionErrorMessage && !transactionWarnMessage && (
                <>
                    <DialogContent>
                        <Typography variant='h2'>
                            Confirm Cancelation of Forward Trade
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Forward ID:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.forwardId}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '1rem' }}>
                            <Typography>
                                Counterparty:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{forwardTradeRow.counterParty}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2rem' }}>
                            <Typography>
                                Value Date:
                            </Typography>
                            <Box sx={{  width: '20em', textAlign: 'right', flex: '1'}}>
                                <Typography>{dayjs(forwardTradeRow.valueDate).format(DATE_DISPLAY_FORMAT)}</Typography>
                            </Box>
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" variant="outlined">
                            Go Back
                        </Button>
                        <Button
                            onClick={() => performTransaction()}
                            color="primary"
                            variant="outlined"
                        >
                            {isTransactionInProgress ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '2px'
                                    }}
                                >
                                    <CircularProgress size={20} />
                                </Box>
                            ) : (
                                'Confirm Cancelation'
                            )}
                        </Button>
                    </DialogActions>
                </>
            )}
            { transactionSuccess && (<TransactionOverview
                open={transactionSuccess}
                onClose={handleDialogClose}
                title='Forward Trade Canceled Successfully'
                attributes={{
                    ...(pendingTransaction.current !== null) && {
                        'Transaction ID': {
                            value: pendingTransaction.current,
                            shorterFormId: true,
                            key: 'transactionId'
                        }
                    }
                }}
            />)}
            {transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Error}
                    alertMessage={transactionErrorMessage}
                    errorCode={transactionErrorCode}
                    handleDialogClose={onClose}
                />
            )}
            {transactionWarnMessage && !transactionErrorMessage && (
                <AlertDialogContent
                    alertType={AlertType.Warning}
                    alertMessage={transactionWarnMessage}
                    errorCode={null}
                    handleDialogClose={onClose}
                />
            )}
        </Dialog>

    );
};

export { ForwardTradeCancelDialog };
