import { ProjectData } from '../model/Shared';

// construct project details UI display object from response data
const projectDisplayDetails = (project: any, appConfigState: any) => {

    {/* Client holdings is assignedAmount minus issuerAmount */}
    const clientHoldingsString = (+project.balances.assignedAmount - +project.balances.issuerAmount).toString();

    const displayValues: ProjectData = {
        projectId: project.attributes[appConfigState.getAttribute('PROJECT', 'ID').key],
        name: project.attributes[appConfigState.getAttribute('PROJECT', 'NAME').key],
        countryCode: project.attributes[appConfigState.getAttribute('PROJECT', 'COUNTRY_CODE').key],
        state: project.attributes[appConfigState.getAttribute('PROJECT', 'STATE').key],
        type: project.attributes[appConfigState.getAttribute('PROJECT', 'TYPE').key],
        uri: project.attributes[appConfigState.getAttribute('PROJECT', 'URI').key],
        inventoryAmount: project.balances.issuerAmount,
        escrowAmount: project.balances.escrowAmount,
        clientAmountAssigned: clientHoldingsString,
        clientAmountUnassigned: project.balances.unassignedAmount
    };

    return displayValues;
};

export { projectDisplayDetails };
