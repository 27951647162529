import { Alert, Box, Button, CircularProgress, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../state/AuthProvider";
import { tagProjectsWithInventoryScores } from "../../utility/Fetch";
import { useAppConfigState } from "../../state/AppConfig";
import SellIcon from "@mui/icons-material/Sell";

enum TaggingState {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
}

/**
 * Component that allows project (+vintage) items to be tagged with inventory score (using an attribute)
 * @param rows  Table data
 */
const InventoryScoreTagger = ({ productId }: { productId: string }) => {

    const [taggingState, setTaggingState] = useState<TaggingState>(TaggingState.NOT_STARTED);

    const config = useAppConfigState();
    const user = useAuth();

    const tagProducts = async () => {
        setTaggingState(TaggingState.IN_PROGRESS);
        tagProjectsWithInventoryScores(productId, user).then(response => {
            if (response.status >= 200 && response.status < 300) {
                setTaggingState(TaggingState.COMPLETED);
                response.json().then(responseJson => {
                    if (responseJson.success) {
                        toast(<Alert severity='success'>Successfully tagged {responseJson.successCount} project{responseJson.successCount === 1 ? '': 's'}</Alert>);
                    } else {
                        toast(<Alert severity='error'>Successfully tagged {responseJson.successCount} project{responseJson.successCount === 1 ? '': 's'} but could not tag {responseJson.errorCount} project{responseJson.errorCount === 1 ? '': 's'}</Alert>);
                    }
                });
            } else {
                toast(<Alert severity='error'>An error occurred when tagging projects</Alert>);
                setTaggingState(TaggingState.ERROR);
                console.log(response);
            }
        })
    };

    return (
        <Box sx={{ float: 'right' }}>
            <Box alignItems='center'>
                {taggingState === TaggingState.IN_PROGRESS ? (
                    <Typography><CircularProgress size={14} sx={{ marginRight: '5px' }}/> Tagging projects is in progress</Typography>
                ) : (
                    <Button
                        size='small'
                        variant='outlined'
                        onClick={tagProducts}
                    >
                        <SellIcon sx={{ mr: '5px', ml: '-5px' }}/>
                        Tag inventory
                    </Button>
                )}
            </Box>
        </Box>
    )
};

export { InventoryScoreTagger };
