type BalanceData = {
    assignedAmount: string;
    escrowAmount: string;
    issuerAmount: string;
    unassignedAmount: string;
    productItemId: string;
};

interface SumProjectBalances {
    assignedAmount: string | number;
    issuerAmount: string | number;
    unassignedAmount: string | number;
    escrowAmount: string | number
}

type ProductData = {
    issuerId: string;
    code: string;
    name: string;
    minDecimalPos: number;
    maxDecimalPos: number;
    attributes: { [key: string]: any };
    itemAttributes: ItemAttributes[];
};

interface ProjectData {
    projectId: string;
    name: string;
    countryCode: string;
    state: string | undefined;
    type: string;
    uri: string;
    clientAmountAssigned: string;
    clientAmountUnassigned: string;
    inventoryAmount: string;
    escrowAmount: string;
}

type ProductItemData = {
    productId: string;
    issuerId: string;
    type: string;
    canFractionalize: boolean;
    canInflate: boolean;
    isUnassigned: boolean;
    initialAmount: string;
    unitAmount: string;
    attributes: { [key: string]: any };
};

type ProductItem = {
    productItemId: string;
    data: ProductItemData;
};

type ItemAttributes = {
    tag: string;
    required: boolean;
    validationRules: any[];
};

enum CurrentState {
    LOADING = 'LOADING',
    READY = 'READY'
}

interface Holding {
    holdingId: string;
    accountId: string;
    state: string;
    quantity: number;
    amount: string;
    issuedIn: string;
    index: number;
    issuerId: string;
    productId: string;
    productItemId: string;
    bakedIntoProductItemId?: string;
    bakedAttributes?: any;
}

interface TransactionDetail {
    txId: string;
    blockNumber: number;
    index: number;
    timestamp: string;
    request: {
        type: string;
        fromAccountId: string;
        from: {
            type: string;
            productItemIds: string[];
            amount: string;
        };
        attributes: any;
        nonce: number;
    };
    spent: Record<string, Record<string, string>>;
    issued: Record<string, Record<string, string>>;
    spentHoldings: Holding[];
    issuedHoldings: Holding[];
}

export {
    type BalanceData,
    type SumProjectBalances,
    type ProductData,
    type ProductItem,
    type ProductItemData,
    CurrentState,
    type ProjectData,
    type Holding,
    type TransactionDetail
};
