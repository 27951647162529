import {
    Box,
    Button,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { sectionSpace } from '../../Themes';
import { SyntheticEvent, useEffect, useState } from 'react';

import { useAppConfigState } from '../../state/AppConfig';
import { fetchWithCorten } from '../../state/CortenClient';
import { Holdings, HoldingQueryType } from '../../component/Holdings';
import { HoldingRetirement, ProductDataRaw } from '../../component/HoldingRetirement';
import { fetchProducts } from '../../utility/Fetch';
import Transactions from './Transactions';
import { AmountFormatWrapper } from '../../utility/AmountFormatWrapper';
import { useLayoutState } from '../../state/Layout';
import { useProductDataState } from '../../state/ProductDataProvider';
import { TransactionType } from '../../state/Variables';

// Differs from BalanceData in Shared.ts which is for product item balances only
type ProductBalanceData = {
    issuerAmount: string;
    assignedAmount: string;
    unassignedAmount: string;
    escrowAmount: string;
    productId: string;
};

type AccountBalanceInfo = {
    productId: string;
    productCode: string;
    productName: string;
    registryId: string;
    assignedAmount: string;
    unassignedAmount: string;
};

const ClientManagement = () => {
    const [products, setProducts] = useState<ProductDataRaw[] | undefined>(undefined);
    const [addressId, setAddressId] = useState<string>('');
    const [validAddressId, setValidAddressId] = useState<boolean>(false);
    const [balanceData, setBalanceData] = useState<AccountBalanceInfo[] | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);
    const [selectedHoldings, setSelectedHoldings] = useState<any[]>([]);
    const [childRefreshSignal, setChildRefreshSignal] = useState<number | any>(0);
    const [retirementDialogActive, setRetirementDialogActive] = useState(false);
    const { productData } = useProductDataState();

    const {customTheme} = useLayoutState()

    // tab item text highlighting
    const hoverTabTextColor = {color: customTheme.theme.palette.secondary.main}

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        // If we change to the Transactions Tab then reset the selected holdings
        if (newValue == 1) {
            setSelectedHoldings([]);
        }
        setTabValue(newValue);
    };

    const appConfigState = useAppConfigState();

    useEffect(() => {
        fetchProducts(
            appConfigState.getAccount('INVENTORY_ISSUER').id,
            appConfigState.getProducts(true)
        ).then((foundProducts) => {
            const filteredProducts = foundProducts?.filter(
                (item: ProductDataRaw) =>
                    appConfigState.getProducts(true).map(product => product.id).includes(item.productId)
            );
            setProducts(filteredProducts);
        });
    }, []);

    const handleClientChange = (event: SelectChangeEvent<string>) => {
        const address = appConfigState.getAddressForClient(event.target.value)!;
        setAddressId(address);
        setBalanceData(undefined);
        setIsError(false);
        setValidAddressId(true);
        fetchAccountBalances(address);
        setSelectedHoldings([]);
    };

    const getAmount = (
        foundBalanceData: ProductBalanceData[],
        amountType: string,
        productId: string
    ): string => {
        for (const foundBalance of foundBalanceData) {
            if (foundBalance.productId === productId) {
                return foundBalance[amountType as keyof ProductBalanceData];
            }
        }
        return '0';
    };

    const fetchAccountBalances = async (searchAddressId: string) => {
        try {
            let url = `/api/pub/balances?accountId=${searchAddressId}&sumProductItems=true`;

            let response = await fetchWithCorten(url, {
                method: 'GET'
            });

            if (!response.ok) {
                setIsError(true);
                throw new Error('Failed to fetch account balances');
            }

            let foundBalanceData = await response.json();

            if (foundBalanceData.length === 0) {
                setBalanceData([]);
                return;
            }

            const accountBalanceData: AccountBalanceInfo[] = [];
            for (const product of products!) {
                accountBalanceData.push({
                    productCode: appConfigState.getProduct(product.productId)?.displayCode ?? product.data.code,
                    productName: appConfigState.getProduct(product.productId)?.displayName ?? product.data.name,
                    registryId: product.data.attributes[appConfigState.getAttribute('REGISTRY', 'ID').key],
                    assignedAmount: getAmount(
                        foundBalanceData,
                        'assignedAmount',
                        product.productId
                    ),
                    unassignedAmount: getAmount(
                        foundBalanceData,
                        'unassignedAmount',
                        product.productId
                    ),
                    productId: product.productId
                });
            }
            setBalanceData(accountBalanceData);
        } catch (error) {
            setIsError(true);
            console.error('error', error);
        }
    };

    const openRetirementDialog = () => {
        setRetirementDialogActive(true);
    };

    const onHoldingSelectionUpdated = (holdings: any[]) => {
        setSelectedHoldings(holdings);
    };

    const onRetirementDialogClosed = () => {
        setSelectedHoldings([]);
        setChildRefreshSignal(childRefreshSignal + 1);
        setRetirementDialogActive(false);
        fetchAccountBalances(addressId);
    };

    return (
        <>
            <Typography variant='h2'>Client Management</Typography>

            {!products ? <LinearProgress /> :
                <>
                    <Box sx={{marginBottom: sectionSpace}}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={4}
                                sx={{ marginBottom: '0px' }}
                                style={{ marginBottom: '20px' }}
                            >
                                <Typography>Client</Typography>
                                <Select
                                    size="small"
                                    id="clientId"
                                    name="clientId"
                                    role="select"
                                    value={appConfigState.getClientForAddress(addressId) || ''}
                                    onChange={handleClientChange}
                                    sx={{ width: '10em' }} // Arbitrarily set based on the length of the client names
                                >
                                    {appConfigState.getClients().map((client) => (
                                        <MenuItem key={client.display} value={client.display}>
                                            {client.display}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                        {!validAddressId && (
                            <Typography>
                                Please select a client
                            </Typography>
                        )}

                        {validAddressId && balanceData === undefined && !isError && (
                            <LinearProgress />
                        )}

                        {isError && <Typography>An error occurred.</Typography>}
                    </Box>

                    {!isError && balanceData !== undefined && (
                        <>

                            <Box sx={{marginBottom: sectionSpace}}>
                                <Typography variant='h3'>
                                    Balances
                                </Typography>

                                <TableContainer component={Paper} sx={{maxWidth: "50em"}}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{maxWidth: "1em"}}>Product Code</TableCell>
                                                <TableCell sx={{maxWidth: "4em"}}>Product Name</TableCell>
                                                <TableCell sx={{maxWidth: "1em"}}>Registry</TableCell>
                                                <TableCell sx={{maxWidth: "2em"}} align="right">Holdings</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { balanceData.length > 0 ? balanceData.map((row: any, index: number) => (
                                                <TableRow hover key={'accountBalance' + index}>
                                                    <TableCell sx={{maxWidth: "1em"}}>{row.productCode}</TableCell>
                                                    <TableCell sx={{maxWidth: "4em"}}>{row.productName}</TableCell>
                                                    <TableCell sx={{maxWidth: "1em"}}>{row.registryId}</TableCell>
                                                    <TableCell sx={{maxWidth: "2em"}} align="right">
                                                        <AmountFormatWrapper
                                                            amount={row.assignedAmount}
                                                            minDecimalPos={productData.get(row.productId)?.minDecimalPos!}
                                                            maxDecimalPos={productData.get(row.productId)?.maxDecimalPos!} />
                                                    </TableCell>
                                                </TableRow>
                                            )) : appConfigState.getProducts(true).map((p) => (
                                                // Show zero balances for the default products if no balance data is available
                                                <TableRow hover key={'accountBalance' + p.id}>
                                                    <TableCell sx={{maxWidth: "1em"}}>{p.displayCode}</TableCell>
                                                    <TableCell sx={{maxWidth: "4em"}}>{p.displayName}</TableCell>
                                                    <TableCell sx={{maxWidth: "1em"}}>{productData.get(p.id)?.attributes[appConfigState.getAttribute('REGISTRY', 'ID').key]}</TableCell>
                                                    <TableCell sx={{maxWidth: "2em"}} align="right">
                                                        <AmountFormatWrapper 
                                                        amount={0} 
                                                        minDecimalPos={productData.get(p.id)?.minDecimalPos!} 
                                                        maxDecimalPos={productData.get(p.id)?.maxDecimalPos!}/>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>

                            <Typography variant='h3'>
                                Holdings, Transactions and Forward Trades
                            </Typography>

                            <Button variant="outlined" onClick={openRetirementDialog}>Retire</Button>
                                {retirementDialogActive && (
                                    <HoldingRetirement
                                        useDialog={true}
                                        accountId={addressId}
                                        holdings={selectedHoldings}
                                        products={products}
                                        retirementDialogActive={retirementDialogActive}
                                        onRetirementDialogClosed={onRetirementDialogClosed}
                                        preSelectedProjectId={undefined}
                                        preSelectedProjectType={undefined}
                                        preSelectedVintage={undefined}
                                    />
                                )}

                            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
                                <Tabs value={tabValue} onChange={handleTabChange}>
                                    <Tab 
                                        label="Holdings" 
                                        sx={{
                                            ...hoverTabTextColor,
                                            transition: customTheme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            },
                                        }}
                                    />
                                    <Tab 
                                        label="Transactions" 
                                        sx={{
                                            ...hoverTabTextColor,
                                            transition: customTheme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            },
                                        }}
                                    />
                                    <Tab
                                        label="Forward Trades"
                                        sx={{
                                            ...hoverTabTextColor,
                                            transition: customTheme.customProps.navHoverTransition,
                                            '&:hover': {
                                                backgroundColor: customTheme.customProps.navHoverBackgroundColor,
                                            },
                                        }}
                                    />
                                </Tabs>
                            </Box>

                            {tabValue === 0 && (
                                <Holdings
                                    queryType={HoldingQueryType.ACCOUNT_ID}
                                    columnDefinitions={[
                                        {key: 'selectbox', showByDefault: true},
                                        {key: 'holdingId', showByDefault: true},
                                        {key: 'amount', showByDefault: true},
                                        {key: 'product', showByDefault: true},
                                        {key: 'projectId', showByDefault: true},
                                        {key: 'vintage', showByDefault: true},
                                        {key: 'fuelSource', showByDefault: false},
                                        {key: 'creationYear', showByDefault: false},
                                        {key: 'generationYear', showByDefault: false},
                                        {key: 'serialNumRange', showByDefault: true},
                                        {key: 'state', showByDefault: true},
                                        {key: 'info', showByDefault: true}
                                    ]}
                                    // Filters:
                                    accountId={addressId}
                                    // Other:
                                    selectedHoldings={selectedHoldings}
                                    onHoldingSelectionUpdated={onHoldingSelectionUpdated}
                                    refreshSignal={childRefreshSignal}
                                />
                            )}
                            {tabValue === 1 && (
                                <Transactions
                                    accountId={addressId}
                                    products={products}
                                    refreshSignal={childRefreshSignal}
                                />
                            )}
                            {tabValue === 2 && (
                                <Holdings
                                    queryType={HoldingQueryType.FORWARDS}
                                    columnDefinitions={[
                                        {key: 'tradeDate', showByDefault: false},
                                        {key: 'valueDate', showByDefault: true},
                                        {key: 'forwardId', showByDefault: true},
                                        {key: 'tradeId', showByDefault: true},
                                        {key: 'amount', showByDefault: true},
                                        {key: 'product', showByDefault: true},
                                        {key: 'projectType', showByDefault: false},
                                        {key: 'projectId', showByDefault: false},
                                        {key: 'projectName', showByDefault: false},
                                        {key: 'vintage', showByDefault: false},
                                        {key: 'accreditationCode', showByDefault: false},
                                        {key: 'fuelSource', showByDefault: false},
                                        {key: 'creationYear', showByDefault: false},
                                        {key: 'generationYear', showByDefault: false},
                                        {key: 'generationState', showByDefault: false},
                                        {key: 'greenpowerAccredited', showByDefault: false},
                                        {key: 'currency', showByDefault: true},
                                        {key: 'price', showByDefault: true},
                                        {key: 'trader', showByDefault: false},
                                        {key: 'salesPerson', showByDefault: false},
                                        {key: 'salesCredits', showByDefault: false},
                                        {key: 'brokerName', showByDefault: false},
                                        {key: 'brokerage', showByDefault: false},
                                        {key: 'info', showByDefault: true}
                                    ]}
                                    defaultOrdering={`+${appConfigState.getAttribute('TRADE', 'VALUE_DATE').key}`}
                                    // Filters:
                                    accountId={addressId}
                                    holdingState={'Unassigned'}
                                    // Other:
                                    selectedHoldings={[]}
                                    onHoldingSelectionUpdated={undefined}
                                    refreshSignal={childRefreshSignal}
                                />
                            )}
                        </>
                    )}
                </>
            }
        </>
    );
};

export default ClientManagement;
