import { cortenApiKey, cortenApiUrl } from './AppConfig';

const fetchWithCorten = async (path: string, options: RequestInit, encodeUri: boolean = true) => {
    const headers = {
        ...options.headers,
        'X-Trovio-DemoAuthorization': cortenApiKey
    };
    const requestOptions: RequestInit = {
        ...options,
        headers
    };
    return fetch(`${cortenApiUrl}${encodeUri ? encodeURI(path): path}`, requestOptions);
};

const fetchWithCortenAndHandleAbort = async (path: string, options: RequestInit, signal: AbortSignal, encodeUri: boolean = true) => {
    try {
        let response = await fetchWithCorten(path, { ...options, ...(signal && { signal: signal }) }, encodeUri);
        if (!response.ok) throw new Error(`${response.statusText} ${await response.text()}`)
        return response.json();
    } catch (error: any) {
        if (error.toString() !== 'AbortError: The user aborted a request.') {
            throw error;
        }
        return null;
    }
};

const executeTransaction = async(request: any, user: any) => {
    const unsignedRequestData = {
        txId: null,
        accessKeyId: user.user.profile.accountId,
        request: request,
        signatures: []
    };
    return fetchWithCorten(
        `/api/auth/tx/async`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.user.access_token}`
            },
            body: JSON.stringify(unsignedRequestData)
        }
    )
}

export { fetchWithCorten, fetchWithCortenAndHandleAbort, executeTransaction };
