import { Box, Typography } from '@mui/material';
import { useAppConfigState } from '../../state/AppConfig';
import { useProductDispatch, useProductState } from './state/ProductState';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAppNavigate } from '../../component/AppNavigate';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ProductSelector = () => {
    const appConfigState = useAppConfigState();
    const { mblProductOptions, mblSelectedProduct, fetchAndInitProducts } = useProductState();
    const { setMblSelectedProduct, setLoading, setIsPathError } = useProductDispatch();

    const navigate = useAppNavigate();

    // extract the product ID from url
    const location = useLocation();
    const pathname = location.pathname;
    const splitLocation = pathname.split('/');
    const productID = splitLocation[4];

    // fetch products on mount
    useEffect(() => {
        // clear path error
        setIsPathError(false)

        // if theres a product ID in path, check its valid before making any requests
       if ((productID === undefined) || (productID &&  appConfigState.getProducts(true).map(p => p.id).includes(productID))) {
            let currentProductId: string = '';
            // send empty string if there is no defined product id in url
            productID ? (currentProductId = productID) : (currentProductId = '');
            // make request
            fetchAndInitProducts(currentProductId);
       } else {
        // show an error
        setIsPathError(true)
       }
    }, []);

    // handle product selection change from dropdown
    const handleProductChange = (event: any) => {
        setLoading(true);
        // set selected product state
        for (let option of mblProductOptions) {
            if (event.target.value === option.code) {
                setMblSelectedProduct(option);

                // change the url
                navigate(`/inventory-management/product/${option.id}`);
            }
        }
    };

    /**
     * runs when navigating using browser back button
     * 
     * listen for productID changes in the path
     * set selected product according to path productID
     */
    useEffect(() => {
        // productID is read from the path
        if (productID && productID !== mblSelectedProduct.id) {
            // start page loader
            setLoading(true);

            // find the option object corresponding to the productID
            for (let option of mblProductOptions) {
                if (productID === option.id) {
                    // set state
                    setMblSelectedProduct(option)
                }
            }
        }        
    }, [productID]);

    return (
        <>
            {mblSelectedProduct.code !== '' && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: 1
                        }}
                    >
                        <Typography sx={{ marginRight: 2 }}>
                            Code:
                        </Typography>

                        <Select
                            size="small"
                            id="product-code"
                            value={mblSelectedProduct.code}
                            onChange={handleProductChange}
                            sx={{ m: 1, minWidth: 400 }}
                        >
                            {mblProductOptions.map((item, index) => (
                                <MenuItem value={item.code} key={index}>
                                    {item.code}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: 4
                        }}
                    >
                        <Typography sx={{ marginRight: 2 }}>
                            Name:
                        </Typography>
                        <Typography sx={{ marginRight: 2 }}>
                            {mblSelectedProduct.name}
                        </Typography>
                    </Box>
                </>
            )}
        </>
    );
};

export default ProductSelector;
